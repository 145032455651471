import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Plan, PlanUnsaved } from '../services/plan-helpers';
import reflectionImages from '../assets/reflection-images/reflectionImages';
import { EstimateCompletionRates } from './InsightsController';
import PlanScatterCard from './visualizations/PlanScatterCard';
import { FormattedMessage } from 'react-intl';
import sample from 'lodash.sample';
import ReflectionStats from './ReflectionStats';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const moodImageKey = sample(Object.keys(reflectionImages));
// const moodImageKey = Object.keys(reflectionImages)[0];
const moodImage = moodImageKey ? reflectionImages[moodImageKey] : '';

const useStyles = makeStyles(theme => ({
	container: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	title: {
		flexGrow: 1,
	},
	footer: {
		padding: theme.spacing(2),
		position: "fixed",
		bottom: 0,
		width: "100%",
	},
	bottomAppBar: {
		top: 'auto',
		bottom: 0,
	},
	headerBackground: {
		backgroundColor: theme.palette.grey[800],
		color: theme.palette.common.white,
		marginBottom: theme.spacing(4),
		backgroundImage: 'url(https://source.unsplash.com/user/erondu)',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
	},
	headerBackground2: {
		// backgroundColor: theme.palette.grey[800],
		color: theme.palette.common.white,
		backgroundImage: `linear-gradient(135deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.0)),url(${moodImage})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		// width: "100%",
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		marginBottom: theme.spacing(1),
	},
	header: {
		padding: theme.spacing(2),
	},
	section: {
		padding: theme.spacing(2),
	},
	heading: {
		marginBottom: theme.spacing(1),
	},
	bottomGrid: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
		marginTop: theme.spacing(2),
	},
	toggleContainer: {
		// height: 56,
		// padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		// margin: `${theme.spacing(1)}px 0`,
		// background: theme.palette.background.default,
		marginTop: theme.spacing(2),
	},
}));

interface Props {
	plan: Plan | PlanUnsaved,
	onContinue(): void,
	planEstimateCompletionRates: EstimateCompletionRates,
	currentEstimate: number,
	currentPlanningStreakLength: number,
	longestPlanningStreakLength: number,
	reflectionRateNumerator: number,
	reflectionRateDenominator: number,
	imageId?: string,
}

function ReflectionWizardInsights(props: Props) {
	const {
		plan,
		onContinue,
		currentPlanningStreakLength, longestPlanningStreakLength,
		imageId,
	 } = props;
	const classes = useStyles();

	return (
		<React.Fragment>
			<Box className={classes.headerBackground2}
				style={imageId ? {backgroundImage: `linear-gradient(135deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.0)),url(${reflectionImages[imageId]})`} : undefined}
				>
				<Container maxWidth="sm">
					<Box className={classes.header}>
						<Typography variant="h4" color="inherit" className={classes.heading}><FormattedMessage id="reflectionWizard.insights.title" defaultMessage="Well done!"/></Typography>
						<Typography variant="body1" color="inherit"><FormattedMessage id="reflectionWizard.insights.subtitle" defaultMessage="Your reflection is complete."/></Typography>
					</Box>
				</Container>
			</Box>
			<Container maxWidth="sm">
				<Box className={classes.header}>
					<Card>
						<CardContent>
							<ReflectionStats
								numerator={props.reflectionRateNumerator}
								denominator={props.reflectionRateDenominator}
								streakCurrent={currentPlanningStreakLength}
								streakMax={longestPlanningStreakLength}
								planType={plan.type}
							/>
						</CardContent>
					</Card>
					{/* <StreaksReflection type={plan.type} currentStreakLength={currentPlanningStreakLength} longestStreakLength={longestPlanningStreakLength}/> */}
				</Box>
				{props.currentEstimate > 0 && 
					<Box className={classes.header}>
						<PlanScatterCard estimateCompletionRates={props.planEstimateCompletionRates} highlightEstimate={props.currentEstimate}/>
					</Box>
				}
				{/* <Box className={classes.header}>
					- Percentage change vs. average<br/>
					- Streak length<br/>
					<br/>
					- percent completed vs 1/7/30<br/>
					- day satisfaction vs 1/7/30<br/>
					- plan satisfaction vs 1/7/30<br/>
					- hours completed vs yesterday, past 7 days, past 30 days<br/>
				</Box> */}

				<Grid container justify="space-between" className={classes.bottomGrid}>
					<Grid item><Button variant="contained" color="primary" onClick={onContinue}><FormattedMessage id="reflectionWizard.insights.close" defaultMessage="Close"/></Button></Grid>
				</Grid>
			</Container>
		</React.Fragment>
	);
}

export default ReflectionWizardInsights;