import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import { Plan, PlanUnsaved } from '../services/plan-helpers';


const useStyles = makeStyles(theme => ({
	container: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	title: {
		flexGrow: 1,
	},
	footer: {
		padding: theme.spacing(2),
		position: "fixed",
		bottom: 0,
		width: "100%",
	},
	bottomAppBar: {
		top: 'auto',
		bottom: 0,
	},
	header: {
		padding: theme.spacing(2),
	},
	section: {
		padding: theme.spacing(2),
	},
	heading: {
		marginBottom: theme.spacing(1),
	},
	bottomGrid: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		marginTop: theme.spacing(2),
	},
	toggleContainer: {
		// height: 56,
		// padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		// margin: `${theme.spacing(1)}px 0`,
		// background: theme.palette.background.default,
		marginTop: theme.spacing(2),
	},
}));

interface Props {
	plan: Plan | PlanUnsaved,
	onSavePlan(plan: Plan | PlanUnsaved): void,
	onContinue(): void,
	onBack(): void,
}

function ReflectionWizardRatings(props: Props) {
	const { onContinue, onBack } = props;

	const classes = useStyles();

	const [planRatingBinary, setPlanRatingBinary] = useState(props.plan.planSatisfactory);
	const [periodRatingBinary, setPeriodRatingBinary] = useState(props.plan.periodSatisfactory);

	const handlePeriodRatingBinary = (event: unknown, rating: boolean) => {
		setPeriodRatingBinary(rating);
		props.onSavePlan(Object.assign({}, props.plan, {
			periodSatisfactory: rating,
		}));
	};
	const handlePlanRatingBinary = (event: unknown, rating: boolean) => {
		setPlanRatingBinary(rating);
		props.onSavePlan(Object.assign({}, props.plan, {
			planSatisfactory: rating,
		}));
	}



	return (
		<Container maxWidth="sm" className={classes.container}>
			<Box className={classes.header}>
				<Typography variant="h4" color="inherit" className={classes.heading}><FormattedMessage id="reflectionWizard.ratings.title" defaultMessage="Rate your {type}" values={{type: props.plan.type}}/></Typography>
				<Typography variant="body1" color="inherit"><FormattedMessage id="reflectionWizard.ratings.description" defaultMessage="Have you been satisfied with your {type}? We will show you how your {type}s developed over the course of time." values={{type: props.plan.type}}/></Typography>
			</Box>

			<Paper elevation={0} className={classes.section}>
				<Typography variant="subtitle1"><FormattedMessage id="plan.reflection.ratingPeriod" defaultMessage="How was your {type}?" values={{type: props.plan.type}}/></Typography>
				<div className={classes.toggleContainer}>
					<ToggleButtonGroup value={periodRatingBinary} exclusive onChange={handlePeriodRatingBinary}>
						<ToggleButton value={false}>
							<ThumbDownIcon />
						</ToggleButton>
						<ToggleButton value={true}>
							<ThumbUpIcon />
						</ToggleButton>
					</ToggleButtonGroup>
				</div>
			</Paper>
			<Paper elevation={0} className={classes.section}>
				<Typography variant="subtitle1"><FormattedMessage id="plan.reflection.ratingPlan" defaultMessage="How was your plan?"/></Typography>
				<div className={classes.toggleContainer}>
					<ToggleButtonGroup value={planRatingBinary} exclusive onChange={handlePlanRatingBinary}>
						<ToggleButton value={false}><ThumbDownIcon /></ToggleButton>
						<ToggleButton value={true}><ThumbUpIcon /></ToggleButton>
					</ToggleButtonGroup>
				</div>
			</Paper>

			<Grid container justify="space-between" className={classes.bottomGrid}>
				<Grid item><Button variant="text" color="default" onClick={onBack}><FormattedMessage id="reflectionWizard.back" defaultMessage="Back"/></Button></Grid>
				<Grid item><Button variant="contained" color="primary" onClick={onContinue}><FormattedMessage id="reflectionWizard.continue" defaultMessage="Continue"/></Button></Grid>
			</Grid>
		</Container>
	);
}

export default ReflectionWizardRatings;