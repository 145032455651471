import iIjx8OxvKrgM from './david-gavi-Ijx8OxvKrgM-unsplash.landscape2.jpg';
import ipV_zJiLiD0 from './marvin-meyer--pV_zJiLiD0-unsplash.landscape.jpg';
import i20190921_124041 from './2019-09-21-124043-IMG_20190921_124041.landscape.jpg';
import i20190921_123234 from './2019-09-21-123236-IMG_20190921_123234.landscape.jpg';
import Goirt63y1 from './samuel-bordo-Goirt63y1-8-unsplash.landscape.jpg';
import fItRJ7AHak8 from './galen-crout-fItRJ7AHak8-unsplash.landscape.jpg';
import DSC_2771 from './20160704-2004--DSC_2771.landscape.jpg';
// import test from './david-gavi-Ijx8OxvKrgM-unsplash.landscape2.jpg';

const reflectionImages: {[key:string]: string} = {
    // "test": test,
    "20160704-2004--DSC_2771": DSC_2771,
    "tegalen-crout-fItRJ7AHak8-unsplash": fItRJ7AHak8,
    "samuel-bordo-Goirt63y1-8-unsplash": Goirt63y1,
    "20190921_124041": i20190921_124041,
    "20190921_123234": i20190921_123234,
    "david-gavi-Ijx8OxvKrgM-unsplash": iIjx8OxvKrgM,
    "marvin-meyer--pV_zJiLiD0-unsplash": ipV_zJiLiD0,
};
export default reflectionImages;
