import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl, defineMessages } from 'react-intl';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PlanScatterCard from './visualizations/PlanScatterCard';
import ObjectiveScatterCard from './visualizations/ObjectiveScatterCard';
import CompletionByGoalBars from './visualizations/CompletionByGoalBars';
import { FormattedMessage } from 'react-intl';
import { InsightsPlanType, EstimateCompletionRates } from './InsightsController';
import { PlanType, RatingsData } from '../services/plan-helpers';
import PlanningStats from './PlanningStats';
import { FailedReasonCounted } from '../services/objective-helpers';
import RatingsCurve from './visualizations/RatingsCurve';
import ButtonDialog from './ButtonDialog';
import ReflectionStats from './ReflectionStats';

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(1),
	},
	gridContainer: {
		padding: theme.spacing(1),
		marginBottom: theme.spacing(8),
	},
	divider: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	tabs: {
		position: "sticky",
		top: 0,
		zIndex: 9,
	},
	navContainer: {
		paddingLeft: 0,
		paddingRight: 0,
	},
}));

const messages = defineMessages({
	year: {
		id: 'period.year',
		defaultMessage: 'Year',
	},
	month: {
		id: 'period.month',
		defaultMessage: 'Month',
	},
	week: {
		id: 'period.week',
		defaultMessage: 'Week',
	},
	day: {
		id: 'period.day',
		defaultMessage: 'Day',
	},
	overall: {
		id: 'insights.overall',
		defaultMessage: 'Overall',
	},
	streakTitle: {
		id: 'insights.streakTitle',
		defaultMessage: '{count, number} days and counting…',
	},
	streakSubheader: {
		id: 'insights.streakSubheader',
		defaultMessage: 'You have fully achieved your objectives in the past {current, number} times. Your record is {record, number} times in a row.',
	},
});

interface InsightsViewProps {
	onSetType(type: InsightsPlanType): void;
	type: InsightsPlanType;
	goalStats: Array<unknown>;
	allReflectionInsights: Array<string>;
	allGoodThings: Array<string>;
	allBadThings: Array<string>;
	failedReasons: FailedReasonCounted[];
	cycleStreakCurrent: number,
	cycleStreakMax: number,
	planningStreakCurrent: number,
	planningStreakMax: number,
	planningRateDenominator: number,
	planningRateNumerator: number,
	reflectionRateNumerator: number,
	planEstimateCompletionRates: EstimateCompletionRates;
	objectiveEstimateCompletionRates: EstimateCompletionRates;
	ratingsData: RatingsData;
}

function InsightsView(props: InsightsViewProps) {

	const { onSetType, type } = props;
	const classes = useStyles();
	const { formatMessage } = useIntl();

	const typeWithoutAll = type === "all" ? PlanType.day : type;

	return (
		<React.Fragment>
			<Container maxWidth="md" className={classes.navContainer}>
				<Paper elevation={2} square className={classes.tabs}>
					<Tabs
						value={type}
						indicatorColor="secondary"
						textColor="secondary"
						onChange={(event, value) => onSetType(value)}
						// variant="scrollable"
						variant="fullWidth"
						scrollButtons="auto"
					>
						{/* <Tab value="all" label={formatMessage(messages.overall)} /> */}
						<Tab value="day" label={formatMessage(messages.day)} />
						<Tab value="week" label={formatMessage(messages.week)} />
						<Tab value="month" label={formatMessage(messages.month)} />
						{/* <Tab value="quarter" label="Quarter" /> */}
						<Tab value="year" label={formatMessage(messages.year)} />
					</Tabs>
				</Paper>
			</Container>
			<Container maxWidth="sm">

				<div className={classes.gridContainer}>
				<Grid container spacing={1}>

					<Grid item xs={12}>
						<Card>
							<CardContent>
								<PlanningStats
									numerator={props.planningRateNumerator}
									denominator={props.planningRateDenominator}
									streakCurrent={props.planningStreakCurrent}
									streakMax={props.planningStreakMax}
									planType={typeWithoutAll}
								/>
							</CardContent>
						</Card>
					</Grid>

					<Grid item xs={12}>
						<Card>
							<CardContent>
								<ReflectionStats
									numerator={props.reflectionRateNumerator}
									denominator={props.planningRateDenominator}
									streakCurrent={props.cycleStreakCurrent}
									streakMax={props.cycleStreakMax}
									planType={typeWithoutAll}
								/>
							</CardContent>
						</Card>
					</Grid>

					{/* <Grid item xs={12}>
						<StreaksReflection type={typeWithoutAll} longestStreakLength={props.cycleStreakMax} currentStreakLength={props.cycleStreakCurrent}/>
					</Grid> */}

					<Grid item xs={12}>
						<Card>
							<CardHeader
								title={
									<>
									<FormattedMessage id="insights.ratings.title" defaultMessage="Satisfaction" values={{type: type}}/>
									<ButtonDialog analyticsId="planningstats-info">
										<Typography variant="h5" gutterBottom><FormattedMessage id="insights.ratings.help.title" defaultMessage="How satisfaction stats are calculated"/></Typography>
										<Typography variant="body1" paragraph><FormattedMessage id="insights.ratings.help.p1" defaultMessage="During reflection, you can specify if you have been happy with your {type} and plan (separately)." values={{type: type}}/></Typography>
										<Typography variant="body1" paragraph><FormattedMessage id="insights.ratings.help.p2" defaultMessage="This graph shows your satisfaction as a moving average. Each point represents the average satisfaction of that {type} and the three ones before." values={{type: type}}/></Typography>
									</ButtonDialog>
									</>
								}
								subheader={<FormattedMessage id="insights.ratings.subheader" defaultMessage="How happy were you, on average, with plan &amp; {type}?" values={{type: type}}/>}
							/>
							<CardContent>
								<RatingsCurve data={props.ratingsData}/>
							</CardContent>
						</Card>
					</Grid>

					<Grid item xs={12}>
						<Card>
							<CardHeader
								title={<FormattedMessage id="insights.timePerGoalTitle" defaultMessage="Time per goal"/>}
								subheader={<FormattedMessage id="insights.timePerGoalSubheader" defaultMessage="How much time do you estimate to spend on each goal"/>}
							/>
							<CardContent>
								<CompletionByGoalBars data={props.goalStats} time={true}/>
							</CardContent>
						</Card>
					</Grid>

					<Grid item xs={12}>
						<Card>
							<CardHeader
								title={<FormattedMessage id="insights.completionRatePerGoalTitle" defaultMessage="Completion rate per goal"/>}
								subheader={<FormattedMessage id="insights.completionRatePerGoalSubheader" defaultMessage="What percentage of objectives do you complete for each goal"/>}
							/>
							<CardContent>
								<CompletionByGoalBars data={props.goalStats} time={false}/>
							</CardContent>
						</Card>
					</Grid>

					<Grid item xs={12}>
						<PlanScatterCard estimateCompletionRates={props.planEstimateCompletionRates}/>
					</Grid>

					<Grid item xs={12}>
						<ObjectiveScatterCard estimateCompletionRates={props.objectiveEstimateCompletionRates}/>
					</Grid>

					<Grid item xs={12}>
						<Card>
							<CardHeader
								title={<FormattedMessage id="insights.insightsTitle" defaultMessage="Improvements"/>}
								subheader={<FormattedMessage id="insights.insightsSubheader" defaultMessage="Your insights for self-improvement"/>}
							/>
							<CardContent>
								<List component="nav" dense={true}>
									{props.allReflectionInsights.map(thing => <ListItem key={thing}><ListItemText primary={'"' + thing + '"'}/></ListItem>)}
								</List>
								{props.allReflectionInsights.length === 0 && <Typography variant="body1"><FormattedMessage id="insights.nolog" defaultMessage="You haven't noted anything, yet."/></Typography>}
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12}>
						<Card>
							<CardHeader
								title={<FormattedMessage id="insights.goodThingsTitle" defaultMessage="That went well"/>}
								subheader={<FormattedMessage id="insights.goodThingsSubheader" defaultMessage="Positive experiences that you have recorded"/>}
							/>
							<CardContent>
								<List component="nav" dense={true}>
									{props.allGoodThings.map(thing => <ListItem key={thing}><ListItemText primary={'"' + thing + '"'}/></ListItem>)}
								</List>
								{props.allGoodThings.length === 0 && <Typography variant="body1"><FormattedMessage id="insights.nolog" defaultMessage="You haven't noted anything, yet."/></Typography>}
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12}>
						<Card>
							<CardHeader
								title={<FormattedMessage id="insights.badThingsTitle" defaultMessage="Impediments"/>}
								subheader={<FormattedMessage id="insights.badThingsSubheader" defaultMessage="Things that didn't go so well"/>}
							/>
							<CardContent>
								<List component="nav" dense={true}>
									{props.allBadThings.map(thing => <ListItem key={thing}><ListItemText primary={'"' + thing + '"'}/></ListItem>)}
								</List>
								{props.allBadThings.length === 0 && <Typography variant="body1"><FormattedMessage id="insights.nolog" defaultMessage="You haven't noted anything, yet."/></Typography>}
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12}>
						<Card>
							<CardHeader
								title={<FormattedMessage id="insights.failedReasons.title" defaultMessage="Missed objectives: What happened?"/>}
								subheader={<FormattedMessage id="insights.failedReasons.subheader" defaultMessage="Sorted by number of occurrences"/>}
							/>
							<CardContent>
								<List component="nav" dense={true}>
									{props.failedReasons.map(item => <ListItem key={item.reason}><ListItemText primary={'"' + item.reason + '" ('+item.count+'×)'}/></ListItem>)}
								</List>
								{props.failedReasons.length === 0 && <Typography variant="body1"><FormattedMessage id="insights.nolog" defaultMessage="You haven't noted anything, yet."/></Typography>}
							</CardContent>
						</Card>
					</Grid>

				</Grid>
				</div>
			</Container>
		</React.Fragment>
	);
}

// InsightsView.propTypes = {
// 	goalStats: PropTypes.array.isRequired,
// 	onSetType: PropTypes.func.isRequired,
// };

export default InsightsView;