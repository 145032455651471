import React, { useState } from 'react';
import logger from '../services/logger';
import { useSnackbar } from 'notistack';
import { defineMessages, useIntl } from 'react-intl';
import SignUpTeaser from './SignUpTeaser';
import { SignUpOptins } from './AuthController';
// import SignUpProviderChoice from './SignUpProviderChoice';


interface TeaserValidationErrors {
	optinTOSPP?: string,
}

const messages = defineMessages({
	validationErrorOptinTOSPP: {
		id: "signup.validation.optinTOSPP",
		defaultMessage: "Please agree to our terms.",
	},
});

interface Props {
	onFacebook(optins: SignUpOptins): void,
	onApple(optins: SignUpOptins): void,
	onGoogle(optins: SignUpOptins): void,
	onEmailSignUp(optins: SignUpOptins): void,
	onEmailSignIn(): void,
}

export default function SignUpTeaserController(props: Props) {

	const [validationErrors, setValidationErrors] = useState<TeaserValidationErrors>({});
	const [optinTOSPP, setOptinTOSPP] = useState(false);
	const [optinMarketing, setOptinMarketing] = useState(false);
	const { formatMessage } = useIntl();

	const { enqueueSnackbar } = useSnackbar();

	const validateTeaser = () => {
		const errors: TeaserValidationErrors = {};
		if (!optinTOSPP)
			errors.optinTOSPP = formatMessage(messages.validationErrorOptinTOSPP);
		
		setValidationErrors(errors);
		logger.debug('Validated', errors);
		if (Object.keys(errors).length > 0) {
			const errorMessages = Object.values(errors);
			enqueueSnackbar(errorMessages.join(' '), {
				variant: "error",
			});
		}
		return errors;
	}

	const validateAndProceed = (callback: (optins: SignUpOptins) => void) => {
		const errors = validateTeaser();
		if (Object.keys(errors).length === 0)
			callback({
				optinTOSPP: optinTOSPP,
				optinMarketing: optinMarketing,
			});
	}

	return <SignUpTeaser
		onSignIn={props.onEmailSignIn}
		onSignUp={() => validateAndProceed(props.onEmailSignUp)}
		onFacebook={() => validateAndProceed(props.onFacebook)}
		onApple={() => validateAndProceed(props.onApple)}
		onGoogle={() => validateAndProceed(props.onGoogle)}
		onOptinMarketingChange={b => setOptinMarketing(b)}
		onOptinTOSPPChange={b => setOptinTOSPP(b)}
		validationErrors={validationErrors}
	/>;

}
