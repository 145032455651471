import i1119678 from '../../assets/goal-images/chris-ainsworth-1119678-unsplash.1920.jpg';
import i669210 from '../../assets/goal-images/edgar-chaparro-669210-unsplash.1920.jpg';
import i1052516 from '../../assets/goal-images/jp-valery-1052516-unsplash.1920.jpg';
import i127432 from '../../assets/goal-images/larisa-birta-127432-unsplash.1920.jpg';
import i335248 from '../../assets/goal-images/lucas-favre-335248-unsplash.1920.jpg';
import i1113391 from '../../assets/goal-images/shifaaz-shamoon-1113391-unsplash.1920.jpg';

const goalImages: {[key:string]: string} = {
    "chris-ainsworth-1119678-unsplash": i1119678,
    "edgar-chaparro-669210-unsplash": i669210,
    "jp-valery-1052516-unsplash": i1052516,
    "larisa-birta-127432-unsplash": i127432,
    "lucas-favre-335248-unsplash": i335248,
    "shifaaz-shamoon-1113391-unsplash": i1113391,
};
export default goalImages;