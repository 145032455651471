import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';
import CenterFocusWeakIcon from '@material-ui/icons/CenterFocusWeak';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import goalImages from '../assets/goal-images/goalImages';
import ConfirmationDialog from '../ConfirmationDialog';
import { List } from '../services/list-helpers';
import { Objective } from '../services/objective-helpers';
import ObjectivesCard from './ObjectivesCard';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles(theme => ({
	toolbarTitle: {
		flexGrow: 1,
	},
	goalTitle: {
		textOverflow: "ellipsis",
		overflow: "hidden",
	},
	headerBackground: {
		// backgroundColor: theme.palette.grey[800],
		color: theme.palette.common.white,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		// width: "100%",
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		marginBottom: theme.spacing(1),
	},
	section: {
		padding: theme.spacing(2),
	},
	preWrapped: {
		whiteSpace: "pre-wrap",
	},
	fab: {
		position: 'fixed',
		bottom: "calc(56px + "+theme.spacing(2)+"px + env(safe-area-inset-bottom))",
		right: theme.spacing(2),
		// color: theme.palette.common.white,
		fallbacks: {
			bottom: "calc(56px + "+theme.spacing(2)+"px)",
		},
	},
	content: {
		paddingBottom: theme.spacing(8),
	},
	objectiveSelection: {
		marginBottom: theme.spacing(2),
	},
}));

enum TabViews {
	objectivesCurrent = "objectives-current",
	objectivesPast = "objectives-past",
	about = "about",
}

interface Props {
	onBack(): void,
	onEdit(): void,
	onDelete(goal: List): void,
	onSetArchived(goal: List, archived: boolean): void,
	onSetFocus(goal: List, focus: boolean): void,
	goal: List | null,
	objectivesOverdue: Objective[],
	objectivesUnscheduled: Objective[],
	objectivesScheduled: Objective[],
	objectivesCompletedOrFailed: Objective[],
	onCheckObjective(objective: Objective): void,
	onFailObjective(objective: Objective): void,
	onUncheckObjective(objective: Objective): void,
	onEditObjective(objective: Objective): void,
	onAddUnscheduledObjective(): void,
	showFocusSetting: boolean,
}

export default function GoalDetailViewV2(props: Props) {
	const { 
		goal,
		onBack,
		onEdit,
		onDelete,
		onSetArchived,
		onCheckObjective,
		onFailObjective,
		onUncheckObjective,
		onEditObjective,
		onAddUnscheduledObjective,
	} = props;
	const classes = useStyles();
	const handleDelete = () => {setDeleteConfirmationOpen(true)};
	const handleDeleteCancel = () => {setDeleteConfirmationOpen(false)};
	const [deleteConfimationOpen, setDeleteConfirmationOpen] = useState(false);
	const [archiveConfirmationOpen, setArchiveConfirmationOpen] = useState(false);
	const [unfocusConfirmationOpen, setUnfocusConfirmationOpen] = useState(false);
	const [view, setView] = useState(TabViews.objectivesCurrent);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const menuHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
  
	const menuHandleClose = () => {
		setAnchorEl(null);
	};

	// console.log('unscheduledObjectives', unscheduledObjectives, unscheduledObjectives.length);

	return (
		<React.Fragment>
			<AppBar position="static">
				<Toolbar>
				
					<IconButton color="inherit" onClick={onBack}>
						<ArrowBackIcon />
					</IconButton>
					<Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
						{/* <FormattedMessage id="goals.detail.title" defaultMessage="Your Goal"/> */}
					</Typography>
					{goal &&
						<React.Fragment>
							<Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={menuHandleClose}>
								<MenuItem onClick={onEdit}>
									<ListItemIcon>
										<EditIcon fontSize="small" />
									</ListItemIcon>
									<ListItemText primary="Edit…" />
								</MenuItem>
								{props.showFocusSetting && !goal.focus &&
									<MenuItem onClick={() => {props.onSetFocus(goal, true); setAnchorEl(null);}}>
										<ListItemIcon>
											<CenterFocusStrongIcon fontSize="small" />
										</ListItemIcon>
										<ListItemText primary="Set Focus" />
									</MenuItem>
								}
								{props.showFocusSetting && goal.focus &&
									<MenuItem onClick={() => setUnfocusConfirmationOpen(true)}>
										<ListItemIcon>
											<CenterFocusWeakIcon fontSize="small" />
										</ListItemIcon>
										<ListItemText primary="Remove Focus…" />
									</MenuItem>
								}
								{!goal.archived &&
									<MenuItem onClick={() => setArchiveConfirmationOpen(true)}>
										<ListItemIcon>
											<ArchiveIcon fontSize="small" />
										</ListItemIcon>
										<ListItemText primary="Archive…" />
									</MenuItem>
								}
								{goal.archived &&
									<MenuItem onClick={() => onSetArchived(goal, false)}>
										<ListItemIcon>
											<UnarchiveIcon fontSize="small" />
										</ListItemIcon>
										<ListItemText primary="Unarchive" />
									</MenuItem>
								}
								<MenuItem onClick={handleDelete}>
									<ListItemIcon>
										<DeleteIcon fontSize="small" />
									</ListItemIcon>
									<ListItemText primary="Delete…" />
								</MenuItem>
							</Menu>
							<Button color="inherit" onClick={menuHandleClick}>
								<MoreVertIcon/>
							</Button>
							<ConfirmationDialog
								open={deleteConfimationOpen}
								title={<FormattedMessage id="goals.delete.confirmationTitle" defaultMessage="Delete this goal and associated objectives?"/>}
								contentText={<FormattedMessage id="goals.delete.confirmationTitle" defaultMessage="Deleting can not be undone."/>}
								cancelButtonText={<FormattedMessage id="goals.delete.confirmationCancel" defaultMessage="Cancel"/>}
								confirmationButtonText={<FormattedMessage id="goals.delete.confirmationConfirm" defaultMessage="Delete"/>}
								onCancel={handleDeleteCancel}
								onConfirm={() => onDelete(goal)}
							/>
							<ConfirmationDialog
								open={archiveConfirmationOpen}
								title={<FormattedMessage id="goals.archive.confirmationTitle" defaultMessage="Archive this goal and associated objectives?"/>}
								contentText={<FormattedMessage id="goals.archive.confirmationTitle" defaultMessage="You can restore archived goals at any time."/>}
								cancelButtonText={<FormattedMessage id="goals.archive.confirmationCancel" defaultMessage="Cancel"/>}
								confirmationButtonText={<FormattedMessage id="goals.archive.confirmationConfirm" defaultMessage="Delete"/>}
								onCancel={() => setArchiveConfirmationOpen(false)}
								onConfirm={() => onSetArchived(goal, true)}
							/>
							<ConfirmationDialog
								open={unfocusConfirmationOpen}
								title={<FormattedMessage id="goals.unfocus.confirmationTitle" defaultMessage="Remove goal from focus"/>}
								contentText={<FormattedMessage id="goals.unfocus.confirmationTitle" defaultMessage="If you remove focus from this goal, it will no longer have its own area in your plans. You can undo this at any time."/>}
								cancelButtonText={<FormattedMessage id="goals.archive.confirmationCancel" defaultMessage="Cancel"/>}
								confirmationButtonText={<FormattedMessage id="goals.unfocus.confirmationConfirm" defaultMessage="Remove focus"/>}
								onCancel={() => setUnfocusConfirmationOpen(false)}
								onConfirm={() => {props.onSetFocus(goal, false); setUnfocusConfirmationOpen(false); setAnchorEl(null);}}
							/>
						</React.Fragment>
					}
				</Toolbar>
			</AppBar>

			<Box className={classes.headerBackground} style={{backgroundImage: `linear-gradient(135deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.0)),url(${goalImages[goal ? goal.image : 0]})`,}}>
				<Container maxWidth="sm">
					<Box className={classes.section}>
						<Typography variant="h4" className={classes.goalTitle}>{goal ? goal.title : <FormattedMessage id="plan.otherTitle" defaultMessage="Other"/>}</Typography>
					</Box>
				</Container>
			</Box>



			<Container maxWidth="sm" className={classes.content}>

				<Tabs value={view} onChange={(event, value) => setView(value)}>
					<Tab label={<FormattedMessage id="GoalDetailView.current" defaultMessage="Current"/>} value="objectives-current"/>
					<Tab label={<FormattedMessage id="GoalDetailView.past" defaultMessage="Past"/>} value="objectives-past"/>
					<Tab label={<FormattedMessage id="GoalDetailView.about" defaultMessage="About"/>} value="about"/>
				</Tabs>

				{view === TabViews.objectivesCurrent &&
					<>
						<Box marginBottom={4}></Box>
						<ObjectivesCard
							title={<FormattedMessage id="GoalDetailView.overdue" defaultMessage="Overdue"/>}
							objectives={props.objectivesOverdue}
							overarchingObjectives={[]}
							overarchingPlanTitle={""}
							onCheckObjective={onCheckObjective}
							onFailObjective={onFailObjective}
							onUncheckObjective={onUncheckObjective}
							onAddObjective={onAddUnscheduledObjective}
							onEditObjective={onEditObjective}
							editable={false}
							variant="with-schedule"
						/>

						<ObjectivesCard
							title={<FormattedMessage id="GoalDetailView.scheduled" defaultMessage="Scheduled"/>}
							objectives={props.objectivesScheduled}
							overarchingObjectives={[]}
							overarchingPlanTitle={""}
							onCheckObjective={onCheckObjective}
							onFailObjective={onFailObjective}
							onUncheckObjective={onUncheckObjective}
							onAddObjective={onAddUnscheduledObjective}
							onEditObjective={onEditObjective}
							editable={false}
							variant="with-schedule"
						/>

						<ObjectivesCard
							title={<FormattedMessage id="GoalDetailView.unscheduled" defaultMessage="Unscheduled"/>}
							objectives={props.objectivesUnscheduled}
							overarchingObjectives={[]}
							overarchingPlanTitle={""}
							onCheckObjective={onCheckObjective}
							onFailObjective={onFailObjective}
							onUncheckObjective={onUncheckObjective}
							onAddObjective={onAddUnscheduledObjective}
							onEditObjective={onEditObjective}
							editable={false}
							variant="with-schedule"
						/>

					</>
				}

				{view === TabViews.objectivesPast &&
					<>
						<Box marginBottom={4}></Box>
						<ObjectivesCard
							title={<FormattedMessage id="GoalDetailView.completedOrFailed" defaultMessage="Completed or failed"/>}
							objectives={props.objectivesCompletedOrFailed}
							overarchingObjectives={[]}
							overarchingPlanTitle={""}
							onCheckObjective={onCheckObjective}
							onFailObjective={onFailObjective}
							onUncheckObjective={onUncheckObjective}
							onAddObjective={onAddUnscheduledObjective}
							onEditObjective={onEditObjective}
							editable={false}
							variant="with-schedule"
						/>
					</>
				}

				{view === TabViews.about &&
					<>
						{goal && goal.description &&
							<Box className={classes.section}>
								<Typography variant="body1" className={classes.preWrapped}>{goal.description}</Typography>
							</Box>
						}
						{goal && goal.motivationWhy &&
							<Box className={classes.section}>
								<Typography variant="h5" gutterBottom><FormattedMessage id="goals.motivationTitle" defaultMessage="Motivation"/></Typography>
								<Typography variant="body1" className={classes.preWrapped}>{goal.motivationWhy}</Typography>
							</Box>
						}
						{goal && <>
							<Box className={classes.section}>
								<Button
									variant="contained"
									// color="primary"
									// className={classes.button}
									startIcon={<EditIcon />}
									onClick={onEdit}
									>
									Edit
								</Button>
							</Box>
						</>}
					</>
				}
			</Container>
			{goal &&
				<Fab color="primary" aria-label="add" className={classes.fab} onClick={onAddUnscheduledObjective}>
					<AddIcon />
				</Fab>
			}
		</React.Fragment>
	);
}
