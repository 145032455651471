import t from 'tcomb-validation';
import { parseISO } from 'date-fns';

export const Null = t.refinement(t.Any, (item) => item === null, 'Null');
export const StringNonEmpty = t.refinement(t.String, (s) => s.length > 0, 'StringNonEmpty');
export const StringOrNull = t.refinement(t.Any, (s) => s === null || (typeof s === "string" && s.length > 0), 'StringOrNull');
// export const StringNonEmptyOrNull = t.refinement(t.String, (s) => (s === null) || (s.length > 0), 'StringNonEmptyOrNull');
export const StringDateOrNull = t.refinement(t.Any, (s) => s === null || (typeof s === "string" && parseISO(s) instanceof Date), 'StringDate');
export const dayPeriod = t.refinement(t.Any, (s) => (typeof s === "string" && !isNaN(parseISO(s).valueOf()) && /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(s)), 'dayPeriod');
export const weekPeriod = t.refinement(t.Any, (s) => (typeof s === "string" && !isNaN(parseISO(s).valueOf()) && /^[0-9]{4}-W[0-9]{2}$/.test(s)), 'weekPeriod');
export const monthPeriod = t.refinement(t.Any, (s) => (typeof s === "string" && !isNaN(parseISO(s).valueOf()) && /^[0-9]{4}-[0-9]{2}$/.test(s)), 'monthPeriod');
export const yearPeriod = t.refinement(t.Any, (s) => (typeof s === "string" && !isNaN(parseISO(s).valueOf()) && /^[0-9]{4}$/.test(s)), 'yearPeriod');
