import React from 'react';
// import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Avatar from '@material-ui/core/Avatar';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import MenuItem from '@material-ui/core/MenuItem';
import { PeriodType, WeekDay, allWeekDays, MonthDayType } from '../services/recurring-helpers';



const useStyles = makeStyles(theme => ({
	headingGrid: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(2),
	},
	periodControls: {
		textAlign: "right"
	},
	back: {
		textAlign: "left"
	},
	forward: {
		textAlign: "right"
	},
	title: {
		textAlign: "center"
	},
	periodChooser: {
		textAlign: "center",
	},
	periodChooserButton: {
		height: "40px",
	},
	unscheduled: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(2),
		textAlign: "center",
		height: "48px",
		boxSizing: "content-box",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	defaultSection: {
		marginBottom: theme.spacing(2),
	},
	formControl: {
		// margin: theme.spacing(1),
		// minWidth: 120,
		marginTop: -3.5,
		marginLeft: theme.spacing(1),
	  },
	  selectEmpty: {
		// marginTop: -8,
	},
	weekDayBox: {
		display: "flex",
		'& > *': {
			marginRight: theme.spacing(1),
		},
		marginBottom: theme.spacing(2),
	},
	weekDaysInactive: {
		width: theme.spacing(4),
		height: theme.spacing(4),
		fontSize: "14px",
		cursor: "pointer",
	},
	weekDaysActive: {
		width: theme.spacing(4),
		height: theme.spacing(4),
		fontSize: "14px",
		cursor: "pointer",
		backgroundColor: theme.palette.secondary.main,
	},
}));

interface Props {
	onCancel(): void,
	onSave(): void,
	onPeriodChange(type: PeriodType): void,
	onFrequencyChange(frequency: number): void,
	onToggleWeekday(day: WeekDay): void,
	frequency: number,
	periodType: PeriodType,
	availablePeriodTypes: PeriodType[],
	showWeekdaySelector: boolean,
	showMonthDaySelector: boolean,
	showYearDaySelector: boolean,
	monthDayType: MonthDayType | null,
	yearDayType: string,
	monthDay: number,
	monthDayCount: number,
	weekDays: WeekDay[],
	weekDay: string,
	month: string,
	testEnvironment?: boolean,
}

export default function RecurringObjectiveDialog(props: Props) {
	const {
		onCancel,
		onSave,
		availablePeriodTypes,
		periodType,
		showWeekdaySelector,
		showMonthDaySelector,
		showYearDaySelector,
		monthDayType,
		yearDayType,
		monthDay,
		monthDayCount,
		weekDay,
		month,
		weekDays,
		testEnvironment,
		onPeriodChange,
		frequency,
		onFrequencyChange,
		onToggleWeekday,
	} = props;
	// const theme = useTheme();
	// const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const periodTypes = [
		{id: PeriodType.days, label: <FormattedMessage id="RecurringObjectiveDialog.days" defaultMessage="days"/>}, 
		{id: PeriodType.weeks, label: <FormattedMessage id="RecurringObjectiveDialog.weeks" defaultMessage="weeks"/>}, 
		{id: PeriodType.months, label: <FormattedMessage id="RecurringObjectiveDialog.months" defaultMessage="months"/>}, 
		{id: PeriodType.years, label: <FormattedMessage id="RecurringObjectiveDialog.years" defaultMessage="years"/>}, 
	];

	const handlePeriodChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		if (event.target.value === PeriodType.days
			|| event.target.value === PeriodType.months
			|| event.target.value === PeriodType.weeks
			|| event.target.value === PeriodType.years
			)
		{
			onPeriodChange(event.target.value);
		} else {
			throw new Error("Invalid period selected: "+event.target.value);
		}
	};

	const handleFrequencyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		const value = parseInt(event.target.value as string, 10);
		if (isNaN(value) || value < 1 || value > 100) {
			throw new Error("Unexpected frequency: "+value);
		}
		onFrequencyChange(value);
	};
	const handleMonthDayChange = () => {}
	const handleYearDayChange = () => {}

	// return (<div>yoooooooooooo</div>);

	return (
		<Dialog
			fullScreen={false}
			open={true}
			onClose={onCancel}
			aria-labelledby="responsive-dialog-title"
			disablePortal={testEnvironment}
		>
			{/* <DialogTitle id="responsive-dialog-title">Schedule Objective</DialogTitle> */}
			<DialogTitle id="responsive-dialog-title"><FormattedMessage id="RecurringObjectiveDialog.title" defaultMessage="Repeat objective"/></DialogTitle>
			
			<DialogContent>

			{/* <Box className={classes.defaultSection}>
				<ToggleButtonGroup size="medium" value={type === null ? "" : type} exclusive onChange={(event: unknown, type) => onTypeChange(type !== "" ? type : null)}>
							<ToggleButton value="" className={classes.periodChooserButton}> 
								Don't repeat
							</ToggleButton>
							<ToggleButton value={PlanType.day} className={classes.periodChooserButton}>
								Repeat
							</ToggleButton>
				</ToggleButtonGroup>
			</Box> */}

			<Box className={classes.defaultSection}>
				<Typography variant="body1" component="div"><FormattedMessage id="RecurringObjectiveDialog.frequency" defaultMessage="Repeat every <frequency> </frequency> <period> </period>" values={{
					frequency: (children: unknown) =>
						<FormControl className={classes.formControl}>
							<Select
							value={frequency}
							// defaultValue={frequency}
							onChange={handleFrequencyChange}
							name="num"
							className={classes.selectEmpty}
							>
							{[1,2,3,4,5,6,7,8,9,10].map(num => <MenuItem key={num} value={num}>{num}</MenuItem>)}
							</Select>
						</FormControl>,
					period: (children: unknown) => 
						<FormControl className={classes.formControl}>
							<Select
							value={periodType}
							onChange={handlePeriodChange}
							name="period"
							className={classes.selectEmpty}
							>
							{/* <option value="never">Don't repeat</option> */}
							{periodTypes.filter(t => availablePeriodTypes.indexOf(t.id) !== -1).map(t => <MenuItem key={t.id} value={t.id}>{t.label}</MenuItem>)}
							</Select>
							{/* <FormHelperText>With visually hidden label</FormHelperText> */}
						</FormControl>
				}}/>
				</Typography>
			</Box>

			{showWeekdaySelector && 
				<Box className={classes.weekDayBox}>
					{/* {[..."MDMDFSS"].map(item => <Avatar className={classes.weekDays} color={}>{item}</Avatar>)} */}
					{allWeekDays.map(item => {
						const selected = weekDays.indexOf(item) !== -1;
						return (
							<Avatar
								className={selected ? classes.weekDaysActive : classes.weekDaysInactive}
								data-test-weekday-id={item}
								data-test-weekday-status={selected}
								key={item}
								onClick={() => onToggleWeekday(item)}
								>
								{<FormattedMessage id={'RecurringObjectiveDialog.weekdays.'+item} defaultMessage={item.charAt(0).toUpperCase()}/>}
							</Avatar>
						);
					})}
					{/* {["MDMDFSS"].map(item => <Avatar>{item}</Avatar>} */}
				</Box>
			}


			{showMonthDaySelector &&
				<Box className={classes.defaultSection}>
					<FormControl component="fieldset" className={classes.formControl}>
						<RadioGroup name="monthday" value={monthDayType} onChange={handleMonthDayChange}>
							<FormControlLabel value="day" control={<Radio data-test-monthday={monthDay}/>} label={"On the "+monthDay+"th"} />
							<FormControlLabel value="nth-weekday" control={<Radio data-test-monthdaycount={monthDayCount}/>} label={"On the "+monthDayCount+"th "+weekDay} />
							<FormControlLabel value="last-weekday" control={<Radio />} label={"On the last "+weekDay} />
						</RadioGroup>
					</FormControl>
				</Box>
			}

			{showYearDaySelector &&
				<Box className={classes.defaultSection}>
					<FormControl component="fieldset" className={classes.formControl}>
						<RadioGroup name="yearday" value={yearDayType} onChange={handleYearDayChange}>
							<FormControlLabel value="day" control={<Radio />} label={"On the "+monthDay+"th "+month} />
							<FormControlLabel value="nth-weekday" control={<Radio />} label={"On the "+monthDayCount+"th "+weekDay+" in "+month} />
							<FormControlLabel value="last-weekday" control={<Radio />} label={"On the last "+weekDay+" in "+month} />
						</RadioGroup>
					</FormControl>
				</Box>
			}

			</DialogContent>

			<DialogActions>
				<Button onClick={onCancel} color="primary">
					<FormattedMessage id="SchedulerDialog.cancel" defaultMessage="Cancel"/>
				</Button>
				<Button onClick={onSave} color="primary" autoFocus>
					<FormattedMessage id="SchedulerDialog.save" defaultMessage="Ok"/>
				</Button>
			</DialogActions>
		</Dialog>
	);
}
