import React from 'react';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';

import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

import { Plan, PlanUnsaved } from '../services/plan-helpers';

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(1),
	},
	card: {
		margin: theme.spacing(1),
	},
	ratingLine: {
		display: 'flex',
		alignItems: 'center'
	},
	selfCitation: {
		marginLeft: theme.spacing(1),
		fontStyle: "italic",
	},
}));

interface MessagesType {
	[index: string]: {
		id: string,
		defaultMessage: string,
	}
}

const messageDefinitions: MessagesType = {
	ratingTitle: {
		id: 'plan.reflection.ratingTitle',
		defaultMessage: 'Ratings',
	},
	otherTitle: {
		id: 'plan.otherTitle',
		defaultMessage: 'Other',
	},
	insightsTitle: {
		id: 'plan.reflection.insightsTitle',
		defaultMessage: 'Insights',
	},
	insightsSubheader: {
		id: 'plan.reflection.insightsSubheader',
		defaultMessage: 'Think about the good and bad things and how you can improve.',
	},
	labelGood: {
		id: 'plan.reflection.labelGood',
		defaultMessage: 'What went well?',
	},
	labelBad: {
		id: 'plan.reflection.labelBad',
		defaultMessage: 'What did not go well?',
	},
	labelInsight: {
		id: 'plan.reflection.labelInsight',
		defaultMessage: 'What should you do differently next time?',
	},
	ratingLabel_day: {
		id: "plan.reflection.ratingLabelDay",
		defaultMessage: "How was your day?",
	},
	ratingLabel_week: {
		id: "plan.reflection.ratingLabelWeek",
		defaultMessage: "How was your week?",
	},
	ratingLabel_month: {
		id: "plan.reflection.ratingLabelMonth",
		defaultMessage: "How was your month?",
	},
	ratingLabel_year: {
		id: "plan.reflection.ratingLabelYear",
		defaultMessage: "How was your year?",
	},
};

const messages = defineMessages(messageDefinitions);

interface Props {
	plan: Plan | PlanUnsaved,
	onEditReflection(): void,
}

function PlanReflectionInsights(props: Props) {


	const { formatMessage } = useIntl();
	const classes = useStyles();

	// Only render something if there is actual content
	if (props.plan.periodSatisfactory === null
		&& props.plan.planSatisfactory === null
		&& props.plan.goodThings === null
		&& props.plan.badThings === null
		&& props.plan.insights === null) {
		return null;
	}

	return (
		<React.Fragment>
		<Card className={classes.card}>
			<CardHeader
				title={formatMessage(messages.insightsTitle)}
			/>
			<CardContent>
				{props.plan.periodSatisfactory !== null && <Typography variant="subtitle1" className={classes.ratingLine}><FormattedMessage id="PlanReflectionInsights.periodRating" defaultMessage="Your {type} was:" values={{type: props.plan.type}}/>&nbsp;{props.plan.periodSatisfactory ? <ThumbUpIcon fontSize="small"/> : <ThumbDownIcon fontSize="small"/>}</Typography>}
				{props.plan.planSatisfactory !== null && <Typography variant="subtitle1" gutterBottom className={classes.ratingLine}><FormattedMessage id="PlanReflectionInsights.planRating" defaultMessage="Your plan was:"/>&nbsp;{props.plan.planSatisfactory ? <ThumbUpIcon fontSize="small"/> : <ThumbDownIcon fontSize="small"/>}</Typography>}
				
				{props.plan.goodThings !== null &&
					<React.Fragment> 
						<Typography variant="subtitle2" style={{marginTop: "150"}}><FormattedMessage id="PlanReflectionInsights.good" defaultMessage="This went well:"/></Typography>
						<Typography variant="body1" gutterBottom className={classes.selfCitation}>"{props.plan.goodThings}"</Typography>
					</React.Fragment>
				}
				{props.plan.badThings !== null &&
					<React.Fragment> 
						<Typography variant="subtitle2"><FormattedMessage id="PlanReflectionInsights.bad" defaultMessage="This did not go well:"/></Typography>
						<Typography variant="body1" gutterBottom className={classes.selfCitation}>"{props.plan.badThings}"</Typography>
						</React.Fragment>
				}
				{props.plan.insights !== null &&
					<React.Fragment> 
						<Typography variant="subtitle2"><FormattedMessage id="PlanReflectionInsights.improvements" defaultMessage="What you could differently next time:"/></Typography>
						<Typography variant="body1" className={classes.selfCitation}>"{props.plan.insights}"</Typography>
					</React.Fragment>
				}
			</CardContent>
			<CardActions>
				<Button size="small" onClick={props.onEditReflection}><FormattedMessage id="PlanReflectionInsights.edit" defaultMessage="Edit"/></Button>
			</CardActions>
		</Card>
		</React.Fragment>
	);
}

export default PlanReflectionInsights;
