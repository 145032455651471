import React, { useContext } from 'react';
import RemoteConfigContext from './RemoteConfigContext';
import logger from '../services/logger.js';
import { Environment } from '../index';
import { setScreenName } from '../services/analytics';
import UpdateRequired from './UpdateRequired';

interface Props {
	children: React.ReactNode,
	environment: Environment,
}
export default function UpdateCheckController(props: Props) {
	const {
		environment,
		children,
	} = props;

	const remoteConfig = useContext(RemoteConfigContext);

	if (remoteConfig.getMinVersion(environment.platform) > environment.jsBuildNumber && environment.jsBuildNumber > 0) {
		logger.error("Outdated release detected. Requiring update.", environment.jsBuildNumber, remoteConfig.getMinVersion(environment.platform));
		setScreenName('UpdateRequired');
		return <UpdateRequired environment={environment}/>;
	}
	
	return <React.Fragment>{children}</React.Fragment>;
}
