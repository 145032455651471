import React, { useState, useEffect } from 'react';
import { List } from '../services/list-helpers';
import logger from '../services/logger';
import GoalWizard, { GoalWizardStep } from './GoalWizard';
import goalImages from '../assets/goal-images/goalImages';
// import { withSnackbar, useSnackbar } from 'notistack';
// import { FormattedMessage } from 'react-intl';
import isEqual from 'lodash.isequal';

interface Props {
	goal: List,
	onClose(): void,
	onSave(goal: List): void,
}

function GoalWizardController(props: Props) {
	logger.debug('Rendering GoalWizardController...');
	// const { lists, plans, objectives, getPlan, type, period, onSaveObjective, onToggleObjective, onFailObjective, onDeleteObjective, onCheckObjective, onSavePlan, history } = props;
	const { goal, onClose, onSave } = props;

	const [step, setStep] = useState(GoalWizardStep.title);
	const [title, setTitle] = useState(goal.title);
	const [description, setDescription] = useState(goal.description);
	const [image, setImage] = useState(goal.image);
	const [motivationWhy, setMotivationWhy] = useState(goal.motivationWhy);
	// const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const imagePrev = () => {
		logger.debug('Next image');
		const images = Object.keys(goalImages);
		const curIdx = images.indexOf(image);
		if (curIdx <= 0) {
			setImage(images[images.length-1]);
		} else {
			setImage(images[curIdx-1]);
		}
	}
	
	const imageNext = () => {
		logger.debug('Previous image');
		const images = Object.keys(goalImages);
		const curIdx = images.indexOf(image);
		if (curIdx === -1 || curIdx === (images.length - 1)) {
			setImage(images[0]);
		} else {
			setImage(images[curIdx+1]);
		}
	}

	const handleSave = () => {
		logger.debug('Handling save...');
		const updatedGoal = Object.assign({}, goal, {
			title: title !== "" ? title : null,
			description: description !== "" ? description : null,
			image: image,
			motivationWhy: motivationWhy !== "" ? motivationWhy : null,
		});
		if (isEqual(updatedGoal, goal)) {
			logger.debug('No changes detected. Not saving.', updatedGoal, goal);
			return;
		} else {
			logger.debug('Changes detected. Saving...', updatedGoal, goal);
		}
		onSave(updatedGoal);
		// enqueueSnackbar(<FormattedMessage id="goalWizard.saveSnack" defaultMessage="Saved changes"/>, {
		// 	preventDuplicate: true,
		// 	autoHideDuration: 1000,
		// 	anchorOrigin: {
		// 		vertical: 'bottom',
		// 		horizontal: 'center',
		// 	},
		// });
	};

	const handleFinish = () => {
		handleSave();
		onClose();
	};

	const handleClose = () => {
		handleSave();
		onClose();
	}

	const handleSetStep = (step: GoalWizardStep) => {
		handleSave();
		setStep(step);
	};

	const isNewGoal = goal.version === 1;

	// Save data when component unmounts (e.g. due to navigation)
	useEffect(() => {
		return () => {
			logger.debug('Saving data through effect hook');
			handleSave();
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<GoalWizard
			newGoal={isNewGoal}
			title={title || ""}
			onChangeTitle={(value) => setTitle(value)}
			description={description || ""}
			onChangeDescription={(value) => setDescription(value)}
			image={image}
			onNextImage={imageNext}
			onPreviousImage={imagePrev}
			motivationWhy={motivationWhy || ""}
			onChangeMotivationWhy={(value) => setMotivationWhy(value)}
			step={step}
			onSetStep={(step) => handleSetStep(step)}
			onClose={handleClose}
			onFinish={handleFinish}
			onFieldBlur={handleSave}
		/>
	);
}

export default GoalWizardController;
