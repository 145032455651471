import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import DoneIcon from '@material-ui/icons/Done';
import GoalWizardTitle from './GoalWizardTitle';
import GoalWizardImage from './GoalWizardImage';
import GoalWizardMotivation from './GoalWizardMotivation';

const useStyles = makeStyles(theme => ({
	title: {
		flexGrow: 1,
	},
	body: {
		paddingBottom: theme.spacing(16),
	},
	footer: {
		padding: theme.spacing(2),
		position: "fixed",
		bottom: 0,
		width: "100%",
	},
}));

export enum GoalWizardStep { 
	title = "title",
	image = "image",
	motivation = "motivation",
	// deterrence = "deterrence",
}

interface GoalWizardProps {
	title: string,
	onChangeTitle(value: string): void,
	description: string,
	onChangeDescription(value: string): void,
	image: string,
	onNextImage(): void,
	onPreviousImage(): void,
	motivationWhy: string,
	onChangeMotivationWhy(value: string): void,
	step: GoalWizardStep,
	onSetStep(step: GoalWizardStep): void,
	onClose(): void,
	onFinish(): void,
	onFieldBlur(): void,
	newGoal?: boolean,
}

function GoalWizard(props: GoalWizardProps) {
	const {
		step, onSetStep,
		onClose, onFinish,
		newGoal,
		title, onChangeTitle,
		description, onChangeDescription,
		image, onNextImage, onPreviousImage,
		motivationWhy, onChangeMotivationWhy,
		onFieldBlur,
	} = props;
	const classes = useStyles();
	const stepSequence = [GoalWizardStep.title, GoalWizardStep.image, GoalWizardStep.motivation];
	var stepIndex = stepSequence.indexOf(props.step);
	const handleNext = () => {
		onSetStep(stepSequence[stepIndex + 1]);
	}
	const handleBack = () => {
		onSetStep(stepSequence[stepIndex - 1]);
	}
	const handleFinish = () => {
		onFinish();
	}

	return (
		<React.Fragment>
			<AppBar position="static">
				<Toolbar >
					<Typography variant="h6" color="inherit" noWrap className={classes.title}>
						{newGoal ? 
							<FormattedMessage id="goalWizard.titleCreate" defaultMessage="Create goal"/>
						:
							<FormattedMessage id="goalWizard.titleEdit" defaultMessage="Edit goal: {title}" values={{title: title}} />
						}
					</Typography>
					<IconButton color="inherit" onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<Box className={classes.body}>
				{step === GoalWizardStep.title && <GoalWizardTitle title={title} onChangeTitle={onChangeTitle} description={description} onChangeDescription={onChangeDescription} onFieldBlur={onFieldBlur}/>}
				{step === GoalWizardStep.image && <GoalWizardImage image={image} onNext={onNextImage} onPrevious={onPreviousImage}/>}
				{step === GoalWizardStep.motivation && <GoalWizardMotivation motivationWhy={motivationWhy} onChangeMotivationWhy={onChangeMotivationWhy} onFieldBlur={onFieldBlur}/>}
			</Box>
			<Paper className={classes.footer} elevation={4}>
				<MobileStepper
					variant="dots"
					steps={3}
					position="static"
					activeStep={stepIndex}
					nextButton={
						step === GoalWizardStep.motivation ?
						<Button onClick={handleFinish} variant="contained" color="secondary" style={{color: "white"}}><DoneIcon/>&nbsp;<FormattedMessage id="reflectionWizard.finish" defaultMessage="Finish"/></Button>
						:
						<Button onClick={handleNext} variant="contained" color="primary"><FormattedMessage id="reflectionWizard.continue" defaultMessage="Continue"/><KeyboardArrowRight /></Button>
						// <Button size="small" onClick={handleNext} disabled={activeStep === 5}>
						// 	Next
						// 	{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
						// </Button>
					}
					backButton={
						<Button onClick={handleBack} variant="text" color="default" disabled={step === GoalWizardStep.title}><KeyboardArrowLeft /><FormattedMessage id="reflectionWizard.back" defaultMessage="Back"/></Button>
					}
				/>
			</Paper>
		</React.Fragment>
	);
}

export default GoalWizard;