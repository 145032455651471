import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FlareIcon from '@material-ui/icons/Flare';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
			backgroundColor: theme.palette.common.white,
		},
	},
	container: {
		backgroundColor: "white",
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	paper: {
		// marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	confirmationText: {
		marginTop: theme.spacing(1),
	},
	button: {
		marginTop: theme.spacing(2),
	},
}));

export default function SignUpConfirmationSuccess(props) {

	const classes = useStyles();

	return (
	<Container component="main" maxWidth="xs" className={classes.container}>
		<CssBaseline />
		<div className={classes.paper}>
			<Avatar className={classes.avatar}>
				<FlareIcon />
			</Avatar>
			<Typography variant="h5">Welcome!</Typography>
			<Typography variant="body1" className={classes.confirmationText}>Thank you for confirming your email address. Your account is now activated.</Typography>
			<Button href="https://run.focalityapp.com/" fullWidth variant="contained" color="primary" className={classes.button}>Get started</Button>
		</div>

	</Container>
);
}