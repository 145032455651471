import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Plan, PlanType, PlanUnsaved } from '../services/plan-helpers';
import { FormattedMessage } from 'react-intl';


const useStyles = makeStyles(theme => ({
	container: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	header: {
		padding: theme.spacing(2),
	},
	heading: {
		marginBottom: theme.spacing(1),
	},
	bottomGrid: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		marginTop: theme.spacing(2),
	},
}));

interface ReflectionWizardBadThingsProps {
	plan: Plan | PlanUnsaved,
	onSavePlan(plan: Plan | PlanUnsaved): void,
	onContinue(): void,
	onBack(): void,
}

function ReflectionWizardBadThings(props: ReflectionWizardBadThingsProps) {
	const { onContinue, onBack, plan} = props;

	const classes = useStyles();

	const [fieldValue, setFieldValue] = useState(props.plan.badThings);

	const handleSave = () => {
		const updated = Object.assign({}, props.plan, {
			badThings: fieldValue !== "" ? fieldValue : null
		});
		props.onSavePlan(updated);
	};

	return (
		<Container maxWidth="sm" className={classes.container}>
			<Box className={classes.header}>
				<Typography variant="h4" color="inherit" className={classes.heading}><FormattedMessage id="plan.reflection.labelBad" defaultMessage="What did not go well?"/></Typography>
				<Typography variant="body1" color="inherit"><FormattedMessage id="reflectionWizard.badThings.description" defaultMessage="Briefly note the bad things that happened. What did keep you from reaching your objectives? What were the obstacles?"  values={{type: props.plan.type}}/></Typography>
			</Box>

			<Box className={classes.header}>
				<TextField
					label={<FormattedMessage id="reflectionWizard.badThings.label" defaultMessage="Bad things"/>}
					multiline
					margin="dense"
					fullWidth	
					value={fieldValue || ""}
					onChange={(event) => setFieldValue(event.target.value)}
					InputProps={{
						onBlur: handleSave,
					}}
				/>
			</Box>
			<Box className={classes.header}>
				<Typography variant="body2" color="inherit" gutterBottom>
					<FormattedMessage id="reflectionWizard.badThings.explanation" defaultMessage="Writing down challenges will help you find solutions. We will compile a collection of these challenges so that you can discover patterns and focus on improving these."/>
				</Typography>
				{plan.type === PlanType.day &&
					<Typography variant="body2" color="inherit">
						<FormattedMessage id="reflectionWizard.badThings.skippingNote" defaultMessage="<strong>Note:</strong> Feel free to skip this on daily reflections if nothing springs to mind. No need to focus on the bad if everything is great." values={{strong: (msg: string) => <strong>{msg}</strong>}}/>
					</Typography>
				}
			</Box>

			<Grid container justify="space-between" className={classes.bottomGrid}>
				<Grid item><Button variant="text" color="default" onClick={onBack}><FormattedMessage id="reflectionWizard.back" defaultMessage="Back"/></Button></Grid>
				<Grid item><Button variant="contained" color="primary" onClick={onContinue}><FormattedMessage id="reflectionWizard.continue" defaultMessage="Continue"/></Button></Grid>
			</Grid>
		</Container>
	);
}

export default ReflectionWizardBadThings;