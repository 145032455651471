import React, { useState, useEffect } from 'react';
import RemoteConfigContext from './RemoteConfigContext';
import logger from '../services/logger.js';
import { Platform } from '..';
import { OnboardingSurveyIntl } from './OnboardingSurveyController';

interface Props {
	children: React.ReactNode,
}
export default function RemoteConfigProvider(props: Props) {

	const [ready, setReady] = useState<boolean>(false);
	const [innovators, setInnovators] = useState<string[]>([]);
	const [minVersions, setMinVersions] = useState({web:0, android: 0, ios: 0});
	const [onboardingSurvey, setOnboardingSurvey] = useState<OnboardingSurveyIntl>({});
	const [onboardingInterviewShow, setOnboardingInterviewShow] = useState<boolean>(true);
	const [onboardingInterviewShowPhone, setOnboardingInterviewShowPhone] = useState<boolean>(true);
	const [helpShowPhone, setHelpShowPhone] = useState<boolean>(false);

	useEffect(() => {
		try {
			fetch("https://www.focalityapp.com/rc.json")
				.then(response => response.json())
				.then(jsonResponse => {
					logger.debug("Remote config response JSON", jsonResponse);
					if (typeof jsonResponse.innovators !== "object" || !Array.isArray(jsonResponse.innovators)) {
						throw new Error("Unexpected remote config response");
					}
					setInnovators(jsonResponse.innovators);
					if (typeof jsonResponse.minVersion !== "object")
						throw new Error("Unexpected remote config response (minVersion)");
					["web", "ios", "android"].forEach(item => {
						if (typeof jsonResponse.minVersion[item] !== "number")
							throw new Error("Unexpected remote config response (minVersion["+item+"])");
					});
					setMinVersions(jsonResponse.minVersion);
					setReady(true);
					setOnboardingSurvey(jsonResponse.onboardingSurvey);
					setOnboardingInterviewShow(jsonResponse.onboardingInterviewShow === true);
					setOnboardingInterviewShowPhone(jsonResponse.onboardingInterviewShowPhone === true);
					setHelpShowPhone(jsonResponse.helpShowPhone === true);
				})
				.catch(e => {
					logger.error("Failed to catch or parse remote config", e);
				});
		} catch (e) {
			logger.error("Failed to catch or parse remote config", e);
		}
	}, []);

	const contextValue = {
		ready: () => ready,
		getInnovators: () => innovators,
		getMinVersion: (platform: Platform) => {
			if (!platform || platform === "electron") {
				return 0;
			} else if (typeof minVersions[platform] === "number") {
				return minVersions[platform];
			} else {
				return 0;
			}
		},
		getOnboardingSurvey: () => onboardingSurvey,
		onboardingInterviewShow: onboardingInterviewShow,
		onboardingInterviewShowPhone: onboardingInterviewShowPhone,
		helpShowPhone: helpShowPhone,
	}

	return (
		<RemoteConfigContext.Provider value={contextValue}>
			{props.children}
		</RemoteConfigContext.Provider>
	);
}
