import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FlareIcon from '@material-ui/icons/Flare';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppleStoreBadge from '../assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import GoogleStoreBadge from '../assets/google-play-badge.png';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
			backgroundColor: theme.palette.common.white,
		},
	},
	container: {
		backgroundColor: "white",
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	paper: {
		// marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	confirmationText: {
		marginTop: theme.spacing(1),
	},
	button: {
		marginTop: theme.spacing(2),
	},
	badgeContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: "space-around",
		marginBottom: theme.spacing(4),
	},
	badgeApple: {
		// width: "100",
		display: "inline-block",
	},
	badgeGoogle: {
		height: "58px",
		display: "inline-block",
	},
}));

const messages = defineMessages({
	androidurl: {
		id: 'urls.android-app',
		defaultMessage: 'https://www.focalityapp.com/en/android-app/',
	},
	iosurl: {
		id: 'urls.ios-app',
		defaultMessage: 'https://www.focalityapp.com/en/ios-app/',
	},
});

interface Props {
}

export default function SignUpConfirmationSuccessWeb(props: Props) {
	const classes = useStyles();
	const { formatMessage } = useIntl();

	// const androidurl = formatMessage(messages.androidurl);
	const androidurl = "https://play.google.com/store/apps/details?id=com.focalityapp";
	const iosurl = formatMessage(messages.iosurl);

	return (
		<Container component="main" maxWidth="xs" className={classes.container}>
			<CssBaseline />
			<div className={classes.paper}>
				<Avatar className={classes.avatar}>
					<FlareIcon />
				</Avatar>
				<Typography variant="h5"><FormattedMessage id="signup.confirmation.title" defaultMessage="Welcome!"/></Typography>
				<Typography variant="body1" className={classes.confirmationText} paragraph><FormattedMessage id="signup.confirmation.description" defaultMessage="Thank you for confirming your email address. Your account is now activated."/></Typography>
				<Typography variant="h6" gutterBottom><FormattedMessage id="signup.confirmation.mobileTitle" defaultMessage="Install Mobile App"/></Typography>
				<div className={classes.badgeContainer}>
					<a href={iosurl}><img className={classes.badgeApple} alt="" src={AppleStoreBadge}/></a>
					<a href={androidurl}><img className={classes.badgeGoogle} alt="" src={GoogleStoreBadge}/></a>
				</div>
				<Typography variant="h6" gutterBottom><FormattedMessage id="signup.confirmation.web.title" defaultMessage="Use Web App"/></Typography>
				<Button href="https://run.focalityapp.com/" fullWidth variant="contained" color="primary" className={classes.button}><FormattedMessage id="signup.confirmation.web.button" defaultMessage="Start browser version"/></Button>
			</div>
		</Container>
	);
}
