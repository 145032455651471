import React, { useState } from 'react';
// import logger from '../services/logger';
import { FormattedMessage } from 'react-intl';
import RecurringObjectiveDialogController from './RecurringObjectiveDialogController';
import { Objective } from '../services/objective-helpers';
import ObjectiveEditor from './ObjectiveEditor';
import { Plan, PlanStub, PlanType } from '../services/plan-helpers';
import { Recurrer, Recurring } from '../services/recurring-helpers';
import SchedulerDialogController from './SchedulerDialogController';
import ConfirmationDialog from '../ConfirmationDialog';
import RecurrerDeleteDialog from './RecurrerDeleteDialog';

enum Modes {
	existing,
	new,
}

interface ObjectiveEditorControllerPropsExisting {
	doCancel(): void,
	objective: Objective,
	recurrer: Recurrer | undefined,
	plan: Plan | null | undefined,
	doSaveObjective(objective: Objective, recurring: Recurring | null): void,
	getSavedPlan(plan: PlanStub): Promise<Plan>,
	doDelete(): void,
	doDeleteRecurrerWithInstances(recurrer: Recurrer): void,
	doDeleteRecurrerCurrentAndFollowing(recurrer: Recurrer, current: Objective): void,
}

interface ObjectiveEditorControllerPropsNew {
	doCancel(): void,
	planType: PlanType | null,
	doCreateObjective(title: string, estimate: number | null, secondary: boolean, recurring: Recurring | null): void,
}

export default function ObjectiveEditorController(props: ObjectiveEditorControllerPropsNew) : JSX.Element;
export default function ObjectiveEditorController(props: ObjectiveEditorControllerPropsExisting): JSX.Element;
export default function ObjectiveEditorController(props: any): any {
	const [title, setTitle] = useState<string>(props.objective && props.objective.title ? props.objective.title : "");
	const [estimate, setEstimate] = useState<string>(props.objective && props.objective.estimate ? props.objective.estimate.toString() : "");
	const [secondary, setSecondary] = useState<boolean>(props.objective && props.objective.secondary ? props.objective.secondary : false);
	const [showRecurringDialog, setShowRecurringDialog] = useState(false);
	const [showRecurringDeleteDialog, setShowRecurringDeleteDialog] = useState(false);
	const [showSchedulingDialog, setShowSchedulingDialog] = useState(false);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [saving, setSaving] = useState(false);
	const [recurring, setRecurring] = useState<Recurring | null>(props.recurrer ? props.recurrer.recurring : null);
	const [currentPlan, setCurrentPlan] = useState<PlanStub|null>(props.plan || null);

	// logger.debug("ObjectiveEditorController, props:", props);

	const mode = props.objective ? Modes.existing : Modes.new;
	
	const handleSave = async () => {
		setSaving(true);
		const est = estimate === "" ? null : parseFloat(estimate);
		if (props.objective) {
			const newObjective: Objective = Object.assign({}, props.objective, {
				title: title,
				estimate: est,
				secondary: secondary,
			});
			if (currentPlan === null)
				newObjective.planId = null;
			else if (!props.plan || props.plan.type !== currentPlan.type || props.plan.period !== currentPlan.type) {
				const savedPlan = await props.getSavedPlan(currentPlan);
				newObjective.planId = savedPlan.id;
			}
			props.doSaveObjective(newObjective, recurring);
		} else if (props.doCreateObjective) {
			props.doCreateObjective(
				title,
				est,
				secondary,
				recurring,
			);
		}
	};

	const handleSelectNonRecurring = () => {
		setRecurring(null);
	};

	const handleRecurringCancel = () => {
		setShowRecurringDialog(false);
	};

	const handleRecurringSave = (repetition:Recurring) => {
		setRecurring(repetition);
		setShowRecurringDialog(false);
	}

	const handleHelp = () => {
		throw new Error("Not implemented");
	}
	const handleSchedule = () => {
		setShowSchedulingDialog(true);
	}

	const planType : PlanType|null = currentPlan ? currentPlan.type : props.planType || null;

	const isScheduled = props.objective ? props.objective.planId !== null : false;

	const showRecurring = planType !== null; // Recurrance can only be set within a plan / plan type.

	return <>
		{showRecurringDialog && planType && <RecurringObjectiveDialogController planType={planType} onCancel={handleRecurringCancel} onSave={handleRecurringSave} recurring={recurring}/>}
		<ObjectiveEditor
			onSave={handleSave}
			title={title}
			estimate={estimate}
			recurring={recurring !== null}
			secondary={secondary}
			scheduled={isScheduled}

			showRecurring={showRecurring}
			showScheduler={mode === Modes.existing}
			schedulerDisabled={recurring !== null}
			showDelete={mode === Modes.existing}
			cta={mode === Modes.existing ? "save" : "add"}
			saveDisabled={saving}
			deleteDisabled={saving}

			onTitleChange={value => setTitle(value)}
			onEstimateChange={value => setEstimate(value)}
			onToggleSecondary={() => setSecondary(!secondary)}
			onDelete={() => recurring ? setShowRecurringDeleteDialog(true) : setShowDeleteDialog(true)}
			onHelp={handleHelp}
			onSchedule={handleSchedule}
			onSelectRecurring={() => setShowRecurringDialog(true)}
			onSelectNonRecurring={handleSelectNonRecurring}
		/>
		{showSchedulingDialog &&
			<SchedulerDialogController
					onCancel={() => setShowSchedulingDialog(false)}
					objective={props.objective}
					initialType={currentPlan ? currentPlan.type : null}
					initialPeriod={currentPlan ? currentPlan.period : null}
					onSetPlan={(plan) => {
						setCurrentPlan(plan);
						setShowSchedulingDialog(false);
					}}
			/>
		}
		{showDeleteDialog && <>
			<ConfirmationDialog
				open={true}
				title={<FormattedMessage id="objectiveEditor.delete.confirmationTitle" defaultMessage="Delete objective?"/>}
				contentText={<FormattedMessage id="objectiveEditor.delete.confirmationMessage" defaultMessage="Deleting an objective will also remove all stats collected for it."/>}
				cancelButtonText={<FormattedMessage id="objectiveEditor.delete.confirmationCancel" defaultMessage="Cancel"/>}
				confirmationButtonText={<FormattedMessage id="objectiveEditor.delete.confirmationConfirm" defaultMessage="Delete"/>}
				onCancel={() => setShowDeleteDialog(false)}
				onConfirm={props.doDelete}
			/>
		</>}

		{showRecurringDeleteDialog && <>
			<RecurrerDeleteDialog
				onCancel={() => setShowRecurringDeleteDialog(false)}
				onDeleteCurrent={() => {}}
				onDeleteCurrentPlus={() => {props.doDeleteRecurrerCurrentAndFollowing(props.recurrer, props.objective); setShowRecurringDeleteDialog(false);}}
				onDeleteAll={() => {props.doDeleteRecurrerWithInstances(props.recurrer); setShowRecurringDeleteDialog(false);}}
			/>
		</>}

	</>;
}
