import React from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(() => ({
	fullScreen: {
		paddingTop: `env(safe-area-inset-top)`,
		paddingBottom: `env(safe-area-inset-bottom)`,
		paddingLeft: `env(safe-area-inset-left)`,
		paddingRight: `env(safe-area-inset-right)`,
	},
	default: {
		zIndex: 99999,
	},
}));

/**
 * Same as Dialog, but adds padding 
 * @param props 
 */
export default function DialogNotched(props: DialogProps) {
	const classes = useStyles();

	return (
		<Dialog 
			classes={
				props.fullScreen ? {paper: classes.fullScreen} : {paper: classes.default}
			}
			{...props}>

		</Dialog>
	);
}
