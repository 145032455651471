import PropTypes from 'prop-types';
import { useEffect, useContext } from "react";
import { useIntl } from "react-intl";
import SettingsContext from './SettingsContext';

import { Plugins, LocalNotification } from '@capacitor/core';
import { subHours, isPast, set as setDate } from 'date-fns';
import { Plan, isBefore, getEnd, getRemainingObjectives, PlanType, getPlanByTypePeriod, getNextPeriod, dateToPeriod, getBeginning } from '../services/plan-helpers';
import logger from '../services/logger.js';
import { defineMessages } from 'react-intl';
import { Objective } from '../services/objective-helpers';
import { logEvent } from '../services/analytics';
import { getPlanPath } from './PlanViewController';


const messages = defineMessages({
	reflectionTitle_day: {
		id: "notifications.reflection.day.title",
		defaultMessage: "A good day?",
	},
	reflectionBody_day: {
		id: "notifications.reflection.day.body",
		defaultMessage: "How was you day? What are your plans for tomorrow?",
	},
	reflectionTitle_week: {
		id: "notifications.reflection.week.title",
		defaultMessage: "A good week?",
	},
	reflectionBody_week: {
		id: "notifications.reflection.week.body",
		defaultMessage: "How was your week? What do you want to accomplish next week?",
	},
	reflectionTitle_month: {
		id: "notifications.reflection.month.title",
		defaultMessage: "A good month?",
	},
	reflectionBody_month: {
		id: "notifications.reflection.month.body",
		defaultMessage: "How was your month? What do you want to accomplish next month?",
	},
	reflectionTitle_year: {
		id: "notifications.reflection.year.title",
		defaultMessage: "A good year?",
	},
	reflectionBody_year: {
		id: "notifications.reflection.year.body",
		defaultMessage: "How was this year? What do you want to accomplish next year?",
	},
	checkupTitle: {
		id: "notifications.checkup.title",
		defaultMessage: "How is your plan going?",
	},
	checkupBody: {
		id: "notifications.checkup.body",
		defaultMessage: "Check which objectives are remaining.",
	},
	planningReminderTitle: {
		id: "notifications.planningreminder.title",
		defaultMessage: "Don't forget to plan",
	},
	planningReminderBody: {
		id: "notifications.planningreminder.body",
		defaultMessage: "Complete your plan for the {type}. Don't let this be a reactive {type}.",
	},
});

function NotificationsController(props: {
	plans: Plan[],
	objectives: Objective[],
	doNavigate(path: string): void,
}) {
	const { 
		plans, 
		objectives,
		doNavigate,
	} = props;
	const { formatMessage } = useIntl();
	const settings = useContext(SettingsContext);
	const notificationsEnabled = settings.getNotificationsEnabled();
	
	useEffect(() => {	
		const { LocalNotifications } = Plugins;

		logger.debug('Scheduling notifications...');

		const now = new Date();
		const futurePlans = plans.filter(plan => !isBefore(plan, now));

		var nextSchedulerId = 1;
		const notifications: LocalNotification[] = [];
		futurePlans.forEach(plan => {
			const end = getEnd(plan);
			var reflectionReminderTime = null;
			var planReminderTime = null;
			switch (plan.type) {
				case "day":
					reflectionReminderTime = subHours(end, 2);
					// reflectionReminderTime = subMinutes(end, 23);
					planReminderTime = subHours(end, 10);
					break;
				case "week":
					reflectionReminderTime = subHours(end, 4);
					// planReminderTime = subHours(end, 10);
					break;
				case "month":
					reflectionReminderTime = subHours(end, 8);
					break;
				case "year":
					reflectionReminderTime = subHours(end, 12);
					break;
				default:
					reflectionReminderTime = end;
			}
			if (reflectionReminderTime && !isPast(reflectionReminderTime)) {
				const notification = {
					// @ts-ignore
					title: formatMessage(messages['reflectionTitle_'+plan.type]),
					// @ts-ignore
					body: formatMessage(messages['reflectionBody_'+plan.type]),
					id: nextSchedulerId++,
					schedule: { at: reflectionReminderTime },
					// sound: null,
					// attachments: null,
					actionTypeId: "REFLECT",
					extra: {
						navigateTo: getPlanPath(plan)+'/reflect'
					},
				};
				// logger.debug('Setting notification', notification);
				notifications.push(notification);
			}
			if (planReminderTime && !isPast(planReminderTime)) {
				const remainingObjectives = getRemainingObjectives(plan, objectives);
				if (remainingObjectives.length > 0) {
					// logger.debug('Remaining objectives:', remainingObjectives.length);
					const objectivesString = remainingObjectives.map(o => o.estimate ? o.title+': '+o.estimate+'h' : o.title).join('\n');
					// const objectivesString = remainingObjectives.map(o => o.title).join('\n');
					const checkupNotification = {
						title: formatMessage(messages['checkupTitle']),
						body: formatMessage(messages['checkupBody'])+'\n'+objectivesString,
						id: nextSchedulerId++,
						schedule: { at: planReminderTime },
						// sound: null,
						// attachments: null,
						actionTypeId: "PLAN",
						extra: {
							navigateTo: getPlanPath(plan)
						}
					};
					// logger.debug('Setting plan reminder notification', checkupNotification);
					notifications.push(checkupNotification);
				}
			}
		});

		// Reminders to create plans
		[
			{
				type: PlanType.day,
				when: {hours: 10, minutes: 0},
				instances: 7,
			},
			{
				type: PlanType.week,
				when: {hours: 10, minutes: 0},
				instances: 2,
			},
			{
				type: PlanType.month,
				when: {hours: 10, minutes: 0},
				instances: 2,
			},
			{
				type: PlanType.year,
				when: {hours: 10, minutes: 0},
				instances: 2,
			},
		].forEach(({type, when, instances}) => {
			let period = dateToPeriod(now, type);
			// logger.debug("Checking planning reminder notification for "+period);
			for (let i = 0; i < instances; i++) {
				const plan = getPlanByTypePeriod(type, period, plans);
				if (!plan || !plan.complete) {
					const end = getBeginning({
						period: period,
						type: type,
					});
					const notificationTime = setDate(end, when);
					if (!isPast(notificationTime)) {
						const n = {
							// title: <FormattedMessage id="notifications.planningreminder.title" defaultMessage="Don't forget to plan" />,
							title: formatMessage(messages['planningReminderTitle'], {type: type}),
							body: formatMessage(messages['planningReminderBody'], {type: type}),
							id: nextSchedulerId++,
							schedule: { at: notificationTime },
							// sound: null,
							// attachments: null,
							actionTypeId: "",
							// extra: null
						};
						// logger.debug('Setting planning reminder notification', n);
						notifications.push(n);
					} else {
						// logger.debug("Skipping notification for "+period+". Too late.");
					}
				} else {
					// logger.debug("Not setting notifiction for "+period+". Plan complete.");
				}
				period = getNextPeriod(period, type);
			}
		});

		// notifications.push({
		// 	title: "Test",
		// 	body: "Test body",
		// 	id: nextSchedulerId++,
		// 	schedule: { at: addSeconds(new Date(), 15) },
		// 	actionTypeId: "TEST",
		// });

		LocalNotifications.getPending().then(pastNotifications => {
			if (pastNotifications.notifications.length > 0) {
				// logger.debug('Cancelling pending notifications:', pastNotifications, pastNotifications.notifications.length);
				if (notificationsEnabled) {
					LocalNotifications.cancel(pastNotifications).then(() => {
						// logger.debug('Scheduling new notification:', notifications);
						LocalNotifications.schedule({notifications: notifications}).then(() => {
							LocalNotifications.getPending().then(notifications => logger.debug('Pending notifications after update: ', notifications));
						});
					});
				}
			} else if (notificationsEnabled) {
				// logger.debug('Scheduling new notification:', notifications);
				LocalNotifications.schedule({notifications: notifications}).then(() => {
					LocalNotifications.getPending().then(notifications => logger.debug('Pending notifications after update: ', notifications));
				});
			}
		});

	}, [objectives, plans, formatMessage, notificationsEnabled]);

	useEffect(() => {
		const { LocalNotifications } = Plugins;

		const listener = LocalNotifications.addListener('localNotificationActionPerformed', (action) => {
			logger.debug("Notification action performed", action);
			if (action && action.notification && action.notification.actionTypeId) {
				logEvent('notifications_action', {type: action.notification.actionTypeId});
				if (action.notification.extra && action.notification.extra.navigateTo) {
					doNavigate(action.notification.extra.navigateTo);
				};
			}
		});

		return () => {
			listener.remove();
		}
	}, [doNavigate]);


	return null;
}

NotificationsController.propTypes = {
	plans: PropTypes.array.isRequired,
	objectives: PropTypes.array.isRequired,
};

export default NotificationsController;
