import React, { useMemo } from 'react';
// import { Link } from '@material-ui/core';
import IntlMessageFormat from 'intl-messageformat';
// import LangContext from '../contexts/LangContext';
import { useIntl } from 'react-intl';
import { commonMessageValues } from './intl-helpers';

// const commonMessageValues = {
// 	br: <br/>,
// 	b: (m:any) => <strong>{m}</strong>,
// 	strong: (m:any) => <strong>{m}</strong>,
// 	"contact-en": (m:any) => <Link target="_blank" rel="noopener" href="https://www.focustrainer.app/en/contact/">{m}</Link>,
// 	"contact-de": (m:any) => <Link target="_blank" rel="noopener" href="https://www.focustrainer.app/de/kontakt/">{m}</Link>,
// }


interface LTextProps {
	de: string,
	en: string,
	values?: Record<string, string | number | boolean | null | undefined | Date | JSX.Element>,
}

export default function LText(props: LTextProps) {
	const { locale } = useIntl();

	const message = locale === "de" ? props.de : props.en;

	const msg = new IntlMessageFormat(message, locale);
	
	const values = Object.assign({}, commonMessageValues, props.values);

	// https://formatjs.io/docs/intl-messageformat/

	// @ts-ignore
	return <>{msg.format(values)}</>;
}

export function useLString() {
	const { locale } = useIntl();


	const lstr = useMemo(() => (en: string, de:string, values?: Record<string, string | number | boolean | Date>): string => {
		const message = locale === "de" ? de : en;
		const msg = new IntlMessageFormat(message, locale);
		// const v = Object.assign({}, values);
		const r = msg.format(values);
		if (typeof r === "string")
			return r;
		else if (Array.isArray(r))
			// @ts-ignore
			return r.join('');
		else
			throw new Error("Unexpected type " + typeof r);
	}, [locale]);
	
	return lstr;
}
