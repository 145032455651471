import React from 'react';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import { PlanType } from '../services/plan-helpers';
import ButtonDialog from './ButtonDialog';
import DualStreak from './DualStreak';
import { commonMessageValues } from '../services/intl-helpers';


interface Props {
	numerator: number,
	denominator: number,
	streakCurrent: number,
	streakMax: number,
	planType: PlanType,
	onOpenInfo?(): void,
}

export default function ReflectionStats(props: Props) {
	const {
		numerator,
		denominator,
		streakCurrent,
		streakMax,
		planType,
		onOpenInfo,
	} = props;

	return (
		<DualStreak
			numerator={numerator}
			denominator={denominator}
			streakCurrent={streakCurrent}
			streakMax={streakMax}
			title={
				<>
					<FormattedMessage id="ReflectionStats.title" defaultMessage="Reflection rate" values={{}}/>
					<ButtonDialog analyticsId="reflectionstats-info" onOpen={onOpenInfo}>
						<Typography variant="h5" gutterBottom><FormattedMessage id="ReflectionStats.help.title" defaultMessage="About reflection stats"/></Typography>
						<Typography variant="h6" gutterBottom><FormattedMessage id="ReflectionStats.help.rateTitle" defaultMessage="Recent reflection rate"/></Typography>
						<Typography variant="body1" paragraph><FormattedMessage id="ReflectionStats.help.rtp1" defaultMessage="This graph represents your recent reflection rate. Keep this at 100%."/></Typography>
						<Typography variant="body1" paragraph><FormattedMessage id="ReflectionStats.help.rtp2" defaultMessage="Note: Only the current and recent plans are counted. <strong>Future plans are not</strong> (yet)." values={{strong: (msg: string) => <strong>{msg}</strong>}}/></Typography>
						<Typography variant="h6" gutterBottom><FormattedMessage id="ReflectionStats.help.streaksTitle" defaultMessage="Reflection streaks"/></Typography>
						<Typography variant="body1" paragraph><FormattedMessage id="ReflectionStats.help.sp1" defaultMessage="A streak is an unbroken chain of plans. If you miss one plan, the counter resets. Try to set a new personal record."/></Typography>
						<Typography variant="h6" gutterBottom><FormattedMessage id="ReflectionStats.help.whatCountsTitle" defaultMessage="What counts?"/></Typography>
						<Typography variant="body1"><FormattedMessage id="ReflectionStats.help.wc1" defaultMessage="Focality considers a plan as reflected when A) you rated period &amp; plan and B) you entered good things, bad things and insights."/></Typography>
						<Typography variant="body1"><FormattedMessage id="ReflectionStats.help.wc2" defaultMessage="<b>Exception</b>: You can skip bad things and insights for daily reflections. The day will still be counted as fully reflected." values={commonMessageValues}/></Typography>
					</ButtonDialog>
				</>
			}
			labelCurrent={<FormattedMessage id="PlanningStats.current" defaultMessage="{numerator}/{denominator} {planType}s" values={{numerator: numerator, denominator: denominator, planType: planType}}/>}
			labelStreak={<FormattedMessage id="PlanningStats.streak" defaultMessage="{streakCurrent} {streakCurrent, plural, one {{planType}} other {{planType}s}} in a row. Max: {streakMax}." values={{streakCurrent: streakCurrent, streakMax: streakMax, planType: planType}}/>}
		/>
	);
}
