import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Box from '@material-ui/core/Box';
import PlanningStats from './PlanningStats';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import { PlanType } from '../services/plan-helpers';



const useStyles = makeStyles(theme => ({
	container: {
		position: "fixed",
		bottom: "calc(50px + env(safe-area-inset-bottom))",
		zIndex: 2,
		width: "100%",
		backgroundColor: theme.palette.grey[200],
		padding: theme.spacing(1),
		paddingBottom: theme.spacing(2),
		fallbacks: {
			bottom: "50px",
		},
	},
	close: {
		position: "absolute",
		right: theme.spacing(1),
	},
}));



interface Props {
	show: boolean,
	onClose(): void,
	numerator: number,
	denominator: number,
	streakCurrent: number,
	streakMax: number,
	planType: PlanType,
	onOpenInfo?(): void,
}

export default function PlanningStatsWidget(props: Props) {
	const {
		show,
		onClose,
		numerator,
		denominator,
		streakCurrent,
		streakMax,
		planType,
		onOpenInfo,
	} = props;
	const classes = useStyles();

	return (
		<Slide direction="up" in={show} mountOnEnter unmountOnExit>
			<Box className={classes.container}>
				<Container maxWidth="sm">
				<IconButton className={classes.close} onClick={onClose}>
					<CloseIcon/>
				</IconButton>
				<PlanningStats
					planType={planType}
					numerator={numerator}
					denominator={denominator}
					streakCurrent={streakCurrent}
					streakMax={streakMax}
					onOpenInfo={onOpenInfo}
				/>
				</Container>
			</Box>
		</Slide>
	);
}
