import React from 'react';
import Button from '@material-ui/core/Button';
import DialogNotched from './DialogNotched';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

interface Props {
	onClose(): void,
}

export default function SubscriptionPurchasedDialog(props: Props) {

	const { onClose } = props;
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<DialogNotched
			fullScreen={fullScreen}
			open={true}
			onClose={onClose}
			aria-labelledby="responsive-dialog-title"
		>
			<DialogContent>
				<Typography variant="h6" gutterBottom>Thank you!</Typography>
				<Typography variant="body1" paragraph>Your subscription is now active.</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">
					Close
				</Button>
			</DialogActions>
		</DialogNotched>
	);
}
