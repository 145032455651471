import React, { Component } from 'react';
import './App.css';
import './declarations.d.ts';
import { Route, Redirect, Switch, withRouter } from "react-router-dom";
import { Auth } from 'aws-amplify';
import gql from 'graphql-tag';
import logger from './services/logger';
import CssBaseline from '@material-ui/core/CssBaseline';
import uuidv4 from 'uuid';

import NavigationBottom from './components/NavigationBottom';

import { listLists, listObjectives, listRecurrers, listPlans } from './graphql/queriesCustom';
// import {  } from './graphql/mutationsCustom';
import { updateList, createList, deleteList, updatePlan, createObjective, updateObjective, deleteObjective, createRecurrer as createRecurrerMutation, updateRecurrer as updateRecurrerMutation, deleteRecurrer } from './graphql/mutations';
import goalImages from './assets/goal-images/goalImages';
import PlanViewController from './components/PlanViewController';
import AccountViewController from './components/AccountViewController';
import DebugViewController from './components/DebugViewController';
import ChangePasswordController from './components/ChangePasswordController';
import { dateToPeriod, PlanType, Plan, findDuplicatePlans, createPlan, loadPlans, PlanStub, PlanUnsaved, PlanState, createVirtualPlan, getPlanByTypePeriod, findPlanById, getBeginning, getEnd, isPlan, getPreviousPlanStub } from './services/plan-helpers';
import InsightsController from './components/InsightsController';
import NotificationsController from './components/NotificationsController';
import { listIsValid, listValidationErrors, List, findListById, loadLists } from './services/list-helpers';
import { Objective, ObjectiveState, getObjectivesByRecurrer, objectivesByList } from './services/objective-helpers';
import { objectiveIsValid, objectiveValidationErrors } from './services/objective-helpers';
import { Recurrer, Recurring, findRecurrerById, updateRecurring, recurringIsEqual, recurrerIsValid, recurrerValidationErrors } from './services/recurring-helpers';
import { withSnackbar } from 'notistack';
import { defineMessages, injectIntl } from 'react-intl';
import * as Sentry from '@sentry/browser';
import Button from '@material-ui/core/Button';
import ReflectionWizardController from './components/ReflectionWizardController';
import GoalDetailController from './components/GoalDetailController';
import GoalWizardController from './components/GoalWizardController';
import NotFoundView from './components/NotFoundView';
import LoadingViewController from './components/LoadingViewController';
import { User } from './components/AuthController';
import sample from 'lodash.sample';
import OnboardingController from './components/OnboardingController';
import { Subscription, SubscriptionType } from './components/SubscriptionController';
import { setScreenName, logEvent, signOut as analyticsSignOut } from './services/analytics';
import ErrorView from './components/ErrorView';
import SubscriptionPurchaseControllerV2 from './components/SubscriptionPurchaseControllerV2';
import { formatISO, parseISO, subDays, isBefore, isDate, isValid, isAfter } from 'date-fns';
import SettingsConsumerHOC from './components/SettingsConsumerHOC';
import { Settings } from './components/SettingsContext';
import appSyncClient from './AppSyncClient';
import { Environment } from '.';
import HelpView from './components/HelpView';
import RemoteConfigConsumerHOC from './components/RemoteConfigConsumerHOC';
import { RemoteConfig } from './components/RemoteConfigContext';
import GoalsViewController from './components/GoalsViewController';

const messages = defineMessages({
	errorSaveFailed: {
		id: 'errors.genericsavefailed',
		defaultMessage: 'An error occured during saving. Your changes might habe been lost.',
	},
	objectiveDeleted: {
		id: "objective.deleted",
		defaultMessage: "Deleted objective"
	},
	goalTitleDefault: {
		id: "goals.defaultTitle",
		defaultMessage: "New Goal",
	},
	undo: {
		id: "global.undo",
		defaultMessage: "Undo"
	}
});

type AppV2Props = {
	history: {
		push(url: string): void,
		goBack(): void,
	},
	enqueueSnackbar(message: string, options? : unknown): unknown,
	closeSnackbar(key: unknown): void,
	intl: {
		formatMessage(message: any): string,
	}
	user: User,
	subscription: Subscription,
	environment: Environment,
	settings: Settings,
	remoteConfig: RemoteConfig,
}

interface State {
	goals: List[],
	goalsLoaded: boolean,
	plans: Plan[],
	plansLoaded: boolean,
	objectives: Objective[],
	objectivesLoaded: boolean,
	crashed: boolean,
	crashEventId: any,
	recurrers: Recurrer[],
	recurrersLoaded: boolean,
	skipSignupPurchase: boolean,
}

class AppV2 extends Component<AppV2Props, State> {

	constructor(props: AppV2Props) {
		super(props);
		this.state = {
			// navigationOpen: false,
			// language: 'de',
			goals: [],
			goalsLoaded: false,
			plans: [],
			plansLoaded: false,
			objectives: [],
			objectivesLoaded: false,
			recurrers: [],
			recurrersLoaded: false,
			// _goals: {
			// 	byId: {
			// 		"1": {
			// 			title: "Mein erstes Ziel"
			// 		},
			// 		"2": {
			// 			title: "Mein zweites Ziel"
			// 		}
			// 	},
			// 	allIds: ["1", "2"]
			// },
			// period: "week",
			// planEditing: false,
			crashed: false,
			crashEventId: null,
			skipSignupPurchase: false,
		};
		// this.navigationClose = this.navigationClose.bind(this);
		// this.navigationOpen = this.navigationOpen.bind(this);
		// this.navigationToggle = this.navigationToggle.bind(this);
		// @ts-ignore
		window.focalityDumpData = this.dumpData;
	}

	async retrieveItems(appSyncClient: any, query: any, key: string): Promise<Array<any>> {
		const items = [];
		let nextToken = null;
		let safetyCounter = 0;
		do {
			const response = await appSyncClient.query({
				query: gql(query),
				fetchPolicy: "network-only",
				variables: {
					limit: 1000,
					nextToken: nextToken,
				},
			});
			const data: any = response.data[key];
			// logger.debug("Retrieved data", data);
			nextToken = data.nextToken;
			items.push(...data.items);
			safetyCounter++;
			if (safetyCounter > 100) {
				throw new Error("Item retrieval limit exceeded: "+safetyCounter);
			}

		} while (nextToken && safetyCounter <= 100);
		return items;
	}

	componentDidMount() {
		// this.props.enqueueSnackbar("Synching...", {autoHideDuration: 5000});
		this.retrieveItems(appSyncClient, listLists, "listLists").then(items => {
			logger.info('Retrieved lists.');
			const lists = loadLists(items, this.props.settings.experimentAData);
			// logger.debug('List data:', lists, items);
			this.setState({
				goals: lists,
				goalsLoaded: true,
			});
		}).catch(e => {
			logger.error("Error loading lists", e);
			const eventId = Sentry.captureException(e);
			this.setState({
				crashed: true,
				crashEventId: eventId,
			});
		});
		this.retrieveItems(appSyncClient, listPlans, "listPlans").then(items => {
			logger.info('Retrieved plans.');
			// logger.info('Retrieved plans:', items);
			const plans = loadPlans(items);
			const duplicates = findDuplicatePlans(plans);
			if (duplicates.length > 0) {
				logger.error('Loaded duplicate plans', duplicates);
				Sentry.captureException(new Error('Loaded duplicate plans'));
			}
			this.setState({
				plans: plans,
				plansLoaded: true,
				// {
				// 	byId: listPlans.items.reduce((obj, item) => {
				// 		obj[item.id] = item;
				// 		return obj;
				// 	}, {}),
				// 	allIds: listPlans.items.map(item => item.id)
				// }
					
			});
		}).catch(e => {
			logger.error("Error loading plans", e);
			const eventId = Sentry.captureException(e);
			this.setState({
				crashed: true,
				crashEventId: eventId,
			});
		});
		this.retrieveItems(appSyncClient, listObjectives, "listObjectives").then(items => {
			logger.debug('Retrieved objectives.');
			// logger.debug('Retrieved objectives:', items);
			const objectiveState = items.map((o: any) => {
				const objective: Objective = {
					id: o.id,
					title: o.title && o.title !== null ? o.title : "¯\\_(ツ)_/¯", // should not be null. But fill with placeholder so that app doesn't crash if the assumption fails.
					listId: o.list ? o.list.id : null,
					secondary: o.secondary,
					planId: o.plan ? o.plan.id : null,
					version: o.version,
					success: o.success,
					failedReason: o.failedReason ? o.failedReason : null,
					estimate: o.estimate,
					recurrerId: o.recurrer ? o.recurrer.id : null,
					state: ObjectiveState.default,
				}
				if (!objectiveIsValid(objective)) {
					logger.error('Loading invalid objective', objectiveValidationErrors(objective), objective, o);
					Sentry.captureException(new Error('Loaded invalid objective'));
				}
				return objective;
			});
			this.setState({
				objectives: objectiveState,
				objectivesLoaded: true,
			});
		}).catch(e => {
			logger.error("Error loading objectives", e);
			const eventId = Sentry.captureException(e);
			this.setState({
				crashed: true,
				crashEventId: eventId,
			});
		});
		this.retrieveItems(appSyncClient, listRecurrers, "listRecurrers").then(items => {
			logger.info('Retrieved recurrers.');
			// logger.info('Retrieved recurrers:', items);
			const recurrerState = items.map((item: any) => {
					const recurrer: Recurrer = {
						id: item.id,
						title: item.title && item.title !== null ? item.title : "¯\\_(ツ)_/¯", // should not be null. But fill with placeholder so that app doesn't crash if the assumption fails.
						listId: item.list ? item.list.id : null,
						secondary: item.secondary,
						planType: item.planType,
						version: item.version,
						estimate: item.estimate,
						recurring: {
							start: parseISO(item.start),
							end: item.end ? parseISO(item.end) : null,
							periodType: item.periodType,
							periodFrequency: item.periodFrequency,
							monthdayType: item.monthdayType,
							weekDays: item.weekdays,
						},
					}

					// id
					// title
					// estimate
					// secondary
					// planType
					// start
					// end
					// periodType
					// periodFrequency
					// monthdayType
					// weekDays
					// version
					// owner
					if (!recurrerIsValid(recurrer)) {
						logger.error('Loading invalid recurrer', recurrerValidationErrors(recurrer), recurrer, item);
						Sentry.captureException(new Error('Loaded invalid recurrer'));
					}
					if (!isDate(recurrer.recurring.start) || !isValid(recurrer.recurring.start)) {
						logger.error('Loading invalid recurrer. Start is not a valid date.', item);
						Sentry.captureException(new Error('Loaded invalid recurrer'));
						recurrer.recurring.start = new Date(); // dumb "repair"
					}
					return recurrer;
				});
			this.setState({
				recurrers: recurrerState,
				recurrersLoaded: true,
			// {
			// 	byId: listObjectives.items.reduce((obj, item) => {
			// 		obj[item.id] = item;
			// 		return obj;
			// 	}, {}),
			// 	allIds: listObjectives.items.map(item => item.id)
			// }
			});
		}).catch(e => {
			logger.error("Error loading recurrers", e);
			const eventId = Sentry.captureException(e);
			this.setState({
				crashed: true,
				crashEventId: eventId,
			});
		});
		try {
			Sentry.configureScope((scope) => {
				scope.setUser({"id": this.props.user.id});
			});
		} catch (err) {
			Sentry.captureException(err);
		}
	}

	dumpData = () => {
		const data = {
			lists: this.state.goals,
			plans: this.state.plans,
			objectives: this.state.objectives,
		};
		console.log('######## Data #############');
		console.log(data);
		console.log(JSON.stringify(data));
	}

	async signOut() {
		logger.info('Signing out...');
		Auth.signOut({ global: true })
    		.then(data => {
				logger.debug(data);
				appSyncClient.resetStore();
				analyticsSignOut();
			})
    		.catch(err => {
				logger.error(err);
				appSyncClient.resetStore();
				Sentry.captureException(err);
			});
		// appSyncClient.initQueryManager();
	}

	goalCreate = (title?: string, image?: string) => {
		logger.info('Creating goal...');
		const newId = uuidv4();
		if (!image)
			// image = Object.keys(goalImages)[Math.floor(Math.random() * Object.keys(goalImages).length)];
			image = sample(Object.keys(goalImages)) || Object.keys(goalImages)[0];
		if (!title)
			title = this.props.intl.formatMessage(messages.goalTitleDefault);
		const newGoalInput = {
			id: newId,
			image: image,
			title: title,
		};
		logger.debug('newGoalInput:', newGoalInput);
		// if (!listIsValid(newGoalInput))
		// 	logger.error('Invalid goal input', listValidationErrors(newGoalInput));
		try {
			appSyncClient.mutate({
				mutation: gql(createList),
				variables: {
					input: newGoalInput
				}
			}).catch(err => {
				logger.error('Create goal mutation failed:', err);
				this.props.enqueueSnackbar(this.props.intl.formatMessage(messages.errorSaveFailed));
				Sentry.captureException(err);
			});
		} catch(err) {
			logger.error('Create goal mutation failed:', err);
			this.props.enqueueSnackbar(this.props.intl.formatMessage(messages.errorSaveFailed));
			Sentry.captureException(err);
		};
		const newGoal: List = {
			id: newId,
			image: image,
			title: title,
			description: null,
			motivationWhy: null,
			motivationFail: null,
			due: null,
			archived: false,
			focus: true,
			version: 1, // needed for future mutations
		};
		logger.debug('newGoal:', newGoal);
		if (!listIsValid(newGoal))
			logger.error('Invalid goal input', listValidationErrors(newGoal));
		this.setState((prevState: any) => ({goals: [...prevState.goals, newGoal]}));
		return newGoal;
	};

	goalSave = (goal: List) => {
		logger.info('Saving goal', goal);
		// Mutate backend via AppSync
		// const updateGoalInput = pick(goal, ["id", "title", "description", "due", "motivationWhy", "motivationFail", "image"]);
		const updateGoalInput = {
			id: goal.id,
			title: goal.title !== "" ? goal.title : null,
			description: goal.description !== "" ? goal.description : null,
			due: goal.due !== "" ? goal.due: null,
			archived: goal.archived === true || false,
			motivationWhy: goal.motivationWhy !== "" ? goal.motivationWhy : null,
			motivationFail: goal.motivationFail !== "" ? goal.motivationFail : null,
			image: goal.image,
			expectedVersion: goal.version,
		};
		logger.debug("Mutating goal. Input:", updateGoalInput);
		// if (!listIsValid(updateGoalInput))
		// 	logger.error('Invalid goal input', listValidationErrors(updateGoalInput));
		try {
			appSyncClient.mutate({
				mutation: gql(updateList),
				variables: {
					input: updateGoalInput
				}
			}).catch(err => {
				logger.error('Goal save mutation failed:', err);
				this.props.enqueueSnackbar(this.props.intl.formatMessage(messages.errorSaveFailed));
				Sentry.captureException(err);
			});
		} catch(err) {
			logger.error('Goal save mutation failed:', err);
			this.props.enqueueSnackbar(this.props.intl.formatMessage(messages.errorSaveFailed));
			Sentry.captureException(err);
		};

		// Update version before saving state
		goal.version++; 
		this.goalSaveState(goal);
	}

	goalSaveState = (updatedGoal: List) => {
		logger.debug('Updating goal state', updatedGoal);
		if (!listIsValid(updatedGoal))
			logger.error('Invalid goal', listValidationErrors(updatedGoal));
		const updatedGoals = this.state.goals.map((goal: List) =>
			goal.id === updatedGoal.id
			  ? Object.assign({}, goal, updatedGoal)
			  : goal
		);
		this.setState({goals: updatedGoals});
		logger.debug('Updated goals:', updatedGoals);
	}

	goalDelete = (goal: List) => {
		logger.info('Deleting goal', goal);

		const objectives = objectivesByList(this.state.objectives, goal);
		logger.debug("Deleting "+objectives.length+" objectives from this goal...");
		const deletions: Promise<unknown>[] = [];
		objectives.forEach(o => {
			if (o.state !== ObjectiveState.virtual)
				deletions.push(this.objectiveDeleteRemotely(o));
			deletions.push(this.objectiveDeleteLocally(o));
		});

		try {
			appSyncClient.mutate({
				mutation: gql(deleteList),
				variables: {
					input: {
						id: goal.id,
						expectedVersion: goal.version
					}
				}
			}).catch(err => {
				logger.error('Goal delete mutation failed:', err);
				this.props.enqueueSnackbar(this.props.intl.formatMessage(messages.errorSaveFailed));
				Sentry.captureException(err);
			});
		} catch(err) {
			logger.error('Goal delete mutation failed:', err);
			this.props.enqueueSnackbar(this.props.intl.formatMessage(messages.errorSaveFailed));
			Sentry.captureException(err);
		};
		
		const updatedGoals = this.state.goals.filter((g: List) => g.id !== goal.id); 
		this.setState({goals: updatedGoals});
		this.props.history.push('/goals/');
	}

	goalSetArchived = (goal: List, archived: boolean) => {
		logger.info('Setting goal archived state to '+archived);
		const updatedGoal = Object.assign({}, goal, {
			archived: archived,
		});
		this.goalSave(updatedGoal);
		this.props.history.push('/goals/');
	}

	goalSetFocus = (goal: List, focus: boolean) => {
		logger.info('Setting goal archived state to '+focus);
		const updatedGoal = Object.assign({}, goal, {
			focus: focus,
		});
		this.goalSave(updatedGoal);
		if (this.props.settings.experimentA) {
			const currentData = this.props.settings.experimentAData;
			const newData = Object.assign({}, currentData, {
				listFocus: currentData && currentData.listFocus ? currentData.listFocus : {}, 
			});
			newData.listFocus[goal.id] = focus;
			this.props.settings.setExperimentAData(newData);
		}
		// this.props.history.push('/goals/');
	}

	// navigationClose() {
	// 		this.setState({navigationOpen: false});
	// }

	// navigationOpen() {
	// 	// console.log(Auth.currentSession());
	// 	this.setState({navigationOpen: true});
	// }

	// navigationToggle() {
	// 	this.setState((prevState: any, props: AppV2Props) => ({
	// 		navigationOpen: !prevState.navigationOpen
	// 	}));
	// }

	// _getPlan = (type: PlanType, period: string): Plan => {
	// 	const plan = this.state.plans.find(p => p.type === type && p.period === period);
	// 	if (plan)
	// 		return plan;
	// 	else {
	// 		const newPlan = createVirtualPlan(type, period);
	// 		this.setState((prevState: any) => {
	// 			const plans = [...prevState.plans];
	// 			if (!plans.find(p => p.id === result.plan.id)) {
	// 				plans.push(newPlan);
	// 				return {
	// 					plans: plans
	// 				};
	// 			} else {
	// 				return null;
	// 			}
	// 		});
	// 	}
	// 	// return getOrCreatePlan(type, period, this.state.plans);
	// }

	getSavedPlan = async (plan: PlanStub) => {
		const p = await this.getPlanOrCreateVirtualPlan(plan.type, plan.period);
		const savedPlan = await this.planEnsureSaved(p);
		return savedPlan;
	}

	getPlanOrCreateVirtualPlan = (type: PlanType, period: string): Promise<Plan> => {
		const plan = getPlanByTypePeriod(type, period, this.state.plans);
		if (plan)
			return Promise.resolve(plan);
		else {
			const newPlan = createVirtualPlan(type, period);
			return new Promise(resolve => {
				this.setState((prevState: any) => {
					const plans = [...prevState.plans];
					if (!getPlanByTypePeriod(type, period, plans)) {
						plans.push(newPlan);
						return {
							plans: plans
						};
					} else {
						return null;
					}
				}, () => resolve(getPlanByTypePeriod(type, period, this.state.plans)));
			});
		}
		// return getOrCreatePlan(type, period, this.state.plans);
	}

	handleCreatePlan = async (plan: Plan | PlanUnsaved) => {
		logger.debug("handleCreatePlan", plan);
		if ("state" in plan && plan.state !== PlanState.virtual && plan.state !== PlanState.creating) {
			throw new Error("Cannot create plan: Already exists");
		}
		if (isPlan(plan)) {
			if ("state" in plan && plan.state === PlanState.creating) {
				logger.error("Trying to create plan which is already being created", plan);
			} else {
				plan.state = PlanState.creating;
				this.setState((prevState: any) => ({
					plans: prevState.plans.map((prev: Plan) =>
					prev.id === plan.id
					? plan
					: prev
					)
				}));
			}
		}
		const result = await createPlan(plan, appSyncClient, this.retrieveItems);
		logger.debug('newPlan:', result.plan);
		if (result.problems) {
			logger.error('There was a problem with creating the plan.');
			this.props.enqueueSnackbar(this.props.intl.formatMessage(messages.errorSaveFailed));
		}
		if (isPlan(plan)) {
			plan.state = PlanState.default;
		}
		this.setState((prevState: any) => {
			// Add new plan to state. Replace current one from state if it already exists.
			const plans = [...prevState.plans].filter(p => p.id !== result.plan.id);
			plans.push(result.plan);
			return {
				plans: plans
			};
		});
		return result.plan;
	}

	planEnsureSaved = async (plan: Plan | PlanUnsaved): Promise<Plan> => {
		if (("id" in plan) && plan.id !== undefined && plan.state === PlanState.default) {
			return plan;
		} else {
			return this.handleCreatePlan(plan);
		}
	}

	planUpdate = async (plan: Plan | PlanUnsaved) => {
		logger.info('Updating plan...');
		
		if (!("id" in plan) || plan.id === undefined || plan.state !== PlanState.default) {
			// Plan not saved yet => create instead of update
			await this.handleCreatePlan(plan);
			return;
		}

		// Mutate backend via AppSync
		const updatePlanInput = {
			id: plan.id,
			type: plan.type,
			period: plan.period,
			complete: plan.complete,
			periodSatisfactory: plan.periodSatisfactory,
			planSatisfactory: plan.planSatisfactory,
			goodThings: plan.goodThings !== "" ? plan.goodThings : null, // DynamoDB won't store empty strings. Set to null.
			badThings: plan.badThings !== "" ? plan.badThings : null,
			insights: plan.insights !== "" ? plan.insights : null,
			expectedVersion: plan.version,
		};
		logger.debug("Mutating plan. Input:", updatePlanInput);
		try {
			appSyncClient.mutate({
				mutation: gql(updatePlan),
				variables: {
					input: updatePlanInput
				}
			}).catch(err => {
				logger.error('Update plan mutation failed:', err);
				this.props.enqueueSnackbar(this.props.intl.formatMessage(messages.errorSaveFailed));
				Sentry.captureException(err);
			});
		} catch(err) {
			logger.error('Update plan mutation failed:', err);
			this.props.enqueueSnackbar(this.props.intl.formatMessage(messages.errorSaveFailed));
			Sentry.captureException(err);
		};
		// Update version before saving state
		plan.version++; 
		this.setState((prevState: any) => ({
			plans: prevState.plans.map((prev: Plan) =>
				prev.id === plan.id
				  ? plan
				  : prev
			  )
		}));
	}

	createObjective = async (goal: List | null, plan: Plan | PlanUnsaved | null, title: string, estimate: number | null, secondary: boolean):Promise<Objective> => {
		logger.info('Creating objective "'+title+'".');
		logger.debug('Objective data:', [goal, plan, title, estimate, secondary]);
		const planSaved = plan ? 
			(!("id" in plan) || plan.id === undefined || plan.state !== PlanState.default) ? await this.handleCreatePlan(plan) : plan
			:
			null
		;
		// if (!("id" in plan) || plan.id === undefined) { // Plan not saved yet => create
		// 	plan = await this.handleCreatePlan(plan);
		// }
		const newId = uuidv4();
		const newObjectiveInput = {
			id: newId,
			title: title,
			estimate: estimate,
			secondary: secondary,
			success: null,
			objectivePlanId: planSaved ? planSaved.id : undefined,
			objectiveListId: goal ? goal.id : undefined,
		};
		// todo: Client side validation
		logger.debug('newObjectiveInput:'+ JSON.stringify(newObjectiveInput));
		try {
			appSyncClient.mutate({
				mutation: gql(createObjective),
				variables: {
					input: newObjectiveInput
				}
			}).catch(err => {
				logger.error('Create objective mutation failed:', err);
				this.props.enqueueSnackbar(this.props.intl.formatMessage(messages.errorSaveFailed));
				Sentry.captureException(err);
			});
		} catch(err) {
			logger.error('Create objective mutation failed:', err);
			this.props.enqueueSnackbar(this.props.intl.formatMessage(messages.errorSaveFailed));
			Sentry.captureException(err);
		};

		const newObjective = Object.assign({}, newObjectiveInput, {
			version: 1, // needed for future mutations
			// plan: planSaved ? planSaved : undefined,
			planId: planSaved ? planSaved.id : null,
			listId: goal ? goal.id : null,
			state: ObjectiveState.default,
			recurrerId: null,
		});
		delete newObjective.objectivePlanId;
		delete newObjective.objectiveListId;
		logger.debug('newObjective:', newObjective);
		// if (!objectiveIsValid(newObjective))
		// 	logger.error('Invalid objective', objectiveValidationErrors(newObjective));
		this.setState((prevState: any) => {
			return {
				objectives: [...prevState.objectives, newObjective]
			}
		});
		logEvent('objective_create');
		return newObjective;
	}

	recurrerCreate = (list: List | null, plan: Plan | PlanUnsaved | undefined, title: string, estimate: number | null, secondary: boolean, recurring: Recurring): Recurrer => {
		logger.info('Creating recurring objective "'+title+'".');
		logger.debug('Objective data:', [list, title, estimate, secondary]);
		const startDate = plan ? getBeginning(plan) : new Date();
		recurring.start = startDate; // ugly hack. Component which creates recurring data does not know the correct start date.
		const newRecurrer: Recurrer = {
			id: uuidv4(),
			listId: list ? list.id : null,
			title: title,
			estimate: estimate,
			secondary: secondary,
			recurring: recurring,
			planType: plan ? plan.type : PlanType.day,
			version: 1, // needed for future mutations
		};
		logger.debug('new recurring objective:', newRecurrer);

		const newRecurrerInput = {
			id: newRecurrer.id,
			title: newRecurrer.title,
			estimate: newRecurrer.estimate,
			secondary: newRecurrer.secondary,
			recurrerListId: newRecurrer.listId || undefined,
			planType: newRecurrer.planType,
			start: formatISO(newRecurrer.recurring.start, {representation: "date"}),
			end: newRecurrer.recurring.end ? formatISO(newRecurrer.recurring.end, {representation: "date"}) : null,
			periodType: newRecurrer.recurring.periodType,
			periodFrequency: newRecurrer.recurring.periodFrequency,
			monthdayType: newRecurrer.recurring.monthdayType,
			weekdays: newRecurrer.recurring.weekDays,
		};
		logger.debug('Saving to backend...', newRecurrerInput);

		try {
			appSyncClient.mutate({
				mutation: gql(createRecurrerMutation),
				variables: {
					input: newRecurrerInput
				}
			}).catch(err => {
				logger.error('Create recurrer mutation failed (inner):', err);
				this.props.enqueueSnackbar(this.props.intl.formatMessage(messages.errorSaveFailed));
				Sentry.captureException(err);
			});
		} catch(err) {
			logger.error('Create recurrer mutation failed (outer):', err);
			this.props.enqueueSnackbar(this.props.intl.formatMessage(messages.errorSaveFailed));
			Sentry.captureException(err);
		};

		this.setState((prevState: any) => ({
			recurrers: [...prevState.recurrers, newRecurrer],
		}));
		logEvent('recurrer_create');
		return newRecurrer;
	}

	recurrerSave = (recurrer: Recurrer) => {
		logger.info("Saving recurrer: ", recurrer);

		// Mutate backend via AppSync
		const updateRecurrerInput = {
			id: recurrer.id,
			title: recurrer.title,
			estimate: recurrer.estimate,
			secondary: recurrer.secondary,
			recurrerListId: recurrer.listId || undefined,
			planType: recurrer.planType,
			start: recurrer.recurring.start ? formatISO(recurrer.recurring.start, {representation: "date"}) : null,
			end: recurrer.recurring.end ? formatISO(recurrer.recurring.end, {representation: "date"}) : null,
			periodType: recurrer.recurring.periodType,
			periodFrequency: recurrer.recurring.periodFrequency,
			monthdayType: recurrer.recurring.monthdayType,
			weekdays: recurrer.recurring.weekDays,
			expectedVersion: recurrer.version,
		}
		logger.debug("Mutating recurrer. Input:", updateRecurrerInput);
		appSyncClient.mutate({
			mutation: gql(updateRecurrerMutation),
			variables: {
				input: updateRecurrerInput
			}
		}).catch(err => {
			logger.error(err);
			this.props.enqueueSnackbar(this.props.intl.formatMessage(messages.errorSaveFailed));
			Sentry.captureException(err);
		});
		// Update version before saving state
		recurrer.version++;

		// Delete virtual objectives (might be outdated, will be re-generated as needed)
		const objectives = getObjectivesByRecurrer(this.state.objectives, recurrer);
		objectives.forEach(o => {
			if (o.state === ObjectiveState.virtual) {
				// virtual objective? Has no right to exist without recurrer, clean from store
				this.objectiveDeleteLocally(o);
			}
		});

		this.setState((prevState: any) => ({
			recurrers: prevState.recurrers.map((prev: Recurrer) =>
				prev.id === recurrer.id
				  ? recurrer
				  : prev
			  )
		}));
	}

	 /**
	  * Delete an recurrer (locally and remotely)
	  * All references in objectives are removed as well
	 * @var recurrer The recurrer to be deleted
	 * @var skipObjective Don't remove reference from this objective (ugly hack because otherweise objective gets saves twice with the same version which causes an remote error)
	 * @var skipAllObjectives Don't remove any references from objectives.
	 */
	recurrerDelete = (recurrer: Recurrer, skipObjective?: Objective, skipAllObjectives?: boolean) => {
		// Delete recurrer and remove all references from objectives
		logger.info('Deleting recurrer', recurrer);

		// First, remove all references to recurrer

		if (!skipAllObjectives) {
			const objectives = getObjectivesByRecurrer(this.state.objectives, recurrer);
			objectives.forEach(o => {
				if (!skipObjective || skipObjective.id !== o.id) {
					if (o.state === ObjectiveState.virtual) {
						// virtual objective? Has no right to exist without recurrer, clean from store
						this.objectiveDeleteLocally(o);
					} else {
						o.recurrerId = null;
						this.objectiveSave(o);
					}
				}
			});
		}

		try {
			logger.debug('Executing recurrer deletion...');
			appSyncClient.mutate({
				mutation: gql(deleteRecurrer),
				variables: {
					input: {
						id: recurrer.id,
						expectedVersion: recurrer.version
					}
				}
			}).catch(err => {
				logger.error('Recurrer delete mutation failed:', err);
				this.props.enqueueSnackbar(this.props.intl.formatMessage(messages.errorSaveFailed));
				Sentry.captureException(err);
			});
		} catch(err) {
			logger.error('Recurrer delete mutation failed:', err);
			this.props.enqueueSnackbar(this.props.intl.formatMessage(messages.errorSaveFailed));
			Sentry.captureException(err);
		};
		const updatedRecurrers = this.state.recurrers.filter((g: Recurrer) => g.id !== recurrer.id); 
		this.setState({recurrers: updatedRecurrers});
	}

	/**
	 * Delete an recurrer and all existing instances
	 * @param recurrer 
	 */
	recurrerDeleteWithInstances = async (recurrer: Recurrer) => {
		logger.info("Deleting recurrer and all instances");

		const objectives = getObjectivesByRecurrer(this.state.objectives, recurrer);
		const deletions: Promise<unknown>[] = [];
		objectives.forEach(o => {
			if (o.state !== ObjectiveState.virtual)
				deletions.push(this.objectiveDeleteRemotely(o));
			deletions.push(this.objectiveDeleteLocally(o));
		});
		await Promise.all(deletions);
		this.recurrerDelete(recurrer, undefined, true);
		logEvent("recurrer_delete", {variant: "AllInstances"});
	}

	/**
	 * Delete the current and all following instances of a recurrer.
	 * @param recurrer 
	 * @param Objective Current instance
	 */
	recurrerDeleteCurrentAndFollowing = async (recurrer: Recurrer, current: Objective) => {
		logger.info("Deleting current recurrer instance and all following");
		logger.debug("Recurrer id: "+recurrer.id+", objective id: "+current.id);
		
		if (!current.planId) {
			throw new Error("recurrerDeleteCurrentAndFollowing: current objective is not associated with plan");
		}
		const plan = findPlanById(current.planId, this.state.plans);
		if (!plan) {
			throw new Error("recurrerDeleteCurrentAndFollowing: could not find plan associated with current objective");
		}
		const currentEnd = getEnd(plan);
		
		const objectives = getObjectivesByRecurrer(this.state.objectives, recurrer);
		const deletions: Promise<unknown>[] = [];
		objectives.forEach(o => {
			// delete every objective that is scheduled after the current one
			if (o.planId) {
				const p = findPlanById(o.planId, this.state.plans);
				if (!p)
					throw new Error("recurrerDeleteCurrentAndFollowing: Could not find plan associated with inspected objective");
				const pEnd = getEnd(p);
				if (isAfter(pEnd, currentEnd)) {
					if (o.state !== ObjectiveState.virtual)
						deletions.push(this.objectiveDeleteRemotely(o));
					deletions.push(this.objectiveDeleteLocally(o));
				}
			}
		});
		const updatedRecurrer = Object.assign({}, recurrer);
		const previousPlan = getPreviousPlanStub(plan);
		const endNew = getEnd(previousPlan);
		updatedRecurrer.recurring.end = endNew;
		this.recurrerSave(updatedRecurrer);
		logEvent("recurrer_delete", {variant: "CurrentAndFollowing"});
	}

	objectiveCreateFromVirtual = async (objective: Objective) => { 
		logger.debug("Manifesting virtual objective", objective);

		objective.state = ObjectiveState.default;

		if (objective.planId) {
			const plan = findPlanById(objective.planId, this.state.plans);
			if (plan) {
				await this.planEnsureSaved(plan);
			} else {
				logger.error("objectiveCreateFromVirtual: Could not find plan", objective.planId);
			}
		}
		// const plan = objective.planId ? await this.planEnsureSaved(findPlanById(objective.planId, this.state.plans)) : null;
		const newObjectiveInput = {
			id: objective.id,
			title: objective.title,
			estimate: objective.estimate,
			secondary: objective.secondary,
			success: objective.success,
			objectivePlanId: objective.planId || undefined,
			objectiveListId: objective.listId || undefined,
			objectiveRecurrerId: objective.recurrerId || undefined,
		};
		// todo: Client side validation
		logger.debug('newObjectiveInput:'+JSON.stringify(newObjectiveInput));
		try {
			appSyncClient.mutate({
				mutation: gql(createObjective),
				variables: {
					input: newObjectiveInput
				}
			})
			.catch(err => {
				logger.error('Create objective mutation failed:', err);
				this.props.enqueueSnackbar(this.props.intl.formatMessage(messages.errorSaveFailed));
				Sentry.captureException(err);
			})
		} catch(err) {
			logger.error('Create objective mutation failed:', err);
			this.props.enqueueSnackbar(this.props.intl.formatMessage(messages.errorSaveFailed));
			Sentry.captureException(err);
		};

		return new Promise((resolve: (value: Objective) => void, reject) =>
			this.setState((prevState: any) => ({
				objectives: [ ...prevState.objectives, objective]
				// objectives: prevState.objectives.map((prev: Objective) =>
				// prev.id === objective.id
				// ? objective
				// : prev
				// )
			}), () => resolve(objective))
		);
	}

	objectiveSave = async (objective: Objective, recurring?: Recurring | null) => {
		logger.info('Saving objective "'+objective.title+'".', objective);
		
		if (typeof recurring !== "undefined") {
			// Cases:
			// Was not recurring, is now
			// Was recurring, is now the same
			// Was recurring, is still recurring, but different
			// Was recurring, is no longer recurring

			const plan = objective.planId ? findPlanById(objective.planId, this.state.plans) : undefined;
			if (!objective.recurrerId && recurring) {
				// Create recurrer
				// const recurrer = recurrerCreateFromObjective(objective, plan ? plan.type : PlanType.day, recurring);
				const list = objective.listId ? findListById(objective.listId, this.state.goals) : undefined;
				const recurrer = this.recurrerCreate(list || null, plan, objective.title, objective.estimate, objective.secondary, recurring);
				objective.recurrerId = recurrer.id;
				// recurrerSave(recurrer);
			} else if (objective.recurrerId) {
				const recurrer = findRecurrerById(objective.recurrerId, this.state.recurrers);
				if (!recurrer) {
					throw new Error("Error saving objective. Could not find associated recurrer");
				}
				if (recurring === null) {
					// end recurrer
					logger.debug('Ending recurring...');
					const before = subDays(plan ? getBeginning(plan) : new Date(), 1);
					if (isBefore(before, recurrer.recurring.start)) {
						// If it is before start, then this would be the first occurence. Completely delete the recurrer instead just deactivating it.
						this.recurrerDelete(recurrer, objective);
						objective.recurrerId = null; // remove recurrer link from current objective. recurrerDelete() does that as well, but there might be race conditions.
					} else {
						// Set new end date to end of previous period and unlink current objective
						// That way the recurrence ends before and the current objective becomes
						// an independent, non-recurring objective.
						const updatedRecurrer = Object.assign({}, recurrer);
						if (!plan)
							throw new Error("Could not find plan when trying to remove recurrence from objective.");
						const previousPlan = getPreviousPlanStub(plan);
						const endNew = getEnd(previousPlan);
						updatedRecurrer.recurring.end = endNew;
						this.recurrerSave(updatedRecurrer);
						objective.recurrerId = null;
					}
					logEvent("recurrer_end");
					// out of scope of this function: maybe ask user if all open items should be removed (would remove recurrer but no non-virtual objectives)
				} else if (!recurringIsEqual(recurrer.recurring, recurring)) {
					// updaterecurrer
					logger.debug('Updating recurring...');
					const updatedRecurrer = updateRecurring(recurrer, recurring);
					this.recurrerSave(updatedRecurrer);
					logEvent("recurrer_update");
				}
			}

		}

		if (objective.state === ObjectiveState.virtual) {
			return this.objectiveCreateFromVirtual(objective);
			// this.createObjective(findListById(objective.listId), findPlanById(objective.planId), titl)
		}

		// Mutate backend via AppSync
		const updateObjectiveInput = {
			id: objective.id,
			title: objective.title !== "" ? objective.title : null,
			estimate: objective.estimate,
			secondary: objective.secondary,
			success: objective.success,
			failedReason: objective.failedReason !== "" ? objective.failedReason : null,
			objectivePlanId: objective.planId,
			objectiveRecurrerId: objective.recurrerId,
			expectedVersion: objective.version,
		};
		logger.debug("Mutating objective. Input:" + JSON.stringify(updateObjectiveInput));
		appSyncClient.mutate({
			mutation: gql(updateObjective),
			variables: {
				input: updateObjectiveInput
			}
		}).catch(err => {
			logger.error(err);
			this.props.enqueueSnackbar(this.props.intl.formatMessage(messages.errorSaveFailed));
			Sentry.captureException(err);
		});
		// Update version before saving state
		objective.version++; 
		if (!objectiveIsValid(objective))
			logger.error('Invalid objective', objectiveValidationErrors(objective));
		
		return new Promise((resolve: (value: Objective) => void, reject) => 
			this.setState((prevState: any) => ({
				objectives: prevState.objectives.map((po: Objective) =>
					po.id === objective.id
					? objective
					: po
				)
			}), () => resolve(objective))
		)
	}

	objectiveDeleteWithUndo = (objective: Objective) => {
		logger.info('Deleting objective', objective);
		const undoDuration = 5000;
		const timerId = setTimeout(() => {
			try {
				logger.debug('Executing deletion...');
				this.objectiveDeleteRemotely(objective)
					.catch(err => {
						logger.error('Objective delete mutation failed:', err);
						this.props.enqueueSnackbar(this.props.intl.formatMessage(messages.errorSaveFailed));
						Sentry.captureException(err);
					});
			} catch(err) {
				logger.error('Objective delete mutation failed:', err);
				this.props.enqueueSnackbar(this.props.intl.formatMessage(messages.errorSaveFailed));
				Sentry.captureException(err);
			};
		}, undoDuration);
		const snackbar = this.props.enqueueSnackbar(this.props.intl.formatMessage(messages.objectiveDeleted), {
			action: () =>
				<Button color="secondary" onClick={() => { 
					clearTimeout(timerId);
					this.setState((prevState: any) => ({objectives: [...prevState.objectives, objective]}));
					this.props.closeSnackbar(snackbar)
					}}>
					{this.props.intl.formatMessage(messages.undo)}
				</Button>,
			autoHideDuration: undoDuration,
		});
		this.objectiveDeleteLocally(objective);
	}

	objectiveDelete = async (objective: Objective) => {
		logger.info("Deleting objective "+objective.id+": "+objective.title);
		await this.objectiveDeleteLocally(objective);
		return this.objectiveDeleteRemotely(objective);
	}

	objectiveDeleteRemotely = (objective: Objective) => {
		return appSyncClient.mutate({
			mutation: gql(deleteObjective),
			variables: {
				input: {
					id: objective.id,
					expectedVersion: objective.version
				}
			}
		});
	}

	objectiveDeleteLocally = (objective: Objective) => {
		return new Promise((resolve, reject) => {
			this.setState(prevState => {
				const updatedObjectives = prevState.objectives.filter((g: Objective) => g.id !== objective.id); 
				return {objectives: updatedObjectives};
			}, () => resolve(undefined));
		});
	}

	objectiveToggle = (objective: Objective) => {
		logger.info('Toggling objective state');
		objective.success = !objective.success;
		const r = this.objectiveSave(objective);
		logEvent('objective_check', {success: objective.success ? "true" : "false"});
		return r;
	}

	objectiveCheck = (objective: Objective) => {
		logger.info('Marking objective as checked');
		objective.success = true;
		const r = this.objectiveSave(objective);
		logEvent('objective_check', {success: "true"});
		return r;
	}

	objectiveUncheck = (objective: Objective) => {
		logger.info('Marking objective as neither checked nor failed');
		objective.success = null;
		const r = this.objectiveSave(objective);
		logEvent('objective_check', {success: "null"});
		return r;
	}

	objectiveFail = (objective: Objective) => {
		logger.info('Marking objective as failed');
		objective.success = false;
		const r = this.objectiveSave(objective);
		logEvent('objective_check', {success: "false"});
		return r;
	}

	objectiveSetPlan = (objective: Objective, plan: Plan | PlanUnsaved | null) => {
		logger.info('Setting plan for objective', objective, plan);
		const updatedObjective = Object.assign({}, objective);
		if (!plan) {
			updatedObjective.planId = null;
			this.objectiveSave(updatedObjective);
		} else {
			this.planEnsureSaved(plan).then((plan) => {
				updatedObjective.planId = plan.id;
				this.objectiveSave(updatedObjective);
			});
		}
		logEvent('objective_schedule', {period: plan ? plan.period : ""});
	}

	// handleAuthStateChange(authState: unknown) {
	// 	this.setState({
	// 		authState: authState
	// 	});
	// }

	handleOnboardingFinish = () => {
		this.props.settings.setOnboardingSetupDone(new Date());
	}

	handleSetPlan = async (objective: Objective, planStub: PlanStub | null) => {
		const plan = planStub ? await this.getPlanOrCreateVirtualPlan(planStub.type, planStub.period) : null;
		if (plan) {
			await this.planEnsureSaved(plan);
		}
		this.objectiveSetPlan(objective, plan)
	}

	navigate = (path: string) => this.props.history.push(path)

	render() {
		if (this.state.crashed) {
			return <ErrorView eventId={this.state.crashEventId}/>;
		}

		// Show loading view until all necessary data is loaded (to prevent inconsistencies)
		if (!this.props.settings.ready())
			return <LoadingViewController what="settings"/>;
		if (!this.state.goalsLoaded)
			return <LoadingViewController what="goals"/>;
		if (!this.state.plansLoaded)
			return <LoadingViewController what="plans"/>;
		if (!this.state.objectivesLoaded)
			return <LoadingViewController what="objectives"/>;
		if (!this.state.recurrersLoaded)
			return <LoadingViewController what="recurrers"/>;

		// Show onboarding flow for new users
		// Assume new user if onboarding is true (see constructor, uses local storage to save onboarding state)
		// and data is empty. If onboarding is true but data is available, assume that user onboarded on different device.
		if (
			!this.props.settings.getOnboardingSetupDone()
			&& this.state.goals.length === 0 && this.state.plans.length === 0 && this.state.objectives.length === 0
			) {
			// Insert subscription purchase if user signed up for a paid standard plan (no trial)
			if (this.props.subscription.type === SubscriptionType.free && this.props.user.signupSubscriptionId === "standard" && !this.state.skipSignupPurchase) {
				logger.info("New user for standard plan (no trial). Showing upgrade dialog.");
				return <SubscriptionPurchaseControllerV2
					currentSubscription={this.props.subscription}
					environment={this.props.environment}
					user={this.props.user}
					onBack={() => {
						logger.info("Plan purchase aborted.");
						this.setState({skipSignupPurchase: true})
					}}/>
			} else {
				return <OnboardingController
					user={this.props.user}
					onCreateGoal={this.goalCreate}
					onCreateObjective={this.createObjective}
					doGetPlanOrCreateVirtualPlan={this.getPlanOrCreateVirtualPlan}
					onFinish={this.handleOnboardingFinish}
					environment={this.props.environment}
				/>;
			}
		}

		const sortedGoals = [...this.state.goals].sort((a,b) => a.title ? a.title.localeCompare(b.title || "") : -1);

		return (
			<React.Fragment>

			<CssBaseline />

			<NotificationsController plans={this.state.plans} objectives={this.state.objectives} doNavigate={this.navigate}/>

			{/* <NavigationMain open={this.state.navigationOpen} onClose={this.navigationClose} taskLists={this.props.tasklists}/> */}

			<div className="App">

				<Switch>
					<Route exact path="/" render={() => (
						<Redirect to={`/plans/day/${dateToPeriod(new Date(), PlanType.day)}`}/>
					)}/>
					{/* <Redirect from='/' to='/plans/day/'/> */}
					<Route exact path="/goals/" render={() => {
						setScreenName('GoalsView');
						const goToList = (goal: List) => {
							if (goal)
								this.props.history.push('/goals/'+goal.id);
							else
							this.props.history.push('/goals/other');
						};
						return (
							<GoalsViewController
								goals={sortedGoals}
								allObjectives={this.state.objectives}
								onListClick={goToList}
								onAddClick={async () => {
									if (!this.props.subscription.unlimited && sortedGoals.length > 0) {
										this.props.history.push('/subscribe/');	
									} else {
										const goal = this.goalCreate();
										this.props.history.push('/goals/'+goal.id+'/edit');
									}
								}}
								onCreateObjective={this.createObjective}
							/>
						);
					}}/>
					<Route exact path="/goals/:goalId" render={(props) => {
						// <GoalEditView goal={sortedGoals.find((goal) => goal.id === props.match.params.goalId)} plans={this.state.plans} objectives={this.state.objectives} onBack={() => this.props.history.push('/goals/')} onSave={this.goalSave} onDelete={this.goalDelete} objectivesCascade={true}/>
						const goal = sortedGoals.find((goal) => goal.id === props.match.params.goalId);
						
						if (!goal && props.match.params.goalId !== "other")
							return <NotFoundView/>
						else
							return <GoalDetailController 
								goal={goal || null}
								objectives={this.state.objectives}
								plans={this.state.plans}
								allRecurrers={this.state.recurrers}
								onBack={() => this.props.history.push('/goals/')}
								onDelete={this.goalDelete}
								onSetArchived={this.goalSetArchived}
								onSetFocus={this.goalSetFocus}
								onEdit={() => this.props.history.push('/goals/'+props.match.params.goalId+'/edit')}
								onSetPlan={this.handleSetPlan}
								onSaveObjective={this.objectiveSave}
								onDeleteObjective={this.objectiveDeleteWithUndo}
								onCreateObjective={this.createObjective}
								onCheckObjective={this.objectiveCheck}
								onFailObjective={this.objectiveFail}
								onUncheckObjective={this.objectiveUncheck}
								getSavedPlan={this.getSavedPlan}
								onDeleteRecurrerWithInstances={this.recurrerDeleteWithInstances}
								onDeleteRecurrerCurrentAndFollowing={this.recurrerDeleteCurrentAndFollowing}
								/>
					}}/>
					<Route exact path="/plans/:type/:period" render={(props) => (
						<PlanViewController
							history={props.history}
							goals={sortedGoals}
							type={props.match.params.type}
							period={props.match.params.period}
							objectives={this.state.objectives} 
							recurrers={this.state.recurrers}
							plans={this.state.plans}
							user={this.props.user}
							createVirtualPlan={this.getPlanOrCreateVirtualPlan}
							onSavePlan={this.planUpdate}
							onEnsurePlanIsSaved={this.planEnsureSaved}
							onSaveObjective={this.objectiveSave}
							onDeleteObjective={this.objectiveDeleteWithUndo}
							doCreateObjective={this.createObjective}
							doCreateRecurrer={this.recurrerCreate}
							onCheckObjective={this.objectiveCheck}
							onFailObjective={this.objectiveFail}
							onToggleObjective={this.objectiveToggle}
							onUncheckObjective={this.objectiveUncheck}
							// onSetPlan={this.objectiveSetPlan}
							onSetPlan={this.handleSetPlan}
							doGetPlanOrCreateVirtualPlan={this.getPlanOrCreateVirtualPlan}
							getSavedPlan={this.getSavedPlan}
							doDeleteRecurrerWithInstances={this.recurrerDeleteWithInstances}
							doDeleteRecurrerCurrentAndFollowing={this.recurrerDeleteCurrentAndFollowing}
						/>
					)}/>
					<Route path="/insights" render={(props) => (
						<InsightsController
							goals={sortedGoals}
							plans={this.state.plans}
							objectives={this.state.objectives}
							recurrers={this.state.recurrers}
						/>
					)}/>
					<Route exact path="/account/" render={(props) => (
						<AccountViewController
							onSignOut={this.signOut}
							onChangePassword={() => this.props.history.push('/account/change-password/')}
							user={this.props.user}
							subscription={this.props.subscription}
							onSubscribe={() => this.props.history.push('/subscribe/')}
							environment={this.props.environment}
							onDebug={() => this.props.history.push('/debug/')}
							onHelp={() => this.props.history.push('/help/')}
						/>
					)}/>
					<Route exact path="/account/change-password/" render={(props) => (
						<ChangePasswordController onBack={() => this.props.history.push('/account/')}/>
					)}/>
					<Route exact path="/help/" render={(props) => (
						<HelpView
							showPhoneNumber={this.props.remoteConfig.helpShowPhone}
						/>
					)}/>
					<Route exact path="/plans/:type/:period/reflect"/>
					<Route exact path="/subscribe/"/>
					<Route exact path="/goals/:goalId/edit"/>
					<Route exact path="/debug/" render={(props) => (
						<DebugViewController
							user={this.props.user}
							environment={this.props.environment}
							subscription={this.props.subscription}
						/>
					)}/>
					<Route><NotFoundView/></Route>
				</Switch>
				<Route path="/:section/" render={(props) => 
					(
						["plans", "goals", "insights", "account", "debug", "help"].indexOf(props.match.params.section) !== -1
						&& !props.location.pathname.match(/^\/goals\/.*\/edit$/g) // Hide navigation in goal edit view (which is fullscreen)
						&&
						<NavigationBottom
							// @ts-ignore
							onNavigatePlans={() => {this.props.history.push('/')}}
							// @ts-ignore
							onNavigateGoals={() => {this.props.history.push('/goals/')}}
							// @ts-ignore
							onNavigateInsights={() => {this.props.history.push('/insights/')}}
							// @ts-ignore
							onNavigateAccount={() => {this.props.history.push('/account/')}}
							onNavigateHelp={() => {this.props.history.push('/help/')}}
							selected={props.match.params.section}
						/>
					)
				}/>


				<Route exact path="/subscribe/" render={(props) => (
					<SubscriptionPurchaseControllerV2 currentSubscription={this.props.subscription} environment={this.props.environment} user={this.props.user} onBack={() => {this.props.history.goBack();}}/>
				)}/>
				<Route exact path="/plans/:type/:period/reflect" render={(props) => (
						<ReflectionWizardController
							history={props.history}
							type={props.match.params.type}
							period={props.match.params.period}
							createVirtualPlan={this.getPlanOrCreateVirtualPlan}
							lists={sortedGoals}
							plans={this.state.plans}
							objectives={this.state.objectives}
							allRecurrers={this.state.recurrers}
							onSavePlan={this.planUpdate}
							onSaveObjective={this.objectiveSave}
							onCheckObjective={this.objectiveCheck}
							onUncheckObjective={this.objectiveUncheck}
							onCreateObjective={this.createObjective}
							onGetPlanOrCreateVirtualPlan={this.getPlanOrCreateVirtualPlan}
						/>
					)}/>

				<Route exact path="/goals/:goalId/edit" render={(props) => {
					const goal = sortedGoals.find((goal) => goal.id === props.match.params.goalId);
					if (!goal)
						return <NotFoundView/>
					else return (
						<GoalWizardController goal={goal} onClose={() => this.props.history.push('/goals/'+props.match.params.goalId)} onSave={this.goalSave}/>
					);
				}}/>

				<Route exact path="/datadump" render={(props) => {
					this.dumpData();
					return null;
				}}/>


			</div>
			</React.Fragment>
		);
	}
}

// export default withRouter(AppV2);
const authApp:any = RemoteConfigConsumerHOC(SettingsConsumerHOC(injectIntl(withSnackbar(withRouter(AppV2 as any) as any) as any) as any));
// const authApp = withAuthenticator(injectIntl(withSnackbar(withRouter(AppV2 as any) as any) as any), false, [
// 	<SignInController/>,
// 	<ConfirmSignIn/>,
// 	<VerifyContact/>,
// 	<ForgotPassword/>,
// 	<RequireNewPassword />
//   ]);
// authApp.defaultProps = { authState: 'signUp' }
  
export default authApp;