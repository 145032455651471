import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';


import {
	ResponsiveContainer,
	BarChart, Bar, XAxis, YAxis, 
	Tooltip, 
	} from 'recharts';

function CompletionByGoalBars(props) {

	const theme = useTheme();
	const data = props.data;
	const maxTime = data.reduce((a, g) => g.estimateSum > a ? g.estimateSum : a, 0);

	if (props.time && maxTime === 0)
		return <Typography variant="body1"><FormattedMessage id="insights.nodata" defaultMessage="Not enough data."/></Typography>;

	return (
		<ResponsiveContainer width="100%" height={(data.length*40)+20}>

			<BarChart layout="vertical" data={data}
						margin={{top: 5, right: 30, left: 20, bottom: 5}}>
				<Tooltip/>
				<Bar dataKey={props.time ? "estimateSum" : "completionRate"} fill={theme.palette.secondary.main} />
				<YAxis dataKey="title" type="category" mirror={true} tick={{width: 300, fill: 'white'}}/>
				<XAxis type="number" domain={props.time ? [0, maxTime] : [0, 100]}/>
			</BarChart>

		</ResponsiveContainer>
	);
}

CompletionByGoalBars.propTypes = {
	data: PropTypes.array.isRequired,
	time: PropTypes.bool,
};

export default CompletionByGoalBars;