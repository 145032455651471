import * as log from 'loglevel';
import { logEvent } from './analytics';
import * as Sentry from '@sentry/browser';

log.setDefaultLevel(typeof STORYBOOK_ENV !== 'undefined' ? 'debug' : process.env.REACT_APP_LOGLEVEL);

function logError() {
	log.error.apply(this, arguments);
	logEvent("app_error", {
		message: String(arguments[0]),
	});
	Sentry.addBreadcrumb({
		message: String(arguments[0]),
		level: Sentry.Severity.Error,
	});
}

function logDebug() {
	log.debug.apply(this, arguments);
	Sentry.addBreadcrumb({
		message: String(arguments[0]),
		level: Sentry.Severity.Debug,
	});
}

function logInfo() {
	log.info.apply(this, arguments);
	Sentry.addBreadcrumb({
		message: String(arguments[0]),
		level: Sentry.Severity.Info,
	});
}

function logWarn() {
	log.warn.apply(this, arguments);
	Sentry.addBreadcrumb({
		message: String(arguments[0]),
		level: Sentry.Severity.Warning,
	});
}

const logger = {
	trace: log.trace,
	debug: logDebug,
	info: logInfo,
	warn: logWarn,
	error: logError,
}

export default logger;