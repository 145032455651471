import React, { useState, useContext, useEffect } from 'react';
import AccountView from './AccountView';
import { User } from './AuthController';
import { Subscription, SubscriptionCategory } from './SubscriptionController';
import SubscriptionPurchaseControllerV2 from './SubscriptionPurchaseControllerV2';
import { setScreenName, logEvent } from '../services/analytics';
import SubscriptionStatusTrial from './SubscriptionStatusTrial';
import SubscriptionStatusPaidV2 from './SubscriptionStatusPaidV2';
import SubscriptionStatusFree from './SubscriptionStatusFree';
import SubscriptionChangeDialog from './SubscriptionChangeDialog';
import SettingsContext from './SettingsContext';
import { checkNotificationsPermission, getNotificationPermissionStatus } from '../services/notifications-helpers';
import { useSnackbar } from 'notistack';
// import logger from '../services/logger.js';


// interface PlanStatusProps {
// 	subscription: Subscription,
// }
// function SubscriptionStatus(props: PlanStatusProps) {
// 	switch (props.subscription.state) {
// 		default:
// 			return <SubscriptionStatusTrial title={props.subscription.title}/>
// 	}
// }

interface Props {
	user: User,
	subscription: Subscription,
	onSignOut(): void,
	onChangePassword(): void,
	environment: any,
	onSubscribe(): void,
	onDebug(): void,
	onHelp(): void,
}

export default function AccountViewController(props: Props) {
	const { subscription, environment, onSubscribe, user } = props;

	const [subscribe, setSubscribe] = useState(false);
	const [change, setChange] = useState(false);
	const [debugCounter, setDebugCounter] = useState(1);
	const [notificationsPermission, setNotificationsPermission] = useState<boolean|null>(null);
	const settings = useContext(SettingsContext);
	const { enqueueSnackbar } = useSnackbar();

	const updateNotificationStatus = () => getNotificationPermissionStatus().then((result) => setNotificationsPermission(result));
	useEffect(() => {
		updateNotificationStatus();
	}, []);

	const notificationsEnabled = (settings.getNotificationsEnabled() && notificationsPermission === true);

	const doDebug = () => {
		if (debugCounter >= 3) {
			props.onDebug();
			// const debugInput = prompt();
			// if (debugInput)
			// 	subscription.doOverride(debugInput);
			// subscription.doRefresh();
		}
		setDebugCounter(debugCounter+1);
	}

	const statusWidget = (subscription: Subscription) => {
		switch (subscription.category) {
			case SubscriptionCategory.paid:
				return <SubscriptionStatusPaidV2 title={subscription.title} onChange={() => {setChange(true)}} expirationDate={subscription.expirationDate}/>
			case SubscriptionCategory.trial:
				return <SubscriptionStatusTrial title={subscription.title} onUpgrade={onSubscribe} expirationDate={subscription.expirationDate}/>
			default:
				return <SubscriptionStatusFree title={subscription.title} onUpgrade={onSubscribe}/>
		}
	}

	const toggleNotificationsEnabled = () => {
		if (!notificationsEnabled) {
			logEvent('notifications_enable');
			checkNotificationsPermission().then(result => {
				setNotificationsPermission(result);
				if (!result) {
					enqueueSnackbar("Failed to enable notifications. Did you deny the permission before? You will then have to re-enable them in your system or browser settings. Contact us if you need help with that.");
				}
			});
			settings.setNotificationsEnabled(true);
		} else {
			logEvent('notifications_disable');
			settings.setNotificationsEnabled(false);
		}
	}

	if (subscribe) {
		return <SubscriptionPurchaseControllerV2 onBack={() => setSubscribe(false)} currentSubscription={subscription} environment={environment} user={user}/>
	} else {
		setScreenName('AccountView');
		return (
			<React.Fragment>
				<SubscriptionChangeDialog open={change} onClose={() => setChange(false)}/>
				<AccountView
					onDebugClick={doDebug}
					onSignOut={props.onSignOut}
					onHelp={props.onHelp}
					email={user.email}
					subscription={props.subscription}
					subscriptionStatus={statusWidget(props.subscription)}
					onUpgradeSubscription={() => setSubscribe(true)}
					onChangePassword={props.onChangePassword}
					environment={props.environment}
					notificationsEnabled={notificationsEnabled}
					toggleNotificationsEnabled={toggleNotificationsEnabled}
				/>
			</React.Fragment>
			);
	}
}
