import React, { useState } from 'react';
import logger from '../services/logger';
import { Auth } from 'aws-amplify';
import ChangePassword from './ChangePassword';
import { useSnackbar } from 'notistack';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
	missingfields: {
		id: 'changePassword.missingfields',
		defaultMessage: 'Please enter all fields.',
	},
	nomatch: {
		id: 'changePassword.nomatch',
		defaultMessage: 'New passwords did not match. Please try again.',
	},
	confirmed: {
		id: 'changePassword.confirmed',
		defaultMessage: 'Password changed.',
	},
	currentPasswordWrong: {
		id: 'changePassword.currentPasswordWrong',
		defaultMessage: 'The provided current password is not correct.',
	},
	proposedPasswordWrong: {
		id: 'changePassword.proposedPasswordWrong',
		defaultMessage: 'The new password does not meet the requirements. Password have to be at least 6 characters long.',
	},
	unknownerror: {
		id: 'changePassword.unknownerror',
		defaultMessage: 'An unknwon error occurred. Password could not be changed.',
	},
});

interface Props {
	onBack(): void,
}

function AccountViewController(props: Props) {
	const {
		onBack,
	} = props;
	const { enqueueSnackbar } = useSnackbar();
	const { formatMessage } = useIntl();
	const [currentPW, setCurrentPW] = useState("");
	const [newPW, setNewPW] = useState("");
	const [newPWConfirmation, setNewPWConfirmation] = useState("");
	
	const handleSave = () => {
		logger.debug('Changing password');
		if (currentPW === "" || newPW === "" || newPWConfirmation === "") {
			logger.debug('Not all field filled. Abort.');
			enqueueSnackbar(formatMessage(messages.missingfields), { variant: 'warning' });
		} else if (newPW !== newPWConfirmation) {
			logger.debug('New password did not match. Abort.');
			enqueueSnackbar(formatMessage(messages.nomatch), { variant: 'error' });
		} else {
			logger.debug('Initiating change...');
			Auth.currentAuthenticatedUser({
				bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
			})
			.then(user => {
				return Auth.changePassword(user, currentPW, newPW);
			})
			.then(data => {
				logger.info('Password changed.');
				logger.debug(data);
				enqueueSnackbar(formatMessage(messages.confirmed), { variant: 'success'});
			})
			.catch(err => {
				logger.debug('Error:', err);
				if ((err && err.code === "NotAuthorizedException")
					|| (err && err.message && err.message.includes('Value at \'previousPassword\' failed'))) {
					logger.info('Current password is wrong. Unchanged.');
					enqueueSnackbar(formatMessage(messages.currentPasswordWrong), { variant: 'error'});
				} else if (err && err.message && err.message.includes('Value at \'proposedPassword\' failed')) {
					logger.info('Proposed password too weak. Unchanged.');
					enqueueSnackbar(formatMessage(messages.proposedPasswordWrong), { variant: 'error'});
				} else {
					logger.error("Unknown during password change", err);
					enqueueSnackbar(formatMessage(messages.unknownerror), { variant: 'error'});
				}
			});
		}
	};

	return (
		<ChangePassword onCancel={onBack} onSave={handleSave} currentPW={currentPW} onChangeCurrentPW={(value) => setCurrentPW(value)} newPW={newPW} onChangeNewPW={(value) => setNewPW(value)} newPWConfirmation={newPWConfirmation} onChangeNewPWConfirmation={(value) => setNewPWConfirmation(value)}/>
	);
}

export default AccountViewController;