import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { AppState, Plugins } from '@capacitor/core';
import logger from '../services/logger';
import LoadingView from './LoadingView';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';


export default function LoadingViewController(props: {
	what?: string,
	timeSpent?: MutableRefObject<number>,
}) {
	const [showReload, setShowReload] = useState(false);
	let history = useHistory();
	const timeSpentLocal = useRef(0);
	const timeSpent = props.timeSpent ? props.timeSpent : timeSpentLocal;

	useEffect(() => {
		timeSpent.current = 0;
		let active = true;
		logger.debug("LoadingViewController init");
		const listener = Plugins.App.addListener('appStateChange', (state: AppState) => {
			logger.debug('App state changed. Is active?', state.isActive);
			active = state.isActive;
		});
		const timer = setInterval(() => {
			if (active)
				timeSpent.current++;
			// else logger.debug("LoadingViewController: Inactive, not counting...");
			// logger.debug("Loading timer: ", timeSpent);
			if (timeSpent.current === 15) {
				setShowReload(true);
				logger.error("LoadingViewController timeout. What: " + props.what);
				Sentry.captureException(new Error("LoadingViewController timeout. What: "+props.what));
			}
		}, 1000);
		return () => {
			listener.remove();
			clearInterval(timer);
		}
	}, [props.what, timeSpent]);
	
	const handleReload = () => {
		history.push("/");
		window.location.reload();
	}

	return (
		<LoadingView what={props.what} showReload={showReload} onReload={handleReload}/>
	);
}
