import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import logger from '../services/logger';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import { PlanType, PlanStub } from '../services/plan-helpers';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
// import InputLabel from '@material-ui/core/InputLabel';
import PlanChooser from './PlanChooser';
import DialogNotched from './DialogNotched';
import ImageHeader from './ImageHeader';
import moodImage from '../assets/misc-images/aaron-thomas-wsi9Te1yNwA-unsplash.1920.jpg';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import { WhatNowState } from './FailedObjectiveDialogController';

const useStyles = makeStyles(theme => ({
	headingGrid: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(2),
	},
	periodControls: {
		textAlign: "right"
	},
	back: {
		textAlign: "left"
	},
	forward: {
		textAlign: "right"
	},
	title: {
		textAlign: "center"
	},
	periodChooser: {
		textAlign: "center",
	},
	periodChooserButton: {
		height: "40px",
	},
	unscheduled: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(2),
		textAlign: "center",
		height: "48px",
		boxSizing: "content-box",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	whatNow: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
	},
	recurringNotice: {
		marginTop: theme.spacing(4),
	},
	titleRoot: {
		paddingLeft: 0,
		paddingRight: 0,
		marginTop: theme.spacing(3) * -1,
	},
	chip: {
		marginRight: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	whatNowTitle: {
		marginTop: theme.spacing(4),
	},
}));


interface Props {
	onCancel(): void,
	onSave(): void,
	objectiveTitle: string,
	isRecurring: boolean,
	onChangeRescheduleTarget(plan: null|PlanStub): void,
	onChangeFailedReason(reason: string): void,
	initialPeriod: string | null,
	initialType: PlanType | null,
	suggestedReasons: string[],
	initialFailedReason: string,
	whatNowState: WhatNowState,
	onChangeWhatNowState(state: WhatNowState): void,
	nextPlan?: PlanStub | null,
}

export default function FailedObjectiveDialog(props: Props) {
	const { 
		onCancel,
		onSave,
		objectiveTitle,
		isRecurring,
		onChangeRescheduleTarget,
		onChangeFailedReason,
		initialPeriod,
		initialType,
		suggestedReasons,
		initialFailedReason,
		whatNowState,
		onChangeWhatNowState,
		// nextPlan,
	} = props;
	// const suggestedReasons = Array(200).fill("").map(() => String(Math.round(Math.random() * 20000)));
	const theme = useTheme();
	const { formatMessage, locale } = useIntl();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const [failedReason, setFailedReason] = useState(initialFailedReason);
	const [showCustomReason, setShowCustomReason] = useState(false);

	const handleReasonClick = (reason: string) => {
		if (reason === failedReason) {
			setFailedReason("");
			onChangeFailedReason("");
		} else {
			setFailedReason(reason);
			onChangeFailedReason(reason);
		}
		setShowCustomReason(false);
	}

	const handleOtherReasonClick = () => {
		setShowCustomReason(!showCustomReason);
		setFailedReason("");
		onChangeFailedReason("");
	}

	// logger.debug("Initial failed reason:", initialFailedReason, failedReason);

	const reasons = locale === "de" ?
		[
			"Wurde abgelenkt",
			"Dauerte länger als gedacht",
			"Andere Vorhaben brauchten länger",
			"Vergessen",
			"Störung durch Dritte",
			"Motivationsschwäche",
		]
		:
		[
			"Got distracted",
			"Took longer than expected",
			"Other objectives took longer",
			"Forgotten",
			"Third party intervened",
			"Lack of motivation",
		]
	;

	return (
		<DialogNotched
			fullScreen={fullScreen}
			open={true}
			onClose={onCancel}
			aria-labelledby="responsive-dialog-title"
		>
			{/* <DialogTitle id="responsive-dialog-title">Schedule Objective</DialogTitle> */}
			<DialogTitle id="responsive-dialog-title" classes={{root:classes.titleRoot}}>
				{/* <ImageHeader
					image={moodImage}
					title="A setback?"
					subtitle={"Mark objective as missed: "+objectiveTitle}
				/> */}
				<ImageHeader
					image={moodImage}
					title={formatMessage({id: "FailedObjectiveDialog.title", defaultMessage: "Missed objective"})}
					subtitle={objectiveTitle}
				/>
				{/* <FormattedMessage id="FailedObjectiveDialog.title" defaultMessage="Missed: {objectiveTitle}" values={{objectiveTitle: objectiveTitle}}/> */}
			</DialogTitle>
			<DialogContent >

				<Typography variant="h6" gutterBottom paragraph><FormattedMessage id="FailedObjectiveDialog.titleReason" defaultMessage="What happened?"/></Typography>

				{reasons.map(reason => 
					<Chip key={reason} label={reason} className={classes.chip} color={reason === failedReason ? "primary" : undefined} onClick={() => handleReasonClick(reason)}/>
				)}
				<Chip label={<FormattedMessage id="FailedObjectiveDialog.other" defaultMessage="Other"/>} className={classes.chip} color={showCustomReason ? "primary" : undefined} onClick={handleOtherReasonClick}/>

				{showCustomReason && 
					<Autocomplete
						freeSolo
						disableClearable
						options={suggestedReasons}
						inputValue={failedReason}
						onInputChange={(e, value) => {
							logger.debug("Reason changed", e, value, typeof value);
							if (e) { // Hack: Only change value if e is set. Otherwise it resets value to empty on initialization.
								setFailedReason(value);
								onChangeFailedReason(value);
							}
						}}
						renderInput={(params) => (
							<TextField
								label={<FormattedMessage id="objectivesTableRow.labelFail" defaultMessage="What happened?"/>}
								fullWidth
								{...params}
								// value={failedReason}
								// onChange={(e) => {
								// 	const value = e.target.value;
								// 	logger.debug("Reason changed (textfield)", value);
								// 	setFailedReason(value);
								// 	onChangeFailedReason(value);
								// }}
							/>
						)}
					/>
				}
			{/* </DialogContent>
			<DialogContent> */}
				<React.Fragment>
					<Typography variant="h6" gutterBottom paragraph className={classes.whatNowTitle}><FormattedMessage id="FailedObjectiveDialog.nextTitle" defaultMessage="What now?"/></Typography>
					<Chip label={<FormattedMessage id="FailedObjectiveDialog.drop" defaultMessage="Drop it"/>} color={whatNowState === WhatNowState.drop ? "primary" : undefined} className={classes.chip} onClick={() => {onChangeWhatNowState(WhatNowState.drop);}}/>
					{/* {nextPlan &&
						<Chip label="Plan for tomorrow" color={whatNowState === WhatNowState.next ? "primary" : undefined} className={classes.chip} onClick={() => onChangeWhatNowState(WhatNowState.next)}/>
					} */}
					<Chip label={<FormattedMessage id="FailedObjectiveDialog.keep" defaultMessage="Reschedule"/>} color={whatNowState === WhatNowState.choose ? "primary" : undefined} className={classes.chip} onClick={() => onChangeWhatNowState(WhatNowState.choose)}/>

					{/* <FormControl className={classes.whatNow}>
						<InputLabel><FormattedMessage id="FailedObjectiveDialog.nextTitle" defaultMessage="What now?"/></InputLabel>
						<Select
							value={reschedule ? "keep" : "drop"}
							onChange={(e) => {
								logger.debug("What now? Changed: ", e);
								setReschedule(e.target.value === "keep");
							}}
							displayEmpty
							// className={classes.selectEmpty}
							inputProps={{ 'aria-label': 'Without label' }}
						>
							<MenuItem value="drop"><FormattedMessage id="FailedObjectiveDialog.drop" defaultMessage="Let it be for now"/></MenuItem>
							<MenuItem value="keep"><FormattedMessage id="FailedObjectiveDialog.keep" defaultMessage="Reschedule objective"/></MenuItem>
						</Select>
					</FormControl> */}
					
					{/* <Typography variant="body2" paragraph className={classes.rescheduleDescription}>Choose a plan to add a copy of your objective to</Typography> */}
					
					{whatNowState === WhatNowState.choose &&
						<React.Fragment>
							<Box marginTop={2}>
								<PlanChooser initialPeriod={initialPeriod} initialType={initialType} onChange={onChangeRescheduleTarget}/>
							</Box>
						</React.Fragment>
					}
					{isRecurring && 
						<React.Fragment>
							<Typography variant="body1" className={classes.recurringNotice}><FormattedMessage id="FailedObjectiveDialog.recurringNotice" defaultMessage="Note: This is a recurring objective. The next iteration will be planned as scheduled, regardless of your selection above. If you choose to reschedule, an additional copy will be created."/></Typography>
						</React.Fragment>
					}
				</React.Fragment>

			</DialogContent>
			<DialogActions>
				<Button onClick={onCancel} color="primary" data-analytics-id="FailedObjectiveDialog-cancel">
					<FormattedMessage id="SchedulerDialog.cancel" defaultMessage="Cancel"/>
				</Button>
				<Button onClick={onSave} color="primary" autoFocus data-analytics-id="FailedObjectiveDialog-save">
					<FormattedMessage id="SchedulerDialog.save" defaultMessage="Save"/>
				</Button>
			</DialogActions>
		</DialogNotched>
	);
}
