import React, { useState, ReactNode } from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
// import InfoIcon from '@material-ui/icons/Info';
import HelpIcon from '@material-ui/icons/Help';
import DialogNotched from './DialogNotched';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { FormattedMessage } from 'react-intl';

export default function ButtonDialog(props: {
		children: React.ReactNode,
		analyticsId?: string,
		icon?: ReactNode,
		onOpen?(): void,
		edge?: 'start' | 'end' | false;
	}) {
	const [open, setOpen] = useState(false);
	
	const onClose = () => setOpen(false);
	const onOpen = () => {
		setOpen(true);
		if (props.onOpen)
			props.onOpen();
	};
	
	return (
		<React.Fragment>
			<IconButton onClick={onOpen} edge={props.edge} data-analytics-id={props.analyticsId || "button-dialog"}>
				{ props.icon || <HelpIcon fontSize="small"/> }
			</IconButton>
			<DialogNotched
				open={open}
				onClose={onClose}
				aria-labelledby="responsive-dialog-title"
			>
				<DialogContent>
					{props.children}
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose} color="primary">
						<FormattedMessage id="ButtonDialog.close" defaultMessage="Close"/>
					</Button>
				</DialogActions>
			</DialogNotched>
		</React.Fragment>
	);	
}
