import Auth from '@aws-amplify/auth';

import React, { useState, useEffect } from 'react';
import SignUpForm from './SignUpForm';
import SignUpAwaitingConfirmation from './SignUpAwaitingConfirmation';
import SignUpError from './SignUpError';
import SignUpLoading from './SignUpLoading';
// import SignUpChooseSubscription from './SignUpChooseSubscription';
import logger from '../services/logger';
import { useSnackbar } from 'notistack';
import { setScreenName, logEvent } from '../services/analytics';
import { Environment } from '..';
import * as Sentry from '@sentry/browser';
// import { getUrlParameter } from '../services/getUrlParameter';
import { defineMessages, useIntl } from 'react-intl';
import { SignUpOptins } from './AuthController';
// import SignUpProviderChoice from './SignUpProviderChoice';

enum SignUpStates {
	chooser,
	form,
	awaitingConfirmation,
	error,
	loading,
	// providerChoice,
}

// enum Subscription {
// 	free = "free",
// 	standardTrial = "standard-trial",
// 	standard = "standard",
// }

export interface SignUpValidationErrors {
	email?: string,
	password?: string,
	optinTOSPP?: string,
	optinMarketing?: string,
}

const messages = defineMessages({
	titleFree: {
		id: 'subscriptions.free.title',
		defaultMessage: 'Free Basic Plan',
	},
	titleStandardTrial: {
		id: 'subscriptions.standard-trial.title',
		defaultMessage: 'Standard Plan (Free Trial)',
	},
	titleStandard: {
		id: 'subscriptions.standard.title',
		defaultMessage: 'Standard Plan',
	},
	validationErrorOptinMarketing: {
		id: "signup.validation.optinMarketing",
		defaultMessage: "On this plan, you must agree to be contacted. Choose a different plan if you prefer not to be contacted.",
	},
	validationErrorEmail: {
		id: "signup.validation.email",
		defaultMessage: "Please enter your email address.",
	},
	validationErrorPassword: {
		id: "signup.validation.password",
		defaultMessage: "Please choose a password.",
	},
	validationErrorPasswordLength: {
		id: "signup.validation.passwordLength",
		defaultMessage: "Your password must have at least 6 characters.",
	},
	validationErrorOptinTOSPP: {
		id: "signup.validation.optinTOSPP",
		defaultMessage: "Please agree to our terms.",
	},
});

interface Props {
	onBack(): void,
	onSignIn(): void,
	environment: Environment,
	optins: SignUpOptins,
}

function SignUpController(props: Props) {
	const { onSignIn, onBack, environment, optins } = props;

	// const [status, setStatus] = useState(getUrlParameter("plan") !== "" ? SignUpStates.form : SignUpStates.chooser);
	const [status, setStatus] = useState(SignUpStates.form);
	const [error, setError] = useState("");
	const [validationErrors, setValidationErrors] = useState<SignUpValidationErrors>({});
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	// const [optinTOSPP, setOptinTOSPP] = useState(false);
	// const [optinMarketing, setOptinMarketing] = useState(false);
	// const [subscription, setSubscription] = useState(getUrlParameter("plan") !== "" ? getUrlParameter("plan") : Subscription.free);
	// const [subscriptionTitle, setSubscriptionTitle] = useState("Unknown subscription");
	const { formatMessage, locale } = useIntl();
	const { enqueueSnackbar } = useSnackbar();

	// useEffect(() => {
	// 	switch (subscription) {
	// 		case Subscription.standard:
	// 			setSubscriptionTitle(formatMessage(messages.titleStandard));
	// 			break;
	// 		case Subscription.standardTrial:
	// 			setSubscriptionTitle(formatMessage(messages.titleStandardTrial));
	// 			break;
	// 		case Subscription.free:
	// 			setSubscriptionTitle(formatMessage(messages.titleFree));
	// 			break;
	// 		default:
	// 			logger.error('Unknown subscription', subscription);
	// 			setSubscriptionTitle(formatMessage(messages.titleFree));
	// 	}
	// }, [subscription, formatMessage]);

	function errorHandler(error: any) {
		logger.error(error);
		if (typeof error.message === "string") {
			setError(error.message);
		}
		Sentry.captureException(error);
		setStatus(SignUpStates.error);
	}

	function validate() {
		const errors: SignUpValidationErrors = {};
		if (!email)
			errors.email = formatMessage(messages.validationErrorEmail);
		if (!password)
			errors.password = formatMessage(messages.validationErrorPassword);
		else if (password.length < 6)
			errors.password = formatMessage(messages.validationErrorPasswordLength);
		// if (!optinTOSPP)
		// 	errors.optinTOSPP = formatMessage(messages.validationErrorOptinTOSPP);
		// if (subscription === Subscription.free && !optinMarketing)
		// 	errors.optinMarketing = formatMessage(messages.validationErrorOptinMarketing);
		
		setValidationErrors(errors);
		logger.debug('Validated', errors);
		return errors;
	}

	function revalidate() {
		if (Object.keys(validationErrors).length > 0)
			validate();
	}


	function handleSignup() {
		logger.info('Signing up...');

		const errors = validate();
		if (Object.keys(errors).length > 0) {
			const errorMessages = Object.values(errors);
			enqueueSnackbar(errorMessages.join(' '), {
				variant: "error",
			});
			return;
		}

		const metaData = {
			language: locale,
			confirmationURL: process.env.REACT_APP_SIGNUP_CONFIRMATION_URL,
			optinTOSPP: optins.optinTOSPP,
			optinMarketing: optins.optinMarketing,
			signupPlatform: environment.platform,
			created: new Date(),
			awsEnv: environment.awsEnvironment,
			// subscription: subscription,
			queryString: window.location.search,
		}

		const signup_info = {
			username: email,
			password: password,
			attributes: {
				"custom:focalityCustomData": JSON.stringify(metaData)
			}
		};

		logger.debug('Signup info', signup_info);

		Auth.signUp(signup_info).then((data) => {
			setStatus(SignUpStates.awaitingConfirmation);
			// logEvent('sign_up_unconfirmed', {subscription: subscription});
			logEvent('sign_up_unconfirmed');
		})
		.catch(err => {
			if (err.code === "UsernameExistsException") {
				logger.info('Resending confirmation email...');
				Auth.resendSignUp(email).then(() => {
					setStatus(SignUpStates.awaitingConfirmation);
				}).catch(e => {
					logger.error('Failed to resend confirmation email.');
					errorHandler(err);
				});
			} else {
				errorHandler(err);
			}
		});

		setStatus(SignUpStates.loading);
		
	}

	useEffect(() => {
		switch (status) {
			case SignUpStates.awaitingConfirmation:
				setScreenName('SignUpAwaitingConfirmation');
				break;
			case SignUpStates.error:
				setScreenName('SignUpError');
				break;
			case SignUpStates.form:
				setScreenName('SignUpForm');
				break;
			case SignUpStates.chooser:
				setScreenName('SignUpChooseSubscription');
				break;
		};
	}, [status]);

	// function handleChoice(subscription: string) {
	// 	setSubscription(subscription);
	// 	setStatus(SignUpStates.form);
	// 	logEvent('choose_subscription', {subscription: subscription});
	// }

	switch (status) {
		// case SignUpStates.providerChoice:
		// 	return <SignUpProviderChoice />;
		case SignUpStates.awaitingConfirmation:
			return <SignUpAwaitingConfirmation onSignIn={onSignIn}/>
		case SignUpStates.error:
			return <SignUpError error={error} onRetry={() => setStatus(SignUpStates.form)}/>
		case SignUpStates.loading:
			return <SignUpLoading/>;
		case SignUpStates.form:
		default:
			return <SignUpForm
				onSubmit={handleSignup}
				revalidate={revalidate}
				validationErrors={validationErrors}
				onEmailChange={value => setEmail(value)}
				onPasswordChange={value => setPassword(value)}
				// onOptinTOSPPChange={value => setOptinTOSPP(value)}
				// onOptinMarketingChange={value => setOptinMarketing(value)}
				// optinMarketingRequired={subscription === Subscription.free}
				onSignIn={onSignIn}
				// onChangeSubscription={() => setStatus(SignUpStates.chooser)}
				platform={environment.platform}
				// subscriptionTitle={subscriptionTitle}
				// ctaFree={subscription === Subscription.free}
				onBack={onBack}
			/>
		// default:
		// 	return <SignUpChooseSubscription onChoose={handleChoice} onBack={onBack}/>;
	}
}

export default SignUpController;
