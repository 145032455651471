import React from 'react';
// import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';
import { PlanType } from '../services/plan-helpers';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';



const useStyles = makeStyles(theme => ({
	headingGrid: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(2),
	},
	periodControls: {
		textAlign: "right"
	},
	back: {
		textAlign: "left"
	},
	forward: {
		textAlign: "right"
	},
	title: {
		textAlign: "center"
	},
	periodChooser: {
		textAlign: "center",
	},
	periodChooserButton: {
		height: "40px",
	},
	unscheduled: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(2),
		textAlign: "center",
		height: "48px",
		boxSizing: "content-box",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
}));


interface Props {
	onCancel(): void,
	onSave(): void,
	onPeriodBack(): void,
	onPeriodForward(): void,
	onTypeChange(type: PlanType | null): void,
	objectiveTitle: string,
	planTitle: string,
	type: PlanType | null,
}

export default function SchedulerDialog(props: Props) {
	const { onCancel, onSave, onPeriodBack, planTitle, onPeriodForward, objectiveTitle, onTypeChange, type } = props;
	// const theme = useTheme();
	// const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();

	return (
		<Dialog
			fullScreen={false}
			open={true}
			onClose={onCancel}
			aria-labelledby="responsive-dialog-title"
		>
			{/* <DialogTitle id="responsive-dialog-title">Schedule Objective</DialogTitle> */}
			<DialogTitle id="responsive-dialog-title"><FormattedMessage id="SchedulerDialog.title" defaultMessage="Schedule: {objectiveTitle}" values={{objectiveTitle: objectiveTitle}}/></DialogTitle>
			<DialogContent>
				<Box className={classes.periodChooser}>
					<ToggleButtonGroup size="medium" value={type === null ? "" : type} exclusive onChange={(event: unknown, type) => onTypeChange(type !== "" ? type : null)}>
						<ToggleButton value="" className={classes.periodChooserButton}> 
							∅
						</ToggleButton>
						<ToggleButton value={PlanType.day} className={classes.periodChooserButton}>
							<FormattedMessage id="period.day" defaultMessage="Day"/>
						</ToggleButton>
						<ToggleButton value={PlanType.week} className={classes.periodChooserButton}>
							<FormattedMessage id="period.week" defaultMessage="Week"/>
						</ToggleButton>
						<ToggleButton value={PlanType.month} className={classes.periodChooserButton}>
							<FormattedMessage id="period.month" defaultMessage="Month"/>
						</ToggleButton>
						<ToggleButton value={PlanType.year} className={classes.periodChooserButton}>
							<FormattedMessage id="period.year" defaultMessage="Year"/>
						</ToggleButton>
					</ToggleButtonGroup>
				</Box>
				{type &&
					<Grid container className={classes.headingGrid} alignItems="center">
						<Grid item xs={2} className={classes.back}><IconButton onClick={onPeriodBack}><NavigateBeforeIcon/></IconButton></Grid>
						<Grid item xs={8} className={classes.title}><Typography variant="h6">{planTitle}</Typography></Grid>
						<Grid item xs={2} className={classes.forward}><IconButton onClick={onPeriodForward}><NavigateNextIcon/></IconButton></Grid>
					</Grid>
				}
				{!type &&
					<Typography variant="h6" className={classes.unscheduled}>
						<FormattedMessage id="SchedulerDialog.unscheduled.title" defaultMessage="Unscheduled"/>
						<Tooltip disableFocusListener title={<FormattedMessage id="SchedulerDialog.unscheduled.tooltip" defaultMessage="Navigate to your goal to see unscheduled objectives"/>}>
							<HelpIcon fontSize="small" style={{marginLeft: 5}} color="primary"/>
						</Tooltip>
					</Typography>
				}
			</DialogContent>
			<DialogActions>
				<Button onClick={onCancel} color="primary">
					<FormattedMessage id="SchedulerDialog.cancel" defaultMessage="Cancel"/>
				</Button>
				<Button onClick={onSave} color="primary" autoFocus>
					<FormattedMessage id="SchedulerDialog.save" defaultMessage="Save"/>
				</Button>
			</DialogActions>
		</Dialog>
	);
}
