import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import goalImages from '../assets/goal-images/goalImages';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBeforeOutlined';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
	container: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	header: {
		padding: theme.spacing(2),
	},
	heading: {
		marginBottom: theme.spacing(1),
	},
	cardMediaControls: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		height: "100%",
	},
	buttonIcon: {
		backgroundColor: "rgba(256,256,256,0.5)",
		borderRadius: "5px",
	},
	cardMedia: {
		height: 140,
	},
}));

interface Props {
	image: string,
	onNext(): void,
	onPrevious(): void,
}

function GoalWizardImage(props: Props) {
	const { image, onNext, onPrevious } = props;
	const classes = useStyles();

	return (
		<Container maxWidth="sm" className={classes.container}>
			<Box className={classes.header}>
				<Typography variant="h4" color="inherit" className={classes.heading}><FormattedMessage id="goalWizard.image.title" defaultMessage="Choose an image"/></Typography>
			</Box>

			{/* <Box className={classes.header}> */}
				{/* <Grid container justify="space-between" className={classes.bottomGrid}>
					<Grid item><IconButton onClick={imagePrev}><NavigateBeforeIcon/></IconButton></Grid>
					<Grid item><IconButton onClick={imageNext}><NavigateNextIcon/></IconButton></Grid>
				</Grid> */}

				{/* <Grid container>
					<Grid item xs={1}><IconButton onClick={imagePrev}><NavigateBeforeIcon/></IconButton></Grid>
					<Grid item xs={10}> */}
						<Card>
							<CardMedia
								className={classes.cardMedia}
								image={goalImages[image]}
								title=""
								>
								{/* <div className={classes.cardMediaControls}>
									<IconButton onClick={imagePrev}><NavigateBeforeIcon/></IconButton>
									<IconButton onClick={imageNext}><NavigateNextIcon/></IconButton>
								</div> */}
								<Grid container justify="space-between" alignItems="center" className={classes.cardMediaControls}>
									<Grid item><IconButton onClick={onNext}><NavigateBeforeIcon className={classes.buttonIcon} fontSize="large"/></IconButton></Grid>
									<Grid item><IconButton onClick={onPrevious}><NavigateNextIcon className={classes.buttonIcon} fontSize="large"/></IconButton></Grid>
								</Grid>
							</CardMedia>
						</Card>
					{/* </Grid>
					<Grid item xs={1}><IconButton onClick={imageNext}><NavigateNextIcon/></IconButton></Grid>
				</Grid> */}
			{/* </Box> */}
			<Box className={classes.header}>
				<Typography variant="body2" color="inherit" gutterBottom>
					<FormattedMessage id="goalWizard.image.description" defaultMessage="Use the left/right arrows to switch through the available images. Find one that fits your goal. We will use it throughout the app to visualize your goal."/>
				</Typography>
			</Box>
		</Container>
	);
}

export default GoalWizardImage;
