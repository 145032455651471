import React from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import Button from '@material-ui/core/Button';
import { FormattedMessage, FormattedDate } from 'react-intl';

interface Props {
	onChange(): void,
	expirationDate: Date | null,
	title: string,
}

export default function SubscriptionStatusPaidV2(props: Props) {

	const { onChange, expirationDate, title } = props;

	return (
		<Card>
			<CardHeader title={<FormattedMessage id="SubscriptionStatus.title" defaultMessage="Your plan"/>} style={{paddingBottom: 0}}></CardHeader>
			<CardContent>
				<Typography variant="h4" color="textPrimary">
					{title}
				</Typography>
				<Typography variant="h6" color="textSecondary">
					<FormattedMessage id="SubscriptionStatus.renewal" defaultMessage="Renews on {date}" values={{date: (expirationDate ? <FormattedDate value={expirationDate}/> : null)}}/>
				</Typography>
			</CardContent>
			<CardActions>
				<Button fullWidth variant="contained" color="primary" onClick={onChange} x-tracking="subscription-change">
					<FormattedMessage id="SubscriptionStatus.change" defaultMessage="Change"/>
				</Button>
			</CardActions>
		</Card>
	);
}
