import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { commonMessageValues } from '../services/intl-helpers';
import logger from '../services/logger';
import PlanningChecklist from './PlanningChecklist';
import { getEstimateSum, Objective, objectivesByList } from '../services/objective-helpers';
import { List } from '../services/list-helpers';
import { User } from './AuthController';

export interface PlanningChecklistItem {
	type: string,
	primary: React.ReactNode,
	secondary?: React.ReactNode,
	checked: boolean,
}

export default function PlanningChecklistController(props: {
	onCancel(): void,
	onSkip(): void,
	onDone(): void,
	objectives: Objective[],
	lists: List[],
	insights: string | null,
	user: User,
}) {

	const [items, setItems] = useState<PlanningChecklistItem[]>([]);

	const { formatNumber } = useIntl();

	useEffect(() => {
		const newItems: PlanningChecklistItem[] = [];

		const estimateSum = getEstimateSum(props.objectives);
		if (estimateSum > 0) {
			newItems.push({
				type: "volume",
				primary: <FormattedMessage id="PlanningChecklist.volume.primary" defaultMessage="Is <b>{volume} hours</b> a reasonable time commitment?" values={Object.assign({}, commonMessageValues, {
					volume: formatNumber(estimateSum, { maximumFractionDigits: 2 })
				})}/>,
				checked: false,
			});
		}

		let unEstimatedCount = 0;
		props.objectives.forEach(o => {
			if (!o.estimate)
				unEstimatedCount++;
		});

		if (unEstimatedCount > 0) {
			newItems.push({
				type: "unestimated",
				primary: <FormattedMessage id="PlanningChecklist.unestimated.primary" defaultMessage="{unestimated, plural, one {{unestimated} objective has} other {{unestimated} objectives have}} <b>no estimate</b>. Proceed anyway?" values={Object.assign({}, commonMessageValues, {
					unestimated: unEstimatedCount
				})}/>,
				secondary: <FormattedMessage id="PlanningChecklist.unestimated.secondary" defaultMessage="Make a habit of always adding an estimate. Even for small objectives."/>,
				checked: false,
			});
		}

		props.lists.filter(l => !l.archived).forEach(list => {
			if (objectivesByList(props.objectives, list).length === 0) {
				newItems.push({
					type: "skipped-goal",
					primary: <FormattedMessage id="PlanningChecklist.skipped-goal.primary" defaultMessage="You have nothing planned for your goal '<b>{goal}</b>'. Are you sure?" values={Object.assign({}, commonMessageValues, {
						goal: list.title,
					})}/>,
					checked: false,
				});
			}
		});

		if (props.insights) {
			newItems.push({
				type: "insights",
				primary: <FormattedMessage id="PlanningChecklist.insights.primary" defaultMessage="Did you take your last insight into account? ('<b>{insights}</b>')" values={Object.assign({}, commonMessageValues, {
					insights: props.insights.length < 30 ? props.insights : props.insights.substr(0, 30) + "…",
				})}/>,
				checked: false,
			});
		}

		if (props.user.idHashed === "5fe1e628f000037a12538a3e27007b2e515a45ee5c4d1c89ded7416be8cae62c") {
			newItems.push({
				type: "values",
				primary: "Hast Du Deine Werte und Dein Leitbild berücksichtigt?",
				checked: false,
			});
		}

		logger.debug("Initialized checklist items: ", newItems.map(item => item.type).join(","));

		setItems(newItems);
	}, [formatNumber, props.insights, props.lists, props.objectives, props.user.idHashed]);

	const handleToggle = (index:number) => {
		const newItems = items.map((item, idx) => idx === index ? Object.assign({}, item, {checked: !item.checked}) : item);
		setItems(newItems);
	}

	let isReady = true;
	for (let i = 0; i < items.length; i++) {
		if (!items[i].checked) {
			isReady = false;
			break;
		}
	}

	return (
		<PlanningChecklist
			onCancel={props.onCancel}
			onSkip={props.onSkip}
			onDone={props.onDone}
			onToggle={handleToggle}
			ready={isReady}
			items={items}
		/>
	);
}
