import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FlareIcon from '@material-ui/icons/Flare';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { FormattedMessage } from 'react-intl';
import { Environment } from '..';

const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
			backgroundColor: theme.palette.common.white,
		},
	},
	container: {
		backgroundColor: "white",
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	paper: {
		// marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	confirmationText: {
		marginTop: theme.spacing(1),
	},
	button: {
		marginTop: theme.spacing(2),
	},
	build: {
		position: "absolute",
		bottom: theme.spacing(2),
		paddingBottom: "env(safe-area-inset-bottom)",
	},
}));

interface Props {
	environment: Environment,
}

export default function UpdateRequired(props: Props) {
	const {
		environment,
	} = props;

	const classes = useStyles();

	return (
		<Container component="main" maxWidth="xs" className={classes.container}>
			<CssBaseline />
			<div className={classes.paper}>
				<Avatar className={classes.avatar}>
					<FlareIcon />
				</Avatar>
				<Typography variant="h5"><FormattedMessage id="UpdateRequired.title" defaultMessage="Update required"/></Typography>
				<Typography variant="body1" className={classes.confirmationText} paragraph><FormattedMessage id="UpdateRequired.description" defaultMessage="Your version of Focality is outdated. Please install the latest version to continue using it."/></Typography>
				<Button href="https://www.focalityapp.com/update-instructions/" fullWidth variant="outlined" color="primary" className={classes.button}><FormattedMessage id="UpdateRequired.button" defaultMessage="Instructions"/></Button>
				<Typography variant="caption" color="textSecondary" className={classes.build}>Build {environment.jsBuildNumber}, {environment.jsBuildTime}, {environment.awsEnvironment}.</Typography>
			</div>
		</Container>
	);
}
