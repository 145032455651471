import React, { useState } from 'react';
import logger from '../services/logger';
import SchedulerDialog from './SchedulerDialog';
import { PlanType, getNextPeriod, getPreviousPeriod, getPlanTitle, PlanStub, dateToPeriod } from '../services/plan-helpers';
import { Objective } from '../services/objective-helpers';
import { useIntl } from 'react-intl';
import { parseISO } from 'date-fns';


interface Props {
	onCancel(): void,
	// onSaveObjective(objective: Objective): void,
	objective: Objective,
	initialPeriod: string | null,
	initialType: PlanType | null,
	onSetPlan(plan: PlanStub | null): void,
}

export default function SchedulerDialogController(props: Props) {
	const { onCancel, initialPeriod, initialType, objective, onSetPlan } = props;
	const { locale } = useIntl();

	const [type, setType] = useState<PlanType|null>(initialType);
	const [period, setPeriod] = useState(initialPeriod || dateToPeriod(new Date(), PlanType.day));
	const onTypeChange = (type: PlanType | null) => {
		setType(type);
		if (type) {
			const currentPeriodDate = parseISO(period);
			setPeriod(dateToPeriod(currentPeriodDate, type));
		}
	}

	const handlePeriodBack = () => {
		if (type) 
			setPeriod(getPreviousPeriod(period, type));
	}
	const handlePeriodForward = () => {
		if (type)
			setPeriod(getNextPeriod(period, type));
	}

	const handleSave = () => {
		logger.info('Rescheduling objective', objective, type, period);
		if (type)
			onSetPlan({type: type, period: period});
		else
			onSetPlan(null);
		// onSetPlan(type, type ? period : null);
		// if (type) {
		// }
		
		// const updatedObjective = Object.assign({}, objective);
		// if (type) {
		// 	const plan = getPlan(type, period);
		// 	updatedObjective.planId = plan.id;
		// }
		
		// const updatedObjective = Object.assign({}, objective, {
		// 	// plan: 
		// });
	}

	const planTitle = type !== null ? getPlanTitle({type: type, period: period}, locale) : "";

	return (
		<SchedulerDialog
			onCancel={onCancel}
			onSave={handleSave}
			onPeriodBack={handlePeriodBack}
			onPeriodForward={handlePeriodForward}
			objectiveTitle={objective.title}
			planTitle={planTitle}
			onTypeChange={onTypeChange}
			type={type}
		/>
	);
}
