import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import moodImage from '../assets/reflection-images/20160704-2004--DSC_2771.landscape.jpg';

const useStyles = makeStyles(theme => ({
	headerBackground: {
		color: theme.palette.common.white,
		backgroundImage: `linear-gradient(135deg, rgba(0, 0, 0, 0.5) 30%, rgba(0, 0, 0, 0.0) 60%),url(${moodImage})`,
		// backgroundImage: `linear-gradient(135deg, rgba(255, 255, 255, 1), rgba(0, 0, 0, 0.0)),url(${moodImage})`,
		// backgroundImage: `url(${moodImage})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	description: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(-1),
	},
	container: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	header: {
		padding: theme.spacing(2),
	},
	heading: {
		marginBottom: theme.spacing(1),
	},
	bottomGrid: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		marginTop: theme.spacing(2),
	},
}));

interface Props {
	value: string,
	onChange(value: string): void,
	objectiveMonthTitle: string,
}

function OboardingObjectiveWeek(props: Props) {
	const { value, onChange, objectiveMonthTitle } = props;
	const classes = useStyles();

	return (
		<React.Fragment>
			<Box className={classes.headerBackground}>
				<Container maxWidth="xs">
					<Box className={classes.header}>
						<Typography variant="h4" color="inherit" className={classes.heading}><FormattedHTMLMessage id="onboarding.objectiveWeek.title" defaultMessage="Your primary objective for this week"/></Typography>
						{/* <Typography variant="body1" color="inherit"><FormattedMessage id="onboarding.start.subtitle" defaultMessage="First steps"/></Typography> */}
					</Box>
				</Container>
			</Box>


			<Container maxWidth="xs">
				<Box className={classes.description}>
					<Typography variant="body1" color="inherit">
						<FormattedMessage id="onboarding.objectiveWeek.description" defaultMessage="You guessed it: Considering your objective for the month, &quot;{objectiveMonthTitle}&quot;, what do you want to accomplish this week to get closer to that?" values={{objectiveMonthTitle: objectiveMonthTitle}}/>
					</Typography>
				</Box>
				<Box className={classes.header}>
					<TextField
							label={<FormattedMessage id="onboarding.objectiveWeek.label" defaultMessage="Objective for this week"/>}
							margin="dense"
							fullWidth	
							value={value}
							autoFocus
							onChange={(event) => onChange(event.target.value)}
							helperText="e.g. write down first ideas; get running gear; think about possible styles"
						/>
				</Box>
				<Box className={classes.header}>
					<Typography variant="body2" color="inherit" gutterBottom>
						<strong>
							<FormattedHTMLMessage id="onboarding.objectiveWeek.recurringTitle" defaultMessage="Recurring Objectives"/>
						</strong>
					</Typography>
					<Typography variant="body2" color="inherit" gutterBottom>
						<FormattedHTMLMessage id="onboarding.objectiveWeek.recurringDescription" defaultMessage="Do you have objectives that occur frequently? You can create automatically recurring objectives later."/>
					</Typography>
				</Box>
			</Container>
		</React.Fragment>
	);
}

export default OboardingObjectiveWeek;
