import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
// import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Zoom from '@material-ui/core/Zoom';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import logger from '../services/logger';
import ButtonDialog from './ButtonDialog';
import { FormattedMessage } from 'react-intl';
import { PlanType } from '../services/plan-helpers';
import { commonMessageValues } from '../services/intl-helpers';

const useStyles = makeStyles(theme => ({
	barSticky: {
		position: "sticky",
		// bottom: "calc(60px + env(safe-area-inset-bottom))",
		bottom: "calc(50px + env(safe-area-inset-bottom))",
		backgroundColor: theme.palette.grey[200],
		zIndex: 1,
		padding: theme.spacing(1),
		textAlign: "center",
		marginBottom: theme.spacing(1),
		width: "100%",
		fallbacks: {
			bottom: 50,
		},
	},
	bar: {
		backgroundColor: theme.palette.grey[200],
		padding: theme.spacing(1),
		textAlign: "center",
		marginTop: theme.spacing(4),
		marginBottom: 55,
		// marginBottom: 65,
		width: "100%",
	},
	buttonGroup: {
		marginLeft: theme.spacing(1),
	},
	buttons: {
		height: 30,
	},
	spacer: {
		marginBottom: 70,
	},
}));

interface Props {
	show: boolean,
	sticky: boolean,
	complete: boolean,
	planType: PlanType,
	onChange(value: boolean): void,
}

export default function PlanViewCompletionQuery(props: Props) {

	const {
		show,
		sticky,
		complete,
		planType,
		onChange,
	} = props;
	
	const classes = useStyles();

	const handleChange = (event: React.MouseEvent<HTMLElement>, value: string | null) => {
		logger.debug("Change complete status", value);
		if (value) {
			onChange(value === "yes");
		}
	};

	return (
		<React.Fragment>
			{sticky && <Box className={classes.spacer}></Box>}
			<Zoom in={show}>
			<Paper elevation={0} square className={sticky ? classes.barSticky : classes.bar} data-onboarding-markers="plan-completion-query">
				<Typography variant="body1" component="span"><FormattedMessage id="PlanViewCompletionQuery.question" defaultMessage="Done planning this {planType}?" values={{planType: planType}}/></Typography>
				{/* <Button startIcon={complete ? <CheckBoxIcon/> : undefined} variant="contained" color="primary" onClick={() => onChange(!complete)}><FormattedMessage id="PlanViewCompletionQuery.button" defaultMessage="I am done planning"/></Button> */}
				<ToggleButtonGroup
					value={complete ? "yes" : "no"}
					exclusive
					size="small"
					onChange={handleChange}
					className={classes.buttonGroup}
					>
					<ToggleButton value="yes" className={classes.buttons}>
						<FormattedMessage id="generic.Yes" defaultMessage="Yes"/>
					</ToggleButton>
					<ToggleButton value="no" className={classes.buttons}>
						<FormattedMessage id="generic.No" defaultMessage="No"/>
					</ToggleButton>
				</ToggleButtonGroup>
				<ButtonDialog>
					<Typography variant="h5" gutterBottom><FormattedMessage id="PlanViewCompletionQuery.help.title" defaultMessage="Consciously note when you are done planning"/></Typography>
					<Typography variant="body1" paragraph><FormattedMessage id="PlanViewCompletionQuery.help.text0" defaultMessage="Always have a plan. Planning is key to living proactively. How much you plan is up to you - just make sure that you know what you want to achieve. Even on holidays. Having a plan can be as simple as planning to &quot;have a relaxed afternoon at the lake&quot;."/></Typography>
					<Typography variant="body1" paragraph><FormattedMessage id="PlanViewCompletionQuery.help.text1" defaultMessage="When you have added all objectives - when you think &quot;<b>yes, this is what I want to achieve {planType, select, day {today} week {this week} month {this month} year {this year}}</b>&quot; - tell Focality and yourself that you are done planning. Focality will keep track of how often you complete this process. Strive to keep your planning rate at 100%." values={Object.assign({}, commonMessageValues, {planType: planType})}/></Typography>
					<Typography variant="body1" paragraph><FormattedMessage id="PlanViewCompletionQuery.help.text2" defaultMessage="You can still change the plan if necessary. Try to avoid it, though. If you fail to complete an item, just mark it as such at the end of the period. If you are constantly adding items during the period, you are not planning carefully enough."/></Typography>
				</ButtonDialog>
			</Paper>
			</Zoom>
		</React.Fragment>
	);
}
