import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import moodImage from '../assets/reflection-images/20160704-2004--DSC_2771.landscape.jpg';

const useStyles = makeStyles(theme => ({
	headerBackground: {
		color: theme.palette.common.white,
		backgroundImage: `linear-gradient(135deg, rgba(0, 0, 0, 0.5) 30%, rgba(0, 0, 0, 0.0) 60%),url(${moodImage})`,
		// backgroundImage: `linear-gradient(135deg, rgba(255, 255, 255, 1), rgba(0, 0, 0, 0.0)),url(${moodImage})`,
		// backgroundImage: `url(${moodImage})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	description: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(-1),
	},
	container: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	header: {
		padding: theme.spacing(2),
	},
	heading: {
		marginBottom: theme.spacing(1),
	},
	bottomGrid: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		marginTop: theme.spacing(2),
	},
}));

interface Props {
}

function OnboardingPrinciple(props: Props) {
	// const {  } = props;
	const classes = useStyles();

	return (
		<React.Fragment>
			<Box className={classes.headerBackground}>
				<Container maxWidth="xs">
					<Box className={classes.header}>
						<Typography variant="h4" color="inherit" className={classes.heading}><FormattedHTMLMessage id="onboarding.principle.title" defaultMessage="Plan, Reflect, Improve"/></Typography>
						{/* <Typography variant="body1" color="inherit"><FormattedMessage id="onboarding.start.subtitle" defaultMessage="First steps"/></Typography> */}
					</Box>
				</Container>
			</Box>


			<Container maxWidth="xs">
				<Box className={classes.description}>
					<Typography variant="body1" color="inherit" paragraph><FormattedMessage id="onboarding.principle.intro" defaultMessage="Focality follows a simple principle: Plan, reflect, improve. This continuous cycle will help you reach your full potential."/></Typography>
					<Typography variant="body1" color="inherit"><strong><FormattedMessage id="onboarding.principle.plan" defaultMessage="Plan (deeply)"/></strong></Typography>
					<Typography variant="body1" color="inherit" paragraph><FormattedMessage id="onboarding.principle.plantext1" defaultMessage="Always have a plan – or circumstances will make the plan for you. And circumstances will not lead you where you want to go."/></Typography>
					<Typography variant="body1" color="inherit" paragraph><FormattedMessage id="onboarding.principle.plantext2" defaultMessage="Plan from big to small. First, decide what you want to accomplish this year. Then, this month, this week and finally today."/></Typography>
					<Typography variant="body1" color="inherit"><strong><FormattedMessage id="onboarding.principle.reflect" defaultMessage="Reflect"/></strong></Typography>
					<Typography variant="body1" color="inherit" paragraph><FormattedMessage id="onboarding.principle.reflecttext" defaultMessage="At the end of each planned period, look back. What went well? Where did I succeed? Where not? Why not?"/></Typography>
					<Typography variant="body1" color="inherit"><strong><FormattedMessage id="onboarding.principle.improve" defaultMessage="Improve"/></strong></Typography>
					<Typography variant="body1" color="inherit" paragraph><FormattedMessage id="onboarding.principle.improvetext" defaultMessage="Taking the insights from your reflection, think forward: What can I do differently next time?"/></Typography>
					{/* <Typography variant="body1" color="inherit" paragraph><FormattedMessage id="onboarding.principle." defaultMessage="Plans will invariably change, but that's ok. Life happens. You will still be closer to your goals than if you had started without a plan."/></Typography> */}
				</Box>
			</Container>
		</React.Fragment>
	);
}

export default OnboardingPrinciple;
