import React from 'react';
import { SignUpOptins } from './AuthController';

export interface SettingsExperimentAData {
	listFocus?: {
		[key: string]: boolean,
	}
}

export interface Settings {
	ready(): boolean,
	getNotificationsEnabled(): boolean,
	setNotificationsEnabled(state: boolean): void,
	getOnboardingSetupDone(): string|null,
	setOnboardingSetupDone(d: Date): void,
	getOnboardingTourDone(): string|null,
	setOnboardingTourDone(d: Date): void,
	experimentA: boolean,
	setExperimentA(state: boolean): void,
	experimentAData: SettingsExperimentAData,
	setExperimentAData(d: SettingsExperimentAData): void,
	addSignUpOptIn(optins: SignUpOptins, date: Date): void,
	getSignUpOptIns(): {optins: SignUpOptins, date: Date}[],
	getLatestSignUpOptIn(): {optins: SignUpOptins, date: Date} | undefined,
}

const SettingsContext = React.createContext<Settings>({
	ready: () => false,
	getNotificationsEnabled: () => true,
	setNotificationsEnabled: (b): void => {},
	getOnboardingSetupDone: () => null,
	setOnboardingSetupDone: (d: Date) => {},
	getOnboardingTourDone: () => null,
	setOnboardingTourDone: (d: Date) => {},
	experimentA: false,
	setExperimentA: (state: boolean): void => {},
	experimentAData: {},
	setExperimentAData: (d: Object) => {},
	addSignUpOptIn: (optins: SignUpOptins, date: Date) => {},
	getSignUpOptIns: () => [],
	getLatestSignUpOptIn: () => undefined,
});

export default SettingsContext;
