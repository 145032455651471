import React, { useState, useContext, useEffect } from 'react';
import { User } from './AuthController';
import { setScreenName } from '../services/analytics';
import SettingsContext from './SettingsContext';
import DebugView from './DebugView';
import { Plugins, LocalNotificationEnabledResult } from '@capacitor/core';
import { requestPermissions } from '../services/notifications-helpers';
import logger from '../services/logger';
import { Environment } from '..';
import { addSeconds } from 'date-fns';
import { Subscription } from './SubscriptionController';


interface Props {
	user: User,
	environment: Environment,
	subscription: Subscription,
}

export default function DebugViewController(props: Props) {
	const settings = useContext(SettingsContext);
	const notificationsEnabled = settings.getNotificationsEnabled();
	const [notificationsPermission, setNotificationsPermission] = useState<boolean|null>(null);

	const updateNotificationStatus = () => Plugins.LocalNotifications.areEnabled().then((result: LocalNotificationEnabledResult) => setNotificationsPermission(result.value));
	useEffect(() => {
		updateNotificationStatus();
	}, []);

	const doRequestPermission = () => {
		requestPermissions().then((result) => {
			logger.debug("Permission requested", result);
			updateNotificationStatus();
		});
	}

	const scheduleTestNotification = () => {
		const notificationId = 9998;
		Plugins.LocalNotifications.schedule({notifications: [{
			title: "Notifications are working",
			body: "Notifications for Focality have successfully been enabled",
			id: notificationId,
			schedule: { at: addSeconds(new Date(), 30) },
		}]}).then(result => {
			logger.debug("Test notification scheduled: ", result);
		});
	}

	const handleToggleExperimentA = () => settings.setExperimentA(!settings.experimentA);

	setScreenName('DebugView');
	return (
		<React.Fragment>
			<DebugView
				user={props.user}
				notificationsEnabledSetting={notificationsEnabled}
				notificationsPermission={notificationsPermission}
				environment={props.environment}
				subscription={props.subscription}
				onRequestNotificationPermission={doRequestPermission}
				onScheduleTestNotification={scheduleTestNotification}
				experimentA={settings.experimentA}
				onToggleExperimentA={handleToggleExperimentA}
			/>
		</React.Fragment>
	);
}
