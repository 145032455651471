import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
	current: {
		textAlign: "center",
	},
}));


function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
	return (
		<Box position="relative" display="inline-flex">
			<CircularProgress variant="static" {...props} />
			<Box
				top={0}
				left={0}
				bottom={0}
				right={0}
				position="absolute"
				display="flex"
				alignItems="center"
				justifyContent="center"
			>
				<Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
				props.value,
				)}%`}</Typography>
			</Box>
		</Box>
	);
}

interface DualStreakProps {
	numerator: number,
	denominator: number,
	streakCurrent: number,
	streakMax: number,
	title: React.ReactElement,
	labelCurrent: React.ReactElement,
	labelStreak: React.ReactElement,
}

export default function DualStreak(props: DualStreakProps) {
	const {
		numerator,
		denominator,
		streakCurrent,
		streakMax,
	} = props;
	const classes = useStyles();

	return (
		<Grid container spacing={2} alignItems="center">
			<Grid item xs={12} sm={12} md={12}>
				<Typography variant="h6" gutterBottom>{props.title}</Typography>
			</Grid>
			<Grid item xs={4} className={classes.current}>
				<CircularProgressWithLabel value={(numerator/denominator)*100}/>
			</Grid>
			<Grid item xs={8}>
				<LinearProgress variant="determinate" value={(streakCurrent/streakMax)*100} color="secondary"/>
			</Grid>
			<Grid item xs={4} className={classes.current}>
				<Typography variant="body2" gutterBottom>{props.labelCurrent}</Typography>
			</Grid>
			<Grid item xs={8}>
				<Typography variant="body2" gutterBottom>{props.labelStreak}</Typography>
			</Grid>
		</Grid>
	);
}
