import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import moodImage from '../assets/reflection-images/20160704-2004--DSC_2771.landscape.jpg';
import { Card, List, ListItem, ListItemText } from '@material-ui/core';
import CompletionByGoalBars from './visualizations/CompletionByGoalBars';

const useStyles = makeStyles(theme => ({
	headerBackground: {
		color: theme.palette.common.white,
		backgroundImage: `linear-gradient(135deg, rgba(0, 0, 0, 0.5) 30%, rgba(0, 0, 0, 0.0) 60%),url(${moodImage})`,
		// backgroundImage: `linear-gradient(135deg, rgba(255, 255, 255, 1), rgba(0, 0, 0, 0.0)),url(${moodImage})`,
		// backgroundImage: `url(${moodImage})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	description: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(-1),
	},
	container: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	header: {
		padding: theme.spacing(2),
	},
	heading: {
		marginBottom: theme.spacing(1),
	},
	bottomGrid: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		marginTop: theme.spacing(2),
	},
	listItemText: {
		margin: 0,
	},
}));

export function MyList(props: {items: string[]}) {
	const classes = useStyles();
	
	return (
		<List component="nav" dense={true}>
			{props.items.map(item => <ListItem key={item}><ListItemText primary={item} className={classes.listItemText}/></ListItem>)}
		</List>
	);
}

interface Props {
}

function OnboardingInsightsPreview(props: Props) {
	const { locale } = useIntl();
	if (locale === "de") {
		return <OnboardingInsightsPreviewDE/>;
	} else {
		return <OnboardingInsightsPreviewEN/>;
	}
}

function OnboardingInsightsPreviewEN(props: Props) {
	// const {  } = props;
	const classes = useStyles();

	const fails = [
		"\"Took longer than expected\" (37×)",
		"\"Colleague did not deliver\" (21×)",
		"\"Got distracted\" (14×)",
		"…",
	];

	const goodStuff = [
		"\"Spent a wonderful day at the lake with my family\"",
		"\"Baby fell totally in love with her new toy\"",
		"\"Signed a new client\"",
		"\"Discovered promising marketing angle\"",
		"…",
	];

	const completionRates = [
		{title: "Start my own business", completionRate: 87.6},
		{title: "Buy an electric car", completionRate: 57.1},
		{title: "Learn to play the guitar", completionRate: 74.2},
	];

	return (
		<React.Fragment>
			<Box className={classes.headerBackground}>
				<Container maxWidth="xs">
					<Box className={classes.header}>
						<Typography variant="h4" color="inherit" className={classes.heading}><FormattedHTMLMessage id="onboarding.insightsPreview.title" defaultMessage="Gain Insight"/></Typography>
						{/* <Typography variant="body1" color="inherit"><FormattedMessage id="onboarding.start.subtitle" defaultMessage="First steps"/></Typography> */}
					</Box>
				</Container>
			</Box>


			<Container maxWidth="xs">
				<Box className={classes.description}>
				<div style={{marginLeft:16, marginBottom: 16}}>
					<Typography variant="body2">
						“Reflection turns experience into insight”
					</Typography>
					<Typography variant="caption">
						― John Maxwell
					</Typography>
				</div>
					<Typography variant="body1" color="inherit" paragraph>With each planning cycle, you will learn more about your approach and challenges. After a few cycles, you will start to notice patterns.</Typography>

					<Typography variant="body1" color="inherit" gutterBottom><strong>What causes me to fail?</strong></Typography>
					<Card style={{marginBottom: 8}}>
							<MyList items={fails}/>
					</Card>
					<Typography variant="body1" color="inherit" paragraph>Learn why your plans fail. What are the frequent reasons? Those are the ones that you should tackle first.</Typography>

					<Typography variant="body1" color="inherit" gutterBottom><strong>What makes me happy?</strong></Typography>
					<Card style={{marginBottom: 8}}>
							<MyList items={goodStuff}/>
					</Card>
					<Typography variant="body1" color="inherit" paragraph>Scroll through your journal of positive experiences. Discover common themes.</Typography>

					<Typography variant="body1" color="inherit" gutterBottom><strong>Where do I succeed?</strong></Typography>
					<Card style={{marginBottom: 8, paddingTop: 8}}>
						<CompletionByGoalBars data={completionRates} time={false}/>
					</Card>
					<Typography variant="body1" color="inherit" paragraph>Which of your goals are you pursuing most successfully? Where do you often miss your objectives?</Typography>

				</Box>
			</Container>
		</React.Fragment>
	);
}
function OnboardingInsightsPreviewDE(props: Props) {
	// const {  } = props;
	const classes = useStyles();

	const fails = [
		"\"Dauerte länger als gedacht\" (37×)",
		"\"Kollege hat nicht geliefert\" (21×)",
		"\"Wurde abgelenkt\" (14×)",
		"…",
	];

	const goodStuff = [
		"\"Wunderschönen Tag am See mit der Familie verbracht\"",
		"\"Das Baby hat sich total in das neue Spielzeug verliebt\"",
		"\"Neuen Kunden gewonnen\"",
		"\"Vielversprechenden Marketingansatz entdeckt\"",
		"…",
	];

	const completionRates = [
		{title: "Eigene Firma gründen", completionRate: 87.6},
		{title: "Elektro-Auto kaufen", completionRate: 63.1},
		{title: "Gitarrespielen lernen", completionRate: 74.2},
	];

	return (
		<React.Fragment>
			<Box className={classes.headerBackground}>
				<Container maxWidth="xs">
					<Box className={classes.header}>
						<Typography variant="h4" color="inherit" className={classes.heading}>Erkenntnisgewinne</Typography>
						{/* <Typography variant="body1" color="inherit"><FormattedMessage id="onboarding.start.subtitle" defaultMessage="First steps"/></Typography> */}
					</Box>
				</Container>
			</Box>


			<Container maxWidth="xs">
				<Box className={classes.description}>
				<div style={{marginLeft:16, marginBottom: 16}}>
					<Typography variant="body2">
						“Reflexion verwandelt Erfahrung in Erkenntnis”
					</Typography>
					<Typography variant="caption">
						― John Maxwell
					</Typography>
				</div>
					<Typography variant="body1" color="inherit" paragraph>Mit jedem Planungszyklus lernst Du mehr über Dein Vorgehen und Deine Herausforderungen. Nach einigen Zyklen wirst Du beginnen, Muster zu erkennen.</Typography>

					<Typography variant="body1" color="inherit" gutterBottom style={{marginTop: 32}}><strong>Was bremst mich aus?</strong></Typography>
					<Card style={{marginBottom: 8}}>
							<MyList items={fails}/>
					</Card>
					<Typography variant="body1" color="inherit" paragraph>Erkenne, warum Pläne scheitern. Was sind die häufigsten Gründe? Diese solltest Du als Erstes angehen.</Typography>

					<Typography variant="body1" color="inherit" gutterBottom style={{marginTop: 32}}><strong>Was macht mich glücklich?</strong></Typography>
					<Card style={{marginBottom: 8}}>
							<MyList items={goodStuff}/>
					</Card>
					<Typography variant="body1" color="inherit" paragraph>Scrolle durch Deine positiven Momente. Werde Dir bewusst, was Dich regelmäßig erfreut.</Typography>

					<Typography variant="body1" color="inherit" gutterBottom style={{marginTop: 32}}><strong>Worin bin ich gut?</strong></Typography>
					<Card style={{marginBottom: 8, paddingTop: 8}}>
						<CompletionByGoalBars data={completionRates} time={false}/>
					</Card>
					<Typography variant="body1" color="inherit" paragraph>Welche Deiner Ziele verfolgst Du am konsequentesten? Wo verfehlst Du am häufigsten Deine Vorhaben?</Typography>

				</Box>
			</Container>
		</React.Fragment>
	);
}

export default OnboardingInsightsPreview;
