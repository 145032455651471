import React from 'react';
import Joyride, { Step, CallBackProps, EVENTS, STATUS } from 'react-joyride';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import logger from '../services/logger';
import { FormattedMessage } from 'react-intl';
import { logEvent } from '../services/analytics';

interface Props {
	stepIndex?: number,
	onEnded(): void,
}

export default function PlanViewOnboarding(props: Props) {

	const { stepIndex, onEnded } = props;

	const theme = useTheme();
	

	const steps:Step[] = [
		{
			target: 'body',
			// title: "You're all set up!",
			content:
				<React.Fragment>
					<Typography variant="h6" gutterBottom><FormattedMessage id="PlanViewOnboading.start.title" defaultMessage="You're all set up!"/></Typography>
					<Typography><FormattedMessage id="PlanViewOnboading.start.description" defaultMessage="Focality is now ready to help you achieve your goals. Let us show you around real quick."/></Typography>
				</React.Fragment>,
			disableBeacon: true,
			placement: "center",
		},
		{
			target: '*[data-onboarding-markers~="list-0"]',
			content: <FormattedMessage id="PlanViewOnboading.goals" defaultMessage="Each of your goals has its own section in your plan."/>,
		},
		{
			target: '*[data-onboarding-markers~="list-other"]',
			content: <FormattedMessage id="PlanViewOnboading.other" defaultMessage="Objectives which do not belong to any goal can be added in a separate section."/>,
		},
		{
			target: '*[data-onboarding-markers~="ObjectiveCheckbox"]',
			content: <FormattedMessage id="PlanViewOnboading.checkbox" defaultMessage="Click to mark your objectives as achieved. <strong>Long-click</strong> to mark them as failed. Click again to mark them unchecked." values={{strong: (m:any) => <strong>{m}</strong>}}/>,
		},
		{
			target: '*[data-onboarding-markers~="plan-completion-query"]',
			content: <FormattedMessage id="PlanViewOnboading.planCompletionQuery" defaultMessage="When you have added all objectives, mark your plan as complete. You can still change it afterwards."/>,
		},
		{
			target: '*[data-onboarding-markers~="plan-reflection-notice"]',
			content: <FormattedMessage id="PlanViewOnboading.planReflectionNotice" defaultMessage="At the end of each planning period, take a step back and reflect. Use this button to start the process."/>,
		},
		{
			target: '*[data-onboarding-markers~="PlanViewNavigationTabs"]',
			content: <FormattedMessage id="PlanViewOnboading.period" defaultMessage="You can switch between daily, weekly, monthly, and yearly plans."/>,
		},
		{
			target: '*[data-onboarding-markers~="navigation-goals"]',
			content: <FormattedMessage id="PlanViewOnboading.goalsview" defaultMessage="Use the goals view to manage your goals."/>,
		},
		{
			target: '*[data-onboarding-markers~="navigation-insights"]',
			content: <FormattedMessage id="PlanViewOnboading.insightsview" defaultMessage="Use the insights view to gain data-driven insights about your progress."/>,
		},
	];

	const onStateChange = (event: CallBackProps) => {
		// logger.debug("PlanViewOnboarding, state changed: ", event);
		if (event.type === EVENTS.TOUR_END && event.status === STATUS.SKIPPED && event.index === 0) {
			logger.info("Tour skipped");
			logEvent("tour_skipped");
		}
		if (event.type === EVENTS.TOUR_END && event.status === STATUS.SKIPPED && event.index > 0) {
			logger.info("Tour aborted");
			logEvent("tour_aborted");
		}
		if (event.type === EVENTS.TOUR_END && event.status === STATUS.FINISHED) {
			logger.info("Tour completed");
			logEvent("tour_completed");
		}
		if (event.type === EVENTS.BEACON) {
			logger.info("Tour paused");
			logEvent("tour_paused");
		}
		if (event.type === EVENTS.TOUR_END) {
			logger.info('PlanViewOnboarding: Tour ended');
			onEnded();
		}
	}

	return <Joyride
				steps={steps}
				continuous={true}
				showSkipButton={true}
				stepIndex={stepIndex}
				locale={{
					back: <FormattedMessage id="PlanViewOnboading.back" defaultMessage="Back"/>, close: 'Close', last: <FormattedMessage id="PlanViewOnboading.done" defaultMessage="Done"/>, next: <FormattedMessage id="PlanViewOnboading.next" defaultMessage="Next"/>, skip: <FormattedMessage id="PlanViewOnboading.skip" defaultMessage="Skip"/>
				}}
				styles={{
					options: {
						primaryColor: theme.palette.secondary.main,
					}
				}}
				callback={onStateChange}
			/>
}
