import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
	box: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(8, 0, 6),
		textAlign: "center",
	},
}));

interface Props {
	onCreateGoal(): void,
}

function GoalsEmpty(props: Props) {
	const { onCreateGoal } = props;
	const classes = useStyles();
	
	return (
		<Box className={classes.box}>
			<Typography variant="h4" style={{fontWeight: 300}} align="center" color="textPrimary" gutterBottom>
				<FormattedMessage id="GoalsEmpty.title" defaultMessage="What are your goals?"/>
			</Typography>
			<Typography variant="body1" align="center" color="textPrimary" paragraph>
			<FormattedMessage id="GoalsEmpty.description" defaultMessage="Define what is important to you and pursue it with consistency. Each goal will be shown as a separate section in your plans."/>
			</Typography>
			<Button variant="contained" color="primary" onClick={onCreateGoal}>
				<FormattedMessage id="GoalsEmpty.button" defaultMessage="Create first goal"/>
			</Button>
		</Box>
	);
}

export default GoalsEmpty;
