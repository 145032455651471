import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { PlanningChecklistItem } from './PlanningChecklistController';
import ButtonDialog from './ButtonDialog';

const useStyles = makeStyles(theme => ({
	primary: {
		textOverflow: "ellipsis",
		overflow: "hidden",
	},
}));

export default function PlanningChecklist(props: {
	onCancel(): void,
	onSkip(): void,
	onDone(): void,
	onToggle(index: number): void,
	items: PlanningChecklistItem[],
	ready: boolean,
	testEnvironment?: boolean,
}) {

	const classes = useStyles();

	return (
		<Dialog
			fullScreen={false}
			open={true}
			onClose={props.onCancel}
			aria-labelledby="responsive-dialog-title"
			disablePortal={props.testEnvironment}
		>
			{/* <DialogTitle id="responsive-dialog-title"><FormattedMessage id="PlanningChecklist.title" defaultMessage="Make sure you didn't miss anything"/></DialogTitle> */}
			
			<DialogContent>
				<Typography variant="h6"><FormattedMessage id="PlanningChecklist.title" defaultMessage="Check your plan"/></Typography>
				<Typography variant="body1"><FormattedMessage id="PlanningChecklist.description" defaultMessage="Take a second and make sure that your plan is well thought out."/></Typography>
				<List>
					{props.items.map((item, index) => 
						<ListItem key={index} role={undefined} dense button onClick={() => props.onToggle(index)} data-analytics-id={"planning-checklist-item "+item.type}>
							<ListItemIcon style={{minWidth: "inherit"}}>
								<Checkbox
									edge="start"
									checked={item.checked}
									tabIndex={-1}
									disableRipple
								/>
							</ListItemIcon>
							<ListItemText primary={item.primary} classes={{primary: classes.primary}}/>
							{item.secondary &&
								<ListItemSecondaryAction>
									<ButtonDialog edge="end">
										{item.secondary}
									</ButtonDialog>
								</ListItemSecondaryAction>
							}
						</ListItem>
					)}
				</List>
			</DialogContent>

			<DialogActions>
				<Button onClick={props.onCancel} color="primary">
					<FormattedMessage id="PlanningChecklist.cancel" defaultMessage="Cancel"/>
				</Button>
				<Button onClick={props.onSkip} color="primary" variant="text">
					<FormattedMessage id="PlanningChecklist.skip" defaultMessage="Skip"/>
				</Button>
				<Button onClick={props.onDone} color="primary" variant="contained" disabled={!props.ready}>
					<FormattedMessage id="PlanningChecklist.done" defaultMessage="Done"/>
				</Button>
			</DialogActions>
		</Dialog>
	);
}
