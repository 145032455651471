import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import VolumeCompletionScatter from './VolumeCompletionScatter';

import { EstimateCompletionRates } from '../InsightsController';

const messages = defineMessages({
	title: {
		id: 'insights.objectiveScatter.title',
		defaultMessage: 'Objectives: Estimated time vs. completion rate',
	},
});

type ObjectiveScatterCardProps = {
	estimateCompletionRates: EstimateCompletionRates
}

function ObjectiveScatterCard(props: ObjectiveScatterCardProps) {

	const { formatMessage } = useIntl();
	const { estimateCompletionRates } = props;

	return (
		<Card>
			<CardHeader
				title={formatMessage(messages.title)}
				subheader=""
			/>
			<CardContent>
				<VolumeCompletionScatter estimateCompletionRates={estimateCompletionRates}/>
			</CardContent>
		</Card>
	);
}

ObjectiveScatterCard.propTypes = {
	estimateCompletionRates: PropTypes.array.isRequired,
};

export default ObjectiveScatterCard;
