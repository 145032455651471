import Auth from '@aws-amplify/auth';

import React, { useState, useEffect } from 'react';
import logger from '../services/logger';
import SignIn from './SignIn';
import { useSnackbar } from 'notistack';
import SignUpAwaitingConfirmation from './SignUpAwaitingConfirmation';
import { logEvent, setScreenName } from '../services/analytics';

enum SignInStates {
	form,
	verification,
}

interface Props {
	onForgotPassword(): void,
	onSignUp(): void,
	onLogIn(cognitoUser: unknown): void,
}

function SignInController(props: Props) {
	const { onForgotPassword, onSignUp, onLogIn } = props;

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [signInState, setSignInState] = useState(SignInStates.form);
	const { enqueueSnackbar } = useSnackbar();

	const showError = (msg: string) => {
		logger.info(msg);
		enqueueSnackbar(msg, {
			variant: "error"
		});
	}

	const handleSignIn = async () => {
		setLoading(true);
		try {
			const cognitoUser = await Auth.signIn(email, password);
			logger.debug(cognitoUser);
			if (['SMS_MFA', 'SOFTWARE_TOKEN_MFA', 'NEW_PASSWORD_REQUIRED', 'MFA_SETUP'].indexOf(cognitoUser.challengeName) !== -1) {
				showError('Login failed: '+cognitoUser.challengeName);
			} else {
				logger.info('User logged in.');
				Auth.verifiedContact(cognitoUser)
				.then((data: any) => {
					logger.debug('verifiedContact result:', data);
					// if (!JS.isEmpty(data.verified)) {
					// 	this.changeState('signedIn', user);
					// } else {
					// 	user = Object.assign(user, data);
					// 	this.changeState('verifyContact', user);
					// }
					if (!data || !data.verified || !data.verified.email) {
						logger.error('Email address does not seem to be verified.');
					}
				});
				setEmail("");
				setPassword("");
				setLoading(false);
				onLogIn(cognitoUser);
				logEvent('login', {method: "email"});
			}
		} catch (err) {
			logger.debug('Login failed:', err);
			if (err.code === 'UserNotConfirmedException') {
				logger.info('the user is not confirmed');
				setSignInState(SignInStates.verification);
			} else if (err.info === 'PasswordResetRequiredException') {
				logger.error('the user requires a new password');
				// this.changeState('forgotPassword', {username});
			} else {
				showError(err.message);
			}
			setLoading(false);
		}
	};

	useEffect(() => {
		switch (signInState) {
			case SignInStates.form:
				setScreenName('SignIn');
				break;
			case SignInStates.verification:
				setScreenName('SignUpAwaitingConfirmation');
				break;
		}
	}, [signInState]);

	switch (signInState) {
		case SignInStates.form:
			return <SignIn
				onEmailChange={(value) => setEmail(value)}
				onPasswordChange={(value) => setPassword(value)}
				onForgotPassword={onForgotPassword}
				onSignUp={onSignUp}
				onSubmit={handleSignIn}
				loading={loading}
				/>
		case SignInStates.verification:
			return <SignUpAwaitingConfirmation onSignIn={() => setSignInState(SignInStates.form)}/>
	}
}

export default SignInController;