import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { FormattedMessage, FormattedHTMLMessage, defineMessages, useIntl } from 'react-intl';
import moodImage from '../assets/misc-images/david-gavi-Ijx8OxvKrgM-unsplash.signup-teaser.jpg';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { SignUpValidationErrors } from './SignUpController';
import Link from '@material-ui/core/Link';
import FacebookIcon from '@material-ui/icons/Facebook';
import EmailIcon from '@material-ui/icons/Email';
import GoogleIcon from '../assets/GoogleIcon';
import AppleButtonDE from '../assets/appleid_button@2x.de.png';
import AppleButtonEN from '../assets/appleid_button@2x.en.png';

const useStyles = makeStyles(theme => ({
	headerBackground: {
		color: theme.palette.common.white,
		// backgroundImage: `linear-gradient(135deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.0)),url(${moodImage})`,
		backgroundImage: `url(${moodImage})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		marginBottom: theme.spacing(1),
	},
	header: {
		padding: theme.spacing(2),
	},
	heading: {
		marginBottom: theme.spacing(1),
	},
	container: {
		padding: theme.spacing(4),
		paddingTop: theme.spacing(2),
	},
	description: {
		// padding: theme.spacing(2),
		marginBottom: theme.spacing(4),
	},
	signup: {
		margin: theme.spacing(3, 0, 1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	signIn: {
		position: "absolute",
		bottom: 0,
	},
	optinError: {
		color: theme.palette.error.main,
	},
	checkboxLabel: {
		fontSize: "0.9rem",
	},
	link: {
		textDecoration: "underline",
	},
	checkboxTOSPP: {
		marginBottom: theme.spacing(2),
		fontSize: "0.9rem !important",
	},
	checkboxMarketingOptin: {
		marginBottom: theme.spacing(2),
	},
	button: {
		margin: 0,
		marginTop: theme.spacing(2),
		textTransform: "none",
	},
	legend: {
		marginTop: theme.spacing(4),
	},
	appleButton: {
		backgroundColor: "black",
		borderRadius: 4,
		width: "100%",
		marginTop: theme.spacing(2),
		cursor: "pointer",
		backgroundImage: (props:{locale:string}) => `url(${props.locale === "de" ? AppleButtonDE : AppleButtonEN})`,
		// backgroundImage: `url(${AppleButtonEN})`,
		backgroundSize: "auto 100%",
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		height: 36,
	}
}));

const messages = defineMessages({
	tosurl: {
		id: 'urls.terms-of-service',
		defaultMessage: 'https://www.focalityapp.com/en/terms-of-service/',
	},
	ppurl: {
		id: 'urls.privacy-policy',
		defaultMessage: 'https://www.focalityapp.com/en/privacy-policy/',
	},
});

interface Props {
	onSignUp(): void,
	onSignIn(): void,
	onFacebook(): void,
	onApple(): void,
	onGoogle(): void,
	validationErrors: SignUpValidationErrors,
	onOptinTOSPPChange(status: boolean): void,
	onOptinMarketingChange(status: boolean): void,
}

function SignUpTeaser(props: Props) {
	const { 
		onSignIn,
		onSignUp,
		onFacebook,
		onApple,
		onGoogle,
		validationErrors,
		onOptinMarketingChange,
		onOptinTOSPPChange,
	} = props;
	const { formatMessage, locale } = useIntl();
	const classes = useStyles({locale: locale});

	const tosurl = formatMessage(messages.tosurl);
	const ppurl = formatMessage(messages.ppurl);

	return (
		<React.Fragment>
			<Box className={classes.headerBackground}>
				<Container maxWidth="xs">
					<Box className={classes.header}>
						<Typography variant="h4" color="inherit" className={classes.heading}><FormattedHTMLMessage id="signup.teaser.title" defaultMessage="Become your<br/>better self"/></Typography>
						<Typography variant="body1" color="inherit"><FormattedMessage id="signup.teaser.subtitle" defaultMessage="Plan, reflect, improve."/></Typography>
					</Box>
				</Container>
			</Box>

		<Container maxWidth="xs" className={classes.container}>
			<Box className={classes.description}>
				<Typography variant="body1" color="inherit"><FormattedMessage id="signup.teaser.description" defaultMessage="Focality will help you achieve your goals, focus on the important things und unleash your true potential."/></Typography>
			</Box>
			<Box className={classes.checkboxTOSPP}>
				<FormControlLabel
					className={typeof validationErrors.optinTOSPP !== "undefined" ? classes.optinError : undefined}
					control={<Checkbox value="optinTOSPP" color="primary" onChange={(e, isChecked) => onOptinTOSPPChange(isChecked)}/>}
					label={
						<span className={classes.checkboxLabel}>
							<FormattedMessage
								id="signup.optinTOSPP"
								defaultMessage="I accept the <toslink>terms of service</toslink> and <pplink>privacy policy</pplink>.&nbsp;*"
								values={{
									toslink: (msg: string) => <Link className={classes.link} href={tosurl} target="_blank" rel="noopener">{msg}</Link>,
									pplink: (msg: string) => <Link className={classes.link} href={ppurl} target="_blank" rel="noopener">{msg}</Link>,
								}}
								/>
						</span>
						}
					/>
			</Box>
			<Box className={classes.checkboxMarketingOptin}>
					<FormControlLabel
					className={typeof validationErrors.optinMarketing !== "undefined" ? classes.optinError : undefined}
					control={<Checkbox value="optinMarketing" color="primary" onChange={(e, isChecked) => onOptinMarketingChange(isChecked)}/>}
					label={
						<React.Fragment>
							<span className={classes.checkboxLabel}>
								<FormattedMessage
									id="signup.optinMarketing"
									defaultMessage="Focality can ask me via email about my experiences with the product and send me related information. I can revoke my consent at any time by contacting info@focalityapp.com or using the opt-out link included in every email."
									/>
								{/* {optinMarketingRequired && " *"} */}
							</span>
						</React.Fragment>
						}
					/>
			</Box>

			<Button
				onClick={onFacebook}
				variant="contained"
				color="primary"
				// color="secondary"
				// className={classes.button}
				fullWidth={true}
				startIcon={<FacebookIcon />}
				className={classes.button}
				style={{backgroundColor: "#3b5998"}}
				>
					<FormattedMessage id="signup.teaser.facebook" defaultMessage="Continue with Facebook"/>
			</Button>
			<Button
				onClick={onGoogle}
				variant="contained"
				color="primary"
				// color="secondary"
				// className={classes.button}
				fullWidth={true}
				startIcon={<GoogleIcon />}
				className={classes.button}
				style={{backgroundColor: "#4285F4"}}
				>
					<FormattedMessage id="signup.teaser.google" defaultMessage="Continue with Google"/>
			</Button>

			<div onClick={onApple} className={classes.appleButton}>
				{/* <img src={AppleButton} /> */}
			</div>

			<Button
				onClick={onSignUp}
				fullWidth
				variant="contained"
				color="primary"
				startIcon={<EmailIcon />}
				className={classes.button}

				>
					<FormattedMessage id="signup.teaser.emailSignUp" defaultMessage="Sign up with email"/>
			</Button>
			<Button
				onClick={onSignIn}
				fullWidth
				variant="outlined"
				color="primary"
				startIcon={<EmailIcon />}
				className={classes.button}
				>
					<FormattedMessage id="signup.teaser.emailSignIn" defaultMessage="Log in with email"/>
			</Button>
			{/* <Box textAlign="center">
				<Typography variant="body2" color="inherit" ><FormattedMessage id="signup.teaser.smallprint" defaultMessage="Expires after 14 days. No strings attached."/></Typography>
			</Box> */}
				{/* <Button
					onClick={onSignIn}
					fullWidth
					variant="outlined"
					color="primary"
					className={classes.submit}
					>
						<FormattedMessage id="signup.teaser.signin" defaultMessage="Already a member? Sign in."/>
				</Button> */}
				<Typography variant="body2" className={classes.legend}>* <FormattedMessage id="signup.teaser.required" defaultMessage="Required"/></Typography>
			</Container>
		</React.Fragment>
	);
}

export default SignUpTeaser;
