import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import moodImage from '../assets/misc-images/david-gavi-Ijx8OxvKrgM-unsplash.signup-teaser.jpg';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles(theme => ({
	headerBackground: {
		color: theme.palette.common.white,
		// backgroundImage: `linear-gradient(135deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.0)),url(${moodImage})`,
		backgroundImage: `url(${moodImage})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(4),
		marginBottom: theme.spacing(1),
	},
	header: {
		padding: theme.spacing(2),
	},
	heading: {
		marginBottom: theme.spacing(1),
	},
	description: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(-1),
	},
	cardPricing: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'baseline',
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(4),
	},
	signup: {
		margin: theme.spacing(3, 0, 1),
		color: "white",
	},
	container: {
		paddingBottom: theme.spacing(10),
	},
	buttonProgress: {
		position: 'absolute',
	},
	restorePurchases: {
		marginTop: theme.spacing(2),
	},
}));

interface Props {
	onBuy(): void,
	onBack(): void,
	onRestorePurchases(): void,
	price: string,
	priceLoading: boolean,
	transactionLoading: boolean,
}

export default function SubscriptionUpgradeV2(props: Props) {
	const { onBuy, onBack, onRestorePurchases, price, transactionLoading, priceLoading } = props;
	const classes = useStyles();

	return (
		<React.Fragment>
			<Box className={classes.headerBackground}>
				<IconButton color="inherit" onClick={onBack}><ArrowBackIcon/></IconButton>
				<Container maxWidth="xs">
					<Box className={classes.header}>
						<Typography variant="h4" color="inherit" className={classes.heading}><FormattedHTMLMessage id="SubscriptionUpgrade.title" defaultMessage="Become your<br/>better self"/></Typography>
						<Typography variant="body1" color="inherit"><FormattedMessage id="SubscriptionUpgrade.subtitle" defaultMessage="Subscribe to use all features"/></Typography>
					</Box>
				</Container>
			</Box>

		<Container maxWidth="xs" className={classes.container}>

			<Box className={classes.cardPricing}>
				{!priceLoading &&
					<Typography component="h2" variant="h3" color="textPrimary">
							{price}
					</Typography>
				}
				{priceLoading && <React.Fragment><CircularProgress size={32}/>&nbsp;</React.Fragment>}
				<Typography variant="h6" color="textSecondary">
					/<FormattedMessage id="SubscriptionUpgrade.month" defaultMessage="month"/>
				</Typography>
			</Box>
			

			<List dense={false}>
				<ListItem>
					<ListItemIcon>
						<CheckCircleIcon />
					</ListItemIcon>
					<ListItemText
						primary={<FormattedMessage id="SubscriptionUpgrade.bullet1" defaultMessage="Unlimited goals"/>}
					/>
				</ListItem>
				<ListItem>
					<ListItemIcon>
						<CheckCircleIcon />
					</ListItemIcon>
					<ListItemText
						primary={<FormattedMessage id="SubscriptionUpgrade.bullet2" defaultMessage="Plan further into the future"/>}
					/>
				</ListItem>
				<ListItem>
					<ListItemIcon>
						<CheckCircleIcon />
					</ListItemIcon>
					<ListItemText
						primary={<FormattedMessage id="SubscriptionUpgrade.bullet3" defaultMessage="Use all past data for insights"/>}
					/>
				</ListItem>
			</List>

			<Button
				onClick={onBuy}
				fullWidth
				variant="contained"
				color="secondary"
				className={classes.signup}
				disabled={transactionLoading || priceLoading}
				x-tracking="SubscriptionUpgrade.cta"
				>
					<FormattedMessage id="SubscriptionUpgrade.cta" defaultMessage="Subscribe now"/>
					{transactionLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
			</Button>
			<Box textAlign="center">
				<Typography variant="body2" color="inherit" ><FormattedMessage id="SubscriptionUpgrade.conditions" defaultMessage="Subscriptions auto-renew after each billing cycle. You can cancel any time to end your subscription with the current subscription cycle."/></Typography>
			</Box>
			{/* <Box textAlign="center">
				<Link><FormattedMessage id="SubscriptionUpgrade.restorePurchases" defaultMessage="Restore purchases"/></Link>
			</Box>
			<Box textAlign="center">
				<Button variant="text"><FormattedMessage id="SubscriptionUpgrade.restorePurchases" defaultMessage="Restore purchases"/></Button>
			</Box> */}
			<Box textAlign="center" className={classes.restorePurchases}>
				<Button variant="text" color="primary" onClick={onRestorePurchases}><FormattedMessage id="SubscriptionUpgrade.restorePurchases" defaultMessage="Restore purchases"/></Button>
			</Box>
		
		</Container>
		</React.Fragment>
	);
}