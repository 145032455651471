import React from 'react';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import {
	ResponsiveContainer,
	XAxis, YAxis, CartesianGrid, 
	Tooltip,
	ScatterChart, Scatter,
	} from 'recharts';
import { EstimateCompletionRates } from '../InsightsController';

type VolumeCompletionScatterProps = {
	estimateCompletionRates: EstimateCompletionRates,
	highlightEstimate?: number,
}

function VolumeCompletionScatter (props: VolumeCompletionScatterProps) {

	const { estimateCompletionRates } = props;

	if (estimateCompletionRates.length === 0)
		return <Typography variant="body1"><FormattedMessage id="insights.nodata" defaultMessage="Not enough data."/></Typography>;

	const estimateCompletionRatesBase100 = estimateCompletionRates.map(e => ({estimate: e.estimate, completionRate: Math.round(e.completionRate * 100)}));

	return (
		<ResponsiveContainer width="100%" aspect={3}>
			<ScatterChart margin={{top: 20, right: 20, bottom: 20, left: 20}}>
				<CartesianGrid />
				<XAxis dataKey={'completionRate'} type="number" name='Completion' unit='%'/>
				<YAxis dataKey={'estimate'} type="number" name='Volume' unit='h'/>
				<Scatter name='Planned Volume and Success Rate' data={estimateCompletionRatesBase100} fill='#8884d8'/>
				{props.highlightEstimate && 
					<Scatter name='' data={estimateCompletionRatesBase100.filter(i => i.estimate === props.highlightEstimate)} fill='#c2185b'/>
				}
				<Tooltip cursor={{strokeDasharray: '3 3'}}/>
			</ScatterChart>
		</ResponsiveContainer>
	);
}

VolumeCompletionScatter.propTypes = {
};

export default VolumeCompletionScatter;