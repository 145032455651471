import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useIntl, defineMessages} from 'react-intl';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Container from '@material-ui/core/Container';
import ListCard from './ListCard';
import { List, getListImage } from '../services/list-helpers';
import { Objective, objectivesByList, getObjectivesDuration } from '../services/objective-helpers';
import GoalsEmpty from './GoalsEmpty';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
	fab: {
		position: 'fixed',
		bottom: "calc(56px + "+theme.spacing(2)+"px + env(safe-area-inset-bottom))",
		right: theme.spacing(2),
		fallbacks: {
			bottom: "calc(56px + "+theme.spacing(2)+"px)",
		},
	},
	root: {
		padding: theme.spacing(2),
		paddingBottom: 56 + theme.spacing(2),
	},
	tile: {
		cursor: "pointer",
	},
	container: {
		paddingBottom: theme.spacing(8),
		paddingLeft: 0,
		paddingRight: 0,
	},
	archivedTitle: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(1),
	},
}));

const messages = defineMessages({
	otherTitle: {
		id: 'plan.otherTitle',
		defaultMessage: 'Other',
	},
});

interface Props {
	goals: List[],
	allObjectives: Objective[],
	onAddClick(): void,
	onListClick(list?: List | null): void,
	onAddObjectiveClick(list: List | null): void,
}

export default function GoalsViewV3(props: Props) {
	const { goals, allObjectives, onListClick } = props;
	const classes = useStyles();
	const {formatMessage} = useIntl();

	const goalsActive = goals.filter(g => !g.archived);
	// const goalsArchived = goalsActive;
	const goalsArchived = goals.filter(g => g.archived);
	
	return (
		<React.Fragment>
			<Container maxWidth="sm" className={classes.container}>
				{goalsActive.length > 0 && goalsActive.map((goal: List) => 
					<ListCard
						key={goal.id}
						title={goal.title || ""}
						headerBackground={getListImage(goal)}
						objectivesNumber={objectivesByList(allObjectives, goal).length}
						objectivesDuration={getObjectivesDuration(objectivesByList(allObjectives, goal))}
						onClick={() => onListClick(goal)}
						onAddObjectiveClick={() => props.onAddObjectiveClick(goal)}
					/>
				)}
				{goals.length === 0 && goalsArchived.length === 0 &&
					<GoalsEmpty  onCreateGoal={props.onAddClick}/>
				}
				<ListCard
					title={formatMessage(messages.otherTitle)}
					objectivesNumber={objectivesByList(allObjectives, null).length}
					objectivesDuration={getObjectivesDuration(objectivesByList(allObjectives, null))}
					onClick={() => onListClick(null)}
					onAddObjectiveClick={() => props.onAddObjectiveClick(null)}
				/>
				{goalsArchived.length > 0 && <>
					<Typography variant="h5" className={classes.archivedTitle}><FormattedMessage id="goals.archivedTitle" defaultMessage="Archived goals"/></Typography>
					{goalsArchived.length > 0 && goalsArchived.map((goal: List) => 
						<ListCard
							key={goal.id}
							title={goal.title || ""}
							headerBackground={getListImage(goal)}
							objectivesNumber={objectivesByList(allObjectives, goal).length}
							objectivesDuration={getObjectivesDuration(objectivesByList(allObjectives, goal))}
							onClick={() => onListClick(goal)}
							onAddObjectiveClick={() => props.onAddObjectiveClick(goal)}
						/>
					)}
				</>}
			</Container>
			<Fab color="primary" aria-label="add" className={classes.fab} onClick={props.onAddClick}>
				<AddIcon />
			</Fab>
		</React.Fragment>
	);
}
