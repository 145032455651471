import React, { useState } from 'react';
import { setScreenName } from '../services/analytics';
import GoalsViewV3 from './GoalsViewV3';
import { List } from '../services/list-helpers';
import { Objective } from '../services/objective-helpers';
import ObjectiveAdderV2B from './ObjectiveAdderV2B';
import { Plan, PlanUnsaved } from '../services/plan-helpers';


interface Props {
	goals: List[],
	allObjectives: Objective[],
	onAddClick(): void,
	onListClick(list?: List | null): void,
	onCreateObjective(goal: List | null, plan: Plan | PlanUnsaved | null, title: string, estimate: number, secondary: boolean): void,
}

export default function GoalsViewController(props: Props) {
	const [objectiveAdderGoal, setObjectiveAdderGoal] = useState<List|null>(null);

	function objectiveAdderSave (goal: List | null, plan: Plan | PlanUnsaved | null, title: string, estimate: number, secondary: boolean) {
		props.onCreateObjective(goal, plan, title, estimate, secondary);
		setObjectiveAdderGoal(null);
	}

	setScreenName('GoalsView');
	return <>
		<GoalsViewV3
			goals={props.goals}
			allObjectives={props.allObjectives}
			onAddClick={props.onAddClick}
			onListClick={props.onListClick}
			onAddObjectiveClick={(list: List | null) => setObjectiveAdderGoal(list)}
		/>
			{objectiveAdderGoal && <>
				<ObjectiveAdderV2B
					doCancel={() => setObjectiveAdderGoal(null)} 
					doSave={(title: string, estimate: number, secondary: boolean) => 
						objectiveAdderSave(
							objectiveAdderGoal,
							null,
							title,
							estimate,
							secondary
							)}
					doReschedule={() => {}}
					objectivesHigherOrder={[]}
					objectivesOverdueOrUnscheduled={[]}
					planType={null}
				/>
			</>}
	</>;
}
