import React from 'react';
import * as Sentry from '@sentry/browser';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

interface Props {
	eventId: any,
}

export default function ErrorView(props: Props) {
	const { eventId } = props;

	return (
		<Container style={{ marginTop: 16, textAlign: "center",}}>
			<Typography variant="h4" gutterBottom paragraph>◉_◉</Typography>
			<Typography variant="h6" gutterBottom paragraph>Sorry, something went wrong. :(</Typography>
			<Button onClick={() => Sentry.showReportDialog({ eventId: eventId })} variant="contained" color="secondary" style={{color: "white", marginBottom: "20px"}}>Report feedback</Button>
			<Typography variant="body1">Or send us an email to <Link href="mailto:info@focalityapp.com">info@focalityapp.com</Link></Typography>
		</Container>
	);

}
