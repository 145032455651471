import React from 'react';
import Button from '@material-ui/core/Button';
import DialogNotched from './DialogNotched';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

interface Props {
	onClose(): void,
}

export default function SubscriptionChangeDialog(props: Props) {

	const { onClose } = props;
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<DialogNotched
			fullScreen={fullScreen}
			open={true}
			onClose={onClose}
			aria-labelledby="responsive-dialog-title"
		>
			<DialogContent>
					<Typography variant="h6" gutterBottom>Please use our iOS or Android apps</Typography>
					<Typography variant="body1" paragraph>Currently, subscriptions can only be purchased on the Apple App Store or Google Play Store. Once purchased, the subscription is valid for the web version of Focality as well.</Typography>
					<Typography variant="body1">Please <Link href="https://www.focalityapp.com/en/contact/">contact us</Link> if you cannot use iOS/Android apps or if we can help in any way.</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">
					Close
				</Button>
			</DialogActions>
		</DialogNotched>
	);
}
