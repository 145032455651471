/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      email
      marketingOptIn
      cognitoCreated
      android
      ios
      web
      lastSeen
      language
      createdAt
      updatedAt
      version
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        email
        marketingOptIn
        cognitoCreated
        android
        ios
        web
        lastSeen
        language
        createdAt
        updatedAt
        version
        owner
      }
      nextToken
    }
  }
`;
export const getList = /* GraphQL */ `
  query GetList($id: ID!) {
    getList(id: $id) {
      id
      title
      description
      due
      archived
      motivationWhy
      motivationFail
      image
      getObjectives {
        nextToken
      }
      createdAt
      updatedAt
      version
      owner
    }
  }
`;
export const listLists = /* GraphQL */ `
  query ListLists(
    $filter: ModelListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        due
        archived
        motivationWhy
        motivationFail
        image
        createdAt
        updatedAt
        version
        owner
      }
      nextToken
    }
  }
`;
export const getPlan = /* GraphQL */ `
  query GetPlan($id: ID!) {
    getPlan(id: $id) {
      id
      type
      period
      complete
      periodSatisfactory
      planSatisfactory
      goodThings
      badThings
      insights
      getObjectives {
        nextToken
      }
      createdAt
      updatedAt
      version
      owner
    }
  }
`;
export const listPlans = /* GraphQL */ `
  query ListPlans(
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        period
        complete
        periodSatisfactory
        planSatisfactory
        goodThings
        badThings
        insights
        createdAt
        updatedAt
        version
        owner
      }
      nextToken
    }
  }
`;
export const getObjective = /* GraphQL */ `
  query GetObjective($id: ID!) {
    getObjective(id: $id) {
      id
      list {
        id
        title
        description
        due
        archived
        motivationWhy
        motivationFail
        image
        createdAt
        updatedAt
        version
        owner
      }
      plan {
        id
        type
        period
        complete
        periodSatisfactory
        planSatisfactory
        goodThings
        badThings
        insights
        createdAt
        updatedAt
        version
        owner
      }
      title
      estimate
      secondary
      success
      failedReason
      recurrer {
        id
        title
        estimate
        secondary
        planType
        start
        end
        periodType
        periodFrequency
        monthdayType
        weekdays
        createdAt
        updatedAt
        version
        owner
      }
      createdAt
      updatedAt
      version
      owner
    }
  }
`;
export const listObjectives = /* GraphQL */ `
  query ListObjectives(
    $filter: ModelObjectiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listObjectives(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        estimate
        secondary
        success
        failedReason
        createdAt
        updatedAt
        version
        owner
      }
      nextToken
    }
  }
`;
export const getRecurrer = /* GraphQL */ `
  query GetRecurrer($id: ID!) {
    getRecurrer(id: $id) {
      id
      title
      estimate
      secondary
      list {
        id
        title
        description
        due
        archived
        motivationWhy
        motivationFail
        image
        createdAt
        updatedAt
        version
        owner
      }
      getObjectives {
        nextToken
      }
      planType
      start
      end
      periodType
      periodFrequency
      monthdayType
      weekdays
      createdAt
      updatedAt
      version
      owner
    }
  }
`;
export const listRecurrers = /* GraphQL */ `
  query ListRecurrers(
    $filter: ModelRecurrerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecurrers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        estimate
        secondary
        planType
        start
        end
        periodType
        periodFrequency
        monthdayType
        weekdays
        createdAt
        updatedAt
        version
        owner
      }
      nextToken
    }
  }
`;
