import React from 'react';
import Button from '@material-ui/core/Button';
import DialogNotched from './DialogNotched';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

interface Props {
	open: boolean,
	onClose(): void,
}

export default function SubscriptionChangeDialog(props: Props) {

	const { open, onClose } = props;
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<DialogNotched
			fullScreen={fullScreen}
			open={open}
			onClose={onClose}
			aria-labelledby="responsive-dialog-title"
		>
			{/* <DialogTitle id="responsive-dialog-title">Changing your subscription</DialogTitle> */}
			<DialogContent>
				{/* <DialogContentText> */}
					{/* <Typography variant="h5" gutterBottom paragraph>Changing your subscription</Typography>
					<Typography variant="h6" gutterBottom>Choosing a different billing cycle</Typography>
					<Typography variant="body1" paragraph>Please contact us if you want to switch from monthly billing to yearly billing or vice versa.</Typography> */}
					<Typography variant="h6" gutterBottom>Cancelling</Typography>
					<Typography variant="body1">You can cancel your subscription through the store. Please contact us if you have any questions.</Typography>
				{/* </DialogContentText> */}
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">
					Close
				</Button>
			</DialogActions>
		</DialogNotched>
	);
}
