import React from 'react';
// import logger from '../services/logger';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import { Objective } from '../services/objective-helpers';
import { PlanType } from '../services/plan-helpers';
import { Recurring } from '../services/recurring-helpers';
import logger from '../services/logger';
import ObjectiveEditorController from './ObjectiveEditorController';

const useStyles = makeStyles(theme => ({
	gridContainer: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		width: '100%',
		margin: 0,
		maxHeight: 330,
	},
	chip: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	hop: {
		paddingTop: "0 !important",
	},
}));

interface ObjectiveAdderV2BProps {
	doCancel(): void,
	objectivesHigherOrder: Objective[],
	objectivesOverdueOrUnscheduled: Objective[],
	planType: PlanType | null,
	doSave(title: string, estimate: number | null, secondary: boolean, recurring: Recurring | null): void,
	doReschedule(objective: Objective): void,
}

export default function ObjectiveAdderV2B(props: ObjectiveAdderV2BProps) {
	const classes = useStyles();

	logger.debug("ObjectiveAdderV2BProps", props);

	return <>
		<Drawer anchor="bottom" open={true} onClose={props.doCancel}>
			<ObjectiveEditorController
				planType={props.planType}
				doCancel={props.doCancel}
				doCreateObjective={props.doSave}
			/>
			<Grid container spacing={3} className={classes.gridContainer}>
				{props.objectivesHigherOrder.length > 0 ?
					<Grid item xs={12} className={classes.hop}>
						<Typography variant="subtitle2">{<FormattedMessage id="objectiveAdder.higherOrder" defaultMessage="… or reschedule from higher order plan:"/>}</Typography>
						{props.objectivesHigherOrder.map(objective => <Chip key={objective.id} label={objective.title} className={classes.chip} onClick={() => props.doReschedule(objective)}/>)}
					</Grid>
				: null }
				{props.objectivesOverdueOrUnscheduled.length > 0 ?
					<Grid item xs={12} className={classes.hop}>
						<Typography variant="subtitle2">{<FormattedMessage id="objectiveAdder.overDueOrUnscheduled" defaultMessage="… or reschedule overdue or unscheduled objectives:"/>}</Typography>
						{props.objectivesOverdueOrUnscheduled.map(objective => <Chip key={objective.id} label={objective.title} className={classes.chip} onClick={() => props.doReschedule(objective)}/>)}
					</Grid>
				: null }
			</Grid>
		</Drawer>
	</>;
}
