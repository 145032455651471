import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Plan, PlanUnsaved } from '../services/plan-helpers';
import { FormattedMessage } from 'react-intl';


const useStyles = makeStyles(theme => ({
	container: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	header: {
		padding: theme.spacing(2),
	},
	heading: {
		marginBottom: theme.spacing(1),
	},
	bottomGrid: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		marginTop: theme.spacing(2),
	},
}));

interface ReflectionWizardGoodThingsProps {
	plan: Plan | PlanUnsaved,
	onSavePlan(plan: Plan | PlanUnsaved): void,
	onContinue(): void,
	onBack(): void,
}

function ReflectionWizardGoodThings(props: ReflectionWizardGoodThingsProps) {
	const { onContinue, onBack} = props;

	const classes = useStyles();

	const [fieldValue, setFieldValue] = useState(props.plan.goodThings);

	const handleSave = () => {
		const updated = Object.assign({}, props.plan, {
			goodThings: fieldValue !== "" ? fieldValue : null
		});
		props.onSavePlan(updated);
	};

	return (
		<Container maxWidth="sm" className={classes.container}>
			<Box className={classes.header}>
				<Typography variant="h4" color="inherit" className={classes.heading}><FormattedMessage id="plan.reflection.labelGood" defaultMessage="What went well?"/></Typography>
				<Typography variant="body1" color="inherit"><FormattedMessage id="reflectionWizard.goodThings.description" defaultMessage="Briefly note the good things that happened. Where did you succeed? What gave you joy? What were the memorable moments?"  values={{type: props.plan.type}}/></Typography>
			</Box>

			<Box className={classes.header}>
				<TextField
						label={<FormattedMessage id="reflectionWizard.goodThings.label" defaultMessage="Good things"/>}
						multiline
						margin="dense"
						fullWidth	
						value={fieldValue || ""}
						onChange={(event) => setFieldValue(event.target.value)}
						InputProps={{
							onBlur: handleSave,
						}}
					/>
			</Box>
			<Box className={classes.header}>
				<Typography variant="body2" color="inherit">
					<FormattedMessage id="reflectionWizard.goodThings.explanation" defaultMessage="Writing down positive experiences will make you more aware of the good things in your life. We will compile a collection of your experiences so that you can cherish these moments when you need a motivational boost."/><br/>
				</Typography>
			</Box>

			<Grid container justify="space-between" className={classes.bottomGrid}>
				<Grid item><Button variant="text" color="default" onClick={onBack}><FormattedMessage id="reflectionWizard.back" defaultMessage="Back"/></Button></Grid>
				<Grid item><Button variant="contained" color="primary" onClick={onContinue}><FormattedMessage id="reflectionWizard.continue" defaultMessage="Continue"/></Button></Grid>
			</Grid>
		</Container>
	);
}

export default ReflectionWizardGoodThings;