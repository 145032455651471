import { Plugins } from '@capacitor/core';
import { addMinutes } from 'date-fns';
import logger from '../services/logger.js';
import * as Sentry from '@sentry/browser';

export async function checkNotificationsPermission() {
	logger.debug("Checking notifications permission status...");

	// Implement default permission check.
	// let areEnabled = new Promise<LocalNotificationEnabledResult>((resolve, reject) => {
	// 	resolve({
	// 		value: typeof Notification !== "undefined" && Notification.permission === "granted"
	// 	})
	// });

	let resultValue = await getNotificationPermissionStatus();

	logger.debug("Notifications status:", resultValue);

	if (!resultValue) {
		return requestPermissions();
	} else {
		logger.info('Notifications are enabled');
		return true;
	}
}

export async function getNotificationPermissionStatus() {
	const { LocalNotifications } = Plugins;

	logger.debug("Checking notifications permission status...");

	// Implement default permission check.
	// let areEnabled = new Promise<LocalNotificationEnabledResult>((resolve, reject) => {
	// 	resolve({
	// 		value: typeof Notification !== "undefined" && Notification.permission === "granted"
	// 	})
	// });

	let resultValue = false;
	
	try {
		// Try to use native permission check. Throws "not implemented" if not implemented.
		const result = await LocalNotifications.areEnabled();
		resultValue = result.value;
	} catch (e) {
		logger.debug('Caught error while checking notification persmission', e);
		resultValue = typeof Notification !== "undefined" && Notification.permission === "granted";
	}

	logger.debug("Notifications status:", resultValue);

	if (!resultValue) {
		logger.info('Notifications are disabled.');
		return false;
	} else {
		logger.info('Notifications are enabled.');
		return true;
	}
}

export async function requestPermissions() {
	const { LocalNotifications } = Plugins;

	logger.info('Requesting notification permissions');
	try {
		await LocalNotifications.requestPermission();
	} catch (e) {
		logger.error('Error while requesting permission:', e);
		Sentry.captureException(e);
	}

	// Ugly hack: Schedule temporary notification because iOS seems to only request permission on the actual scheduling.
	const notificationId = 9999;
	await LocalNotifications.schedule({notifications: [{
		title: "Welcome to Focality",
		body: "",
		id: notificationId,
		schedule: { at: addMinutes(new Date(), 1) },
	}]});
	await LocalNotifications.cancel({notifications: [{id: String(notificationId)}]});
	const notificiationStatus = await getNotificationPermissionStatus();
	return notificiationStatus;
}
