import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import FlareIcon from '@material-ui/icons/FlareRounded';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
	container: {
		backgroundColor: theme.palette.background.paper,
		height: "100vh",
	},
	"@keyframes spinning": {
		from: {
			transform: "rotate(0deg)",
		},
		to: {
			transform: "rotate(360deg)",
		}
	  },
	logo: {
		// animationName: '$spinning',
		animation: "$spinning infinite 5s linear",
		marginBottom: theme.spacing(2),
		fontSize: "7rem",
	},
	timeout: {
		marginTop: theme.spacing(2),
		textAlign: "center",
	},
}));

function LoadingView(props: {
	what?: string,
	showReload?: boolean,
	onReload?(): void,
}) {
	const classes = useStyles();
	
	return (
		<Grid
			container
			direction="column"
			justify="center"
			alignItems="center"
			className={classes.container}
		>
			<Grid item>
				<FlareIcon fontSize="large" className={classes.logo}/>
			</Grid>
			<Grid item>
				<Typography variant="h4" style={{fontWeight: 300}} align="center" color="textPrimary" gutterBottom>
					<FormattedMessage id="LoadingView.title" defaultMessage="Loading…"/>
				</Typography>
				<Typography variant="caption" style={{fontWeight: 100, width: "100%", display: "inline-block"}} align="center" color="textPrimary" gutterBottom>
					… {props.what} …
				</Typography>
			</Grid>
			{props.showReload &&
				<Grid item className={classes.timeout}>
					<Typography variant="h6" style={{fontWeight: 300, marginBottom: 10}} align="center" color="textPrimary" gutterBottom>
						<FormattedMessage id="LoadingView.timeout.title" defaultMessage="This is taking longer than usual. :("/>
					</Typography>
					<Button onClick={props.onReload} variant="contained" color="secondary" style={{color: "white", marginBottom: "20px"}}>Try again</Button>
					<Typography variant="body1" style={{fontWeight: 300}}>Issues? <Link href="mailto:info@focalityapp.com">Contact us</Link> so that we can help.</Typography>
				</Grid>
			}
		</Grid>
	);
}

export default LoadingView;
