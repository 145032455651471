import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FlareIcon from '@material-ui/icons/Flare';
import NearMeIcon from '@material-ui/icons/NearMe';
// import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import PersonIcon from '@material-ui/icons/Person';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { defineMessages, useIntl } from 'react-intl';
import { Paper } from '@material-ui/core';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import LText from '../services/LText';


const useStyles = makeStyles(theme => ({
	bottomNavigation: {
		position: "fixed",
		bottom: 0,
		width: "100%",
		paddingBottom: "env(safe-area-inset-bottom)",
		// paddingLeft: theme.spacing(4),
		// paddingRight: theme.spacing(4),
		zIndex: 99,
	},
	bottomNavigationSelected: {
		color: theme.palette.secondary.main + ' !important',
	},
}));


const messages = defineMessages({
	plans: {
		id: 'navigation.plans',
		defaultMessage: 'Plans',
	},
	goals: {
		id: 'navigation.goals',
		defaultMessage: 'Goals',
	},
	insights: {
		id: 'navigation.insights',
		defaultMessage: 'Insights',
	},
	account: {
		id: 'navigation.account',
		defaultMessage: 'Account',
	},
});

export default function NavigationBottom(props) {
	const { selected } = props;
	const { formatMessage } = useIntl();
	const classes = useStyles();

	const handlePeriodChange = (event, value) => {
		switch (value) {
			case 0:
			case "plans":
				props.onNavigatePlans();
				break;
			case 1:
			case "goals":
				props.onNavigateGoals();
				break;
			case 2:
			case "insights":
				props.onNavigateInsights();
				break;
			case 3:
			case "account":
				props.onNavigateAccount();
				break;
			case 4:
			case "help":
				props.onNavigateHelp();
				break;
			default:
				throw new Error("Unexpected navigation value "+value);
		}
	};

	return (
		<Paper elevation={2} className={classes.bottomNavigation}>
			<BottomNavigation
				value={selected}
				onChange={handlePeriodChange}
				style={{paddingLeft:32, paddingRight:32}}
				>
				<BottomNavigationAction label={formatMessage(messages.plans)} value="plans" icon={<NearMeIcon />} classes={{selected: classes.bottomNavigationSelected}} data-onboarding-markers="navigation-plans"/>
				<BottomNavigationAction label={formatMessage(messages.goals)} value="goals" icon={<FlareIcon />} classes={{selected: classes.bottomNavigationSelected}} data-onboarding-markers="navigation-goals"/>
				<BottomNavigationAction label={formatMessage(messages.insights)} value="insights" icon={<BarChartIcon />} classes={{selected: classes.bottomNavigationSelected}} data-onboarding-markers="navigation-insights"/>
				<BottomNavigationAction label={formatMessage(messages.account)} value="account" icon={<PersonIcon />} classes={{selected: classes.bottomNavigationSelected}} data-onboarding-markers="navigation-account"/>
				<BottomNavigationAction label={<LText en="Help" de="Hilfe"/>} value="help" icon={<HelpOutlineIcon />} classes={{selected: classes.bottomNavigationSelected}} data-onboarding-markers="navigation-help"/>
			</BottomNavigation>
		</Paper>
	);
}

NavigationBottom.propTypes = {
	selected: PropTypes.string.isRequired,
	onNavigatePlans: PropTypes.func.isRequired,
	onNavigateGoals: PropTypes.func.isRequired,
	onNavigateInsights: PropTypes.func.isRequired,
	onNavigateAccount: PropTypes.func.isRequired,
	onNavigateHelp: PropTypes.func.isRequired,
};
