import React, { useState, useEffect, useCallback, useContext } from 'react';
import logger from '../services/logger';
import Container from '@material-ui/core/Container';
import PlanViewNavigation from './PlanViewNavigation';
import PlanViewDetail from './PlanViewDetail';
import { getISOWeek, addWeeks, addDays, parseISO, isPast, isSameDay, isSameWeek, addYears, addMonths } from 'date-fns'
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { dateToPeriod, getOverarchingPlanStub, getPreviousPlanStub, getPlanObjectives, getBeginning, getEnd, planReflected, PlanType, Plan, PlanUnsaved, isPlan, PlanStub, getPlanByTypePeriod, calculatePlanningStreaks, findCurrentStreak, getPlanningRate, findPlanById, isBefore, getSubordinatePlanStubs, getOrCreatePlan } from '../services/plan-helpers';
import { Objective, getObjectivesByPlanId, objectivesByList } from '../services/objective-helpers';
import { List } from '../services/list-helpers';
import { Recurring, Recurrer, getVirtualObjectives, getVirtualObjectivesByPlan, findRecurrerById } from '../services/recurring-helpers';
import PlanReflectionInsights from './PlanReflectionInsights';
import LoadingViewController from './LoadingViewController';
import NotFoundView from './NotFoundView';
import PlanViewOnboardingController from './PlanViewOnboardingController';
import { setScreenName, logEvent } from '../services/analytics';
import capitalize from 'lodash.capitalize';
import SchedulerDialogController from './SchedulerDialogController';
import PlanViewCompletionQuery from './PlanViewCompletionQuery';
import PlanningStatsWidget from './PlanningStatsWidget';
import {planningRateDenominators} from './InsightsController';
import { Plugins, AppState } from '@capacitor/core';
import FailedObjectiveDialogController from './FailedObjectiveDialogController';
import PlanReflectionNotice from './PlanReflectionNotice';
import PlanningChecklistController from './PlanningChecklistController';
import { User } from './AuthController';
import SettingsContext from './SettingsContext';
import { checkNotificationsPermission } from '../services/notifications-helpers';
import ObjectiveAdderV2B from './ObjectiveAdderV2B';
import ObjectiveEditorController from './ObjectiveEditorController';
import { Drawer } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	container: {
		// paddingBottom: theme.spacing(8),
		paddingLeft: 0,
		paddingRight: 0,
	},
	reflectionNotice: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
}));

export function getPlanPath(plan: Plan | PlanUnsaved) {
	return `/plans/${plan.type}/${plan.period}`;
}

enum PlanState {
	loading,
	notfound,
	ready,
}

interface Props {
	onSavePlan(plan: Plan | PlanUnsaved): void,
	onEnsurePlanIsSaved(plan: Plan | PlanUnsaved): Promise<Plan>,
	onSaveObjective(objective: Objective, recurring?: Recurring | null): Promise<Objective>,
	onDeleteObjective(objective: Objective): void,
	onCheckObjective(objective: Objective): Promise<Objective>,
	onUncheckObjective(objective: Objective): Promise<Objective>,
	onFailObjective(objective: Objective): Promise<Objective>,
	onToggleObjective(objective: Objective): void,
	createVirtualPlan(type: PlanType, period: string): Promise<Plan>,
	getSavedPlan(plan: PlanStub): Promise<Plan>,
	history: any,
	goals: Array<List>,
	recurrers: Array<Recurrer>,
	objectives: Array<Objective>,
	plans: Array<Plan>,
	type: PlanType,
	period: string,
	user: User,
	doCreateObjective(goal: List | null, plan: Plan | PlanUnsaved | null, title: string, estimate: number | null, secondary: boolean): Promise<Objective>,
	doCreateRecurrer(list: List | null, plan: Plan | PlanUnsaved | undefined, title: string, estimate: number | null, secondary: boolean, recurring: Recurring): Recurrer,
	doDeleteRecurrerWithInstances(recurrer: Recurrer): void,
	doDeleteRecurrerCurrentAndFollowing(recurrer: Recurrer, objective: Objective): void,
	onSetPlan(objective: Objective, plan: PlanStub | null): void,
	doGetPlanOrCreateVirtualPlan(type: PlanType, period: string): Promise<Plan>,
};

function PlanViewController(props: Props) {
	const {
		recurrers,
		objectives,
		plans,
		createVirtualPlan,
		doCreateObjective,
		doCreateRecurrer,
		onSavePlan,
		doGetPlanOrCreateVirtualPlan,
	} = props;

	const classes = useStyles();
	const { locale, formatMessage } = useIntl();
	const { App } = Plugins;

	// logger.debug('PlanViewController', props);

	// const [reflecting, setReflecting] = useState(false);
	const [objectiveAdderShow, setObjectiveAdderShow] = useState(false);
	const [objectiveAdderGoal, setObjectiveAdderGoal] = useState<List|null>(null);
	const [objectiveAdderObjectives, setObjectiveAdderObjectives] = useState<Objective[]>([]);
	const [objectiveAdderObjectivesUnscheduled, setObjectiveAdderObjectivesUnscheduled] = useState<Objective[]>([]);
	const [schedulingObjective, setSchedulingObjective] = useState<Objective|false>(false);
	const [planState, setPlanState] = useState(PlanState.loading);
	const [showPlanningStats, setShowPlanningStats] = useState(false);
	const [planningStreakMax, setPlanningStreakMax] = useState(0);
	const [planningStreakCurrent, setPlanningStreakCurrent] = useState(0);
	const [planningRateNumerator, setPlanningRateNumerator] = useState(0);
	// const [plan, setPlan] = useState<Plan|undefined>(undefined);
	const [planTitle, setPlanTitle] = useState<string|JSX.Element>("");
	const [appIsActive, setAppIsActive] = useState(true);
	const [failedObjective, setFailedObjective] = useState<null|Objective>(null);
	const hideCompletionStatsTimer = React.useRef<number>();
	const [showPlanningChecklist, setShowPlanningChecklist] = useState(false);
	const [editingObjective, setEditingObjective] = useState<Objective|null>(null);
	const settings = useContext(SettingsContext);
	const [savingObjective, setSavingObjective] = useState(false);

	const goalsActive = props.goals.filter(g => !g.archived);

	// Notifications might be accidentally disabled due to a bug
	// If the setting is enabled, try to regain permission. If not, switch setting off.
	// (Placed in PlanViewController because its a convenient starting point. Appv2 would have
	// to carefully check not to trigger before onboarding. Todo: Clearner architecture. Maybe
	// remove alltogether after some time because all affected users should have been updated by
	// then.)
	useEffect(() => {
		if (settings.getNotificationsEnabled()) {
			checkNotificationsPermission().then(result => {
				if (!result) {
					// Stop trying to request permissions
					settings.setNotificationsEnabled(false);
				}
			});
		}
	}, [settings]);

	useEffect(() => () => {
		clearTimeout(hideCompletionStatsTimer.current);
	}, [],);

	const plan = getPlanByTypePeriod(props.type, props.period, props.plans);
	// useEffect(() => () => {
	// 	logger.debug("Trying to get plan from plans", props.plans);
	// 	const p = getPlanByTypePeriod(props.type, props.period, props.plans);
	// 	logger.debug("PlanView: Got plan", p);
	// 	setPlan(p);
	// }, [props.type, props.period, props.plans, props.plans.length]);

	const planningRateDenominator = plan ? planningRateDenominators[plan.type] : 0;

	useEffect(() => {
		// logger.debug("Checking plan state");
		if (!plan) {
			try {
				logger.debug("Creating virtual plan");
				createVirtualPlan(props.type, props.period);
				setPlanState(PlanState.loading);
			} catch (e) {
				logger.error("Could not load plan.", e);
				setPlanState(PlanState.notfound);
			}
		} else {
			setPlanState(PlanState.ready);
		}
	// }, [props.type, props.period, props.plans]);
	}, [plan, createVirtualPlan, props.type, props.period]);
	// const plan = getPlanByTypePeriod(props.type, props.period, props.plans);

	useEffect(() => {
		if (showPlanningStats && plan) {
			const planDate = getBeginning(plan);
			const planningStreaks = calculatePlanningStreaks(plan.type, plans, objectives);
			setPlanningStreakMax(planningStreaks[0] ? planningStreaks[0].length : 0);
			const planningStreakCurrent = findCurrentStreak(planningStreaks, planDate, false);
			setPlanningStreakCurrent(planningStreakCurrent ? planningStreakCurrent.length : 0);
			setPlanningRateNumerator(getPlanningRate(plan.type, planningRateDenominator, planDate, plans));
		}
	}, [showPlanningStats, planningRateDenominator, plan, plans, objectives]);

	const getPlanTitle = useCallback((type: PlanType, period: string) => {
		const currentPeriodDate = parseISO(period);
		// const format = new Intl.DateTimeFormat(locale, { month: 'long' });
		const now = new Date();
		switch (type) {
			case "day":
				if (isSameDay(now, currentPeriodDate))
					return formatMessage({
						id: "PlanView.today",
						defaultMessage: "Today ({dayNumericMonthShort})"},
						{
							dayNumericMonthShort: currentPeriodDate.toLocaleString(locale, { day: 'numeric', month: 'short' }),
							dayNumericMonthNumeric: currentPeriodDate.toLocaleString(locale, { day: 'numeric', month: 'numeric' }),
						})
				else
					return currentPeriodDate.toLocaleString(locale, { day: 'numeric', month: 'short', year: 'numeric' });
			case "week":
				const week = getISOWeek(currentPeriodDate);
				const year = currentPeriodDate.getFullYear();
				const weekString = (locale === "de" || locale.startsWith("de-")) ? `KW${week} ${year}` : `W${week} ${year}`;
				if (isSameWeek(now, currentPeriodDate, {weekStartsOn: 1}))
					return formatMessage({id: "PlanView.thisWeek", defaultMessage: "This Week: W{week}"}, {week: week, year: year})
				else
					return weekString;
			case "month":
				// if (isSameMonth(now, currentPeriodDate))
				// 	return <FormattedMessage id="PlanView.thisMonth" defaultMessage="This month"/>
				// else
				return currentPeriodDate.toLocaleString(locale, { month: 'short', year: 'numeric' });
			default:
				return period;
		}
	}, [locale, formatMessage]);

	const checkPlanTitle = useCallback(() => {
		if (plan) {
			const title = getPlanTitle(plan.type, plan.period);
			if (title !== planTitle) {
				setPlanTitle(title);
			}
		}
	}, [plan, planTitle, getPlanTitle]);

	useEffect(() => {
		checkPlanTitle();
	}, [checkPlanTitle]);

	useEffect(() => {
		App.getState().then((state: AppState) => setAppIsActive(state.isActive));
		const listener = App.addListener('appStateChange', (state: AppState) => {
			console.log('App state changed. Is active?', state.isActive);
			setAppIsActive(state.isActive);
		});
		return () => {
			listener.remove();
		}
	}, [App]);

	useEffect(() => {
		if (appIsActive) {
			checkPlanTitle();
			// logger.debug("Setting plan title timer");
			const timer = setInterval(() => {
				// logger.debug("Checking plan title");
				checkPlanTitle();
			}, 60*1000);
			return () => {
				// logger.debug("Clearing plan title timer");
				clearInterval(timer);
			};
		}
	}, [checkPlanTitle, appIsActive]);


	if (planState === PlanState.notfound) {
		return <NotFoundView/>;
	} else if (planState === PlanState.loading || !plan) {
		return <LoadingViewController what="plan"/>;
	}

	// if (!plan) {
	// 	try {
	// 		createVirtualPlan(props.type, props.period);
			
	// 	} catch (e) {
	// 		logger.error("Could not load plan.", e);
	// 		return <NotFoundView/>;
	// 	}
	// }


	function buildPath(type: PlanType, date: Date) {
		return `/plans/${type}/${dateToPeriod(date, type)}`;
	}

	function handleTypeChange(type: PlanType) {
		logger.debug("handleTypeChange:", type);
		const now = new Date();
		props.history.push(buildPath(type, now));
	};

	function handleAddObjective(goal: List) {
		const now = new Date();
		setObjectiveAdderGoal(goal);
		const overarchingObjectives = plan ? getOverarchingObjectives(plan, joinedObjectives) : [];
		const goalId = goal ? goal.id : null;
		const objectives = overarchingObjectives.filter(o => o.listId === goalId && !o.success);
		// logger.debug("Triggering objectiveadder", overarchingObjectives, objectives);
		setObjectiveAdderObjectives(objectives);
		const unscheduledObjectives = objectivesByList(joinedObjectives, goal).filter((o:Objective) => {
			if (o.success !== null)
				return false;
			if (o.planId === null)
				return true;
			const plan = findPlanById(o.planId, plans);
			if (!plan)
				return false;
			if (isBefore(plan, now))
				return true;
			return false;
		});
		setObjectiveAdderObjectivesUnscheduled(unscheduledObjectives);
		setObjectiveAdderShow(true);
		if (plan)
			props.onEnsurePlanIsSaved(plan); // Make sure that plan is saved. Otherwise the first objective for the plan will take some time to synch.
	}

	function objectiveAdderSave (goal: List | null, plan: Plan | PlanUnsaved, title: string, estimate: number, secondary: boolean, recurring: Recurring) {
		if (recurring) {
			doCreateRecurrer(goal, plan, title, estimate, secondary, recurring);
		} else {
			doCreateObjective(goal, plan, title, estimate, secondary);
		}
		setObjectiveAdderShow(false);
	}

	function getOverarchingObjectives(currentPlan: Plan | PlanUnsaved, allObjectives: Objective[]) {
		if (currentPlan.type === "year")
			return [];
		const overarchingPlan = getOverarchingPlan(currentPlan);
		// logger.debug('Overarching objective stubs:', overarchingPlan.getObjectives.items);
		// logger.debug('All objectives:', props.objectives);
		let overarchingObjectives: Objective[] = [];
		if (isPlan(overarchingPlan)) {
			overarchingObjectives = getPlanObjectives(overarchingPlan, allObjectives);
		} else {
			logger.debug("Overarching plan is no plan, skipping overarching objectives", overarchingPlan);
		}
		// const overarchingObjectives = isPlan(overarchingPlan) ? getPlanObjectives(overarchingPlan, props.objectives) : [];
		logger.debug('Overarching objectives:', overarchingObjectives);
		return overarchingObjectives;
	}

	function getOverarchingPlan(currentPlan: Plan | PlanUnsaved) {
		const planStub = getOverarchingPlanStub(currentPlan);
		return getPlanByTypePeriod(planStub.type, planStub.period, props.plans);
	}

	// const plan: Plan | PlanUnsaved = planTemp;

	// const currentPeriodDate = parseISO(plan.period);
	// const planTitle = getPlanTitle(plan.type, plan.period);
	
	function handlePeriodChange(plan: Plan | PlanUnsaved, step: number) {
		const currentPeriodDate = parseISO(plan.period);
		switch (plan.type) {
			case "year":
				props.history.push(buildPath(plan.type, addYears(currentPeriodDate, step)));
				break;
			case "month":
				props.history.push(buildPath(plan.type, addMonths(currentPeriodDate, step)));
				break;
			case "week":
				props.history.push(buildPath(plan.type, addWeeks(currentPeriodDate, step)));
				break;
			case "day":
				const newDate = addDays(currentPeriodDate, step);
				// logger.debug('currentPeriodDate, newDate', currentPeriodDate, newDate);
				props.history.push(buildPath(plan.type, newDate));
				break;
			default:
				throw new Error('Unknown plan type: '+plan.type);
		}
	}

	const previousPlan = getPlanByTypePeriod(plan.type, getPreviousPlanStub(plan).period, plans);

	// Show reflection notice if:
	// - no part of reflection has been done yet
	// - plan is the current one or in the past
	let showReflectionNotice = false;
	if (!planReflected(plan)) {
		const timeToReflect = getBeginning(plan);
		// const timeToReflect = subHours(getEnd(plan), 6);
		if (isPast(timeToReflect))
			showReflectionNotice = true;
	}

	const handleReflectionStart = (plan: Plan | PlanUnsaved) => {
		props.history.push(getPlanPath(plan)+'/reflect');
	};

	const handleScheduleObjective = (objective: Objective) => {
		setSchedulingObjective(objective);
	}

	const handleCompletionChange = (value: boolean) => {
		if (value === true) {
			setShowPlanningChecklist(true);
		} else {
			const updatedPlan = Object.assign({}, plan, {
				complete: false
			});
			onSavePlan(updatedPlan);
		}
	}

	const handleChecklistCancel = () => {
		setShowPlanningChecklist(false);
		logEvent('checklist_cancel');
	}

	const handleChecklistSkip = () => {
		logEvent("checklist_skip");
		setShowPlanningChecklist(false);
		planComplete();
	}
	
	const handleChecklistDone = () => {
		logEvent("checklist_done");
		setShowPlanningChecklist(false);
		planComplete();
	}

	const planComplete = () => {
		logEvent("plan_complete");
		const updatedPlan = Object.assign({}, plan, {
			complete: true
		});
		onSavePlan(updatedPlan);
		setShowPlanningStats(true);
		hideCompletionStatsTimer.current = window.setTimeout(() => {setShowPlanningStats(false)}, 5000);
	}

	const handleCompletionStatsInfoOpen = () => {
		clearTimeout(hideCompletionStatsTimer.current);
	}

	// logger.debug('PlanViewController plan:', plan);

	setScreenName('PlanView'+capitalize(plan.type));

	const overarchingPlan = plan.type !== PlanType.year ? getOverarchingPlan(plan) : null;
	// logger.debug("Overarching plan", overarchingPlan);

	const planStart = getBeginning(plan);
	const planEnd = getEnd(plan);
	// console.time("getVirtualObjectives");
	const virtualObjectives = getVirtualObjectives(recurrers, objectives, plans, props.goals, planStart, planEnd, plan.type);
	// console.timeEnd("getVirtualObjectives");
	const virtualOverarchingObjectives = overarchingPlan ? getVirtualObjectives(recurrers, objectives, plans, props.goals, getBeginning(overarchingPlan), getEnd(overarchingPlan), overarchingPlan.type) : [];
	const joinedObjectives = objectives.concat(virtualObjectives, virtualOverarchingObjectives);
	// logger.debug('Created virtual objectives', plan, virtualObjectives);
	const planObjectives = getObjectivesByPlanId(joinedObjectives, plan.id);
	// logger.debug('PlanViewController, plan type', plan.type);

	const subPlanStubs = getSubordinatePlanStubs(plan);
	const subPlans = subPlanStubs.map(stub => getOrCreatePlan(stub.type, stub.period, plans));
	const subObjectives: Objective[] = [];
	subPlans.forEach(plan => {
		const planObjectives = getObjectivesByPlanId(objectives, plan.id).map(o => Object.assign({}, o, {plan: plan}));
		const planObjectivesVirtual = getVirtualObjectivesByPlan(plan, recurrers, objectives, plans, props.goals).map(o => Object.assign({}, o, {plan: plan}));
		subObjectives.push(...planObjectives);
		subObjectives.push(...planObjectivesVirtual);
	});

	// const planObjectives = getPlanObjectives(plan, objectives);
	// const showCompletionQuery = planObjectives.length > 0;
	const showCompletionQuery = true;

	const handleFailObjective = (objective: Objective) => {
		setFailedObjective(objective);
		// props.onFailObjective(objective);
	};

	const handleObjectiveAdderReschedule = (objective: Objective) => {
		props.onSetPlan(objective, plan);
		setObjectiveAdderShow(false);
	};

	const handleCheckObjective = (objective: Objective) => {
		return objectiveSavingHandle(objective, props.onCheckObjective);
	}

	const handleUncheckObjective = (objective: Objective) => {
		return objectiveSavingHandle(objective, props.onUncheckObjective);
	}

	const handleSaveObjective = async (objective: Objective, recurring?: Recurring | null) => {
		setSavingObjective(true);
		const r = await props.onSaveObjective(objective, recurring);
		setSavingObjective(false);
		return r;
	}

	/**
	 * Trigger saving action and show a loading screen if required
	 * @param objective Objective to be saved
	 * @param handler The saving function to be used. Usually delivered via props. For example props.onCheckObjective
	 * @returns 
	 */
	const objectiveSavingHandle = async (objective: Objective, handler: (o: Objective) => Promise<Objective>) => {
		// If current plan is still virtual, show loading screen
		// Otherwise user might be able to re-save the objective with the old state, causing a sync error
		// Background: the state change of the plan will trigger a re-render of the
		// whole component. Even if the checkbox blocks further editing it will be replaced
		// by a re-mounted component which isn't aware of the loading state. Blocking
		// the whole plan view prevents that, although it is quite unsophisticated.
		// if (plan.state === PState.virtual) {
		// 	setSavingObjective(true);
		// }

		// ... or, do it all the time. Seems to work smoothly as well.
		setSavingObjective(true);
		const o = await handler(objective);
		setSavingObjective(false);
		return o;
	}

	if (savingObjective) {
		return <LoadingViewController what="saving objective"/>;
	}

	return(
		<React.Fragment>
			<Container maxWidth="sm" className={classes.container}>
				<PlanViewNavigation
					type={plan.type}
					planTitle={planTitle}
					onTypeChange={handleTypeChange}
					onPeriodForward={() => handlePeriodChange(plan, 1)}
					onPeriodBack={() => handlePeriodChange(plan, -1)}
				/>
				<PlanViewDetail
					plan={plan}
					overarchingPlan={overarchingPlan}
					goals={goalsActive}
					objectives={joinedObjectives}
					subObjectives={subObjectives}
					allRecurrers={recurrers}
					insights={previousPlan ? previousPlan.insights : null}
					onAddObjective={handleAddObjective}
					onSaveObjective={handleSaveObjective}
					onDeleteObjective={props.onDeleteObjective}
					onCheckObjective={handleCheckObjective}
					onUncheckObjective={handleUncheckObjective}
					onFailObjective={handleFailObjective}
					onToggleObjective={props.onToggleObjective}
					onScheduleObjective={handleScheduleObjective}
					onGoalClick={(goalId) => {props.history.push('/goals/'+encodeURIComponent(goalId || "other"))}}
					onEditObjective={objective => setEditingObjective(objective)}
					/>
				<PlanViewOnboardingController/>
				<PlanReflectionInsights plan={plan} onEditReflection={() => handleReflectionStart(plan)}/>
				{ showReflectionNotice &&
					<div className={classes.reflectionNotice} data-onboarding-markers="plan-reflection-notice">
						<PlanReflectionNotice onClick={() => handleReflectionStart(plan)} type={plan.type}/>
					</div>
				}
			</Container>
			<PlanViewCompletionQuery 
					show={showCompletionQuery}
					sticky={!plan.complete}
					complete={plan.complete === true}
					onChange={handleCompletionChange}
					planType={plan.type}
				/>
			{showPlanningChecklist &&
				<PlanningChecklistController
					onCancel={handleChecklistCancel}
					onSkip={handleChecklistSkip}
					onDone={handleChecklistDone}
					objectives={planObjectives}
					lists={props.goals}
					insights={previousPlan ? previousPlan.insights : null}
					user={props.user}
				/>
			}
			<PlanningStatsWidget
				show={showPlanningStats}
				onClose={() => {setShowPlanningStats(false)}}
				planType={plan.type}
				numerator={planningRateNumerator}
				denominator={planningRateDenominator}
				streakCurrent={planningStreakCurrent}
				streakMax={planningStreakMax}
				onOpenInfo={handleCompletionStatsInfoOpen}
			/>
			{objectiveAdderShow && <>
				<ObjectiveAdderV2B
					doCancel={() => setObjectiveAdderShow(false)} 
					doSave={(title: string, estimate: number, secondary: boolean, recurring: Recurring) => 
						objectiveAdderSave(
							objectiveAdderGoal,
							plan,
							title,
							estimate,
							secondary,
							recurring
							)}
					doReschedule={handleObjectiveAdderReschedule}
					objectivesHigherOrder={objectiveAdderObjectives}
					objectivesOverdueOrUnscheduled={objectiveAdderObjectivesUnscheduled}
					planType={plan.type}
				/>
			</>}
			{editingObjective && <>
				<Drawer anchor="bottom" open={true} onClose={() => setEditingObjective(null)}>
					<ObjectiveEditorController
						objective={editingObjective}
						recurrer={editingObjective.recurrerId ? findRecurrerById(editingObjective.recurrerId, props.recurrers) : undefined}
						plan={editingObjective.planId ? findPlanById(editingObjective.planId, props.plans) : null}
						doCancel={() => setEditingObjective(null)} 
						doSaveObjective={(objective: Objective, recurring: Recurring | null) => {handleSaveObjective(objective, recurring); setEditingObjective(null)}}
						getSavedPlan={props.getSavedPlan}
						doDelete={() => {props.onDeleteObjective(editingObjective); setEditingObjective(null);}}
						doDeleteRecurrerWithInstances={(recurrer: Recurrer) => {props.doDeleteRecurrerWithInstances(recurrer); setEditingObjective(null);}}
						doDeleteRecurrerCurrentAndFollowing={(recurrer: Recurrer, objective: Objective) => {props.doDeleteRecurrerCurrentAndFollowing(recurrer, editingObjective); setEditingObjective(null);}}
					/>
				</Drawer>
			</>}
			{/* <ObjectiveAdderV2
				open={objectiveAdderShow}
				objectives={objectiveAdderObjectives}
				planType={plan.type}
				onCancel={() => setObjectiveAdderShow(false)}
				onAdd={(title: string, estimate: number, secondary: boolean, recurring: Recurring) => 
					objectiveAdderSave(
						objectiveAdderGoal, 
						plan,
						title,
						estimate,
						secondary,
						recurring,
					)}
			/> */}
			{schedulingObjective &&
				<SchedulerDialogController
						onCancel={() => setSchedulingObjective(false)}
						objective={schedulingObjective}
						initialType={plan.type}
						initialPeriod={plan.period}
						onSetPlan={(plan) => {
							props.onSetPlan(schedulingObjective, plan);
							setSchedulingObjective(false)
						}}
				/>
			}
			{failedObjective !== null &&
				<FailedObjectiveDialogController
				objective={failedObjective}
				allObjectives={objectives}
				allLists={props.goals}
				allPlans={plans}
				doSaveObjective={handleSaveObjective}
				doCreateObjective={doCreateObjective}
				doClose={() => setFailedObjective(null)}
				doGetPlanOrCreateVirtualPlan={doGetPlanOrCreateVirtualPlan}
				/>
			}
		</React.Fragment>
	);	

}

export default PlanViewController;