import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import FlareIcon from '@material-ui/icons/Flare';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	linkBottom: {
		cursor: "pointer",
	},
}));

interface Props {
	onEmailChange(value: string): void,
	onPasswordChange(value: string): void,
	onSubmit(): void,
	onForgotPassword(): void,
	onSignUp(): void,
	loading: boolean,
}

function SignIn(props: Props) {
	const { onEmailChange, onPasswordChange, onSubmit, onSignUp, loading } = props;
	const classes = useStyles();

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		// avoid submitting the form
		event.preventDefault();
		onSubmit();
	}

	// const handleForgotPassword = (event: any) => {
	// 	event.preventDefault();
	// 	onForgotPassword();
	// }

	const handleSignUp = (event: any) => {
		event.preventDefault();
		onSignUp();
	}

	return (
		<Container component="main" maxWidth="xs">
			{/* <CssBaseline /> */}
			<div className={classes.paper}>
			<Avatar className={classes.avatar}>
				<FlareIcon />
			</Avatar>
			<Typography component="h1" variant="h5">
				<FormattedMessage id="signin.title" defaultMessage="Sign in"/>
			</Typography>
			<form className={classes.form} noValidate onSubmit={handleSubmit}>
				<TextField
				variant="outlined"
				margin="normal"
				required
				fullWidth
				label={<FormattedMessage id="signin.email" defaultMessage="Email Address"/>}
				autoComplete="email"
				type="email"
				autoFocus

				id="username"
				key="username"
				name="username"
				onChange={(e) => onEmailChange(e.target.value)}
				disabled={loading}
				/>
				<TextField
				variant="outlined"
				margin="normal"
				required
				fullWidth
				label={<FormattedMessage id="signin.password" defaultMessage="Password"/>}
				type="password"
				autoComplete="current-password"

				id="password"
				key="password"
				name="password"
				onChange={e => onPasswordChange(e.target.value)}
				disabled={loading}
				/>
				<Button
				type="submit"
				fullWidth
				variant="contained"
				color="primary"
				className={classes.submit}
				disabled={loading}
				data-analytics-id="SignIn-submit"
				>
					<FormattedMessage id="signin.cta" defaultMessage="Sign in"/>
				</Button>
				<Grid container>
				<Grid item xs>
					<Link href={process.env.REACT_APP_PASSWORD_FORGOTTEN} variant="body2" className={classes.linkBottom}>
					<FormattedMessage id="signin.forgotPassword" defaultMessage="Forgot password?"/>
					</Link>
				</Grid>
				<Grid item>
					<Link onClick={handleSignUp} variant="body2" className={classes.linkBottom} data-analytics-id="SignIn-nav-signup">
						<FormattedMessage id="signin.signup" defaultMessage="Don't have an account? Sign Up"/>
					</Link>
				</Grid>
				</Grid>
			</form>
			</div>

		</Container>
	);
}

export default SignIn;
