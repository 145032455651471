import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
	title: {
		flexGrow: 1,
	},
	headerBackground: {
		// backgroundColor: theme.palette.grey[800],
		color: theme.palette.common.white,
		backgroundImage: `linear-gradient(135deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.0)))`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		// width: "100%",
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		marginBottom: theme.spacing(1),
	},
	header: {
		padding: theme.spacing(2),
	},
	heading: {
		marginBottom: theme.spacing(1),
	},
}));

interface ImageHeaderProps {
	image: string,
	title: string,
	subtitle: string,
}

export default function ImageHeader(props: ImageHeaderProps) {
	const classes = useStyles();

	return (
		<>
			<Box className={classes.headerBackground}
				style={{backgroundImage: `linear-gradient(135deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.0)),url(${props.image})`}}
				>
				<Container maxWidth="sm">
					<Box className={classes.header}>
						<Typography variant="h4" color="inherit" className={classes.heading}>{props.title}</Typography>
						<Typography variant="body1" color="inherit">{props.subtitle}</Typography>
					</Box>
				</Container>
			</Box>
		</>
	);
}
