import React, { useState, useEffect, useContext } from 'react';
import logger from '../services/logger';
import { logEvent } from '../services/analytics';
import OnboardingSurvey from './OnboardingSurvey';
import { API } from 'aws-amplify';
import { User } from './AuthController';
import { useIntl } from 'react-intl';
import RemoteConfigContext from './RemoteConfigContext';
import * as Sentry from '@sentry/browser';
import { Environment } from '..';

export interface OnboardingSurveyIntl {
	[key: string]: OnboardingSurveySet,
}

export interface OnboardingSurveySet {
	title: string,
	description: string,
	questions: OnboardingSurveyQuestion[],
	smallprint: string,
	button: string,
}

export interface OnboardingSurveyQuestion {
	id: string,
	type: string,
	label: string,
	helper: string | null,
}

export const defaultSurvey = {
	"title": "2 Quick Questions",
	"description": "Please briefly answer these two questions so that we can improve Focality for you.",
	"questions": [
		{
			"id": "problem",
			"type": "text",
			"label": "What problem are you trying to solve?",
			"helper": "What are you currently unsatisfied with that you are looking to fix?"
		},
		{
			"id": "occupation",
			"type": "text",
			"label": "What is your occupation?",
			"helper": null
		},
	],
	"smallprint": "Answering these questions will help us understand your needs so that we can build the most useful product for you.",
	"button": "Start",
}

interface Props {
	user: User,
	onDone(): void,
	environment: Environment,
}

export default function OnboardingSurveyController(props: Props) {
	const {
		user,
		onDone,
		environment,
	} = props;
	
	const { locale } = useIntl();
	const [ready, setReady] = useState(false);
	const [values, setValues] = useState<{[key: string]: string}>({});
	const remoteConfig = useContext(RemoteConfigContext);

	let survey = defaultSurvey;

	const surveyData: OnboardingSurveyIntl = remoteConfig.getOnboardingSurvey();
	if (surveyData && surveyData[locale]) {
		survey = Object.assign({}, surveyData[locale], {
			questions: []
		});
		surveyData[locale].questions.forEach(question => {
			if (question.type === "text")
				survey.questions.push(question);
		});
	}

	useEffect(() => {
		let complete = true;
		survey.questions.forEach(question => {
			if (!values[question.id]) {
				complete = false;
			}
		});
		setReady(complete);
	}, [survey, values]);

	const handleSend = () => {
		try {
			let body = "New onboarding survey response:\n\n";
			body = body + "Email: " + user.email + "\n"
			body = body + "Username: " + user.username + "\n";
			body = body + "ID: " + user.id + "\n";
			body = body + "Platform: " + environment.platform + "\n\n";
			Object.keys(values).forEach(key => {
				body = body + key + ": " + values[key] + "\n";
			});
			logger.info("Sending survey response: ", body);
			API.post("focalityREST", "/backendNotification", {
				body: {
					subject: "Focality Onboarding Survey",
					body: body,
				},
			});
			API.post("focalityREST", "/onboardingSurvey", {
				body: Object.assign({}, values, {
					platform: environment.platform,
				}),
			});
		} catch (e) {
			logger.error("Error sending survey response", e);
			Sentry.captureException(e);
		}
		logEvent("survey_complete");
		onDone();
	}
	
	const handleChangeValue = (key: string, value: string) => {
		setValues({...values,  [key]: value});
	};

	return (
		<OnboardingSurvey
			onSend={handleSend}
			ready={ready}
			survey={survey}
			onChangeValue={handleChangeValue}
			values={values}
		/>
	);
}
