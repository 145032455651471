import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import FlareIcon from '@material-ui/icons/Flare';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
			backgroundColor: theme.palette.common.white,
		},
	},
	container: {
		backgroundColor: "white",
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	paper: {
		// marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	confirmationText: {
		marginTop: theme.spacing(1),
	},
	button: {
		marginTop: theme.spacing(2),
	},
}));

interface Props {
	error: string,
	onRetry(): void,
}

export default function SignUpError(props: Props) {

	const classes = useStyles();
	const { error, onRetry } = props;
	const contactURL = "https://www.focalityapp.com/en/contact.html";

	return (
	<Container component="main" maxWidth="xs" className={classes.container}>
		<CssBaseline />
		<div className={classes.paper}>
			<Avatar className={classes.avatar}>
				<FlareIcon />
			</Avatar>
			<Typography variant="h5"><FormattedMessage id="signup.error.title" defaultMessage="Oops! Something went wrong."/></Typography>
			<Typography variant="body1" className={classes.confirmationText}><FormattedMessage id="signup.error.description" defaultMessage="Unfortunately we could not activate your account. The following error occured:"/></Typography>
			<Typography variant="subtitle1" className={classes.confirmationText}>{error}</Typography>
			<Button onClick={onRetry} fullWidth variant="contained" color="primary" className={classes.button}><FormattedMessage id="signup.error.button" defaultMessage="Try again"/></Button>
			<Typography variant="body1" className={classes.confirmationText}>
				<FormattedMessage
					id="signup.error.notice"
					defaultMessage="If the issue persists, please <contactlink>contact us</contactlink> so that we can help."
					values={{
						contactlink: (msg: any) => <Link href={contactURL}>{msg}</Link>
					}}
				/>

			</Typography>
		</div>

	</Container>
);
}