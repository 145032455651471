import React from 'react';
import PropTypes from 'prop-types';
import {useIntl, defineMessages, FormattedMessage} from 'react-intl';
import logger from '../services/logger';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import ObjectivesCard from './ObjectivesCard';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import InfoIcon from '@material-ui/icons/InfoOutlined';
import { getListImage } from '../services/list-helpers';
import { objectivesByList, objectivesByPlanAndList } from '../services/objective-helpers';
import { getPlanTitle } from '../services/plan-helpers';

const messages = defineMessages({
	insightsTitle: {
		id: 'plan.insightsTitle',
		defaultMessage: 'Remember your insights',
	},
	otherTitle: {
		id: 'plan.otherTitle',
		defaultMessage: 'Other',
	},
	checklistTitle: {
		id: 'plan.checklistTitle',
		defaultMessage: 'Check your plan',
	},
	checklistReview: {
		id: 'plan.checklist.review',
		defaultMessage: 'Reviewed monthly plan?',
	},
	checklistVolume: {
		id: 'plan.checklist.volume',
		defaultMessage: 'Volume doable?',
	},
	checklistInsights: {
		id: 'plan.checklist.insights',
		defaultMessage: 'Observed insights from last time?',
	},
});


const useStyles = makeStyles(theme => ({
	root: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	list: {
		margin: 0,
		padding: 0
	},
	listItem: {
		margin: 0,
		padding: 0
	},
	card: {
		marginBottom: theme.spacing(2),
	},
}));

function PlanViewDetail(props) {
	const {
		onGoalClick,
		plan,
		overarchingPlan,
	 } = props;
	
	const {formatMessage, locale} = useIntl();
	const classes = useStyles();

	const checklist = [
		formatMessage(messages.checklistReview),
		formatMessage(messages.checklistVolume),
		formatMessage(messages.checklistInsights),
	];

	const overarchingPlanTitle = overarchingPlan ? getPlanTitle(overarchingPlan, locale) : "";

	const focusLists = props.goals.filter(l => l.focus);
	const unfocusLists = props.goals.filter(l => !l.focus);
	logger.debug("unfocusLists", unfocusLists);
	const otherObjectives = objectivesByPlanAndList(props.objectives, props.plan, null);
	unfocusLists.forEach(l => otherObjectives.push(...objectivesByPlanAndList(props.objectives, props.plan, l)));

	logger.debug("otherObjectives", otherObjectives, unfocusLists);

	return (
		<Box className={classes.root}>
			{focusLists.map((list, index) => 
				<ObjectivesCard 
					key={list.id} 
					title={list.title} 
					subObjectives={objectivesByList(props.subObjectives, list)}
					objectives={objectivesByPlanAndList(props.objectives, props.plan, list)}
					overarchingPlanTitle={overarchingPlanTitle}
					overarchingObjectives={overarchingPlan ? objectivesByPlanAndList(props.objectives, overarchingPlan, list) : []}
					allRecurrers={props.allRecurrers}
					planType={plan.type}
					editable={true} 
					onAddObjective={() => props.onAddObjective(list)}
					onSaveObjective={props.onSaveObjective}
					onDeleteObjective={props.onDeleteObjective}
					onCheckObjective={props.onCheckObjective}
					onUncheckObjective={props.onUncheckObjective}
					onFailObjective={props.onFailObjective}
					onToggleObjective={props.onToggleObjective}
					onScheduleObjective={props.onScheduleObjective}
					headerBackground={getListImage(list)}
					onboardingMarkers={"lists list-"+index}
					onTitleClick={() => {onGoalClick(list.id)}}
					onEditObjective={props.onEditObjective}
				/>
			)}
			<ObjectivesCard 
				key="other" 
				title={formatMessage(messages.otherTitle)}
				subObjectives={objectivesByList(props.subObjectives, null)}
				objectives={otherObjectives}
				overarchingPlanTitle={overarchingPlanTitle}
				overarchingObjectives={overarchingPlan ? objectivesByPlanAndList(props.objectives, overarchingPlan, null) : []}
				allRecurrers={props.allRecurrers}
				planType={plan.type}
				editable={true} 
				onAddObjective={() => props.onAddObjective(null)}
				onSaveObjective={props.onSaveObjective}
				onDeleteObjective={props.onDeleteObjective}
				onCheckObjective={props.onCheckObjective}
				onUncheckObjective={props.onUncheckObjective}
				onFailObjective={props.onFailObjective}
				onToggleObjective={props.onToggleObjective}
				onScheduleObjective={props.onScheduleObjective}
				onboardingMarkers={"lists last-list list-other list-"+props.goals.length}
				onTitleClick={() => {onGoalClick(null)}}
				onEditObjective={props.onEditObjective}
			/>
			{props.insights && 
				<Card className={classes.card}>
					<CardHeader
						title={formatMessage(messages.insightsTitle)}
					/>
					<CardContent className={classes.content}>
						<Typography variant="body1" color="inherit">
							"{props.insights}"
						</Typography>
						<Typography variant="caption" color="inherit">
							<FormattedMessage id="plan.insightsSource" defaultMessage="Yourself"/>
						</Typography>
					</CardContent>
					<CardActions>
						<Button size="small" color="primary">
							<FormattedMessage id="plan.insightsReviewLink" defaultMessage="See last week's review"/>
						</Button>
					</CardActions>
				</Card>
			}
			{props.editing ?
				<Card className={classes.card}>
					<CardHeader
						title={formatMessage(messages.checklistTitle)}
					/>
					<CardContent className={classes.content}>
					<List>
						{checklist.map(value => (
						<ListItem
							key={value}
							role={undefined}
							button
							className={classes.listItem}
						>
							<Checkbox
							checked={false}
							tabIndex={-1}
							disableRipple
							/>
							<ListItemText primary={value} />
							<ListItemSecondaryAction>
							<IconButton aria-label="Information">
								<InfoIcon />
							</IconButton>
							</ListItemSecondaryAction>
						</ListItem>
						))}
						</List>
					</CardContent>
				</Card>
			: null }
	</Box>
);
}

PlanViewDetail.propTypes = {
	onCheckObjective: PropTypes.func.isRequired,
	onFailObjective: PropTypes.func.isRequired,
	onToggleObjective: PropTypes.func.isRequired,
	onUncheckObjective: PropTypes.func.isRequired,
	onAddObjective: PropTypes.func.isRequired,
	onSaveObjective: PropTypes.func.isRequired,
	onDeleteObjective: PropTypes.func.isRequired,
	onScheduleObjective: PropTypes.func.isRequired,
	onEditObjective: PropTypes.func.isRequired,
	onGoalClick: PropTypes.func,
	plan: PropTypes.object.isRequired,
	overarchingPlan: PropTypes.object,
	goals: PropTypes.array.isRequired,
	objectives: PropTypes.array.isRequired,
	subObjectives: PropTypes.array.isRequired,
	allRecurrers: PropTypes.array.isRequired,
	insights: PropTypes.string,
};

export default PlanViewDetail;