import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import moodImage from '../assets/reflection-images/20160704-2004--DSC_2771.landscape.jpg';

const useStyles = makeStyles(theme => ({
	headerBackground: {
		color: theme.palette.common.white,
		backgroundImage: `linear-gradient(135deg, rgba(0, 0, 0, 0.5) 30%, rgba(0, 0, 0, 0.0) 60%),url(${moodImage})`,
		// backgroundImage: `linear-gradient(135deg, rgba(255, 255, 255, 1), rgba(0, 0, 0, 0.0)),url(${moodImage})`,
		// backgroundImage: `url(${moodImage})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	description: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(-1),
	},
	container: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	header: {
		padding: theme.spacing(2),
	},
	heading: {
		marginBottom: theme.spacing(1),
	},
	bottomGrid: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		marginTop: theme.spacing(2),
	},
}));

interface Props {
}

export default function OnboardingFocus(props: Props) {
	// const {  } = props;
	const classes = useStyles();

	return (
		<React.Fragment>
			<Box className={classes.headerBackground}>
				<Container maxWidth="xs">
					<Box className={classes.header}>
						<Typography variant="h4" color="inherit" className={classes.heading}><FormattedHTMLMessage id="onboarding.focus.title" defaultMessage="Your focus for now: Plan every day"/></Typography>
						{/* <Typography variant="body1" color="inherit"><FormattedMessage id="onboarding.start.subtitle" defaultMessage="First steps"/></Typography> */}
					</Box>
				</Container>
			</Box>


			<Container maxWidth="xs">
				<Box className={classes.description}>
					<Typography variant="body1" color="inherit" paragraph><FormattedMessage id="onboarding.focus.intro" defaultMessage="Establishing a new routine is hard. Start with small steps to make sure that you establish a sustainable deep planning habit."/></Typography>
					<Typography variant="body1" color="inherit"><strong><FormattedMessage id="onboarding.focus.everyDay" defaultMessage="Step 1: Plan every day"/></strong></Typography>
					<Typography variant="body1" color="inherit" paragraph><FormattedMessage id="onboarding.focus.everyDayText" defaultMessage="Making a habit of consciously planning every day will have a tremendous impact on your life. It makes the difference between being reactive or proactive. So focus on this first. Plan each day."/></Typography>
					<Typography variant="body1" color="inherit"><strong><FormattedMessage id="onboarding.focus.planWhat" defaultMessage="What to plan"/></strong></Typography>
					<Typography variant="body1" color="inherit" paragraph><FormattedMessage id="onboarding.focus.planWhatText1" defaultMessage="Plan what you want to achieve, not what you need to do. Imagine yourself looking back at your day. What are the outcomes that make this a good day?"/></Typography>
					<Typography variant="body1" color="inherit" paragraph><FormattedMessage id="onboarding.focus.planWhatText2" defaultMessage="This can be specific project objectives (&quot;create marketing plan&quot;, &quot;decide which car to buy&quot;) or personal objectives (&quot;don't get stressed by things I cannot influence&quot;, &quot;enjoy a quiet afternoon with my family&quot;)."/></Typography>
					<Typography variant="body1" color="inherit" paragraph><FormattedMessage id="onboarding.focus.planWhatText3" defaultMessage="If you don't want to carry a separate todo list, you can add basic tasks as well (&quot;take out trash&quot;, &quot;do pushups&quot;). Just make sure not to overdo it. If you add every small activity you risk losing track of your goals."/></Typography>
					<Typography variant="body1" color="inherit"><strong><FormattedMessage id="onboarding.focus.advanced" defaultMessage="Step 2+: Reflect, plan deeper"/></strong></Typography>
					<Typography variant="body1" color="inherit" paragraph><FormattedMessage id="onboarding.focus.advancedText" defaultMessage="Are you already a proficient planner? The next steps are:"/></Typography>
					<ul>
						<li><Typography variant="body1" color="inherit" paragraph><FormattedMessage id="onboarding.focus.advancedBullet1" defaultMessage="Reflect on every day"/></Typography></li>
						<li><Typography variant="body1" color="inherit" paragraph><FormattedMessage id="onboarding.focus.advancedBullet2" defaultMessage="Create a plan for this year"/></Typography></li>
						<li><Typography variant="body1" color="inherit" paragraph><FormattedMessage id="onboarding.focus.advancedBullet3" defaultMessage="… this month"/></Typography></li>
						<li><Typography variant="body1" color="inherit" paragraph><FormattedMessage id="onboarding.focus.advancedBullet4" defaultMessage="Establish planning &amp; reflection habits for each week"/></Typography></li>
						<li><Typography variant="body1" color="inherit" paragraph><FormattedMessage id="onboarding.focus.advancedBullet5" defaultMessage="… each month…"/></Typography></li>
						<li><Typography variant="body1" color="inherit" paragraph><FormattedMessage id="onboarding.focus.advancedBullet6" defaultMessage="… and each year."/></Typography></li>
					</ul>
				</Box>
			</Container>
		</React.Fragment>
	);
}
