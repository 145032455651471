/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    // "aws_cognito_identity_pool_id": "eu-central-1:5ca6c4e1-1bfe-45e6-9b0d-fc1448819437",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_4vDHOsNFc",
    "aws_user_pools_web_client_id": "56dsc3bp0lgmbmfekelj4irj2a",
    "oauth": {
        "domain": "focality-prod.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:4000/,https://run.focalityapp.com/,focality://localhost/",
        "redirectSignOut": "http://localhost:4000/,https://run.focalityapp.com/,focality://localhost/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://g5ullmblzrf2zcidljggou4q2e.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "focalityREST",
            "endpoint": "https://9c3cv6zfsb.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        }
    ]
};


export default awsmobile;
