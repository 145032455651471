import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Plugins } from '@capacitor/core';
import logger from '../services/logger';

export default function DeepLinkListener(props: {
	children: React.ReactNode
	}) {

	let history = useHistory();
	
	useEffect(() => {
		logger.debug("Setting up DeepLinkListener...");
		const listener = Plugins.App.addListener('appUrlOpen', (data: any) => {
			logger.info("App opened with URL", data);
			// Plugins.Toast.show({text: "App opened with URL: " + data.url, duration: "long"});
			const url = data.url;
			logger.debug("URL: ", url);
			if (url.substring(0, 27) === "https://run.focalityapp.com") {
				const path = url.substring(27);
				logger.debug("Path: ", path);
				history.push(path);
			} else if (url.substring(0, 20) === "focality://localhost") {
				const path = url.substring(20);
				logger.debug("Path: ", path);
				// Plugins.Toast.show({text: "Changing path: " + path, duration: "long"});
				history.push(path);
			} else {
				// Plugins.Toast.show({text: "URL did not match: " + url, duration: "long"});
			}
		});
		return () => {
			logger.debug("Tearing down DeepLinkListener...");
			listener.remove();
		};
	}, [history]);

	return (
		<React.Fragment>{props.children}</React.Fragment>
	);
};
