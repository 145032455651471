import React, { useState, useEffect, useRef } from 'react';
import FailedObjectiveDialog from './FailedObjectiveDialog';
import { Objective, getFailedReasonsCounted } from '../services/objective-helpers';
import { PlanStub, Plan, PlanUnsaved, findPlanById, getNextPeriod } from '../services/plan-helpers';
import { List, findListById } from '../services/list-helpers';
import logger from '../services/logger';
import { PlanType } from '../API';
import { logEvent } from '../services/analytics';

export enum WhatNowState {
	drop,
	next,
	choose,
}

interface Props {
	objective: Objective,
	allObjectives: Objective[],
	allLists: List[],
	allPlans: Plan[],
	doSaveObjective(objective: Objective): void,
	doCreateObjective(goal: List | null, plan: Plan | PlanUnsaved | null, title: string, estimate: number | null, secondary: boolean): Promise<Objective>,
	doClose(): void,
	doGetPlanOrCreateVirtualPlan(type: PlanType, period: string): Promise<Plan>,
}
export default function FailedObjectiveDialogController(props: Props) {
	const {
		objective,
		allObjectives,
		allLists,
		allPlans,
		doSaveObjective,
		doCreateObjective,
		doClose,
		doGetPlanOrCreateVirtualPlan,
	} = props;

	const [failedReason, setFailedReason] = useState<undefined|string>(objective.failedReason);
	const [rescheduleTarget, setRescheduleTarget] = useState<null|PlanStub>(null);
	const [previousReasons, setPreviousReasons] = useState<string[]>([]);
	const [whatNowState, setWhatNowState] = useState(WhatNowState.drop);

	useEffect(() => {
		const reasons = getFailedReasonsCounted(allObjectives);
		const reasonsPlain = reasons.map(r => r.reason);
		logger.debug("Previous reasons", reasons);
		setPreviousReasons(reasonsPlain);
	}, [allObjectives]);

	const handleSave = () => {
		logger.debug("Saving failed objective reflection...", failedReason, rescheduleTarget);
		// update failure reason and save objective
		const updatedObjective = Object.assign({}, objective, {
			failedReason: failedReason === "" ? undefined : failedReason,
			success: false,
		});
		logger.debug("Updated objective:", updatedObjective);
		doSaveObjective(updatedObjective);
		// if rescheduled, create new objective
		if (whatNowState !== WhatNowState.drop) {
			const list = objective.listId ? findListById(objective.listId, allLists) : undefined;
			if (!rescheduleTarget) {
				doCreateObjective(list || null, null, objective.title, objective.estimate, objective.secondary);
			} else {
				// const targetType = whatNowState === WhatNowState.next ? initialType : rescheduleTarget?.type;
				// const targetPeriod = whatNowState === WhatNowState.next ? initialPeriod : rescheduleTarget?.period;
				doGetPlanOrCreateVirtualPlan(rescheduleTarget.type, rescheduleTarget.period)
					.then(plan => doCreateObjective(list || null, plan, objective.title, objective.estimate, objective.secondary));
			}
			logEvent("objective_retry", {recurring: objective.recurrerId ? "true" : "false"});
		} else {
			logEvent("objective_drop", {recurring: objective.recurrerId ? "true" : "false"});
		}
		doClose();
	}

	const handleChangeWhatNowState = (state: WhatNowState) => {
		setWhatNowState(state);
		if (state === WhatNowState.next) {
			setRescheduleTarget(nextPlan);
		}
	}

	// Todo: Don't calculate on every render
	const plan = objective.planId ? findPlanById(objective.planId, allPlans) : null;
	const initialType = plan ? plan.type : null;
	const initialPeriod = plan ? getNextPeriod(plan.period, plan.type) : null;
	const nextPlan = initialType && initialPeriod ? { type: initialType, period: initialPeriod} : null;

	const updateRescheduleTarget = useRef((target: null|PlanStub) => setRescheduleTarget(target));

	return (
		<FailedObjectiveDialog
			onCancel={doClose}
			onSave={handleSave}
			objectiveTitle={objective.title}
			isRecurring={objective.recurrerId != null}
			onChangeRescheduleTarget={updateRescheduleTarget.current}
			onChangeFailedReason={(reason: string) => setFailedReason(reason)}
			initialPeriod={initialPeriod}
			initialType={initialType}
			suggestedReasons={previousReasons}
			initialFailedReason={objective.failedReason || ""}
			whatNowState={whatNowState}
			onChangeWhatNowState={handleChangeWhatNowState}
			nextPlan={nextPlan}
		/>
	);
}
