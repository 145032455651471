import React from 'react';
// import logger from '../services/logger';
import { makeStyles } from '@material-ui/core/styles';
import {FormattedMessage, defineMessages, useIntl} from 'react-intl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ObjectiveRecurringButton from './ObjectiveRecurringButton';
import ButtonDialog from './ButtonDialog';
import { IconButton } from '@material-ui/core';
import { commonMessageValues } from '../services/intl-helpers';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import ScheduleIcon from '@material-ui/icons/Schedule';
// import HelpIcon from '@material-ui/icons/Help';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles(theme => ({
	gridContainer: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		width: '100%',
		margin: 0,
		maxHeight: 330,
	},
	chip: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	hop: {
		paddingTop: "0 !important",
	},
	addCell: {
		textAlign: "right",
		padding: "0 !important",
	},
	saveCell: {
		textAlign: "right",
		padding: "0px 0 16px 0 !important",
	},
	deleteCell: {
		// textAlign: "right",
		padding: "0 0 16px 0 !important",
		marginTop: -8,
	},
	addButton: {
		// marginTop: 13,
		marginRight: 23,
	},
	secondary: {
		marginTop: 12,
		marginLeft: theme.spacing(1),
	},
	title1: {
		marginBottom: theme.spacing(-2),
	},
	estimateGrid: {
		// display: "flex",
		// justifyContent: "space-between",
	},
	textField: {
		width: 80,
		marginTop: -8,
		marginRight: theme.spacing(2),
	},
	repeatButton: {
		marginTop: 12,
	},
	loopDown: {
		marginLeft: 4,
	},
	disabledIcon: {
		color: "rgba(0, 0, 0, 0.26)",
	},
}));

const messages = defineMessages({
	objectivePlaceholder: {
		id: 'objectiveAdder.objectivePlaceholder',
		defaultMessage: 'e.g. "Research market opportunities"',
	},
	estimatePlaceholder: {
		id: 'objectiveAdder.estimatePlaceholder',
		defaultMessage: 'e.g. 2.5',
	},
});

interface ObjectiveEditorProps {
	title: string,
	estimate: string,
	secondary: boolean,
	scheduled: boolean | null,
	recurring: boolean | null,

	showRecurring: boolean,
	showScheduler: boolean,
	schedulerDisabled: boolean,
	showDelete: boolean,
	saveDisabled: boolean,
	deleteDisabled: boolean,
	cta: "save" | "add",

	onTitleChange(value: string): void,
	onEstimateChange(value: string): void,
	onToggleSecondary(): void,
	onSchedule(): void,
	onSelectRecurring(): void,
	onSelectNonRecurring(): void,
	onDelete(): void,
	onHelp(): void,
	onSave(): void,
}

export default function ObjectiveEditor(props: ObjectiveEditorProps) {
	const { formatMessage } = useIntl();
	const classes = useStyles();

	const handleKeyPress = (event:any) => {
		if (event.key === 'Enter')
			props.onSave();
	}

	// logger.debug('ObjectiveAdder, planType:', planType);

	return (
		<>
			
			<Grid container spacing={3} className={classes.gridContainer}>
				{/* <Grid item xs={12} className={classes.title1}>
					<Typography variant="subtitle2">Create new objective</Typography>
				</Grid> */}
				<Grid item xs={12}>
					<TextField
						id="standard-with-placeholder"
						label={<FormattedMessage id="objectiveAdder.objective" defaultMessage="Objective"/>}
						placeholder={formatMessage(messages.objectivePlaceholder)}
						value={props.title}
						onChange={(event) => props.onTitleChange(event.target.value)}
						margin="none"
						fullWidth
						autoFocus
						onKeyPress={handleKeyPress}
						/>
				</Grid>
				<Grid item xs={12} className={classes.estimateGrid}>
					<TextField
							id="standard-with-placeholder"
							label={<FormattedMessage id="objectiveAdder.estimate" defaultMessage="Estimate"/>}
							placeholder={formatMessage(messages.estimatePlaceholder)}
							value={props.estimate}
							onChange={(event) => props.onEstimateChange(event.target.value)}
							className={classes.textField}
							margin="none"
							// fullWidth
							type="number"
							InputProps={{
								endAdornment: (
								<InputAdornment variant="filled" position="end">
									h
								</InputAdornment>
								),
							}}
							InputLabelProps={{
								shrink: true,
							}}
							onKeyPress={handleKeyPress}
							/>

					<IconButton color={props.secondary ? "secondary" : "primary"} onClick={props.onToggleSecondary}>
						<LowPriorityIcon />
					</IconButton>

					{props.showScheduler && !props.schedulerDisabled && <>
						<IconButton color={props.scheduled ? "secondary" : "primary"} onClick={props.onSchedule}>
							<ScheduleIcon />
						</IconButton>
					</>}

					{props.showScheduler && props.schedulerDisabled && <>
						<ButtonDialog icon={<ScheduleIcon className={classes.disabledIcon}/>}>
							<Typography><FormattedMessage id="objectivesTableRow.reschedulingRecurrers" defaultMessage="This is a recurring objective. Recurring objectives cannot be rescheduled.{br}{br}Need this feature? <contact-en>Let us know!</contact-en>" values={commonMessageValues}/></Typography>
						</ButtonDialog>
					</>}

					{props.showRecurring && props.recurring !== null && <>
						<ObjectiveRecurringButton onSelectRecurring={props.onSelectRecurring} onSelectNonRecurring={props.onSelectNonRecurring} isRecurring={props.recurring}/>
					</>}

					{/* <IconButton>
						<HelpIcon />
					</IconButton> */}

					{/* <FormControlLabel
						control={<Switch checked={secondary} onChange={toggleSecondary} color="primary"/>}
						label={
							<>
								<FormattedMessage id="objectiveAdder.secondary" defaultMessage="Optional"/>
								<ButtonDialog>
									<Typography variant="h5" gutterBottom><FormattedMessage id="objectiveAdder.secondary.help.title" defaultMessage="What are optional objectives?"/></Typography>
									<Typography variant="body1" paragraph><FormattedMessage id="objectiveAdder.secondary.help.text1" defaultMessage="Optional objectives can be missed without consequence. They won't negatively affect your success rate. Mark an objective as optional when you are not sure if you can make it, but still want to keep track of it."/></Typography>
									<Typography variant="body1" paragraph><FormattedMessage id="objectiveAdder.secondary.help.text2" defaultMessage="<strong>Use this sparingly!</strong> You should focus on your primary objectives. Adding optional objectives will only distract you." values={{strong: (m:any) => <strong>{m}</strong>}}/></Typography>
								</ButtonDialog>
							</>
						}
						className={classes.secondary}
					/>
					<Box className={classes.repeatButton}>
						{props.planType || true &&
							<ObjectiveRecurringButton onSelectRecurring={handleSelectRecurring} onSelectNonRecurring={handleSelectNonRecurring} isRecurring={recurring !== null}/>
						}

						{!(props.initialObjective && props.initialObjective.recurrerId) ? // Recurring objectives can't be deleted. Would just plop up again as virtual objectives. Todo: Cleaner solution. e.g. store deleted objectives as "skipped" in recurrer
							<IconButton color="primary" onClick={props.doDelete} data-analytics-id="ObjectiveEditor-delete"><DeleteIcon/></IconButton>
							:
							<ButtonDialog icon={<DeleteIcon className={classes.disabledIcon}/>}>
								<Typography><FormattedMessage id="objectivesTableRow.deletingRecurrers" defaultMessage="This is a recurring objective. Recurring objectives cannot be deleted. First, remove the recurrence. Then you can delete this instance.{br}{br}Want us to improve this behavior? <contact-en>Let us know!</contact-en>" values={commonMessageValues}/></Typography>
							</ButtonDialog>
						}
					</Box> */}
				</Grid>
				{/* <Grid item xs={5}>
					<FormControlLabel control={<Switch checked={this.state.secondary} onChange={this.toggleSecondary} color="primary"/>} label={<FormattedMessage id="objectiveAdder.secondary" defaultMessage="Secondary"/>} className={classes.secondary}/>
				</Grid> */}
				<Grid item xs={6} className={classes.deleteCell}>
					{props.showDelete && <>
						<IconButton onClick={props.onDelete} disabled={props.deleteDisabled}>
							<DeleteIcon />
						</IconButton>
					</>}
					{/*props.showDelete && props.deleteDisabled && <>
						<ButtonDialog icon={<DeleteIcon className={classes.disabledIcon}/>}>
							<Typography><FormattedMessage id="objectivesTableRow.deletingRecurrers" defaultMessage="This is a recurring objective. Recurring objectives cannot be deleted. First, remove the recurrence. Then you can delete this instance.{br}{br}Want us to improve this behavior? <contact-en>Let us know!</contact-en>" values={commonMessageValues}/></Typography>
						</ButtonDialog>
					</>*/}
				</Grid>
				<Grid item xs={6} className={classes.saveCell}>
					{props.cta === "save" && 
						<Button disabled={props.saveDisabled || props.title === ""} className={classes.addButton} color="secondary" onClick={props.onSave} data-analytics-id="ObjectiveAdder-save" startIcon={<SaveIcon/>}>{<FormattedMessage id="objectiveAdder.add" defaultMessage="Save"/>}</Button>
					}
					{props.cta === "add" &&
						<Button disabled={props.saveDisabled || props.title === ""} className={classes.addButton} color="secondary" onClick={props.onSave} data-analytics-id="ObjectiveAdder-save"><AddIcon/>{<FormattedMessage id="objectiveAdder.add" defaultMessage="Add"/>}</Button>
					}
				</Grid>
			</Grid>
		</>
	);
}
