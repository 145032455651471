import t from 'tcomb-validation';
import { StringNonEmpty, StringOrNull } from './validation-types';
import { List } from '../services/list-helpers';
import { findPlanById, isBefore, Plan, PlanUnsaved } from '../services/plan-helpers';
import { Recurrer } from './recurring-helpers';
import countBy from 'lodash.countby';

export type Objective = {
	id: string,
	title: string,
	estimate: number | null,
	secondary: boolean,
	success: boolean | null,
	failedReason?: string,
	version: number,
	listId: string | null,
	planId: string | null,
	plan?: Plan,
	list?: any,
	state: ObjectiveState,
	recurrerId: string | null,
}

export enum ObjectiveState {
	default = "default",
	virtual = "virtual",
}

const ObjectiveType = t.struct({
	id: t.String,
	title: t.String,
	estimate: t.maybe(t.Number),
	secondary: t.Boolean,
	success: t.maybe(t.Boolean),
	failedReason: t.maybe(StringNonEmpty),
	version: t.Number,
	listId: StringOrNull,
	planId: t.maybe(t.String),
	plan: t.maybe(t.Any), // Todo: Remove
	list: t.maybe(t.Any), // Todo: Remove
	state: t.String,
	recurrerId: t.maybe(t.String),
});

export function objectiveIsValid(objective: Objective) {
	const r = t.validate(objective, ObjectiveType, {strict: true});
	if (!r.isValid()) {
		return false;
	} else {
		return true;
	}
}

export function objectiveValidationErrors(objective: Objective) {
	return t.validate(objective, ObjectiveType, {strict: true}).errors;
}

export function getObjectivesByPlanId(objectives: Array<Objective>, planId: string) {
	return objectives.filter(objective => objective.planId === planId);
}

export function objectivesByPlanAndList(objectives: Array<Objective>, plan: Plan | PlanUnsaved, list: List | null) {
	if (!("id" in plan))
		return []; // Unsaved plans cannot have objectives
	return objectives.filter(objective => objective.planId === plan.id && ((!list && objective.listId === null) || (list && objective.listId === list.id)));
}

export function objectivesByList(objectives: Array<Objective>, list: List | null) {
	return objectives.filter(objective => (!list && objective.listId === null) || (list && objective.listId === list.id));
}

export function getObjectivesDuration(objectives: Array<Objective>) {
	const totalDuration = objectives.length > 0 ? objectives.reduce((accumulator, currentValue) => accumulator + (currentValue.estimate || 0), 0) : 0;
	return totalDuration;
}

export function getObjectivesByRecurrer(allObjectives: Objective[], recurrer: Recurrer) {
	return allObjectives.filter(o => o.recurrerId === recurrer.id);
}

export interface FailedReasonCounted {
	reason: string,
	count: number,
}
export function getFailedReasonsCounted(allObjectives: Objective[]): FailedReasonCounted[] {
	const reasonsObj = countBy(allObjectives.map(o => o.failedReason).filter(r => r));
	const reasons = Object.keys(reasonsObj)
		.map(k => ({reason: k, count: reasonsObj[k]}))
		.sort((a, b) => {
			if (a.count < b.count)
				return 1;
			else if (a.count > b.count)
				return -1;
			else
				return 0;
		});
	return reasons;
}

export function getEstimateSum(objectives: Objective[]) {
	return objectives.reduce((accumulator, currentValue) => accumulator + (currentValue.estimate || 0), 0);
}

export function isOverdue(objective: Objective, allPlans: Plan[], when: Date) {
	if (!objective.planId) {
		return false;
	}
	const plan = findPlanById(objective.planId, allPlans);
	if (!plan)
		throw new Error("isOverdue: Plan not found");
	return isBefore(plan, when);
}
