import t from 'tcomb-validation';
import goalImages from '../assets/goal-images/goalImages';
import { StringDateOrNull, StringOrNull } from './validation-types';
import logger from '../services/logger';
import * as Sentry from '@sentry/browser';
import { SettingsExperimentAData } from '../components/SettingsContext';

const ListType = t.struct({
	id: t.String,
	title: StringOrNull,
	description: StringOrNull,
	due: StringDateOrNull,
	archived: t.Boolean,
	motivationWhy: StringOrNull,
	motivationFail: StringOrNull,
	image: t.String,
});

export type List = {
	id: string,
	title: string | null,
	description: string | null,
	due: string | null,
	archived: boolean,
	motivationWhy: string | null,
	motivationFail: string | null,
	image: string,
	version: number,
	focus: boolean,
}

export function listIsValid(list: List) {
	const r = t.validate(list, ListType);
	if (!r.isValid()) {
		return false;
	} else {
		return true;
	}
}

export function loadLists(items: any, experimentAData: SettingsExperimentAData): Array<List> {
	const lists: List[] = items.map((l: any) => {
		const list: List = {
			id: l.id,
			title: l.title,
			description: l.description,
			due: l.due,
			archived: l.archived === true,
			motivationWhy: l.motivationWhy,
			motivationFail: l.motivationFail,
			image: l.image,
			version: l.version,
			focus: !(typeof l.focus === "boolean" && l.focus === false),
		}
		if (typeof l.focus === "undefined" && experimentAData && experimentAData.listFocus) {
			logger.debug("Loading list focus data from experimentAData");
			if (typeof experimentAData.listFocus[l.id] === "boolean")
				list.focus = experimentAData.listFocus[l.id];
			else
				logger.debug("... none found");
		} 
		if (!listIsValid(list)) {
			logger.error('Loading invalid plan', listValidationErrors(list), list, l);
			Sentry.captureException(new Error('Loaded invalid list'));
		}
		return list;
	});
	return lists;
}

export function listValidationErrors(list: List) {
	return t.validate(list, ListType).errors;
}

export function getListImage(list: List) {
	return goalImages[list.image || 0];
}

export function findListById(id: string, allLists: List[]) {
	return allLists.find(list => list.id === id);
}