import React, { useState, useContext } from 'react';
import PlanViewOnboarding from './PlanViewOnboarding';
import logger from '../services/logger';
import SettingsContext from './SettingsContext';

interface Props {
}

export default function PlanViewOnboardingController(props: Props) {

	const settings = useContext(SettingsContext);

	// Show onboarding if primary onboarding is completed but PlanView onboarding has not happened yet.
	// @todo: Implement more robust triggers. e.g. store in user data via API
	const [onboarding, setOnboarding] = useState(settings.getOnboardingTourDone() === null);

	const onEnded = () => {
		if (localStorage) {
			logger.info('PlanViewOnboarding ended.');
			settings.setOnboardingTourDone(new Date());
			setOnboarding(false);
		}
	};

	if (onboarding) {
		logger.info('Rendering PlanViewOnboarding');
		return <PlanViewOnboarding onEnded={onEnded}/>
	} else {
		return null;
	}
}
