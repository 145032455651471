import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';

import moodImage from '../assets/reflection-images/beatriz-perez-moya-672pqakB6Ow-unsplash.300.jpg';
import { FormattedMessage } from 'react-intl';
import { PlanType } from '../services/plan-helpers';

const useStyles = makeStyles(theme => ({
	card: {
		// margin: theme.spacing(1),
	},
	flex: {
		display: "flex",
		justifyContent: "space-between",
	},
	cover: {
		width: 151,
	},
	button: {
		marginTop: theme.spacing(2),
	}
}));

interface Props {
	onClick(event: unknown): void,
	type: PlanType,
}

function PlanReflectionNotice(props: Props) {
	const { onClick, type } = props;
	const classes = useStyles();

	return (
		<Card raised className={classes.card}>
			<div className={classes.flex}>
			<CardContent>
				<Typography variant="h5" gutterBottom>
					<FormattedMessage id="PlanReflectionNotice.title" defaultMessage="Time to look back?" values={{type: type}}/>
				</Typography>
				<Typography variant="body2" gutterBottom> 
					<FormattedMessage id="PlanReflectionNotice.description" defaultMessage="Take stock at the end of the {type}, appreciate the good things and find ways to improve." values={{type: type}}/>
				</Typography>
				<Button onClick={onClick} variant="outlined" color="secondary" className={classes.button}><FormattedMessage id="PlanReflectionNotice.button" defaultMessage="Reflect"/></Button>
			</CardContent>
			<CardMedia
				className={classes.cover}
				image={moodImage}
			/>
			</div>
			{/* <CardActions>
				<Button _size="small">Reflect</Button>
			</CardActions> */}
		</Card>
	);
}

export default PlanReflectionNotice;
