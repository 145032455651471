import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl, defineMessages, FormattedMessage} from 'react-intl';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';

interface StyleProps {
	slim?: boolean,
	headerBackground?: string,
}

const useStyles = makeStyles(theme => ({
	card: {
		margin: theme.spacing(1),
		marginBottom: theme.spacing(2),
		cursor: "pointer",
	},
	content: {
		paddingLeft: 0
	},
	headerImageBackground: (props: StyleProps) => ({
		backgroundColor: theme.palette.grey["500"],
		backgroundImage: props.headerBackground ? `linear-gradient(135deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.0)),url(${props.headerBackground})` : undefined,
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
		color: theme.palette.getContrastText('rgba(0, 0, 0, 0.7)'),
		"& .MuiTypography-colorTextSecondary": {
			color: theme.palette.getContrastText('rgba(0, 0, 0, 0.7)'),
		},
		// padding: [theme.spacing(2), props.slim ? theme.spacing(2) : theme.spacing(6)],
		// padding: [theme.spacing(2), theme.spacing(6)],
		paddingTop: props.slim ? theme.spacing(2) : theme.spacing(6),
		paddingBottom: props.slim ? theme.spacing(2) : theme.spacing(6),
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	}),
	headerNoBackground: {
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	cardContent: {
		overflow: "hidden",
	},
	cardTitle: {
		textOverflow: "ellipsis",
		overflow: "hidden",
	},
	headerGrey: {
		backgroundColor: "#fff",
		backgroundImage: "linear-gradient(135deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2))",
		color: theme.palette.getContrastText('rgba(0, 0, 0, 0.7)'),
		"& .MuiTypography-colorTextSecondary": {
			color: theme.palette.getContrastText('rgba(0, 0, 0, 0.7)'),
		}
	},
	wrapper: {
		position: "relative",
		paddingBottom: 10,
	},
	addIcon: {
		position: "absolute",
		bottom: -2,
		right: 0,
		left: 0,
		marginLeft: "auto",
		marginRight: "auto",
	},
	cardActions: {
		paddingLeft: theme.spacing(3),
	},
}));

const messages = defineMessages({
	objectivesCount: {
		id: "objectivesCard.objectivesCount",
		defaultMessage: "{count, plural, =0 {no objectives} one {1 objective} other {# objectives}}",
	},
	objectivesDuration: {
		id: "objectives.duration",
		defaultMessage: "{count, number}h",
	},
});

interface Props {
	onboardingMarkers?: string,
	title: string,
	headerBackground?: string,
	objectivesNumber: number,
	objectivesDuration: number,
	onClick(): void,
	onAddObjectiveClick(): void,
	slim?: boolean,
}

export default function ListCard(props: Props) {

	const { onboardingMarkers, title, objectivesNumber, objectivesDuration, slim, headerBackground } = props;
	const classes = useStyles({
		slim: slim,
		headerBackground: headerBackground,
	});
	const { formatMessage } = useIntl();

	return (
		<React.Fragment>
			<Card className={classes.card} data-onboarding-markers={onboardingMarkers}>
				<CardHeader
					title={title}
					subheader={formatMessage(messages.objectivesCount, {count: objectivesNumber}) + ' · '+formatMessage(messages.objectivesDuration, {count: objectivesDuration})}
					className={headerBackground ? classes.headerImageBackground : classes.headerNoBackground }
					classes={{title: classes.cardTitle, content: classes.cardContent}}
					onClick={props.onClick}
				/>
				<CardActions className={classes.cardActions}>
					<Button onClick={() => props.onAddObjectiveClick()} color="primary" data-analytics-id="ListCard-add-objective">
						<FormattedMessage id="objectivesCard.addObjective" defaultMessage="Add objective"/>
					</Button>
				</CardActions>
			</Card>
		</React.Fragment>
	);
}
