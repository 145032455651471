import React from 'react';
import logger from '../services/logger';
import * as Sentry from '@sentry/browser';
import ErrorView from './ErrorView';

interface Props {
	showError?: boolean,
}

interface State {
	hasError: boolean,
	eventId?: any,
}

class ErrorBoundary extends React.Component<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			hasError: false,
			eventId: null,
		};
	}
  
	static getDerivedStateFromError(error: any) {
	  // Update state so the next render will show the fallback UI.
	  return { hasError: true };
	}
  
	componentDidCatch(error: any, errorInfo: any) {
	  // You can also log the error to an error reporting service
	//   logErrorToMyService(error, errorInfo);
		logger.error(error, errorInfo);
		Sentry.withScope((scope) => {
			scope.setExtras(errorInfo);
			const eventId = Sentry.captureException(error);
			this.setState({eventId});
		});
	}
  
	render() {
	  if (this.state.hasError || this.props.showError) {
		return <ErrorView eventId={this.state.eventId}/>;
	}

	return this.props.children; 
	}
}

export default ErrorBoundary;