import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
	title: {
		flexGrow: 1,
	},
	currentPW: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(2),
	},
	newPWs: {
		marginTop: theme.spacing(2),
		// marginBottom: theme.spacing(2),
	},
	bottomGrid: {
		// paddingLeft: theme.spacing(1),
		// paddingRight: theme.spacing(1),
		marginTop: theme.spacing(4),
	},
}));

interface Props {
	onCancel(): void,
	onSave(): void,
	onChangeCurrentPW(value: string): void,
	onChangeNewPW(value: string): void,
	onChangeNewPWConfirmation(value: string): void,
	currentPW: string,
	newPW: string,
	newPWConfirmation: string,
}

function ChangePassword(props: Props) {
	const {
		onCancel,
		onChangeCurrentPW,
		onChangeNewPW,
		onChangeNewPWConfirmation,
		currentPW,
		newPW,
		newPWConfirmation,
		onSave,
	} = props;
	const classes = useStyles();

	return (
		<React.Fragment>
			<AppBar position="static">
				<Toolbar >
					<Typography variant="h6" color="inherit" noWrap className={classes.title}>
						<FormattedMessage id="changePassword.title" defaultMessage="Change password"/>
					</Typography>
					<IconButton color="inherit" onClick={onCancel}>
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
				<Container maxWidth="sm">
					<TextField
						className={classes.currentPW}
						label={<FormattedMessage id="changePassword.currentPW.label" defaultMessage="Current password"/>}
						fullWidth
						value={currentPW}
						onChange={(event) => onChangeCurrentPW(event.target.value)}
						type="password"
					/>
					<TextField
						className={classes.newPWs}
						label={<FormattedMessage id="changePassword.newPW.label" defaultMessage="New password"/>}
						fullWidth
						value={newPW}
						onChange={(event) => onChangeNewPW(event.target.value)}
						type="password"
					/>
					<TextField
						className={classes.newPWs}
						label={<FormattedMessage id="changePassword.newPWConfirmation.label" defaultMessage="Confirm new Password"/>}
						fullWidth
						value={newPWConfirmation}
						onChange={(event) => onChangeNewPWConfirmation(event.target.value)}
						type="password"
					/>
					<Grid container justify="space-between" className={classes.bottomGrid}>
						<Grid item><Button variant="text" color="default" onClick={onCancel}><FormattedMessage id="changePassword.cancel" defaultMessage="Cancel"/></Button></Grid>
						<Grid item><Button variant="contained" color="primary" onClick={onSave}><FormattedMessage id="changePassword.save" defaultMessage="Change password"/></Button></Grid>
					</Grid>

				</Container>
		</React.Fragment>
	);
}

export default ChangePassword;