import React, { useState, useEffect } from 'react';
// import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
// import logger from '../services/logger';
import { PlanType, getNextPeriod, getPreviousPeriod, getPlanTitle, PlanStub, dateToPeriod } from '../services/plan-helpers';
import { useIntl } from 'react-intl';
import { parseISO } from 'date-fns';

const useStyles = makeStyles(theme => ({
	headingGrid: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(2),
	},
	periodControls: {
		textAlign: "right"
	},
	back: {
		textAlign: "left"
	},
	forward: {
		textAlign: "right"
	},
	title: {
		textAlign: "center"
	},
	periodChooser: {
		textAlign: "center",
	},
	periodChooserButton: {
		height: "40px",
	},
	unscheduled: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(2),
		textAlign: "center",
		height: "48px",
		boxSizing: "content-box",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
}));

interface Props {
	initialPeriod: string | null,
	initialType: PlanType | null,
	onChange(plan: null | PlanStub): void,
}

export default function PlanChooser(props: Props) {
	const classes = useStyles();
	const { locale } = useIntl();
	const {
		initialPeriod,
		initialType,
		onChange,
	} = props;

	// const type = null, onTypeChange = (foo: any) => {}, onPeriodBack = () => {}, onPeriodForward = () => {}, planTitle = "Titel";

	const [type, setType] = useState<PlanType|null>(initialType);
	const [period, setPeriod] = useState(initialPeriod || dateToPeriod(new Date(), PlanType.day));

	useEffect(() => {
		if (type && period) {
			onChange({
				type: type,
				period: period,
			});
		} else {
			onChange(null);
		}

	}, [type, period]); // eslint-disable-line react-hooks/exhaustive-deps

	const onTypeChange = (type: PlanType | null) => {
		setType(type);
		if (type) {
			const currentPeriodDate = parseISO(period);
			const newPeriod = dateToPeriod(currentPeriodDate, type);
			setPeriod(newPeriod);
		}
	}

	const handlePeriodBack = () => {
		if (type) {
			const newPeriod = getPreviousPeriod(period, type);
			setPeriod(newPeriod);
		}
	}
	const handlePeriodForward = () => {
		if (type) {
			const newPeriod = getNextPeriod(period, type);
			setPeriod(newPeriod);
		}
	}

	const planTitle = type !== null ? getPlanTitle({type: type, period: period}, locale) : "";

	return (
		<React.Fragment>
			<Box className={classes.periodChooser}>
				<ToggleButtonGroup size="medium" value={type === null ? "" : type} exclusive onChange={(event: unknown, type) => onTypeChange(type !== "" ? type : null)}>
					<ToggleButton value="" className={classes.periodChooserButton}> 
						∅
					</ToggleButton>
					<ToggleButton value={PlanType.day} className={classes.periodChooserButton}>
						<FormattedMessage id="period.day" defaultMessage="Day"/>
					</ToggleButton>
					<ToggleButton value={PlanType.week} className={classes.periodChooserButton}>
						<FormattedMessage id="period.week" defaultMessage="Week"/>
					</ToggleButton>
					<ToggleButton value={PlanType.month} className={classes.periodChooserButton}>
						<FormattedMessage id="period.month" defaultMessage="Month"/>
					</ToggleButton>
					<ToggleButton value={PlanType.year} className={classes.periodChooserButton}>
						<FormattedMessage id="period.year" defaultMessage="Year"/>
					</ToggleButton>
				</ToggleButtonGroup>
			</Box>
			{type &&
				<Grid container className={classes.headingGrid} alignItems="center">
					<Grid item xs={2} className={classes.back}><IconButton onClick={handlePeriodBack}><NavigateBeforeIcon/></IconButton></Grid>
					<Grid item xs={8} className={classes.title}><Typography variant="h6">{planTitle}</Typography></Grid>
					<Grid item xs={2} className={classes.forward}><IconButton onClick={handlePeriodForward}><NavigateNextIcon/></IconButton></Grid>
				</Grid>
			}
			{!type &&
				<Typography variant="h6" className={classes.unscheduled}>
					<FormattedMessage id="SchedulerDialog.unscheduled.title" defaultMessage="Unscheduled"/>
					<Tooltip disableFocusListener title={<FormattedMessage id="SchedulerDialog.unscheduled.tooltip" defaultMessage="Navigate to your goal to see unscheduled objectives"/>}>
						<HelpIcon fontSize="small" style={{marginLeft: 5}} color="primary"/>
					</Tooltip>
				</Typography>
			}
	</React.Fragment>
	);
}
