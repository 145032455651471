import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';


const useStyles = makeStyles(theme => ({
	container: {
		marginTop: theme.spacing(4),
		textAlign: "center",
	},
}));

interface Props {
}

function NotFoundView(props: Props) {
	const classes = useStyles();

	return (
		<React.Fragment>
			<Container className={classes.container}>
				<Typography variant="h4" gutterBottom paragraph>¯\_(ツ)_/¯</Typography>
				<Typography variant="h6">Oops, this does not seem to exist.</Typography>
			</Container>
		</React.Fragment>
	);
}

export default NotFoundView;