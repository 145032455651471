import React from 'react';
import { Platform } from '..';
import { OnboardingSurveyIntl } from './OnboardingSurveyController';

export interface RemoteConfig {
	ready(): boolean,
	getInnovators(): string[],
	getMinVersion(platform: Platform): number,
	getOnboardingSurvey(): OnboardingSurveyIntl,
	onboardingInterviewShow: boolean,
	onboardingInterviewShowPhone: boolean,
	helpShowPhone: boolean,
}

const RemoteConfigContext = React.createContext<RemoteConfig>({
	ready: () => false,
	getInnovators: () => [],
	getMinVersion: (platform: Platform) => 0,
	getOnboardingSurvey: () => ({}),
	onboardingInterviewShow: true,
	onboardingInterviewShowPhone: true,
	helpShowPhone: false,
});

export default RemoteConfigContext;
