import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import logger from '../services/logger';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useIntl, defineMessages, FormattedMessage} from 'react-intl';
import ObjectiveRow from './ObjectiveRow';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { Collapse, IconButton, Typography } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

// import Fab from '@material-ui/core/Fab';
// import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
	card: {
		// margin: theme.spacing(1),
		marginBottom: theme.spacing(2),
	},
	contentPlain: {
		// paddingLeft: 0,
		paddingLeft: theme.spacing(1),
		paddingTop: 0,
		paddingBottom: 0,
	},
	contentAfterHeader: {
		// paddingLeft: 0,
		paddingLeft: theme.spacing(1),
		// paddingTop: 0,
		paddingBottom: 0,
	},
	headerImageBackground: {
		backgroundColor: theme.palette.grey["500"],
		backgroundImage: "linear-gradient(135deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.00))",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
		color: theme.palette.getContrastText('rgba(0, 0, 0, 0.7)'),
		"& .MuiTypography-colorTextSecondary": {
			color: theme.palette.getContrastText('rgba(0, 0, 0, 0.7)'),
		},
		"& span:first-child": {
			cursor: "pointer",
		}
	},
	headerPlain: {
		"& span:first-child": {
			cursor: props => props.onTitleClick ? "pointer" : null,
		}
	},
	headerGrey: {
		backgroundColor: "#fff",
		backgroundImage: "linear-gradient(135deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2))",
		color: theme.palette.getContrastText('rgba(0, 0, 0, 0.7)'),
		"& .MuiTypography-colorTextSecondary": {
			color: theme.palette.getContrastText('rgba(0, 0, 0, 0.7)'),
		}
	},
	wrapper: {
		position: "relative",
		paddingBottom: 10,
	},
	addIcon: {
		position: "absolute",
		bottom: -2,
		right: 0,
		left: 0,
		marginLeft: "auto",
		marginRight: "auto",
	},
	overarching: {
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
		maxWidth: "calc(100vw - 50px)",
	},
	strikethrough: {
		textDecoration: "line-through",
	},
	cardContent: {
		overflow: "hidden",
	},
	cardTitle: {
		textOverflow: "ellipsis",
		overflow: "hidden",
	},
	subObjectivesLabel: {
		cursor: "pointer",
	},
}));

const messages = defineMessages({
	objectivesCount: {
		id: "objectivesCard.objectivesCount",
		defaultMessage: "{count, plural, =0 {no objectives} one {1 objective} other {# objectives}}",
	},
	objectivesDuration: {
		id: "objectives.duration",
		defaultMessage: "{count, number}h",
	},
});

function ObjectivesCard(props) {

	const { 
		objectives,
		overarchingPlanTitle,
		overarchingObjectives,
		onboardingMarkers,
		onTitleClick,
	} = props;
	const classes = useStyles(props);
	const { formatMessage } = useIntl();
	const [showSubObjectives, setShowSubObjectives] = useState(false);

	const handleHeaderClick = (arg1, arg2) => {
		if (onTitleClick && arg1.target.textContent === props.title) {
			onTitleClick();
		}
	}

	const totalDuration = props.objectives.length > 0 ? props.objectives.reduce((accumulator, currentValue) => accumulator + (currentValue.estimate || 0), 0) : 0;

	const oaOpen = overarchingObjectives ? overarchingObjectives.filter(o => o.success !== true) : [];
	const oaClosed = overarchingObjectives ? overarchingObjectives.filter(o => o.success === true) : [];

	const oaClosedFormatted = [];
	for (let i = 0; i < oaClosed.length; i++) {
		oaClosedFormatted.push(<span key={oaClosed[i].id} className={classes.strikethrough}>{oaClosed[i].title}</span>);
		if (i < oaClosed.length - 1)
			oaClosedFormatted.push(", ");
	}

	return (
		<React.Fragment>
		{/* <div className={classes.wrapper}> */}
			<Card className={classes.card} data-onboarding-markers={onboardingMarkers}>
				<CardHeader
					title={props.title}
					subheader={
						<Typography variant="body1" component="div">
						{formatMessage(messages.objectivesCount, {count: objectives.length}) + ' · '+formatMessage(messages.objectivesDuration, {count: totalDuration})}<br/>
						{(oaOpen.length + oaClosed.length > 0) &&
							<Typography component="div" variant="caption" className={classes.overarching}>
								{overarchingPlanTitle}:&nbsp;
									{oaOpen.map(o => o.title).join(", ")}
									{oaClosedFormatted.length > 0 && <React.Fragment>{oaOpen.length > 0 && ", "} {oaClosedFormatted}</React.Fragment>}
								</Typography>
						}
						</Typography>
					}
					className={props.headerBackground ? classes.headerImageBackground : classes.headerPlain}
					style={props.headerBackground ? {backgroundImage: `linear-gradient(135deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.0)),url(${props.headerBackground})`} : null}
					onClick={handleHeaderClick}
					classes={{title: classes.cardTitle, content: classes.cardContent}}
				/>
				{props.objectives.length > 0 || (props.subObjectives && props.subObjectives.length) > 0 ?
				<CardContent className={props.headerBackground ? classes.contentAfterHeader : classes.contentPlain}>

					{props.objectives.map(objective =>
							<ObjectiveRow
								key={objective.id}
								objective={objective}
								reflect={props.reflect}
								doCheck={() => props.onCheckObjective(objective)}
								doFail={() => props.onFailObjective(objective)}
								doUncheck={() => props.onUncheckObjective(objective)}
								doEdit={() => props.onEditObjective(objective)}
								showPlan={props.variant === "with-schedule"}
							/>
					)}

					{/* <Divider variant="fullWidth" style={{marginTop: 8}}/> */}
					{props.subObjectives && props.subObjectives.length > 0 && <>
						<IconButton style={{marginLeft:-4}} onClick={() => setShowSubObjectives(!showSubObjectives)}>{showSubObjectives ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}</IconButton><Typography variant="caption" onClick={() => setShowSubObjectives(!showSubObjectives)} className={classes.subObjectivesLabel}><FormattedMessage id="objectivesCard.subObjectives" defaultMessage="{count} {count, plural, one {objective} other {objectives}} in subordinate plans" values={{count: props.subObjectives.length}}/></Typography>
						<Collapse in={showSubObjectives} timeout="auto" unmountOnExit>
							{props.subObjectives.map(objective =>
									<ObjectiveRow
										key={objective.id}
										objective={objective}
										reflect={props.reflect}
										doCheck={() => props.onCheckObjective(objective)}
										doFail={() => props.onFailObjective(objective)}
										doUncheck={() => props.onUncheckObjective(objective)}
										doEdit={() => props.onEditObjective(objective)}
										showPlan={true}
									/>
							)}
						</Collapse>
					</>}
				</CardContent>
				: null }
				{props.editable && 
				<CardActions>
					<Button color="primary" onClick={props.onAddObjective} data-analytics-id="ObjectivesCard-add-objective">
						<FormattedMessage id="objectivesCard.addObjective" defaultMessage="Add objective"/>
					</Button>
				</CardActions>
				}
			</Card>
			{/* {props.editable && 
					<Fab onClick={props.onAddObjective} size="small" color="primary" aria-label="add" className={classes.addIcon}>
						<AddIcon />
					</Fab>
				} */}

			{/* </div> */}
			</React.Fragment>
	);
}

ObjectivesCard.defaultProps = {
	editable: true,
	reflect: false,
};

ObjectivesCard.propTypes = {
	title: PropTypes.any,
	subObjectives: PropTypes.array,
	objectives: PropTypes.array.isRequired,
	overarchingPlanTitle: PropTypes.string.isRequired,
	overarchingObjectives: PropTypes.array.isRequired,
	editable: PropTypes.bool,
	reflect: PropTypes.bool,
	onCheckObjective: PropTypes.func.isRequired,
	onFailObjective: PropTypes.func.isRequired,
	onUncheckObjective: PropTypes.func.isRequired,
	onEditObjective: PropTypes.func.isRequired,
	onAddObjective: PropTypes.func,
	onTitleClick: PropTypes.func,
	headerBackground: PropTypes.any,
	onboardingMarkers: PropTypes.string,
	variant: PropTypes.string,
};

export default ObjectivesCard;