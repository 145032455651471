import React from 'react';
import PropTypes from 'prop-types';
import {useIntl, defineMessages} from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';


const messages = defineMessages({
	year: {
		id: 'period.year',
		defaultMessage: 'Year',
	},
	month: {
		id: 'period.month',
		defaultMessage: 'Month',
	},
	week: {
		id: 'period.week',
		defaultMessage: 'Week',
	},
	day: {
		id: 'period.day',
		defaultMessage: 'Day',
	},
});

const useStyles = makeStyles(theme => ({
	headingGrid: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(2),
	},
	periodControls: {
		textAlign: "right"
	},
	back: {
		textAlign: "left"
	},
	forward: {
		textAlign: "right"
	},
	title: {
		textAlign: "center"
	}
}));

function PlanViewNavigation(props) {
	
	const {formatMessage} = useIntl();
	const handleTabChange = (event, value) => {
		props.onTypeChange(value);
	};
	const classes = useStyles();

	return (
		<React.Fragment>
			<Paper elevation={2} square>
					<Tabs
						value={props.type}
						indicatorColor="secondary"
						textColor="secondary"
						onChange={handleTabChange}
						variant="fullWidth"
						data-onboarding-markers="PlanViewNavigationTabs"
					>
						<Tab value="day" label={formatMessage(messages.day)} />
						<Tab value="week" label={formatMessage(messages.week)} />
						<Tab value="month" label={formatMessage(messages.month)} />
						<Tab value="year" label={formatMessage(messages.year)} />
					</Tabs>
			</Paper>

			<Grid container className={classes.headingGrid} >
				<Grid item xs={2} className={classes.back}><IconButton onClick={props.onPeriodBack}><NavigateBeforeIcon/></IconButton></Grid>
				<Grid item xs={8} className={classes.title}><Typography variant="h4">{props.planTitle}</Typography></Grid>
				<Grid item xs={2} className={classes.forward}><IconButton onClick={props.onPeriodForward}><NavigateNextIcon/></IconButton></Grid>
			</Grid>
		</React.Fragment>
	);
}

PlanViewNavigation.propTypes = {
	type: PropTypes.oneOf(['year', 'month', 'day', 'week']),
	onTypeChange: PropTypes.func.isRequired,
	onPeriodBack: PropTypes.func.isRequired,
	onPeriodForward: PropTypes.func.isRequired,
	planTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export default PlanViewNavigation;