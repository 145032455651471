import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import moodImage from '../assets/reflection-images/20160704-2004--DSC_2771.landscape.jpg';
import Button from '@material-ui/core/Button';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { OnboardingSurveySet } from './OnboardingSurveyController';


const useStyles = makeStyles(theme => ({
	headerBackground: {
		color: theme.palette.common.white,
		backgroundImage: `linear-gradient(135deg, rgba(0, 0, 0, 0.5) 30%, rgba(0, 0, 0, 0.0) 60%),url(${moodImage})`,
		// backgroundImage: `linear-gradient(135deg, rgba(255, 255, 255, 1), rgba(0, 0, 0, 0.0)),url(${moodImage})`,
		// backgroundImage: `url(${moodImage})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	description: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(-1),
	},
	container: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	question: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(-1),
	},
	heading: {
		marginBottom: theme.spacing(1),
	},
	send: {
		marginBottom: theme.spacing(1),
		textAlign: "right",
	},
}));



interface Props {
	onSend(): void,
	ready: boolean,
	survey: OnboardingSurveySet,
	values: {
		[key: string]: string,
	},
	onChangeValue(key: string, value: string): void,
}

function OnboardingSurvey(props: Props) {
	const {
		onSend,
		ready,
		survey,
		values,
		onChangeValue,
	} = props;
	const classes = useStyles();

	return (
		<React.Fragment>
			<Container maxWidth="xs">
				<Box className={classes.description}>
					<Typography variant="h4" color="inherit" className={classes.heading}>{survey.title}</Typography>
					<Typography variant="body1" color="inherit">{survey.description}</Typography>
				</Box>
				{survey.questions.map(question => 
					<Box className={classes.question} key={question.id}>
						<TextField
								label={question.label}
								margin="dense"
								fullWidth	
								value={values[question.id] ? values[question.id] : ""}
								// autoFocus
								onChange={(event) => onChangeValue(question.id, event.target.value)}
								helperText={question.helper ? question.helper : undefined}
							/>
					</Box>
				)}
				<Box className={classes.question}>
					<Typography variant="body2" color="inherit" gutterBottom>{survey.smallprint}</Typography>
				</Box>
				<Box className={classes.send}>
					<Button onClick={onSend} disabled={!ready} variant="contained" color="secondary" style={{color: "white"}}>{survey.button}<KeyboardArrowRight /></Button>
				</Box>
			</Container>
		</React.Fragment>
	);
}

export default OnboardingSurvey;
