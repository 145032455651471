import React, { useState, useEffect } from 'react';
import logger from '../services/logger';
import { useSnackbar } from 'notistack';
import { setScreenName, logEvent } from '../services/analytics';
import SubscriptionUpgradeV2 from './SubscriptionUpgradeV2';
import SubscriptionUnavailableDialog from './SubscriptionUnavailableDialog';
import SubscriptionPurchasedDialog from './SubscriptionPurchasedDialog';
import { Subscription, SubscriptionType, SubscriptionCategory } from './SubscriptionController';
import { Environment } from '../index';
import { User } from './AuthController';
import Purchases, { PurchasesError } from 'cordova-plugin-purchases/www/plugin';
import * as Sentry from '@sentry/browser';

// enum Steps { 
// 	completed = "completed",
// }

interface Props {
	onBack(): void,
	currentSubscription: Subscription,
	environment: Environment,
	user: User,
}

export default function SubscriptionPurchaseControllerV2(props: Props) {
	const { onBack, currentSubscription, environment, user } = props;

	const [unavailable, setUnavailable] = useState(false);
	const [purchased, setPurchased] = useState(false);
	const [rcPackage, setRcPackage] = useState<any | undefined>(undefined);
	const [price, setPrice] = useState("…");
	const [transactionLoading, setTransactionLoading] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		setScreenName('SubscriptionPurchase');
	}, []);

	useEffect(() => {
		if (environment.platform === "android" || environment.platform === "ios") {
			
			Purchases.getOfferings(
				offerings => {
					logger.debug('Purchases offering:', JSON.stringify(offerings));
					// @ts-ignore
					if (offerings.current && offerings.current.availablePackages && offerings.current.availablePackages.length !== 0) {  
						// Display packages for sale
						const packageId = currentSubscription.type === SubscriptionType.innovatorTrial ? "Innovator" : "$rc_monthly";
						const pkg = offerings.current.availablePackages.find(p => p.identifier === packageId);
						logger.debug('Package for purchasing:', JSON.stringify(pkg));
						setRcPackage(pkg);
					} else {
						logger.error('Failed to parse offerings', JSON.stringify(offerings));
						Sentry.captureException("Failed to parse offerings");
					}
				},
				error => {
					logger.error('Failed to fetch offerings', error);
					Sentry.captureException(error);
				}
			);
		}
	}, [currentSubscription.type, environment.platform, user.id]);

	useEffect(() => {
		if (environment.platform === "android" || environment.platform === "ios") {
			if (rcPackage && rcPackage.product && rcPackage.product.price_string) {
				// use price from package if available
				setPrice(rcPackage.product.price_string);
			} else {
				// package price still loading
				setPrice("…");
			}
		} else {
			// Use manual pricing
			switch (currentSubscription.type) {
				case SubscriptionType.innovatorTrial:
					setPrice("€0.99");
					break;
				case SubscriptionType.free:
				case SubscriptionType.standardTrial:
					setPrice("€4.99");
					break;
				default:
					setPrice("…");
			}
		}
	}, [rcPackage, currentSubscription.type, environment.platform]);

	useEffect(() => {
		if (currentSubscription.category === SubscriptionCategory.paid) {
			setPurchased(true);
		} else {
			setPurchased(false);
		}
	}, [currentSubscription.category]);

	const handleBuy = () => {
		logEvent("subscription_purchase_start");
		if (environment.platform === "android" || environment.platform === "ios") {
			if (!rcPackage) {
				logger.error('User tried to purchase, but package data is unavailable');
				enqueueSnackbar("An error occured. Please try again later", {
					variant: "error"
				});
				Sentry.captureException("User tried to purchase, but package data is unavailable");
			} else {
				setTransactionLoading(true);
				Purchases.purchasePackage(
					rcPackage,
					// ({ productIdentifier, purchaserInfo }) => {
					(result) => {
						logger.info('Purchased package', rcPackage, result);
						logEvent("subscription_purchase_complete");
						setPurchased(true);
						currentSubscription.doRefresh();
					},
					({error, userCancelled}: {error: PurchasesError, userCancelled: boolean}) => {
						// Error making purchase
						setTransactionLoading(false);
						if (userCancelled) {
							logger.info('User cancelled transaction', error);
							logEvent("subscription_purchase_canceled");
						} else {
							logger.error('Error processing transaction', error);
							Sentry.captureException(error);
							enqueueSnackbar("An unknown error occured. "+error.readableErrorCode+": "+error.message, {
								variant: "error"
							});
						}
					}
				)
			}
		} else {
			setUnavailable(true);
		}
	}

	const handleRestorePurchases = () => {
		logEvent("restore_purchases");
		logger.info("Restore purchases requested.")
		if (environment.platform === "android" || environment.platform === "ios") {
			setTransactionLoading(true);
			Purchases.restoreTransactions(
				info => {
					logger.debug('Restore purchases complete', info);
					currentSubscription.doRefresh();
					setTransactionLoading(false);
				},
				error => {
					logger.error('Restore purchases failed', error);
					Sentry.captureException(error);
					enqueueSnackbar("An unknown error occured. "+error.readableErrorCode+": "+error.message, {
						variant: "error"
					});
					setTransactionLoading(false);
				}
			);
		} else {
			setUnavailable(true);
		}
	}

	const priceLoading = (!rcPackage && (environment.platform === "android" || environment.platform === "ios"));

	return (
		<React.Fragment>
			{unavailable &&
				<SubscriptionUnavailableDialog onClose={() => setUnavailable(false)}/>
			}
			{purchased &&
				<SubscriptionPurchasedDialog onClose={onBack}/>
			}
			<SubscriptionUpgradeV2
				onBuy={handleBuy}
				onBack={onBack}
				onRestorePurchases={handleRestorePurchases}
				price={price}
				priceLoading={priceLoading}
				transactionLoading={transactionLoading}
			/>
		</React.Fragment>
	);
}
