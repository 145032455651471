import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import FlareIcon from '@material-ui/icons/Flare';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { CircularProgress } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
			backgroundColor: theme.palette.common.white,
		},
	},
	container: {
		backgroundColor: "white",
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	paper: {
		// marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	confirmationText: {
		marginTop: theme.spacing(1),
	},
	button: {
		marginTop: theme.spacing(2),
	},
	progress: {
		marginTop: theme.spacing(2),
	}
}));

export default function SignUpLoading(props) {

	const classes = useStyles();

	return (
	<Container component="main" maxWidth="xs" className={classes.container}>
		<CssBaseline />
		<div className={classes.paper}>
			<Avatar className={classes.avatar}>
				<FlareIcon />
			</Avatar>
			<Typography variant="h5"><FormattedMessage id="signup.loading.title" defaultMessage="Processing…"/></Typography>
			<CircularProgress className={classes.progress}/>
			<Typography variant="caption" className={classes.confirmationText}><FormattedMessage id="signup.loading.description" defaultMessage="This might take a few seconds."/></Typography>
		</div>

	</Container>
);
}