import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Container, Grid } from '@material-ui/core';
import { Environment } from '..';
import { User } from './AuthController';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Subscription } from './SubscriptionController';


const useStyles = makeStyles(theme => ({
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(8),
	}
}));

export interface DebugViewProps {
	user: User,
	notificationsEnabledSetting: boolean,
	notificationsPermission: boolean | null,
	environment: Environment,
	onRequestNotificationPermission(): void,
	onScheduleTestNotification(): void,
	experimentA: boolean,
	subscription: Subscription,
	onToggleExperimentA(): void,
}

export default function DebugView(props: DebugViewProps) {
	const classes = useStyles();

	return (
		<Container maxWidth="xs" className={classes.container}>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<Typography variant="body1">Email address: {props.user.email}</Typography>
					<Typography variant="body1">User id: {props.user.id}</Typography>
					<Typography variant="body1">User name: {props.user.username}</Typography>
				</Grid>

				<Grid item xs={12}>
					<Typography variant="body1">Build: {props.environment.jsBuildNumber}, {props.environment.jsBuildTime}, {props.environment.awsEnvironment}</Typography>
					<Typography variant="body1">Platform: {props.environment.platform}</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="body1">Notifications Enabled (Setting): {props.notificationsEnabledSetting ? "✔" : "❌"}</Typography>
					<Typography variant="body1" gutterBottom>Notifications Permission Enabled: {props.notificationsPermission === true ? "✔" : props.notificationsPermission === false ? "❌" :  "?"}</Typography>
					<Button style={{marginRight:8, marginBottom: 8}} variant="outlined" onClick={props.onRequestNotificationPermission}>Request Permission</Button>
					<Button variant="outlined" onClick={props.onScheduleTestNotification}>Schedule Test Notification</Button>
				</Grid>

				<Grid item xs={12}>
					<Typography variant="body1">Subscription type: {props.subscription.type}</Typography>
					<Typography variant="body1">Subscription category: {props.subscription.category}</Typography>
					<Typography variant="body1">Subscription expiration date: {props.subscription.expirationDate}</Typography>
					<Typography variant="body1">Subscription title: {props.subscription.title}</Typography>
					<Typography variant="body1">Subscription unlimited: {props.subscription.unlimited ? "✔" : "❌"}</Typography>
				</Grid>

				<Grid item xs={12}>
					<FormControlLabel control={<Switch checked={props.experimentA} onChange={props.onToggleExperimentA} color="primary"/>} label="Experiment A enabled"/>
				</Grid>

			</Grid>
		</Container>
	);
}
