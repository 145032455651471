import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { FormattedMessage, useIntl } from 'react-intl';
import florian from '../assets/misc-images/florian-avatar.120.jpg';
import Avatar from '@material-ui/core/Avatar';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
	description: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(-1),
	},
	container: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	question: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(-1),
	},
	heading: {
		marginBottom: theme.spacing(1),
	},
	skip: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(4),
		textAlign: "right",
	},
	avatar: {
		width: "120px",
		height: "120px",
		marginRight: theme.spacing(2),
		float: "left",

	}
}));



export default function OnboardingInterview(props: {
	onSkip(): void,
	showPhoneNumber: boolean,
}) {
	const classes = useStyles();
	const { locale } = useIntl();

	return (
		<React.Fragment>
			<Container maxWidth="xs">
				<Box className={classes.description}>
					<Typography variant="h4" color="inherit" className={classes.heading}><FormattedMessage id="onboarding.interview.title" defaultMessage="6 Months Focality Pro for Free"/></Typography>
					<Typography variant="h6" gutterBottom paragraph color="inherit"><FormattedMessage id="onboarding.interview.subtitle" defaultMessage="Share your first impressions in a short call and get access to all features."/></Typography>
					{/* <Typography variant="h6" gutterBottom color="inherit">Help to improve Focality by telling us your thoughts.</Typography> */}
					<Avatar className={classes.avatar} src={florian}/>
						<Typography variant="body1">
							{/*  “<FormattedMessage id="onboarding.interview.quote" defaultMessage="Help me build the best product for you"/>” */}
							<FormattedMessage id="onboarding.interview.quote" defaultMessage="Help me build the best product for you!"/>
						</Typography>
						<Typography variant="caption">
							― <FormattedMessage id="onboarding.interview.founder" defaultMessage="Florian, founder of Focality"/>
						</Typography>
					<div style={{clear: "both", marginBottom: "0px"}}></div>
					{/* <Typography variant="body1" color="inherit">You are one of the first users, so it would be extremely helpful to hear your first impressions first hand.</Typography> */}

					<ul style={{paddingLeft: 20}}>
						<li><FormattedMessage id="onboarding.interview.bullet1" defaultMessage="Tell me a bit about your expectations."/></li>
						<li><FormattedMessage id="onboarding.interview.bullet2" defaultMessage="Learn how to get the most out of Focality."/></li>
						<li><FormattedMessage id="onboarding.interview.bullet3" defaultMessage="Get access to all features for 6 months for free: Unlimited goals, unlimited insights."/></li>
					</ul>

					<Button variant="contained" color="secondary" style={{color: "white", marginTop: 10, marginBottom: 10}} href={locale === "de" ? "https://www.focalityapp.com/de/termin-vereinbaren/" : "https://www.focalityapp.com/en/schedule-meeting/"} target="_blank" data-analytics-id="OnboardingInterview-schedule"><FormattedMessage id="onboarding.interview.cta" defaultMessage="Schedule Call"/></Button>
					{props.showPhoneNumber && <Typography variant="body1"><FormattedMessage id="onboarding.interview.call" defaultMessage="Or try calling me directly:"/><br/> <Link href="tel:+4976145875709" data-analytics-id="OnboardingInterview-phone">+49 (0) 761 / 458 757 09</Link></Typography>}
				</Box>
				<Box className={classes.question}>
					<Typography variant="body2" color="inherit" gutterBottom><FormattedMessage id="onboarding.interview.explanation" defaultMessage="Why? I want to experience and understand your first impressions. This way I'll know where to improve the onboarding process and what to focus on so that Focality meets and exceeds your expectations."/></Typography>
				</Box>
				<Box className={classes.skip}>
					<Button onClick={props.onSkip} variant="contained" data-analytics-id="OnboardingInterview-skip"><FormattedMessage id="onboarding.interview.skip" defaultMessage="Skip"/><KeyboardArrowRight /></Button>
				</Box>
			</Container>
		</React.Fragment>
	);
}
