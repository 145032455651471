import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { register as registerServiceWorker } from './registerServiceWorker';
import { BrowserRouter as Router, Route, Switch, withRouter } from "react-router-dom";

import { IntlProvider } from 'react-intl';
import 'intl-pluralrules';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/de'; // Add locale data for de
import localeData from './locales/data.json';
import { Plugins } from '@capacitor/core';

import './services/padStart';

import { ThemeProvider } from '@material-ui/styles';
import purposeTheme from './theme-bluegrey-red';
import { SnackbarProvider } from 'notistack';
import * as Sentry from '@sentry/browser';
import AuthController from './components/AuthController';
import { getUrlParameter } from './services/getUrlParameter';
import SignUpConfirmationController from './components/SignUpConfirmationController';
import { negotiateLanguages } from '@fluent/langneg';
import logger from './services/logger';
import ErrorBoundary from './components/ErrorBoundary';
import SettingsProvider from './components/SettingsProvider';
import Amplify from 'aws-amplify';
import aws_config from './aws-exports';
import RemoteConfigProvider from './components/RemoteConfigProvider';
import RemoteConfigContext from './components/RemoteConfigContext';
import UpdateCheckController from './components/UpdateCheckController';
import DeepLinkListener from './components/DeepLinkListener';
import Purchases from 'cordova-plugin-purchases/www/plugin';
import LoadingViewController from './components/LoadingViewController';
const buildMeta = require('./build-metadata.json');
require('promise.prototype.finally').shim();

if (process.env.REACT_APP_OAUTH_REDIRECT_SIGNIN)
	aws_config.oauth.redirectSignIn=process.env.REACT_APP_OAUTH_REDIRECT_SIGNIN;
if (process.env.REACT_APP_OAUTH_REDIRECT_SIGNOUT)
	aws_config.oauth.redirectSignOut=process.env.REACT_APP_OAUTH_REDIRECT_SIGNOUT;

const urlOpener = async (url: string, redirectUrl: unknown) => {
	logger.debug("Amplify called urlOpener", url, redirectUrl);
	const { Browser } = Plugins;

	return Browser.open({ url: url, windowName: "_self" });
}
//@ts-ignore
aws_config.oauth.urlOpener = urlOpener;

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN === "null" ? undefined : process.env.REACT_APP_SENTRY_DSN,
	release: 'Focality, build '+buildMeta.number+', '+buildMeta.time,
	beforeBreadcrumb(breadcrumb, hint) {
		// Add custom attributes to breadcrumb message if available
		if (breadcrumb.category === 'ui.click' && hint && hint.event) {
			const { target } = hint.event;
			// logger.debug("Breadcrumb captured", breadcrumb, hint);
			["data-analytics-id", "data-onboarding-markers"].forEach(key => {
				const value = target.getAttribute(key);
				if (value) {
					breadcrumb.message = breadcrumb.message + "\n" + key + ": " + value;
				} else {
					const ancestor = target.closest("["+key+"]");
					if (ancestor) {
						const ancestorValue = ancestor.getAttribute(key);
						breadcrumb.message = breadcrumb.message + "\nAncestor[" + key + "]: " + ancestorValue;
					}
				}
			});
			// logger.debug("New breadcrumb message", breadcrumb);
		}
		return breadcrumb;
	},
});

// const purposeReducer = combineReducers({
// 	goals: goalReducer,
// 	tasklists: tasklistsReducer,
// 	tasks: tasksReducer,
// 	hydration: hydrationReducer
// });

// console.log(offlineConfig);
// offlineConfig.effect = effect;

// let store = (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__)
// 	? createStore(purposeReducer, compose(offline(offlineConfig), window.__REDUX_DEVTOOLS_EXTENSION__()))
// 	: createStore(purposeReducer, offline(offlineConfig));

export type Platform = "ios" | "android" | "electron" | "web" | null;

export type Environment = {
	awsEnvironment: string,
	jsBuildNumber: number,
	jsBuildTime: string,
	platform: Platform,
	appVersion: string | null,
	envString: string,
}

interface IntlWrapperState {
	ready: boolean,
	language: "de" | "en",
	platform: Platform,
	appVersion: string | null,
}

interface IntlWrapperProps {

}

class IntlWrapper extends React.Component<IntlWrapperProps, IntlWrapperState> {

	constructor(props: IntlWrapperProps) {
		super(props);
		this.state = {
				ready: false,
				language: 'de',
				platform: null,
				appVersion: null,
		}
	}

	componentDidMount() {
		const lp = Plugins.Device.getLanguageCode();
		const dp = Plugins.Device.getInfo();
		Promise.all([lp, dp]).then(([languageCode, deviceInfo]) => {
			const supported = negotiateLanguages([languageCode.value], ["en", "de"], {
				strategy: 'lookup',
				defaultLocale: 'en',
			});
			const selectedLanguage = supported[0] === "de" ? "de" : "en";
			logger.info('Setting language to '+selectedLanguage);

			// Finalize amplify setup
			if (deviceInfo.platform !== "web") {
				aws_config.oauth.redirectSignIn = "focality://localhost/";
				aws_config.oauth.redirectSignOut = "focality://localhost/";
			}
			Amplify.configure(aws_config);

			this.setState({
				language: selectedLanguage,
				platform: deviceInfo.platform,
				appVersion: deviceInfo.appVersion,
				ready: true,
			});
		});
	}

	getEnvironment() {
		const awsEnvironmentMap: {[key: string]: string} = {
			"eu-central-1_VrOS1ZVIz": "dev",
			"eu-central-1_4vDHOsNFc": "prod",
		}
		const awsEnvironment = awsEnvironmentMap[aws_config.aws_user_pools_id];
		const environment: Environment = {
			awsEnvironment: awsEnvironment,
			jsBuildNumber: buildMeta.number,
			jsBuildTime: buildMeta.time,
			platform: this.state.platform,
			appVersion: this.state.appVersion,
			envString: awsEnvironment + '-' + buildMeta.number + '/' + buildMeta.time + '-' + this.state.platform+':'+this.state.appVersion,
		}
		return environment;
	}

	static contextType = RemoteConfigContext;

	render() {
		const messages: {[key: string]: string} = localeData[this.state.language] || localeData.en;
		const environment = this.getEnvironment();
		logger.debug('Environment', environment.envString, environment);

		return (
			<IntlProvider locale={this.state.language} messages={messages}>
				{!this.state.ready && <LoadingViewController what="locale and device"/>}
				{this.state.ready &&
					<UpdateCheckController environment={environment}>
						<Switch>
							<Route exact path="/confirmSignup" render={(props) => {
								const confirmationCode = getUrlParameter("code");
								const username = getUrlParameter("username");						
								return <SignUpConfirmationController username={username} confirmationCode={confirmationCode} environment={environment} onGoHome={() => props.history.push('/')}/>
							}}/>
							<Route>
								<AuthController environment={environment}/>
							</Route>
						</Switch>
					</UpdateCheckController>
				}
			</IntlProvider>
		);
	}
}

//@ts-ignore
const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
	useEffect(() => {
	  window.scrollTo(0, 0);
	}, [pathname]);
	return children || null;
});

ReactDOM.render(
	// <Provider store={store}>
	<ErrorBoundary>
		<Router>
			<DeepLinkListener>
				<ScrollToTop>
					<ThemeProvider theme={purposeTheme}>
						<SnackbarProvider>
							<RemoteConfigProvider>
								<SettingsProvider>
									<IntlWrapper/>
								</SettingsProvider>
							</RemoteConfigProvider>
						</SnackbarProvider>
					</ThemeProvider>
				</ScrollToTop>
			</DeepLinkListener>
		</Router>
	</ErrorBoundary>
	// </Provider>
	,
	document.getElementById('root')
);

Plugins.Device.getInfo().then(deviceInfo => {
	console.log("Device:", deviceInfo);
	if (deviceInfo.platform === "web")
		registerServiceWorker();

	// Initialize RevenueCat's Purchases SDK
	if (deviceInfo.platform === "android" || deviceInfo.platform === "ios") {
		Purchases.setDebugLogsEnabled(true);
		Purchases.setup("iqzAvQLnmLWAmzBGdhEeehlRAZdDcnTT");
	}
});

Plugins.SplashScreen.hide(); // todo: Hide when data is loaded

// Plugins.App.addListener("appStateChange", state => {
// 	console.log('appStateChange: ', state);
// 	Plugins.Toast.show({text: 'appStateChange: '+JSON.stringify(state), duration: "long", durationType: "long"});
// }); 
