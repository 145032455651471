import logger from './logger';
import { getISOWeek, parseISO, endOfMonth, endOfWeek, endOfDay, subDays, subWeeks, subMonths, subYears, addDays, addWeeks, addMonths, addYears, endOfYear, startOfMonth, startOfYear, startOfWeek, startOfDay } from 'date-fns';
import { Objective } from './objective-helpers';
import { List } from './list-helpers';
import t from 'tcomb-validation';
import { StringNonEmpty, StringOrNull, Null, dayPeriod, weekPeriod, monthPeriod, yearPeriod } from './validation-types';
import * as Sentry from '@sentry/browser';
import { createPlan as createPlanMutation } from '../graphql/mutations';
import gql from 'graphql-tag';
import uuidv4 from 'uuid';
import { listPlans as listPlansQuery } from '../graphql/queriesCustom';


type ObjectiveId = {
	id: string,
}

export enum PlanType {
	day = "day",
	week = "week",
	month = "month",
	year = "year",
}

export const TTPlanType = t.enums.of('day week month year');

const TTPlanPeriod = t.union([dayPeriod, weekPeriod, monthPeriod, yearPeriod]);

export type Plan = {
	period: string,
	id: string,
	type: PlanType,
	complete?: boolean,
	periodSatisfactory: boolean | null,
	planSatisfactory: boolean | null,
	insights: string | null,
	goodThings: string | null,
	badThings: string | null,
	// getObjectives: {
	// 	items: Array<ObjectiveId>
	// },
	version: number,
	state: PlanState,
}

export enum PlanState {
	default = "default",
	virtual = "virtual",
	creating = "creating",
}

export type PlanUnsaved = {
	period: string,
	type: PlanType,
	complete: boolean,
	periodSatisfactory: boolean | null,
	planSatisfactory: boolean | null,
	insights: string | null,
	goodThings: string | null,
	badThings: string | null,
	state?: PlanState, // todo: Make state non-optional
}

type PlanRemote = {
	period: string,
	id: string,
	type: PlanType,
	complete?: boolean | undefined,
	periodSatisfactory: boolean | null,
	planSatisfactory: boolean | null,
	insights: string | null,
	goodThings: string | null,
	badThings: string | null,
	getObjectives: {
		items: Array<ObjectiveId>
	},
	version: number,
}

const TTPlanRemote = t.struct({
	period: TTPlanPeriod,
	id: StringNonEmpty,
	type: TTPlanType,
	complete: t.union([t.Boolean, t.Nil]),
	periodSatisfactory: t.union([t.Boolean, Null]),
	planSatisfactory: t.union([t.Boolean, Null]),
	insights: StringOrNull,
	goodThings: StringOrNull,
	badThings: StringOrNull,
	getObjectives: t.struct({
		items: t.list(t.struct({
			id: StringNonEmpty
		}))
	}),
	version: t.Number,
});

const TTPlan = t.struct({
	period: TTPlanPeriod,
	id: StringNonEmpty,
	type: TTPlanType,
	complete: t.union([t.Boolean, t.Nil]),
	periodSatisfactory: t.union([t.Boolean, Null]),
	planSatisfactory: t.union([t.Boolean, Null]),
	insights: StringOrNull,
	goodThings: StringOrNull,
	badThings: StringOrNull,
	version: t.Number,
	state: t.String,
});

const TTPlanUnsaved = t.struct({
	period: TTPlanPeriod,
	type: TTPlanType,
	complete: t.Boolean,
	periodSatisfactory: t.union([t.Boolean, Null]),
	planSatisfactory: t.union([t.Boolean, Null]),
	insights: StringOrNull,
	goodThings: StringOrNull,
	badThings: StringOrNull,
});

// type PlanWithSubs = Plan & { subordinatePlans: Array<PlanWithSubs>, objectives: Array<unknown> }
export type PlanWithSubs = {
	period: string,
	// id: string,
	type: PlanType,
	// insights?: string,
	// goodThings?: string,
	// badThings?: string,
	// getObjectives: {
	// 	items: Array<ObjectiveId>
	// }
	subordinatePlans: Array<PlanWithSubs>, 
	objectives: Array<Objective>,
}
type PlanWithObjectives = Plan & { objectives: Array<Objective> }

export type PlanStub = {
	period: string,
	type: PlanType
}

const TTPlanStub = t.struct({
	period: TTPlanPeriod,
	type: TTPlanType,
});

export type Streak = {
	plans: Array<Plan>,
	firstPlan: Plan,
	lastPlan: Plan,
	length: number,
}

function validatePlanTypePeriod(plan: Plan | PlanUnsaved | PlanRemote) {
	switch (plan.type) {
		case PlanType.day:
			return t.validate(plan.period, dayPeriod).isValid();
		case PlanType.week:
			return t.validate(plan.period, weekPeriod).isValid();
		case PlanType.month:
			return t.validate(plan.period, monthPeriod).isValid();
		case PlanType.year:
			return t.validate(plan.period, yearPeriod).isValid();
		default:
			throw new Error("Invalid plan type");
	}
}

export function planIsValid(plan: Plan) {
	const r = t.validate(plan, TTPlan, {strict: true});
	if (!r.isValid() || !validatePlanTypePeriod(plan)) {
		console.log(r.errors);
		return false;
	} else {
		return true;
	}
}

export function remotePlanIsValid(plan: PlanRemote) {
	const r = t.validate(plan, TTPlanRemote, {strict: true});
	if (!r.isValid() || !validatePlanTypePeriod(plan)) {
		return false;
	} else {
		return true;
	}
}

export function planStubIsValid(plan: Plan) {
	const r = t.validate(plan, TTPlanStub, {strict: true});
	if (!r.isValid() || !validatePlanTypePeriod(plan)) {
		return false;
	} else {
		return true;
	}
}

export function remotePlanValidationErrors(plan: PlanRemote) {
	return t.validate(plan, TTPlanRemote, {strict: true}).errors;
}

export function dateToPeriod(date: Date, type: PlanType) {
	switch (type) {
		case "year":
			return `${date.getFullYear()}`;
		case "month":
			return `${date.getFullYear()}-${(date.getMonth()+1).toString().padStart(2, '0')}`;
		case "week":
			const week = getISOWeek(date).toString().padStart(2, '0');
			if (week === '01' && date.getMonth() === 11) {
				return `${date.getFullYear()+1}-W${week}`;
			} else if ((week === '52' || week === '53') && date.getMonth() === 0) {
				return `${date.getFullYear()-1}-W${week}`;
			} else {
				return `${date.getFullYear()}-W${week}`;
			}
		case "day":
			return `${date.getFullYear()}-${(date.getMonth()+1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
		default:
			throw new Error('Unknown plan type: '+type);
	}
}

/**
 * Return all periods of a given type within a given timeframe
 * @param type 
 * @param from 
 * @param to 
 */
export function getPeriodsByTime(type: PlanType, from: Date, to: Date) {
	if (from > to) {
		logger.error("invalid range while getting periods for time range", type, from, to);
		throw new Error("invalid range while getting periods for time range");
	}
	const periods = [];
	let pStart = from;
	let p = dateToPeriod(from, type);
	let stub = {type: type, period: p};
	let pEnd = getEnd(stub);
	const toNormalized = endOfDay(to);
	// console.log(pStart, toNormalized);
	while (pStart <= toNormalized) {
		periods.push(p);
		pStart = new Date(pEnd.getTime() + 86400000);
		//pStart = addDays(pEnd, 1);
		// console.log(pStart);
		p = dateToPeriod(pStart, type);
		stub.period = p;
		pEnd = getEnd(stub);
	}
	return periods;
}

export function getOverarchingPlanStub(currentPlan: Plan | PlanUnsaved | PlanStub, level?: PlanType): PlanStub {
	const currentPeriodDate = parseISO(currentPlan.period);
	const types = ["day", "week", "month", "year"];
	var oaType = level;
	if (!oaType) {
		const oaTypeIdx = types.indexOf(currentPlan.type);
		if (oaTypeIdx === -1)
			throw new Error("Unknown type "+currentPlan.type);
		if (oaTypeIdx > 2)
			throw new Error("Already at highest level");
		const typestring = types[types.indexOf(currentPlan.type)+1];
		oaType = PlanType[typestring as keyof typeof PlanType];
	}
	const oaPeriod = dateToPeriod(currentPeriodDate, oaType);
	return {
		type: oaType,
		period: oaPeriod,
	}
}

/**
 * Find all plans that are subordinate to a given one. All days within a week. All days and weeks within a month, ...
 * @param currentPlan Plan | PlanStub
 */
export function getSubordinatePlanStubs(currentPlan: Plan | PlanStub): PlanStub[] {
	const types: PlanType[] = [PlanType.day, PlanType.week, PlanType.month, PlanType.year];
	const plans: PlanStub[] = [];
	const start = getBeginning(currentPlan);
	const end = getEnd(currentPlan);
	let currentLevel = types.indexOf(currentPlan.type);
	while (currentLevel > 0) {
		const levelBelow = currentLevel - 1;
		const typeBelow = types[levelBelow];
		const subPeriods = getPeriodsByTime(typeBelow, start, end);
		const subStubs: PlanStub[] = subPeriods.map(period => ({period: period, type: typeBelow}));
		plans.push(...subStubs);
		currentLevel = levelBelow;
	}
	return plans;
}

export function getPreviousPeriod(period: string, type: PlanType): string {
	const periodDate = parseISO(period);
	switch (type) {
		case "day":
			return dateToPeriod(subDays(periodDate, 1), type);
		case "week":
			return dateToPeriod(subWeeks(periodDate, 1), type);
		case "month":
			return dateToPeriod(subMonths(periodDate, 1), type);
		case "year":
			return dateToPeriod(subYears(periodDate, 1), type);
		default:
				throw new Error('Unknown plan type: '+type);		
	}
}

export function getNextPeriod(period: string, type: PlanType): string {
	const periodDate = parseISO(period);
	switch (type) {
		case "day":
			return dateToPeriod(addDays(periodDate, 1), type);
		case "week":
			return dateToPeriod(addWeeks(periodDate, 1), type);
		case "month":
			return dateToPeriod(addMonths(periodDate, 1), type);
		case "year":
			return dateToPeriod(addYears(periodDate, 1), type);
		default:
				throw new Error('Unknown plan type: '+type);		
	}
}

export function getPreviousPlanStub(currentPlan: Plan | PlanUnsaved): PlanStub {
	const stub: PlanStub = {
		type: currentPlan.type,
		period: getPreviousPeriod(currentPlan.period, currentPlan.type),
	};
	return stub;
}

export function isBefore(plan: Plan | PlanUnsaved | PlanStub, date: Date) {
	const end = getEnd(plan);
	return date > end;
}

// Does a date fall within the time range of a plan?
// export function includesDate(plan: Plan | PlanUnsaved | PlanStub, date: Date) {
// 	const start = getBeginning(plan);
// 	const end = getEnd(plan);
// 	return date > end;
// }

export function getEnd(plan: Plan | PlanUnsaved | PlanStub) {
	switch (plan.type) {
		case "month":
			return endOfMonth(parseISO(plan.period));
		case "year":
			return endOfYear(parseISO(plan.period));
		case "week":
			return endOfWeek(parseISO(plan.period), {weekStartsOn: 1});
		case "day":
			return endOfDay(parseISO(plan.period)); // warning: do not use new Date(plan.period) here. Causes nightmare bugs related to timezones.
		default:
			throw new Error('Unknown plan type: '+plan.type);
	}
}

export function getBeginning(plan: Plan | PlanUnsaved | PlanStub) {
	switch (plan.type) {
		case "year":
			return startOfYear(parseISO(plan.period));
		case "month":
			return startOfMonth(parseISO(plan.period));
		case "week":
			return startOfWeek(parseISO(plan.period), {weekStartsOn: 1});
		case "day":
			return startOfDay(parseISO(plan.period));
		default:
			throw new Error('Unknown plan type: '+plan.type);
	}
}

export function isCurrentPlan(plan: Plan) {
	const now = new Date();
	return now > getBeginning(plan) && now < getEnd(plan);
}

export function deepObjectives(list: List | null, plans: Array<Plan>, objectives: Array<Objective>): Array<PlanWithSubs> {
	const listId = list ? list.id : null;
	const goalObjectives = objectives.filter(o => o.listId === listId);
	const hydratedPlans = plans
		.sort((a, b) => {
			const startA = getBeginning(a);
			const startB = getBeginning(b);
			if (startA > startB)
				return 1;
			else if (startB > startA)
				return -1;
			else
				return 0;
		})
		.map(plan => hydratePlan(plan, goalObjectives));
	const deep: Array<PlanWithSubs> = [];

	function getOrCreatePlan(plan: Plan | PlanStub | PlanWithSubs, allPlans: Array<Plan>): PlanWithSubs {
		if (plan.type === PlanType.year) {
			const year = deep.find(p => p.period === plan.period);
			if (year) {
				return year;
			} else {
				const insertablePlan = Object.assign({}, plan, {
					objectives: ("objectives" in plan) ? plan.objectives : [],
					subordinatePlans: ("subordinatePlans" in plan) ? plan.subordinatePlans : [],
				});	
				deep.push(insertablePlan);
				return insertablePlan;
			} 
		}
		const oa = getOverarchingPlanStub(plan);
		const parent = getOrCreatePlan(oa, allPlans);
		const existing = parent.subordinatePlans.find(p => p.period === plan.period);
		if (existing) {
			return existing;
		} else {
			const insertablePlan = Object.assign({}, plan, {
				objectives: ("objectives" in plan) ? plan.objectives : [],
				subordinatePlans: ("subordinatePlans" in plan) ? plan.subordinatePlans : [],
			});
			parent.subordinatePlans.push(insertablePlan);
			return insertablePlan;
		}
	}

	const hydratedPlansWithObjectives = hydratedPlans.filter(p => p.objectives.length > 0);
	hydratedPlansWithObjectives.forEach(p => {
		const tp = getOrCreatePlan(p, hydratedPlansWithObjectives);
		Object.assign(tp, p);
	});
	return deep;
}

export function hydratePlan(plan: Plan, objectives: Array<Objective>): PlanWithObjectives {
	return Object.assign({}, plan, {
		objectives: objectives.filter(o => o.planId === plan.id)
	});
}

// export function isReflected(plan) {
// 	if (['2019-05', '2019-06'].indexOf(plan.period) !== -1)
// 		return true;
// 	return false;
// }


export function getPlanTitle(plan: Plan | PlanUnsaved | PlanStub, locale: string) {
	const currentPeriodDate = parseISO(plan.period);
	switch (plan.type) {
		case "day":
			return currentPeriodDate.toLocaleString(locale, { day: 'numeric', month: 'long', year: 'numeric' });
		case "week":
			if (locale === "de-de")
				return `KW${getISOWeek(currentPeriodDate)} ${currentPeriodDate.getFullYear()}`
			else
				return `W${getISOWeek(currentPeriodDate)} ${currentPeriodDate.getFullYear()}`
		case "month":
			return currentPeriodDate.toLocaleString(locale, { month: 'long', year: 'numeric' });
		default:
			return currentPeriodDate.getFullYear().toString();
	}
}

export function getRemainingObjectives(plan: Plan, objectives: Array<Objective>): Array<Objective> {
	return objectives.filter(o => o.planId === plan.id && o.success !== true);
}

/**
 * Return objectives assigned to a given plan
 * @param plan 
 * @param allObjectives 
 */
export function getPlanObjectives(plan: Plan, allObjectives: Array<Objective>): Array<Objective> {
	const objectives = allObjectives.filter(o => o.planId === plan.id);
	return objectives;
}

function calculateStreaks(type: PlanType, allPlans: Array<Plan>, allObjectives: Array<Objective>, condition: (plan: Plan, allObjectives: Array<Objective>) => boolean): Array<Streak> {
	const typedPlans = allPlans.filter(p => p.type === type);
	const sortedPlans = typedPlans.sort((a,b) => a.period.localeCompare(b.period));
	if (sortedPlans.length === 0)
		return [];
	const streaks: Array<Streak> = [];
	let strk: { plans: Array<Plan>, length: number } = {
		plans: [],
		length: 0,
	}
	for (let i = 0; i < sortedPlans.length; i++) {
		const plan = sortedPlans[i];
		const previousStub = getPreviousPlanStub(plan);
		// if (plan.id === 'eec3795d-8cbb-43f6-9143-11a4f0341466') 
		// console.log('Checking plan '+plan.id+' ('+plan.period+'). Previous stub:', previousStub);
		if (condition(plan, allObjectives)
			&& (strk.plans.length <= 1 || strk.plans[strk.plans.length-1].period === previousStub.period)
			) {
			// if (plan.id === 'eec3795d-8cbb-43f6-9143-11a4f0341466') console.log('Adding plan to streak:');
			strk.plans.push(plan);
			strk.length++;
		} else {
			// if (plan.id === 'eec3795d-8cbb-43f6-9143-11a4f0341466') console.log('Plan failed wrapping up', condition(plan, allObjectives), (strk.plans.length <= 1), strk.plans[strk.plans.length-1].period === previousStub.period, strk.plans[strk.plans.length-1].period);
			if (strk.length > 0) {
				streaks.push({
					plans: strk.plans,
					length: strk.length,
					firstPlan: strk.plans[0],
					lastPlan: strk.plans[strk.plans.length-1],
				});
			}
			strk.plans = [];
			strk.length = 0;
		}
	}
	if (strk.length > 0) {
		// console.log('Adding last streak to collection.');
		streaks.push({
			plans: strk.plans,
			length: strk.length,
			firstPlan: strk.plans[0],
			lastPlan: strk.plans[strk.plans.length-1],
		});
	}
	return streaks.sort((a,b) => b.length - a.length);
}

export function calculateCompletionStreaks(type: PlanType, plans: Array<Plan>, objectives: Array<Objective>): Array<Streak> {
	const condition = (plan: Plan, objectives: Array<Objective>) => (
		planSuccessful(plan, objectives)
		&& planHasObjectives(plan, objectives)
	);
	return calculateStreaks(type, plans, objectives, condition);
}

export function calculatePlanReflectionCycleStreaks(type: PlanType, plans: Array<Plan>, objectives: Array<Objective>): Array<Streak> {
	const condition = (plan: Plan, objectives: Array<Objective>) => (
		planHasObjectives(plan, objectives)
		&& planReflected(plan)
	);
	return calculateStreaks(type, plans, objectives, condition);
}

export function calculateReflectionStreaks(type: PlanType, plans: Array<Plan>, objectives: Array<Objective>): Array<Streak> {
	const condition = (plan: Plan, objectives: Array<Objective>) => (
		planReflected(plan)
	);
	return calculateStreaks(type, plans, objectives, condition);
}

export function calculatePlanningStreaks(type: PlanType, allPlans: Array<Plan>, allObjectives: Array<Objective>): Array<Streak> {
	const condition = (plan: Plan, objectives: Array<Objective>) => (
		plan.complete === true
	);
	return calculateStreaks(type, allPlans, allObjectives, condition);
}

/**
 * Given a list of streaks, find the one that belongs to the given date.
 * 
 * @param allStreaks Array of all streaks
 * @param currentDate The reference date which should be included in the streak
 * @param flex Use the streak that ends on the last period if there is none for the current?
 */
export function findCurrentStreak(allStreaks: Array<Streak>, currentDate: Date, flex: boolean): Streak | undefined {
	if (allStreaks.length === 0)
		return undefined;
	const type = allStreaks[0].plans[0].type;
	const currentPeriod = dateToPeriod(currentDate, type);
	let currentStreak = allStreaks.find(s => s.plans.find(p => p.period === currentPeriod));
	if (!currentStreak && flex) {
		// if there's a streak that ends with the last plan, use that one
		const lastPeriod = getPreviousPeriod(currentPeriod, type);
		currentStreak = allStreaks.find(s => s.plans.find(p => p.period === lastPeriod));
	}
	return currentStreak;
}

function calculateRate(type: PlanType, range: number, start: Date, allPlans: Plan[], condition: (plan: Plan) => boolean) {
	let planningRateNumerator = 0;
	let planningRatePeriod = dateToPeriod(start, type);
	for (let i = 0; i < range; i++) {
		const p = getPlanByTypePeriod(type, planningRatePeriod, allPlans);
		if (p && condition(p))
			planningRateNumerator++;
		planningRatePeriod = getPreviousPeriod(planningRatePeriod, type);
	}
	return planningRateNumerator;
}

export function getPlanningRate(type: PlanType, range: number, start: Date, allPlans: Plan[]) {
	return calculateRate(type, range, start, allPlans, (plan: Plan) => plan.complete === true);
}

export function getReflectionRate(type: PlanType, range: number, start: Date, allPlans: Plan[]) {
	return calculateRate(type, range, start, allPlans, planReflected);
}

export function planSuccessful(plan: Plan, objectives: Array<Objective>) {
	const failedObjectives = objectives.filter(o => o.success !== true && !o.secondary && o.planId === plan.id);
	return failedObjectives.length === 0;
}

export function planReflected(plan: Plan | PlanUnsaved): boolean {
	return (
		plan.periodSatisfactory !== null
		&& plan.planSatisfactory !== null
		&& plan.goodThings !== null
		&& (plan.badThings !== null || plan.type === PlanType.day)
		&& (plan.insights !== null || plan.type === PlanType.day)
	);
}

export function planHasObjectives(plan: Plan, objectives: Array<Objective>) {
	return objectives.filter(o => o.planId === plan.id).length > 0;
}

export function getPlanEstimate(plan: Plan, objectives: Array<Objective>): number {
	const po = objectives.filter(o => o.planId === plan.id && !o.secondary && o.estimate);
	return po.reduce((acc, o) => acc+(o.estimate||0), 0.0);
}

export function findDuplicatePlans(plans: Array<Plan>): Array<Plan> {
	const r: Array<Plan> = [];
	plans.forEach(p => {
		if (plans.find(pp => pp.id !== p.id && pp.period === p.period))
			r.push(p);
	});
	return r;
}

export function loadPlans(items: any): Array<Plan> {
	const plans: Plan[] = items.map((p: PlanRemote) => {
		const newRemotePlan: PlanRemote = {
			id: p.id,
			period: p.period,
			type: p.type,
			complete: p.complete,
			planSatisfactory: p.planSatisfactory,
			periodSatisfactory: p.periodSatisfactory,
			insights: p.insights,
			goodThings: p.goodThings,
			badThings: p.badThings,
			getObjectives: {
				items: p.getObjectives.items.map((i) => ({id: i.id}))
			},
			version: p.version,
			// state: PlanState.default,
		}
		if (!remotePlanIsValid(newRemotePlan)) {
			logger.error('Loading invalid plan', remotePlanValidationErrors(newRemotePlan), newRemotePlan, p);
			Sentry.captureException(new Error('Loaded invalid plan'));
		}
		const newPlan = Object.assign({}, newRemotePlan, {
			state: PlanState.default,
		})
		delete newPlan.getObjectives;
		return newPlan;
	});
	return plans;
}


export async function createPlan(plan: PlanUnsaved | Plan, appSyncClient: any, retrieveItems: any) {
	logger.info('Creating plan...');
	if ("state" in plan && plan.state !== PlanState.virtual && plan.state !== PlanState.creating) { // todo: Gracefully handle "creating" case
		throw new Error("Cannot create plan: Already exists");
	}
	let problems = false;
	const newId = ("id" in plan && typeof plan.id === "string") ? plan.id : uuidv4();
	const newPlanInput = {
		id: newId,
		type: plan.type,
		complete: plan.complete,
		period: plan.period,
		periodSatisfactory: plan.periodSatisfactory,
		planSatisfactory: plan.planSatisfactory,
		goodThings: plan.goodThings,
		badThings: plan.badThings,
		insights: plan.insights,
	}
	logger.debug('newPlanInput:', newPlanInput);
	if (!newPlanInput.type || !newPlanInput.period)
		throw new Error("New plan is missing type or period");
	const remoteItems = await retrieveItems(appSyncClient, listPlansQuery, "listPlans");
	const remotePlans = loadPlans(remoteItems);
	logger.debug('Remote plans:', remotePlans.length, remotePlans);
	logger.debug('Comparing against: "'+newPlanInput.period+'"', newPlanInput.period, typeof newPlanInput.period);
	const remotePlan = remotePlans.find(p => p.period === newPlanInput.period);
	logger.debug('Remote plan:', remotePlan);
	if (remotePlan) {
		// Plan for current period already got created by another client. Synch...
		logger.info('Remote plan for requested period already exists. Using the remote one.');
		return {
			plan: remotePlan,
			plans: remotePlans,
			problems: problems,
		}
	} else {
		logger.debug('Plan does not exist on server. Creating...');
	}
	try {
		await appSyncClient.mutate({
			mutation: gql(createPlanMutation),
			variables: {
				input: newPlanInput
			}
		});
	} catch(err) {
		logger.error('Create plan mutation failed:', err);
		problems = true;
		Sentry.captureException(err);
	};
	const newPlan: Plan = Object.assign({}, newPlanInput, {
		version: 1, // needed for future mutations
		state: PlanState.default,
	});
	logger.debug('newPlan:', newPlan);
	remotePlans.push(newPlan);
	return {
		plan: newPlan,
		plans: remotePlans,
		problems: problems,
	}
}

export function isPlan(plan: any): plan is Plan {
	// return typeof plan.id !== "undefined" && typeof plan.version !== "undefined";
	const r = t.validate(plan, TTPlan, {strict: true});
	if (!r.isValid()) {
		// logger.debug("Plan not valid", plan, r.errors);
		return false;
	} else if (!validatePlanTypePeriod(plan)) {
		// logger.debug("PlanTypePeriod not valid", plan);
		return false;
	} else {
		return true;
	}
}

export function isPlanUnsaved(plan: any): plan is PlanUnsaved {
	const r = t.validate(plan, TTPlanUnsaved, {strict: true});
	if (!r.isValid() || !validatePlanTypePeriod(plan)) {
		return false;
	} else {
		return true;
	}
}

export function findPlanById(id: string, allPlans: Plan[]) {
	return allPlans.find((plan: Plan) => plan.id === id);
}

/**
 * Return a plan object for a given type and period. If no matching plan exists, a virtual one is returned (but not added to state!).
 * @param type 
 * @param period 
 * @param allPlans 
 */
export function getOrCreatePlan(type: PlanType, period: string, allPlans: Plan[]) {
	const plan = allPlans.find((plan: Plan) => plan.type === type && plan.period === period);
	if (plan)
		return plan;
	else {
		const planUnsaved: Plan = {
			id: uuidv4(),
			type: type,
			complete: false,
			period: period,
			periodSatisfactory: null,
			planSatisfactory: null,
			goodThings: null,
			badThings: null,
			insights: null,
			state: PlanState.virtual,
			version: 1,
		};
		if (!planIsValid(planUnsaved)) {
			throw new Error("Unsaved plan is not valid (probably invalid type/period)");
		}
		return planUnsaved;
	}
}

export function createVirtualPlan(type: PlanType, period: string) {
	const planUnsaved: Plan = {
		id: uuidv4(),
		type: type,
		period: period,
		complete: false,
		periodSatisfactory: null,
		planSatisfactory: null,
		goodThings: null,
		badThings: null,
		insights: null,
		state: PlanState.virtual,
		version: 1,
	};
	if (!planIsValid(planUnsaved)) {
		throw new Error("Unsaved plan is not valid (probably invalid type/period)");
	}
	return planUnsaved;
}

// export function getPlanByStub(stub: PlanStub, allPlans: Plan[]) {
// 	return getPlanByTypePeriod(stub.type, stub.period, allPlans);
// }

export function getPlanByTypePeriod(type: PlanType, period: string, allPlans: Plan[]) {
	return allPlans.find(p => p.type === type && p.period === period);
}

export type RatingsData = {
		type: PlanType;
		period: string;
		planSatisfactory: boolean | null;
		planSatisfactoryMA: null;
		periodSatisfactory: boolean | null;
		periodSatisfactoryMA: null;
}[];

export function getRatingsData(allPlans: Plan[], type: PlanType): RatingsData {
	/*
	- get all plans
	- filter for correct type
	- filter for existing rating
	- order by period
	- fill in the gaps
	- create numbers array (one each for planrating / periodrating)
	- calculate moving average
	- insert averages into original array
	*/
	let ratedPlans = allPlans
		.filter(p => 
			p.type === type &&
			(p.planSatisfactory !== null || p.periodSatisfactory !== null)
		)
		.sort((a,b) => a.period.localeCompare(b.period))
	;

	const getFilledPlans = (plans: Plan[]) => {
		if (plans.length === 0)
			return [];
		const firstPlan = plans[0];
		const lastPlan = plans[plans.length-1];
		const periods = getPeriodsByTime(type, getBeginning(firstPlan), getBeginning(lastPlan));
		return periods.map(period => {
			const plan = plans.find(pl => pl.period === period);
			const data = {
				type: type,
				period: period,
				planSatisfactory: plan ? plan.planSatisfactory : null,
				planSatisfactoryMA: null,
				periodSatisfactory: plan ? plan.periodSatisfactory : null,
				periodSatisfactoryMA: null,
			}
			return data;
		});
	}
	let ratingsData = getFilledPlans(ratedPlans);
	// ratingsData = ratingsData.slice(ratingsData.length - 14);
	// logger.debug("filled plans:", ratingsData);

	const fillingMA = (data: Array<number|null>, window: number) => {
		let r = [];
		let buffer: number[] = [];
		let lastAverage: number = 0;
		for (let i = 0; i < data.length; i++) {
			const current = data[i];
			if (current !== null)
				buffer.push(current);
			else
				buffer.push(lastAverage);
			
			if (buffer.length > window) {
				buffer.shift();
			}

			if (current === null) {
				r.push(lastAverage);
			} else {
				const currentAverage = buffer.reduce((a, b) => (a + b)) / buffer.length;
				lastAverage = currentAverage;
				r.push(currentAverage);
			}
		}
		return r;
	}

	const ratingsMapper = (rating: boolean|null) => {
		if (rating === null) {
			return null;
		} else if (rating === true) {
			return 100;
		} else {
			return 0;
		}
	};

	const planRatings = ratingsData.map(p => ratingsMapper(p.planSatisfactory));
	const planRatingsAveraged = fillingMA(planRatings, 7);
	const periodRatings = ratingsData.map(p => ratingsMapper(p.periodSatisfactory));
	const periodRatingsAveraged = fillingMA(periodRatings, 4);

	const ratingsDataWithAverages = ratingsData.map((data, index) => (Object.assign({}, data, {
		planSatisfactoryMA: planRatingsAveraged[index],
		periodSatisfactoryMA: periodRatingsAveraged[index],
	})));
	return ratingsDataWithAverages;
}
