import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import { defineMessages } from 'react-intl';

import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import { Container, Grid } from '@material-ui/core';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
// import SubscriptionStatusTrial from './SubscriptionStatusTrial';
// import SubscriptionStatusPaid from './SubscriptionStatusPaid';

const useStyles = makeStyles(theme => ({
	paper: {
		// marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(8),
	}
}));

const messages = defineMessages({
	tosurl: {
		id: 'urls.terms-of-service',
		defaultMessage: 'https://www.focalityapp.com/en/terms-of-service/',
	},
	ppurl: {
		id: 'urls.privacy-policy',
		defaultMessage: 'https://www.focalityapp.com/en/privacy-policy/',
	},
	contacturl: {
		id: 'urls.contact',
		defaultMessage: 'https://www.focalityapp.com/en/contact/',
	},
});

function AccountView(props) {

	const classes = useStyles();
	const { formatMessage } = useIntl();

	const tosurl = formatMessage(messages.tosurl);
	const ppurl = formatMessage(messages.ppurl);
	// const contacturl = formatMessage(messages.contacturl);

	return (
		<Container maxWidth="xs" className={classes.container}>
			<Grid container spacing={4}>
				<Grid item xs={2}>
					<Avatar className={classes.avatar}>
						<PersonIcon />
					</Avatar>
				</Grid>
				<Grid item xs={8}>
					<Typography component="h1" variant="h5">
						<FormattedMessage id="AccountView.signedin" defaultMessage="Signed in as"/>
					</Typography>
					<Typography variant="body1">{props.email}</Typography>
				</Grid>
				{/* <Grid item xs={2}>
					<Button>Sign out</Button>
				</Grid> */}

				<Grid item xs={12}>
					{/* <SubscriptionStatusTrial onUpgrade={props.onUpgradeSubscription} expirationDate={props.subscription.expirationDate}/> */}
					{props.subscriptionStatus}
				</Grid>

				<Grid item xs={12}>
					<FormControlLabel control={<Switch checked={props.notificationsEnabled} onChange={props.toggleNotificationsEnabled} color="primary"/>} label={<FormattedMessage id="AccountView.enableNotifications" defaultMessage="Enable notifications (reflection reminder, mid-day checkup)"/>}/>
				</Grid>

				<Grid item xs={12}>
					<List component="nav" aria-label="Secondary mailbox folders">
						<ListItem button onClick={props.onHelp}>
							<ListItemText primary={<FormattedMessage id="AccountView.contact" defaultMessage="Support &amp; Contact"/>} />
						</ListItem>
						<Divider component="li" />
						<ListItem button onClick={props.onChangePassword}>
							<ListItemText primary={<FormattedMessage id="AccountView.changepassword" defaultMessage="Change password"/>} />
						</ListItem>
						<Divider component="li" />
						<ListItem button onClick={props.onSignOut}>
							<ListItemText primary={<FormattedMessage id="AccountView.signout" defaultMessage="Sign out"/>} />
						</ListItem>
						<Divider component="li" />
						{/* <ListItem button>
							<ListItemText primary="Change email" />
						</ListItem> */}
					</List>
				</Grid>

				<Grid item xs={12}>
					<Link href={ppurl} target="_blank" rel="noopener"><FormattedMessage id="AccountView.pp" defaultMessage="Privacy Policy"/></Link>&nbsp;·&nbsp;<Link href={tosurl} target="_blank" rel="noopener"><FormattedMessage id="AccountView.tos" defaultMessage="Terms of Service"/></Link>
				</Grid>

				<Grid item xs={12}>
					<Typography variant="caption" color="textSecondary" onClick={props.onDebugClick}>Build {props.environment.jsBuildNumber}, {props.environment.jsBuildTime}, {props.environment.awsEnvironment}, {props.environment.platform}.</Typography>
				</Grid>

			</Grid>
		</Container>
	);
}

AccountView.propTypes = {
	onSignOut: PropTypes.func.isRequired,
	onChangePassword: PropTypes.func.isRequired,
	onDebugClick: PropTypes.func,
	onHelp: PropTypes.func.isRequired,
	notificationsEnabled: PropTypes.bool.isRequired,
	toggleNotificationsEnabled: PropTypes.func.isRequired,
	email: PropTypes.string.isRequired,
	environment: PropTypes.object.isRequired,
	subscription: PropTypes.object.isRequired,
	onUpgradeSubscription: PropTypes.func.isRequired,
	subscriptionStatus: PropTypes.element.isRequired,
};

export default AccountView;