import React, { useContext, useState } from 'react';
// import logger from '../services/logger.js';
import { Plan, PlanStub, PlanUnsaved, findPlanById } from '../services/plan-helpers';
import { List } from '../services/list-helpers';
import { isOverdue, Objective, objectivesByList } from '../services/objective-helpers';
import { findRecurrerById, Recurrer, Recurring } from '../services/recurring-helpers';
import GoalDetailViewV2 from './GoalDetailViewV2';
import SchedulerDialogController from './SchedulerDialogController';
import ObjectiveAdderV2B from './ObjectiveAdderV2B';
import ObjectiveEditorController from './ObjectiveEditorController';
import { Drawer } from '@material-ui/core';
import SettingsContext from './SettingsContext';

interface Props {
	goal: List | null,
	objectives: Array<Objective>,
	plans: Array<Plan>,
	allRecurrers: Array<Recurrer>,
	onBack(): void,
	onDelete(goal: List): void,
	onSetArchived(goal: List, archived: boolean): void,
	onSetFocus(goal: List, focus: boolean): void,
	onEdit(): void,
	onSetPlan(objective: Objective, plan: PlanStub | null): void,
	onSaveObjective(objective: Objective, recurring: Recurring | null): void,
	onCheckObjective(objective: Objective): void,
	onFailObjective(objective: Objective): void,
	onUncheckObjective(objective: Objective): void,
	onDeleteObjective(objective: Objective): void,
	onDeleteRecurrerWithInstances(recurrer: Recurrer): void,
	onDeleteRecurrerCurrentAndFollowing(recurrer: Recurrer, objective: Objective): void,
	onCreateObjective(goal: List | null, plan: Plan | PlanUnsaved | null, title: string, estimate: number, secondary: boolean): void,
	getSavedPlan(plan: PlanStub): Promise<Plan>,
}

export default function GoalDetailController(props: Props) {
	const { goal, objectives, plans, allRecurrers, onBack, onDelete, onSetArchived, onEdit, onSaveObjective, onCheckObjective, onFailObjective, onUncheckObjective, onDeleteObjective, onCreateObjective } = props;

	const settings = useContext(SettingsContext);

	const [schedulingObjective, setSchedulingObjective] = useState<Objective|false>(false);
	const [objectiveAdderShow, setObjectiveAdderShow] = useState(false);
	const [editingObjective, setEditingObjective] = useState<Objective|null>(null);
	// const [objectiveAdderGoal, setObjectiveAdderGoal] = useState<List|null>(null);
	// const [objectiveAdderObjectives, setObjectiveAdderObjectives] = useState<Objective[]>([]);

	const now = new Date();

	function handleAddObjective(goal: List | null, plan: Plan | null) {
		// const overarchingObjectives = plan ? getOverarchingObjectives(plan) : [];
		// const goalId = goal ? goal.id : null;
		// setObjectiveAdderObjectives(overarchingObjectives.filter(o => o.listId === goalId && !o.success));
		setObjectiveAdderShow(true);
		// if (plan)
		// 	props.onEnsurePlanIsSaved(plan); // Make sure that plan is saved. Otherwise the first objective for the plan will take some time to synch.
	}

	function objectiveAdderSave (goal: List | null, plan: Plan | PlanUnsaved | null, title: string, estimate: number, secondary: boolean) {
		onCreateObjective(goal, plan, title, estimate, secondary);
		setObjectiveAdderShow(false);
	}
	
	const handleEditObjective = (objective: Objective) => {
		setEditingObjective(objective);
	}

	const listObjectives = objectivesByList(objectives, goal);
	const objectivesCompletedOrFailed = listObjectives.filter(o => o.success !== null).map(o => Object.assign({}, o, {plan: o.planId ? findPlanById(o.planId, plans) : undefined}));
	const objectivesOpen = listObjectives.filter(o => o.success === null);
	const objectivesOverdue = objectivesOpen.filter(o => isOverdue(o, plans, now)).map(o => Object.assign({}, o, {plan: o.planId ? findPlanById(o.planId, plans) : undefined}));
	const objectivesScheduled = objectivesOpen.filter(o => o.planId !== null && !isOverdue(o, plans, now)).map(o => Object.assign({}, o, {plan: o.planId ? findPlanById(o.planId, plans) : undefined}));
	const objectivesUnscheduled = objectivesOpen.filter(o => !o.planId);

	return (
		<React.Fragment>
			<GoalDetailViewV2 
				goal={goal}
				onBack={onBack}
				onDelete={onDelete}
				onEdit={onEdit}
				onSetArchived={onSetArchived}
				onSetFocus={props.onSetFocus}
				showFocusSetting={settings.experimentA}
				onAddUnscheduledObjective={() => {handleAddObjective(goal, null)}}
				onCheckObjective={onCheckObjective}
				onFailObjective={onFailObjective}
				onUncheckObjective={onUncheckObjective}
				onEditObjective={handleEditObjective}
				objectivesOverdue={objectivesOverdue}
				objectivesCompletedOrFailed={objectivesCompletedOrFailed}
				objectivesScheduled={objectivesScheduled}
				objectivesUnscheduled={objectivesUnscheduled}
			/>
			{schedulingObjective &&
				<SchedulerDialogController
						onCancel={() => setSchedulingObjective(false)}
						objective={schedulingObjective}
						initialType={null}
						initialPeriod={null}
						onSetPlan={(plan) => {
							props.onSetPlan(schedulingObjective, plan);
							setSchedulingObjective(false)
						}}
				/>
			}
			{editingObjective && <>
				<Drawer anchor="bottom" open={true} onClose={() => setEditingObjective(null)}>
					<ObjectiveEditorController
						objective={editingObjective}
						recurrer={editingObjective.recurrerId ? findRecurrerById(editingObjective.recurrerId, allRecurrers) : undefined}
						plan={editingObjective.planId ? findPlanById(editingObjective.planId, props.plans) : null}
						doCancel={() => setEditingObjective(null)} 
						doSaveObjective={(objective: Objective, recurring: Recurring | null) => {onSaveObjective(objective, recurring); setEditingObjective(null)}}
						getSavedPlan={props.getSavedPlan}
						doDelete={() => {onDeleteObjective(editingObjective); setEditingObjective(null);}}
						doDeleteRecurrerWithInstances={props.onDeleteRecurrerWithInstances}
						doDeleteRecurrerCurrentAndFollowing={props.onDeleteRecurrerCurrentAndFollowing}
					/>
				</Drawer>
			</>}
			{objectiveAdderShow && <>
				<ObjectiveAdderV2B
					doCancel={() => setObjectiveAdderShow(false)} 
					doSave={(title: string, estimate: number, secondary: boolean) => 
						objectiveAdderSave(
							goal,
							null,
							title,
							estimate,
							secondary
							)}
					doReschedule={() => {}}
					objectivesHigherOrder={[]}
					objectivesOverdueOrUnscheduled={[]}
					planType={null}
				/>
			</>}
		</React.Fragment>
	);
}
