import React from 'react';
import { RatingsData } from '../../services/plan-helpers';

import {
	ResponsiveContainer,
	ComposedChart,
	Line,
	Area,
	XAxis, YAxis, CartesianGrid, 
	Tooltip, Legend,
	} from 'recharts';
import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import capitalize from 'lodash.capitalize';


export default function RatingsCurve(props: {
	data: RatingsData,
}) {

	const { formatMessage, formatNumber } = useIntl();

	if (props.data.length === 0)
		return <Typography variant="body1"><FormattedMessage id="insights.nodata" defaultMessage="Not enough data."/></Typography>;

	const type = props.data[0].type;

	return (
		<ResponsiveContainer width="100%" aspect={3}>
			<ComposedChart data={props.data}
				margin={{top: 20, right: 0, bottom: 20, left: 0}}>
				<CartesianGrid stroke='#f5f5f5'/>
				<XAxis dataKey="day"/>
				<YAxis
					yAxisId="left"
					tickFormatter={(label:any) => {
						switch (label) {
							case 100:
								return "👍";
							case 0:
								return "👎";
							default:
								return "";
						}
					}}
					domain={[0, 100]}
				/>
				{/* <YAxis yAxisId="right" orientation="right"/> */}
				<Tooltip
					formatter={(value:number) => formatNumber(value, {maximumFractionDigits: 0})}
				/>
				<Legend />
				<Line 
					yAxisId="left"
					type='monotone'
					dot={false}
					dataKey='periodSatisfactoryMA'
					stroke='#ff7300'
					name={formatMessage({id: "insights.ratings.periodLabel", defaultMessage: "{typeCapitalized}"}, {type: type, typeCapitalized: capitalize(type)})}
				/>
				<Area
					yAxisId="left"
					type='monotone'
					dataKey='planSatisfactoryMA'
					fill='#8884d8'
					stroke='#8884d8'
					name={formatMessage({id: "insights.ratings.planLabel", defaultMessage: "Plan"})}
				/>
			</ComposedChart>
	</ResponsiveContainer>
	);

}
