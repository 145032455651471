import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
// import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import DialogNotched from './DialogNotched';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { commonMessageValues } from '../services/intl-helpers';


// const useStyles = makeStyles(theme => ({
// }));

enum What {
	Current = "current",
	CurrentPlus = "currentplus",
	All = "all",
}

interface Props {
	onCancel(): void,
	onDeleteCurrent(): void,
	onDeleteCurrentPlus(): void,
	onDeleteAll(): void,
}

export default function RecurrerDeleteDialog(props: Props) {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	// const classes = useStyles();
	const [what, setWhat] = useState<What>(What.CurrentPlus);


	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = (event.target as HTMLInputElement).value;
		switch (value) {
			case What.Current:
				setWhat(What.Current);
				break;
			case What.CurrentPlus:
				setWhat(What.CurrentPlus);
				break;
			case What.All:
				setWhat(What.All);
				break;
		}
	};

	const handleDelete = () => {
		switch (what) {
			case What.Current:
				props.onDeleteCurrent();
				break;
			case What.CurrentPlus:
				props.onDeleteCurrentPlus();
				break;
			case What.All:
				props.onDeleteAll();
				break;
		}
	}

	// logger.debug("Initial failed reason:", initialFailedReason, failedReason);

	return (
		<DialogNotched
			fullScreen={fullScreen}
			open={true}
			onClose={props.onCancel}
			aria-labelledby="responsive-dialog-title"
		>
			{/* <DialogTitle id="responsive-dialog-title">Schedule Objective</DialogTitle> */}
			<DialogTitle id="responsive-dialog-title">
				<FormattedMessage id="RecurrerDeleteDialog.title" defaultMessage="Delete recurring objective"/>
			</DialogTitle>
			<DialogContent >

			<FormControl component="fieldset">
				<RadioGroup name="what" value={what} onChange={handleChange}>
					{/* <FormControlLabel value={What.Current} control={<Radio />} label="Just this instance" /> */}
					<FormControlLabel value={What.CurrentPlus} control={<Radio />} label={<FormattedMessage id="RecurrerDeleteDialog.currentPlus" defaultMessage="This and all following instances"/>} />
					<FormControlLabel value={What.All} control={<Radio />} label={<FormattedMessage id="RecurrerDeleteDialog.all" defaultMessage="All instances"/>} />
				</RadioGroup>
			</FormControl>

			<Typography variant="body1" style={{marginTop:24}}><FormattedMessage id="RecurrerDeleteDialog.noundo" defaultMessage="<b>Warning</b>: This cannot be undone!" values={commonMessageValues}/></Typography>


			</DialogContent>
			<DialogActions>
				<Button onClick={props.onCancel} color="primary" data-analytics-id="RecurrerDeleteDialog-cancel">
					<FormattedMessage id="RecurrerDeleteDialog.cancel" defaultMessage="Cancel"/>
				</Button>
				<Button onClick={handleDelete} color="secondary" autoFocus data-analytics-id="RecurrerDeleteDialog-save">
					<FormattedMessage id="RecurrerDeleteDialog.delete" defaultMessage="Delete"/>
				</Button>
			</DialogActions>
		</DialogNotched>
	);
}
