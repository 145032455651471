import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ObjectivesCard from './ObjectivesCard';
import { Objective, objectivesByPlanAndList } from '../services/objective-helpers';
import { List, getListImage } from '../services/list-helpers';
import { Plan, PlanUnsaved } from '../services/plan-helpers';


const useStyles = makeStyles(theme => ({
	container: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	title: {
		flexGrow: 1,
	},
	footer: {
		padding: theme.spacing(2),
		position: "fixed",
		bottom: 0,
		width: "100%",
	},
	bottomAppBar: {
		top: 'auto',
		bottom: 0,
	},
	header: {
		padding: theme.spacing(2),
	},
	heading: {
		marginBottom: theme.spacing(1),
	},
	bottomGrid: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		marginTop: theme.spacing(2),
	},
}));

interface ReflectionWizardObjectivesProps {
	plan: Plan | PlanUnsaved,
	lists: Array<List>,
	objectives: Array<Objective>,
	onContinue(): void,
	onFailObjective(objective: Objective): void,
	onCheckObjective(objective: Objective): void,
	onUncheckObjective(objective: Objective): void,
}

function ReflectionWizardObjectives(props: ReflectionWizardObjectivesProps) {
	const { plan, lists, objectives, onContinue, onCheckObjective, onFailObjective, onUncheckObjective } = props;

	const classes = useStyles();

	return (
		<Container maxWidth="sm" className={classes.container}>
			<Box className={classes.header}>
				<Typography variant="h4" color="inherit" className={classes.heading}><FormattedMessage id="reflectionWizard.objectives.title" defaultMessage="Take stock"/></Typography>
				<Typography variant="body1" color="inherit"><FormattedMessage id="reflectionWizard.objectives.description" defaultMessage="Go through your plan and mark all objectives either as completed or missed."/></Typography>
			</Box>

			{lists.map(list => 
				<ObjectivesCard 
					key={list.id} 
					title={list.title} 
					objectives={objectivesByPlanAndList(objectives, plan, list)}
					overarchingPlanTitle=""
					overarchingObjectives={[]}
					editable={false} 
					reflect={true}
					onAddObjective={null}
					onCheckObjective={onCheckObjective}
					onFailObjective={onFailObjective}
					onUncheckObjective={onUncheckObjective}
					headerBackground={getListImage(list)}
					onEditObjective={() => {}}
				/>
			)}
			<ObjectivesCard 
				key="other" 
				title={<FormattedMessage id="plan.otherTitle" defaultMessage="Other"/>} 
				objectives={objectivesByPlanAndList(objectives, plan, null)}
				overarchingObjectives={[]}
				overarchingPlanTitle=""
				editable={false}
				reflect={true}
				onAddObjective={null}
				onCheckObjective={onCheckObjective}
				onFailObjective={onFailObjective}
				onUncheckObjective={onUncheckObjective}
				onEditObjective={() => {}}
			/>

			<Grid container justify="space-between" className={classes.bottomGrid}>
				<Grid item></Grid>
				<Grid item><Button variant="contained" color="primary" onClick={onContinue}><FormattedMessage id="reflectionWizard.continue" defaultMessage="Continue"/></Button></Grid>
			</Grid>
		</Container>
	);
}

export default ReflectionWizardObjectives;