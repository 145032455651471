import React, { useState } from 'react';
import RecurringObjectiveDialog from './RecurringObjectiveDialog';
import { getDate } from 'date-fns';
import { Recurring, MonthDayType, PeriodType, WeekDay, allWeekDays } from '../services/recurring-helpers';
import { PlanType } from '../services/plan-helpers';


interface Props {
	testEnvironment?: boolean,
	recurring: Recurring | null,
	planType: PlanType,
	onCancel(): void,
	onSave(repetition: Recurring): void,
}

export default function RecurringObjectiveDialogController(props: Props) {
	const {
		testEnvironment,
		recurring,
		planType,
		onCancel,
		onSave,
	} = props;

	const getDefaultPeriodType = (planType: PlanType) => {
		switch (planType) {
			case PlanType.day:
				return PeriodType.days;
			case PlanType.week:
				return PeriodType.weeks;
			case PlanType.month:
				return PeriodType.months;
			case PlanType.year:
			default:
				return PeriodType.years;
		}		
	}

	const [periodType, setPeriodType] = useState(recurring ? recurring.periodType : getDefaultPeriodType(planType));
	const [weekDays, setWeekDays] = useState(recurring ? recurring.weekDays: allWeekDays);
	// const [monthDayType, setMonthDayType] = useState(recurring ? recurring.monthdayType : MonthDayType.day);
	const monthDayType = MonthDayType.day;
	const [frequency, setFrequency] = useState(recurring ? recurring.periodFrequency : 1);

	const availablePeriodTypes: PeriodType[] = [];
	switch (planType) {
		case PlanType.day:
			availablePeriodTypes.push(PeriodType.days, PeriodType.weeks, PeriodType.months, PeriodType.years);
			break;
		case PlanType.week:
			availablePeriodTypes.push(PeriodType.weeks, PeriodType.months, PeriodType.years);
			break;
		case PlanType.month:
			availablePeriodTypes.push(PeriodType.months, PeriodType.years);
			break;
		case PlanType.year:
			availablePeriodTypes.push(PeriodType.years);
			break;
	}

	const monthDay = getDate(recurring ? recurring.start : new Date());
	const handleToggleWeekday = (day: WeekDay) => {
		if (weekDays.indexOf(day) !== -1) {
			setWeekDays(weekDays.filter(item => item !== day));
		} else {
			setWeekDays(weekDays.concat(day));
		}
	};

	const handleSave = () => {
		const r: Recurring = {
			start: recurring ? recurring.start : new Date(),
			end: null,
			periodType: periodType,
			periodFrequency: frequency,
			monthdayType: monthDayType,
			weekDays: frequency !== 1 ? allWeekDays : weekDays, // only save weekdays if frequency === 1
		};
		onSave(r);
	};

	return (
		<RecurringObjectiveDialog
			availablePeriodTypes={availablePeriodTypes}
			periodType={periodType}
			showWeekdaySelector={(periodType === PeriodType.days && frequency === 1)}
			// showMonthDaySelector={periodType === PeriodType.months}
			showMonthDaySelector={false}
			// showYearDaySelector={periodType === PeriodType.years}
			showYearDaySelector={false}
			monthDayType={monthDayType}
			monthDay={monthDay}
			monthDayCount={4}
			weekDay=""
			weekDays={weekDays}
			month=""
			yearDayType=""
			testEnvironment={testEnvironment}
			onCancel={onCancel}
			onSave={handleSave}
			onPeriodChange={(type: PeriodType) => setPeriodType(type)}
			frequency={frequency}
			onFrequencyChange={(frequency: number) => setFrequency(frequency)}
			onToggleWeekday={handleToggleWeekday}
		/>
	);
}
