import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import DialogNotched from './components/DialogNotched';

class ConfirmationDialog extends React.Component {

	render() {
		const { fullScreen } = this.props;

		return (
			<DialogNotched
				fullScreen={fullScreen}
				open={this.props.open}
				onClose={this.props.onCancel}
				aria-labelledby="responsive-dialog-title"
			>
				<DialogTitle id="responsive-dialog-title">{this.props.title}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{this.props.contentText}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.props.onCancel} color="primary">
						{this.props.cancelButtonText}
					</Button>
					<Button onClick={this.props.onConfirm} color="primary" autoFocus>
						{this.props.confirmationButtonText}
					</Button>
				</DialogActions>
			</DialogNotched>
		);
	}
}

ConfirmationDialog.propTypes = {
	fullScreen: PropTypes.bool.isRequired,
	open: PropTypes.bool.isRequired,
	title: PropTypes.node.isRequired,
	contentText: PropTypes.node.isRequired,
	cancelButtonText: PropTypes.node.isRequired,
	confirmationButtonText: PropTypes.node.isRequired,
	onCancel: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
};

export default withMobileDialog()(ConfirmationDialog);