import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import AndroidIcon from '@material-ui/icons/Android';
import AppleIcon from '@material-ui/icons/Apple';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import FlareIcon from '@material-ui/icons/Flare';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import { SignUpValidationErrors } from './SignUpController';
import { FormattedMessage } from 'react-intl';
import { Platform } from '..';

// const messages = defineMessages({
// 	tosurl: {
// 		id: 'urls.terms-of-service',
// 		defaultMessage: 'https://www.focalityapp.com/en/terms-of-service/',
// 	},
// 	ppurl: {
// 		id: 'urls.privacy-policy',
// 		defaultMessage: 'https://www.focalityapp.com/en/privacy-policy/',
// 	},
// });

const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(8),
		marginBottom: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	optinError: {
		color: theme.palette.error.main
	},
	link: {
		textDecoration: "underline",
	},
	linkBottom: {
		cursor: "pointer",
	},
	appIconGrid: {
		// marginBottom: theme.spacing(1),
		// marginTop: theme.spacing(1),
	},
}));

interface Props {
	onEmailChange(value: string): void,
	onPasswordChange(value: string): void,
	onSubmit(): void,
	// onChangeSubscription(): void,
	revalidate(): void,
	validationErrors: SignUpValidationErrors,
	// onOptinTOSPPChange(status: boolean): void,
	// onOptinMarketingChange(status: boolean): void,
	onSignIn(): void,
	platform: Platform,
	subscriptionTitle?: string,
	// optinMarketingRequired: boolean,
	// ctaFree: boolean,
	onBack(): void,
}

function SignUpForm(props: Props) {
	const { 
		onEmailChange,
		onPasswordChange,
		onSubmit,
		revalidate,
		validationErrors,
		onSignIn,
		platform,
		subscriptionTitle,
		// optinMarketingRequired,
		// onChangeSubscription,
		// ctaFree,
		onBack
	} = props;
	const classes = useStyles();
	// const { formatMessage } = useIntl();

	// const tosurl = formatMessage(messages.tosurl);
	// const ppurl = formatMessage(messages.ppurl);

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		// avoid submitting the form
		event.preventDefault();
		onSubmit();
	}

	const handleSignIn = (event: any) => {
		// avoid submitting the form
		event.preventDefault();
		onSignIn();
	}

	return (
		<Container component="main" maxWidth="xs">
			{/* <CssBaseline /> */}
			<div className={classes.paper}>
			{platform !== "web" && 
				<Avatar className={classes.avatar}>
					<FlareIcon />
				</Avatar>
			}
			{platform === "web" &&
				<Grid container spacing={1} justify="center" className={classes.appIconGrid}>
					<Grid item>
						<AppleIcon/>
					</Grid>
					<Grid item>
						<AndroidIcon/>
					</Grid>
					<Grid item>
						<DesktopMacIcon/>
					</Grid>
				</Grid>
			}
			<Typography component="h1" variant="h5">
				<FormattedMessage id="signup.title" defaultMessage="Sign up"/>{subscriptionTitle && ":"}
			</Typography>
				{subscriptionTitle &&
					<Typography variant="h5" align="center">{subscriptionTitle}</Typography>
				}
			{platform === "web" && 
				<Typography variant="body2"><FormattedMessage id="signup.appnotice" defaultMessage="Apps can be downloaded after signup"/></Typography>
			}
			<form className={classes.form} noValidate onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextField
					variant="outlined"
					required
					fullWidth
					error={typeof validationErrors.email !== "undefined"}
					id="email"
					label={<FormattedMessage id="signup.email" defaultMessage="Email Address"/>}
					name="email"
					autoComplete="email"
					type="email"
					onChange={(e) => onEmailChange(e.target.value)}
					onBlur={revalidate}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
					variant="outlined"
					required
					fullWidth
					error={typeof validationErrors.password !== "undefined"}
					name="password"
					label={<FormattedMessage id="signup.password" defaultMessage="Password"/>}
					type="password"
					id="password"
					autoComplete="new-password"
					onChange={e => onPasswordChange(e.target.value)}
					onBlur={revalidate}
					/>
				</Grid>
				{/* <Grid item xs={12}>
					<FormControlLabel
					className={typeof validationErrors.optinTOSPP !== "undefined" ? classes.optinError : undefined}
					control={<Checkbox value="optinTOSPP" color="primary" onChange={(e, isChecked) => onOptinTOSPPChange(isChecked)}/>}
					label={
						<FormattedMessage
							id="signup.optinTOSPP"
							defaultMessage="I accept the <toslink>terms of service</toslink> and <pplink>privacy policy</pplink>. *"
							values={{
								toslink: (msg: string) => <Link className={classes.link} href={tosurl} target="_blank" rel="noopener">{msg}</Link>,
								pplink: (msg: string) => <Link className={classes.link} href={ppurl} target="_blank" rel="noopener">{msg}</Link>,
							}}
							/>
						}
					/>
				</Grid>
				<Grid item xs={12}>
					<FormControlLabel
					className={typeof validationErrors.optinMarketing !== "undefined" ? classes.optinError : undefined}
					control={<Checkbox value="optinMarketing" color="primary" onChange={(e, isChecked) => onOptinMarketingChange(isChecked)}/>}
					label={
						<React.Fragment>
							<FormattedMessage
								id="signup.optinMarketing"
								defaultMessage="Focality can ask me via email about my experiences with the product and send me related information. I can revoke my consent at any time by contacting info@focalityapp.com or using the opt-out link included in every email."
								/>
							{optinMarketingRequired && " *"}
						</React.Fragment>
						}
					/>
				</Grid> */}
				</Grid>
				<Button
				type="submit"
				fullWidth
				variant="contained"
				color="primary"
				className={classes.submit}
				>
					<FormattedMessage id="signup.cta-free" defaultMessage="Create free account"/>
					{/* {ctaFree && 
						<FormattedMessage id="signup.cta-free" defaultMessage="Create free account"/>
					}
					{!ctaFree && 
						<FormattedMessage id="signup.cta" defaultMessage="Create account"/>
					} */}
				</Button>
				<Grid container justify="flex-end">
				{/* <Grid item xs>
					<Link onClick={onChangeSubscription} variant="body2" className={classes.linkBottom}>
					<FormattedMessage id="signup.changeSubscription" defaultMessage="Change plan"/>
					</Link>
				</Grid> */}
				<Grid item xs>
					<Link onClick={onBack} variant="body2" className={classes.linkBottom}>
					<FormattedMessage id="signup.back" defaultMessage="Back"/>
					</Link>
				</Grid>
				<Grid item>
					<Link onClick={handleSignIn} variant="body2" className={classes.linkBottom}>
					<FormattedMessage id="signup.signin" defaultMessage="Already have an account? Sign in."/>
					</Link>
				</Grid>
				</Grid>
			</form>
			</div>

		</Container>
	);
}

export default SignUpForm;
