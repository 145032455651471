import logger from './services/logger';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import aws_config from './aws-exports';
import { Auth } from 'aws-amplify';


let appSyncClient_loading = null;
try {
	appSyncClient_loading = new AWSAppSyncClient({
		url: aws_config.aws_appsync_graphqlEndpoint,
		region: aws_config.aws_appsync_region,
		auth: {
			type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
			jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
		},
		disableOffline: true,
	});
} catch (e) {
	logger.error("Error initializing AWSAppSyncClient", e);
	throw e;
}

const appSyncClient = appSyncClient_loading;

export default appSyncClient;
