import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import { Plan, PlanType, PlanUnsaved } from '../services/plan-helpers';
import { FormattedMessage } from 'react-intl';


const useStyles = makeStyles(theme => ({
	container: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	header: {
		padding: theme.spacing(2),
	},
	heading: {
		marginBottom: theme.spacing(1),
	},
	bottomGrid: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		marginTop: theme.spacing(2),
	},
}));

interface Props {
	plan: Plan | PlanUnsaved,
	onSavePlan(plan: Plan | PlanUnsaved): void,
	onContinue(): void,
	onBack(): void,
}

function ReflectionWizardImprovements(props: Props) {
	const { onContinue, onBack, plan} = props;

	const classes = useStyles();

	const [fieldValue, setFieldValue] = useState(props.plan.insights);

	const handleSave = () => {
		const updated = Object.assign({}, props.plan, {
			insights: fieldValue !== "" ? fieldValue : null
		});
		props.onSavePlan(updated);
	};

	return (
		<Container maxWidth="sm" className={classes.container}>
			<Box className={classes.header}>
				<Typography variant="h4" color="inherit" className={classes.heading}><FormattedMessage id="reflectionWizard.improvements.title" defaultMessage="How can you improve?"/></Typography>
				<Typography variant="body1" color="inherit"><FormattedMessage id="reflectionWizard.improvements.description" defaultMessage="Thinking about your successes and challenges - what can you do differently next time?"/></Typography>
			</Box>

			<Box className={classes.header}>
			<	TextField
					label={<FormattedMessage id="reflectionWizard.improvements.label" defaultMessage="How to improve"/>}
					multiline
					margin="dense"
					fullWidth	
					value={fieldValue || ""}
					onChange={(event) => setFieldValue(event.target.value)}
					InputProps={{
						onBlur: handleSave,
					}}
				/>
			</Box>
			<Box className={classes.header}>
				<Typography variant="body2" color="inherit" gutterBottom>
					<FormattedMessage id="reflectionWizard.improvements.explanation" defaultMessage="You will be reminded of these insights so that you won't lose track of them. Note that just because you have ideas for improvement does not reflect poorly on the past. You can have a great {type} and still have ideas how to make the next one even greater." values={{type: plan.type}}/>
				</Typography>
				{plan.type === PlanType.day &&
					<Typography variant="body2" color="inherit">
						<FormattedMessage id="reflectionWizard.improvements.skippingNote" defaultMessage="<strong>Note:</strong> Feel free to skip this on daily reflections if nothing springs to mind. Do take your time on weekly and longer timeframes though. There is always room for improvement." values={{strong: (msg: string) => <strong>{msg}</strong>}}/>
					</Typography>
				}
			</Box>

			<Grid container justify="space-between" className={classes.bottomGrid}>
				<Grid item><Button variant="text" color="default" onClick={onBack}><FormattedMessage id="reflectionWizard.back" defaultMessage="Back"/></Button></Grid>
				<Grid item><Button variant="contained" color="primary" onClick={onContinue}><FormattedMessage id="reflectionWizard.finish" defaultMessage="Finish"/></Button></Grid>
			</Grid>
		</Container>
	);
}

export default ReflectionWizardImprovements;