import React, { useState, useEffect, useContext } from 'react';
import { List } from '../services/list-helpers';
import logger from '../services/logger';
import goalImages from '../assets/goal-images/goalImages';
import Onboarding, { OnboardingStep, stepSequence } from './Onboarding';
import sample from 'lodash.sample';
import { dateToPeriod, PlanType, Plan, PlanUnsaved } from '../services/plan-helpers';
import { Objective } from '../services/objective-helpers';
import { defineMessages, useIntl } from 'react-intl';
import { checkNotificationsPermission } from '../services/notifications-helpers';
import SettingsContext from './SettingsContext';
import { logEvent, setScreenName } from '../services/analytics';
import OnboardingSurveyController from './OnboardingSurveyController';
import { User } from './AuthController';
import RemoteConfigContext from './RemoteConfigContext';
import OnboardingInterview from './OnboardingInterview';
import { Environment } from '..';
// import { API } from 'aws-amplify';

const messages = defineMessages({
	dayObjective: {
		id: "onboarding.objectiveDay.objectiveTitle",
		defaultMessage: "Set up Focality to help me achieve my goal",
	},
});

interface Props {
	user: User,
	onCreateGoal(title: string, image: string): List,
	doGetPlanOrCreateVirtualPlan(type: PlanType, period: string): Promise<Plan>,
	onCreateObjective (goal: List, plan: Plan | PlanUnsaved, title: string, estimate: number | null, secondary: boolean): Promise<Objective>,
	onFinish(): void,
	environment: Environment,
}

function OnboardingController(props: Props) {
	const {
		user, 
		doGetPlanOrCreateVirtualPlan,
		onCreateObjective,
		onCreateGoal,
		onFinish,
		environment,
	} = props;
	const { formatMessage } = useIntl();
	const [step, setStep] = useState(
		OnboardingStep.survey
		// OnboardingStep.start
	);
	const [goalTitle, setGoalTitle] = useState("");
	const [objectiveYearTitle, setObjectiveYearTitle] = useState("");
	const [objectiveMonthTitle, setObjectiveMonthTitle] = useState("");
	const [objectiveWeekTitle, setObjectiveWeekTitle] = useState("");
	const [goalImage, setGoalImage] = useState(sample(Object.keys(goalImages)) as string);
	const [completedSteps, setCompletedSteps] = useState<OnboardingStep[]>([]);
	const [notificationChosen, setNotificationChosen] = useState(false);
	const [awaitingNotificationPermission, setAwaitingNotificationPermission] = useState(false);
	const { 
		setNotificationsEnabled,
		// getLatestSignUpOptIn,
		// getSignUpOptIns,
	 } = useContext(SettingsContext);
	 const {
		onboardingInterviewShow,
		onboardingInterviewShowPhone,
	 } = useContext(RemoteConfigContext);

	useEffect(() => {
		const completed = [];
		completed.push(OnboardingStep.start); // start cannot be uncomplete
		completed.push(OnboardingStep.principle); // principle cannot be uncomplete
		completed.push(OnboardingStep.focus); // focus cannot be uncomplete
		completed.push(OnboardingStep.insightsPreview); // cannot be uncomplete
		if (goalTitle !== "")
			completed.push(OnboardingStep.goal);
		if (objectiveYearTitle !== "")
			completed.push(OnboardingStep.objectiveYear);
		if (objectiveMonthTitle !== "")
			completed.push(OnboardingStep.objectiveMonth);
		if (objectiveWeekTitle !== "")
			completed.push(OnboardingStep.objectiveWeek);
		if (notificationChosen)
			completed.push(OnboardingStep.notifications);
		setCompletedSteps(completed);
	}, [goalTitle, objectiveYearTitle, objectiveMonthTitle, objectiveWeekTitle, notificationChosen]);


	// Send optin data via email
	// TODO: Add solid solution
	// useEffect(() => {
	// 	const latestOptIn = getLatestSignUpOptIn();
	// 	if (latestOptIn) {
	// 		let body = "Opt-in data (at onboarding start):\n\n";
	// 		body = body + "Email: " + user.email + "\n"
	// 		body = body + "Username: " + user.username + "\n";
	// 		body = body + "ID: " + user.id + "\n\n";
	// 		body = body + "Marketing opt-in: " + (latestOptIn.optins.optinMarketing ? "yes\n" : "no\n");
	// 		body = body + "Latest opt-in: " + JSON.stringify(latestOptIn) + "\n";
	// 		body = body + "All opt-ins: " + JSON.stringify(getSignUpOptIns());
	
	// 		logger.info("Sending opt in data: ", body);
	// 		API.post("focalityREST", "/backendNotification", {
	// 			body: {
	// 				subject: "Focality Opt-in Data",
	// 				body: body,
	// 			},
	// 		});
	// 	}
	// }, [getLatestSignUpOptIn, getSignUpOptIns, user.email, user.id, user.username]);

	const imagePrev = () => {
		logger.debug('Next image');
		const images = Object.keys(goalImages);
		const curIdx = images.indexOf(goalImage);
		if (curIdx <= 0) {
			setGoalImage(images[images.length-1]);
		} else {
			setGoalImage(images[curIdx-1]);
		}
	}
	
	const imageNext = () => {
		logger.debug('Previous image');
		const images = Object.keys(goalImages);
		const curIdx = images.indexOf(goalImage);
		if (curIdx === -1 || curIdx === (images.length - 1)) {
			setGoalImage(images[0]);
		} else {
			setGoalImage(images[curIdx+1]);
		}
	}

	const handleFinish = () => {
		logger.info('Onboarding complete, saving data...');
		const goal = onCreateGoal(goalTitle, goalImage);
		const now = new Date();
		Promise.all([
			// doGetPlanOrCreateVirtualPlan(PlanType.year, dateToPeriod(now, PlanType.year)),
			// doGetPlanOrCreateVirtualPlan(PlanType.month, dateToPeriod(now, PlanType.month)),
			// doGetPlanOrCreateVirtualPlan(PlanType.week, dateToPeriod(now, PlanType.week)),
			doGetPlanOrCreateVirtualPlan(PlanType.day, dateToPeriod(now, PlanType.day)),
		])
		.then(async ([
				// yearPlan,
				// monthPlan,
				// weekPlan,
				dayPlan
			]) => {
			await Promise.all([
				// onCreateObjective(goal, yearPlan, objectiveYearTitle, null, false),
				// onCreateObjective(goal, monthPlan, objectiveMonthTitle, null, false),
				// onCreateObjective(goal, weekPlan, objectiveWeekTitle, null, false),
				onCreateObjective(goal, dayPlan, formatMessage(messages.dayObjective), 0.25, false),
			]);
		})
		.finally(() => {
			onFinish();
		});
	};

	useEffect(() => {
		setScreenName("Onboarding"+step.charAt(0).toUpperCase() + step.slice(1));
	}, [step]);

	const handleSetStep = (step: OnboardingStep) => {
		setStep(step);
		window.scrollTo(0, 0);
	};

	const doEnableNotifications = () => {
		setAwaitingNotificationPermission(true);
		setNotificationChosen(true);
		checkNotificationsPermission();
		setNotificationsEnabled(true);
		logEvent('notifications_enable');
	}

	const doPostponeNotifications = () => {
		setNotificationChosen(true);
		setNotificationsEnabled(false);
		logEvent('notifications_disable');
		if (stepSequence.indexOf(step) === (stepSequence.length - 1)) {
			handleFinish();
		} else {
			setStep(stepSequence[stepSequence.indexOf(step)+1]);
		}
	}

	if (step === OnboardingStep.survey) {
		return (
			<OnboardingSurveyController
				user={user}
				onDone={() => setStep(onboardingInterviewShow ? OnboardingStep.interview : stepSequence[0])}
				environment={environment}
			/>
		);
	} else if (step === OnboardingStep.interview) {
		return (
			<OnboardingInterview
				showPhoneNumber={onboardingInterviewShowPhone}
				onSkip={() => setStep(stepSequence[0])}
			/>
		);
	} else {
		return (
			<Onboarding
				goalTitle={goalTitle}
				onChangeGoalTitle={(v) => setGoalTitle(v)}
				image={goalImage}
				onNextImage={imageNext}
				onPreviousImage={imagePrev}
				step={step}
				completedSteps={completedSteps}
				onSetStep={(step) => handleSetStep(step)}
				onFinish={handleFinish}
				objectiveYearTitle={objectiveYearTitle}
				onChangeObjectiveYearTitle={v => setObjectiveYearTitle(v)}
				objectiveMonthTitle={objectiveMonthTitle}
				onChangeObjectiveMonthTitle={v => setObjectiveMonthTitle(v)}
				objectiveWeekTitle={objectiveWeekTitle}
				onChangeObjectiveWeekTitle={v => setObjectiveWeekTitle(v)}
				doEnableNotifications={doEnableNotifications}
				doPostponeNotifications={doPostponeNotifications}
				awaitingNotificationPermission={awaitingNotificationPermission}
			/>
		);
	}
}

export default OnboardingController;
