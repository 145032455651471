import React, { useState } from 'react';
// import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import LoopIcon from '@material-ui/icons/Loop';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
	repeatButton: {
		// marginTop: 12,
	},
	loopDown: {
		marginLeft: 0,
	},
}));

interface Props {
	onSelectRecurring(): void,
	onSelectNonRecurring(): void,
	isRecurring: boolean,
}

export default function ObjectiveRecurringButton(props: Props) {
	const {
		onSelectRecurring,
		onSelectNonRecurring,
		isRecurring: recurring,
	} = props;

	const classes = useStyles();

	const [anchorEl, setAnchorEl] = useState<Element | null>(null);

	return (
		<React.Fragment>
			<IconButton color={recurring ? "secondary" : "primary"} className={classes.repeatButton} onClick={(event) => {setAnchorEl(event.currentTarget)}}>
				<LoopIcon /><ArrowDropDownIcon className={classes.loopDown}/>
			</IconButton>
			<Menu
				id="lock-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}
			>
				<MenuItem
					key={"none"}
					selected={!recurring}
					onClick={event => {onSelectNonRecurring(); setAnchorEl(null);}}
				>
					{!recurring && 
						<FormattedMessage id="ObjectiveRecurringButton.onetime" defaultMessage="One-time objective"/>
					}
					{recurring && 
						<FormattedMessage id="ObjectiveRecurringButton.removerecurring" defaultMessage="Remove recurrence"/>
					}
				</MenuItem>
				<MenuItem
					key={"recurring"}
					selected={recurring}
					onClick={event => {onSelectRecurring(); setAnchorEl(null)}}
				>
					<FormattedMessage id="ObjectiveRecurringButton.recurring" defaultMessage="Recurring objective…"/>
				</MenuItem>
			</Menu>
		</React.Fragment>
	);
}
