import React from 'react';
import SettingsContext from './SettingsContext';

const SettingsConsumerHOC = (WrappedComponent: any) => {
	class HOC extends React.Component {
		render() {
			return (
				<SettingsContext.Consumer>
					{(settings:any) => <WrappedComponent {...this.props } settings={settings}/>}
				</SettingsContext.Consumer>
			);
		}
	}
	
	return HOC;
};

export default SettingsConsumerHOC;
