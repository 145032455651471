import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl, FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Box from '@material-ui/core/Box';

import { Objective } from '../services/objective-helpers';
import { List } from '../services/list-helpers';
import { Plan, getPlanTitle, PlanUnsaved } from '../services/plan-helpers';
import { EstimateCompletionRates } from './InsightsController';

import ReflectionWizardObjectives from './ReflectionWizardObjectives';
import ReflectionWizardRatings from './ReflectionWizardRatings';
import ReflectionWizardGoodThings from './ReflectionWizardGoodThings';
import ReflectionWizardBadThings from './ReflectionWizardBadThings';
import ReflectionWizardImprovements from './ReflectionWizardImprovements';
import ReflectionWizardInsights from './ReflectionWizardInsights';


const useStyles = makeStyles(theme => ({
	title: {
		flexGrow: 1,
	},
	body: {
		paddingBottom: theme.spacing(16),
	},
	footer: {
		padding: theme.spacing(2),
		position: "fixed",
		bottom: 0,
		width: "100%",
		zIndex: 100,
	},
}));

export enum ReflectionWizardStep { 
	objectives = "objectives",
	ratings = "ratings",
	reflectionGood = "reflectionGood",
	reflectionBad = "reflectionBad",
	reflectionImprovements = "reflectionImprovements",
	reflectionInsights = "reflectionInsights",
}

interface ReflectionWizardProps {
	step: ReflectionWizardStep,
	plan: Plan | PlanUnsaved,
	lists: Array<List>,
	objectives: Array<Objective>,
	onSetStep(step: ReflectionWizardStep): void,
	objectivesChecked: boolean,
	ratingsCompleted: boolean,
	// stepReflectionCompleted: boolean,
	onSavePlan(plan: Plan): void,
	onFailObjective(objective: Objective): void,
	onCheckObjective(objective: Objective): void,
	onUncheckObjective(objective: Objective): void,
	onClose(): void,
	currentPlanningStreakLength: number,
	longestPlanningStreakLength: number,
	planEstimateCompletionRates: EstimateCompletionRates,
	reflectionRateNumerator: number,
	reflectionRateDenominator: number,
	currentEstimate: number,
}

function ReflectionWizard(props: ReflectionWizardProps) {
	const {
		plan, onSavePlan,
		lists,
		objectives,
		step, onSetStep,
		objectivesChecked,
		ratingsCompleted,
		onFailObjective, onCheckObjective, onUncheckObjective,
		onClose,
		planEstimateCompletionRates, currentEstimate,
		currentPlanningStreakLength, longestPlanningStreakLength,
	} = props;

	const classes = useStyles();
	const { locale } = useIntl();

	const listsActive = lists.filter(l => !l.archived);

	var stepIndex = 0;
	switch (step) {
		case ReflectionWizardStep.ratings:
			stepIndex = 1;
			break;
		case ReflectionWizardStep.reflectionGood:
		case ReflectionWizardStep.reflectionBad:
		case ReflectionWizardStep.reflectionInsights:
			stepIndex = 2;
		// default:
		// 	stepIndex = 0;
	}
	const stepReflectionCompleted = step === ReflectionWizardStep.reflectionInsights;

	return (
		<React.Fragment>
			<AppBar position="static">
				<Toolbar >
					{/* <IconButton color="inherit">
						<ArrowBackIcon />
					</IconButton> */}
					<Typography variant="h6" color="inherit" noWrap className={classes.title}><FormattedMessage id="reflectionWizard.title" defaultMessage="Reflecting {period}" values={{period: getPlanTitle(plan, locale)}}/></Typography>
					<IconButton color="inherit" onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</Toolbar>
				{/* <Slide appear={false} direction="down" in={!trigger}>
					<Stepper activeStep={0}>
						<Step>
							<StepLabel>Take stock</StepLabel>
						</Step>
						<Step>
							<StepLabel>Reflect</StepLabel>
						</Step>
					</Stepper>
				</Slide> */}
			</AppBar>
			<Box className={classes.body}>

				{step === ReflectionWizardStep.objectives && <ReflectionWizardObjectives onContinue={() => onSetStep(ReflectionWizardStep.ratings)} plan={plan} lists={listsActive} objectives={objectives} onCheckObjective={onCheckObjective} onFailObjective={onFailObjective} onUncheckObjective={onUncheckObjective} /> }
				{step === ReflectionWizardStep.ratings && <ReflectionWizardRatings onContinue={() => onSetStep(ReflectionWizardStep.reflectionGood)} onBack={() => onSetStep(ReflectionWizardStep.objectives)} plan={plan} onSavePlan={onSavePlan}/> }
				{step === ReflectionWizardStep.reflectionGood && <ReflectionWizardGoodThings onContinue={() => onSetStep(ReflectionWizardStep.reflectionBad)} onBack={() => onSetStep(ReflectionWizardStep.ratings)} plan={plan} onSavePlan={onSavePlan}/> }
				{step === ReflectionWizardStep.reflectionBad && <ReflectionWizardBadThings onContinue={() => onSetStep(ReflectionWizardStep.reflectionImprovements)} onBack={() => onSetStep(ReflectionWizardStep.reflectionGood)} plan={plan} onSavePlan={onSavePlan}/> }
				{step === ReflectionWizardStep.reflectionImprovements && <ReflectionWizardImprovements onContinue={() => onSetStep(ReflectionWizardStep.reflectionInsights)} onBack={() => onSetStep(ReflectionWizardStep.reflectionBad)} plan={plan} onSavePlan={onSavePlan}/> }
				{step === ReflectionWizardStep.reflectionInsights && <ReflectionWizardInsights onContinue={onClose} planEstimateCompletionRates={planEstimateCompletionRates} currentEstimate={currentEstimate} currentPlanningStreakLength={currentPlanningStreakLength} longestPlanningStreakLength={longestPlanningStreakLength} plan={plan} reflectionRateNumerator={props.reflectionRateNumerator} reflectionRateDenominator={props.reflectionRateDenominator}/> }

			</Box>
			<Paper className={classes.footer} elevation={4}>
				<Stepper activeStep={stepIndex} nonLinear>
					<Step>
						<StepButton onClick={() => onSetStep(ReflectionWizardStep.objectives)} completed={objectivesChecked}><FormattedMessage id="reflectionWizard.steps.status" defaultMessage="Take stock"/></StepButton>
					</Step>
					<Step>
						<StepButton onClick={() => onSetStep(ReflectionWizardStep.ratings)} completed={ratingsCompleted}><FormattedMessage id="reflectionWizard.steps.rate" defaultMessage="Rate"/></StepButton>
					</Step>
					<Step>
						<StepButton onClick={() => onSetStep(ReflectionWizardStep.reflectionGood)} completed={stepReflectionCompleted}><FormattedMessage id="reflectionWizard.steps.reflect" defaultMessage="Reflect"/></StepButton>
					</Step>
				</Stepper>
			</Paper>
		</React.Fragment>
	);
}

export default ReflectionWizard;