import React, { useState, useEffect } from 'react';
import SettingsContext, { SettingsExperimentAData } from './SettingsContext';
import logger from '../services/logger.js';
import { Plugins } from '@capacitor/core';
import { SignUpOptins } from './AuthController';
import * as Sentry from '@sentry/browser';

const { Storage } = Plugins;

interface Props {
	children: React.ReactNode,
}
export default function SettingsProvider(props: Props) {

	const [ready, setReady] = useState(false);
	const [notificationsEnabled, setNotificationsEnabled] = useState<undefined|boolean>(undefined);
	const [experimentA, setExperimentA] = useState<undefined|boolean>(undefined);
	const [onboardingSetupDone, setOnboardingSetupDone] = useState<undefined|string|null>(null);
	const [onboardingTourDone, setOnboardingTourDone] = useState<undefined|string|null>(null);
	const [signUpOptins, setSignUpOptins] = useState<{optins: SignUpOptins, date: Date}[]>([]);
	const [experimentAData, setExperimentAData] = useState<SettingsExperimentAData>({});

	useEffect(() => {

		// Handle the following settings as batch since they are being used as a ready indicator
		// Todo: Cleaner solution
		const nP = Storage.get({key: "focality.notifications.enabled"});
		const oP = Storage.get({key: "focality.onboarding.setupDone"});
		const exA = Storage.get({key: "focality.experiments.a"});
		const exAData = Storage.get({key: "focality.experiments.a.data"});
		Promise.all([nP, oP, exA, exAData]).then(([notificationsEnabled, onboardingSetupDone, exA, exAData]) => {
			logger.debug('Settings for Notifications:', notificationsEnabled);
			setNotificationsEnabled(notificationsEnabled.value !== "false");
			logger.debug('Settings for onboarding setup:', onboardingSetupDone);
			setOnboardingSetupDone(onboardingSetupDone.value);
			setExperimentA(exA.value === "true");
			logger.debug('Data for ExperimentA:', exAData);
			if (exAData.value) {
				try {
					const data = JSON.parse(exAData.value);
					setExperimentAData(data);
				} catch (e) {
					logger.debug("Failed to parse experiment a data", e);
				}
			}
			setReady(true);
		}).catch(e => {
			logger.error("Error loading settings", e);
			Sentry.captureException(e);
			setReady(true);
		});

		Storage.get({key: "focality.onboarding.tourDone"}).then(value => {
			const oldStorageKey = 'focality.onboarding.PlanView';
			if (localStorage && localStorage.getItem(oldStorageKey)) {
				const oldValue = localStorage.getItem(oldStorageKey);
				logger.debug('Settings for onboarding tour from localstorage:', oldValue);
				setOnboardingTourDone(oldValue);
			} else {
				logger.debug('Settings for onboarding tour:', value);
				setOnboardingTourDone(value.value);
			}
		});
		Storage.get({key: "focality.signUpOptIns"}).then(value => {
			try {
				if (value.value) {
					const optins = JSON.parse(value.value).sort((a: {optins: SignUpOptins, date: Date},b: {optins: SignUpOptins, date: Date}) => {
						if (a.date > b.date)
							return 1;
						else if (a.date < b.date)
							return -1;
						else
							return 0;						
					});
					logger.debug("Loaded sign up optins", optins);
					setSignUpOptins(optins);
				} else {
					logger.debug("No sign up optins");
				}
			} catch (e) {
				logger.error('Could not parse sign up optin settings', e);
			}
		});

		Storage.keys().then(value => logger.debug('Storage keys', value));
	}, []);

	const contextValue = {
		ready: () => ready,
		getNotificationsEnabled: () => {
			if (notificationsEnabled === undefined) {
				logger.error('Tried to access getNotificationsEnabled() before ready');
				return false;
			}
			return notificationsEnabled;
		},
		setNotificationsEnabled: (b: boolean) => {
			Storage.set({key: 'focality.notifications.enabled', value: b ? "true" : "false"});
			setNotificationsEnabled(b);
		},
		getOnboardingSetupDone: () => {
			if (onboardingSetupDone === undefined) {
				logger.error('Tried to access getOnboardingSetupDone() before ready');
				return null;
			}
			return onboardingSetupDone;
		},
		setOnboardingSetupDone: (d: Date) => {
			const ds = d.toISOString();
			Storage.set({key: 'focality.onboarding.setupDone', value: ds});
			setOnboardingSetupDone(ds);
		},
		getOnboardingTourDone: () => {
			if (onboardingTourDone === undefined) {
				logger.error('Tried to access getOnboardingTourDone() before ready');
				return null;
			}
			return onboardingTourDone;
		},
		setOnboardingTourDone: (d: Date) => {
			const ds = d.toISOString();
			Storage.set({key: 'focality.onboarding.tourDone', value: ds}).then(value => logger.debug("Saved tourDone", ds, value));
			setOnboardingTourDone(ds);
		},
		experimentA: experimentA === true,
		setExperimentA: (b: boolean) => {
			Storage.set({key: 'focality.experiments.a', value: b ? "true" : "false"});
			setExperimentA(b);
		},
		experimentAData: experimentAData,
		setExperimentAData: (data: SettingsExperimentAData) => {
			Storage.set({key: 'focality.experiments.a.data', value: JSON.stringify(data)});
			setExperimentAData(data);
		},
		addSignUpOptIn: (optins: SignUpOptins, date: Date) => {
			const updatedOptins = signUpOptins.concat([{optins: optins, date: date}]);
			Storage.set({key: "focality.signUpOptIns", value: JSON.stringify(updatedOptins)});
			setSignUpOptins(updatedOptins);
		},
		getSignUpOptIns: () => signUpOptins,
		getLatestSignUpOptIn: () => signUpOptins.length >= 0 ? signUpOptins[signUpOptins.length - 1] : undefined,
	}

	return (
		<SettingsContext.Provider value={contextValue}>
			{contextValue.ready() && props.children}
		</SettingsContext.Provider>
	);
}
