/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      username
      email
      marketingOptIn
      cognitoCreated
      android
      ios
      web
      lastSeen
      language
      createdAt
      updatedAt
      version
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      username
      email
      marketingOptIn
      cognitoCreated
      android
      ios
      web
      lastSeen
      language
      createdAt
      updatedAt
      version
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      id
      username
      email
      marketingOptIn
      cognitoCreated
      android
      ios
      web
      lastSeen
      language
      createdAt
      updatedAt
      version
      owner
    }
  }
`;
export const createList = /* GraphQL */ `
  mutation CreateList($input: CreateListInput!) {
    createList(input: $input) {
      id
      title
      description
      due
      archived
      motivationWhy
      motivationFail
      image
      getObjectives {
        nextToken
      }
      createdAt
      updatedAt
      version
      owner
    }
  }
`;
export const updateList = /* GraphQL */ `
  mutation UpdateList($input: UpdateListInput!) {
    updateList(input: $input) {
      id
      title
      description
      due
      archived
      motivationWhy
      motivationFail
      image
      getObjectives {
        nextToken
      }
      createdAt
      updatedAt
      version
      owner
    }
  }
`;
export const deleteList = /* GraphQL */ `
  mutation DeleteList($input: DeleteListInput!) {
    deleteList(input: $input) {
      id
      title
      description
      due
      archived
      motivationWhy
      motivationFail
      image
      getObjectives {
        nextToken
      }
      createdAt
      updatedAt
      version
      owner
    }
  }
`;
export const createPlan = /* GraphQL */ `
  mutation CreatePlan($input: CreatePlanInput!) {
    createPlan(input: $input) {
      id
      type
      period
      complete
      periodSatisfactory
      planSatisfactory
      goodThings
      badThings
      insights
      getObjectives {
        nextToken
      }
      createdAt
      updatedAt
      version
      owner
    }
  }
`;
export const updatePlan = /* GraphQL */ `
  mutation UpdatePlan($input: UpdatePlanInput!) {
    updatePlan(input: $input) {
      id
      type
      period
      complete
      periodSatisfactory
      planSatisfactory
      goodThings
      badThings
      insights
      getObjectives {
        nextToken
      }
      createdAt
      updatedAt
      version
      owner
    }
  }
`;
export const deletePlan = /* GraphQL */ `
  mutation DeletePlan($input: DeletePlanInput!) {
    deletePlan(input: $input) {
      id
      type
      period
      complete
      periodSatisfactory
      planSatisfactory
      goodThings
      badThings
      insights
      getObjectives {
        nextToken
      }
      createdAt
      updatedAt
      version
      owner
    }
  }
`;
export const createObjective = /* GraphQL */ `
  mutation CreateObjective($input: CreateObjectiveInput!) {
    createObjective(input: $input) {
      id
      list {
        id
        title
        description
        due
        archived
        motivationWhy
        motivationFail
        image
        createdAt
        updatedAt
        version
        owner
      }
      plan {
        id
        type
        period
        complete
        periodSatisfactory
        planSatisfactory
        goodThings
        badThings
        insights
        createdAt
        updatedAt
        version
        owner
      }
      title
      estimate
      secondary
      success
      failedReason
      recurrer {
        id
        title
        estimate
        secondary
        planType
        start
        end
        periodType
        periodFrequency
        monthdayType
        weekdays
        createdAt
        updatedAt
        version
        owner
      }
      createdAt
      updatedAt
      version
      owner
    }
  }
`;
export const updateObjective = /* GraphQL */ `
  mutation UpdateObjective($input: UpdateObjectiveInput!) {
    updateObjective(input: $input) {
      id
      list {
        id
        title
        description
        due
        archived
        motivationWhy
        motivationFail
        image
        createdAt
        updatedAt
        version
        owner
      }
      plan {
        id
        type
        period
        complete
        periodSatisfactory
        planSatisfactory
        goodThings
        badThings
        insights
        createdAt
        updatedAt
        version
        owner
      }
      title
      estimate
      secondary
      success
      failedReason
      recurrer {
        id
        title
        estimate
        secondary
        planType
        start
        end
        periodType
        periodFrequency
        monthdayType
        weekdays
        createdAt
        updatedAt
        version
        owner
      }
      createdAt
      updatedAt
      version
      owner
    }
  }
`;
export const deleteObjective = /* GraphQL */ `
  mutation DeleteObjective($input: DeleteObjectiveInput!) {
    deleteObjective(input: $input) {
      id
      list {
        id
        title
        description
        due
        archived
        motivationWhy
        motivationFail
        image
        createdAt
        updatedAt
        version
        owner
      }
      plan {
        id
        type
        period
        complete
        periodSatisfactory
        planSatisfactory
        goodThings
        badThings
        insights
        createdAt
        updatedAt
        version
        owner
      }
      title
      estimate
      secondary
      success
      failedReason
      recurrer {
        id
        title
        estimate
        secondary
        planType
        start
        end
        periodType
        periodFrequency
        monthdayType
        weekdays
        createdAt
        updatedAt
        version
        owner
      }
      createdAt
      updatedAt
      version
      owner
    }
  }
`;
export const createRecurrer = /* GraphQL */ `
  mutation CreateRecurrer($input: CreateRecurrerInput!) {
    createRecurrer(input: $input) {
      id
      title
      estimate
      secondary
      list {
        id
        title
        description
        due
        archived
        motivationWhy
        motivationFail
        image
        createdAt
        updatedAt
        version
        owner
      }
      getObjectives {
        nextToken
      }
      planType
      start
      end
      periodType
      periodFrequency
      monthdayType
      weekdays
      createdAt
      updatedAt
      version
      owner
    }
  }
`;
export const updateRecurrer = /* GraphQL */ `
  mutation UpdateRecurrer($input: UpdateRecurrerInput!) {
    updateRecurrer(input: $input) {
      id
      title
      estimate
      secondary
      list {
        id
        title
        description
        due
        archived
        motivationWhy
        motivationFail
        image
        createdAt
        updatedAt
        version
        owner
      }
      getObjectives {
        nextToken
      }
      planType
      start
      end
      periodType
      periodFrequency
      monthdayType
      weekdays
      createdAt
      updatedAt
      version
      owner
    }
  }
`;
export const deleteRecurrer = /* GraphQL */ `
  mutation DeleteRecurrer($input: DeleteRecurrerInput!) {
    deleteRecurrer(input: $input) {
      id
      title
      estimate
      secondary
      list {
        id
        title
        description
        due
        archived
        motivationWhy
        motivationFail
        image
        createdAt
        updatedAt
        version
        owner
      }
      getObjectives {
        nextToken
      }
      planType
      start
      end
      periodType
      periodFrequency
      monthdayType
      weekdays
      createdAt
      updatedAt
      version
      owner
    }
  }
`;
