import React from 'react';
import { Objective } from '../services/objective-helpers';
import ObjectiveCheckbox from './ObjectiveCheckbox';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { getPlanTitle } from '../services/plan-helpers';
import { FormattedMessage } from 'react-intl';
import Checkbox from '@material-ui/core/Checkbox';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import green from '@material-ui/core/colors/green';

const useStyles = makeStyles(theme => ({
	secondaryObjectiveTitle: {
		// color: theme.palette.text.secondary,
		fontStyle: "italic",
	},
	completed: {
		textDecoration: "line-through",
		color: theme.palette.text.disabled,
	},
	objectivesPlanCaption: {
		color: theme.palette.text.secondary,
	},
	successIconChecked: {
		color: green[700] + ' !important',
		// color: teal[700] + ' !important',
	},
}));

interface ObjectiveProps {
	objective: Objective,
	showPlan: boolean,
	doCheck(): Promise<Objective>,
	doUncheck(): Promise<Objective>,
	doFail(): void,
	doEdit(): void,
	reflect: boolean,
}

export default function ObjectiveRow(props: ObjectiveProps) {
	const classes = useStyles();

	return <>
		<Box display="flex">
			<Box 
				flex={props.reflect ? "0 0 95px" : "0 0 48px"}
				// style={{backgroundColor:"lightgrey"}}
			>
				{!props.reflect &&
					<ObjectiveCheckbox objective={props.objective} doFailObjective={props.doFail} doUncheckObjective={props.doUncheck} doCheckObjective={props.doCheck}/>
				}
				{props.reflect && <>
					<Checkbox onClick={props.doCheck} checked={props.objective.success === true} icon={<CheckCircleOutlineIcon/>} checkedIcon={<CheckCircleIcon/>} classes={{checked: classes.successIconChecked}}/> 
					<Checkbox onClick={props.doFail} checked={props.objective.success === false} icon={<RemoveCircleOutlineIcon/>} checkedIcon={<RemoveCircleIcon/>}/>
				</>}

			</Box>
			<Box flex="1" paddingTop="6px" onClick={props.doEdit}>
				<Typography variant="subtitle1" className={props.objective.secondary ? classes.secondaryObjectiveTitle : undefined}>
							{(props.objective.success === true || props.objective.success === false) ?
								<span className={classes.completed}>{props.objective.title}</span>
							:
								props.objective.title
							}
						</Typography>
						{(props.showPlan && props.objective.plan) &&
							<Typography variant="caption" className={classes.objectivesPlanCaption}>
								{getPlanTitle(props.objective.plan, "en")}
							</Typography>
						}
			</Box>
			<Box
				flex="0 0 50px"
				paddingTop="10px"
				textAlign="right"
				// style={{backgroundColor:"lightgrey"}}
				>
				{props.objective.estimate && 
					<FormattedMessage id="objectives.duration" defaultMessage="{count, number}h" values={{count: props.objective.estimate || 0}}/> 
				}
			</Box>
		</Box>
	</>
	;
}
