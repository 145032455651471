import React, { useState, useEffect } from 'react';
import Auth from '@aws-amplify/auth';
import SignUpConfirmationSuccess from './SignUpConfirmationSuccess';
import SignUpConfirmationError from './SignUpError';
import SignUpConfirmationLoading from './SignUpLoading';
import logger from '../services/logger';
import { logEvent } from '../services/analytics';
import SignUpConfirmationSuccessWeb from './SignUpConfirmationSuccessWeb';
import { Environment } from '../index';

interface Props {
	username: string,
	confirmationCode: string,
	environment: Environment,
	onGoHome(): void,
}

export default function SignUpConfirmationController(props: Props) {

	const { username, confirmationCode, environment, onGoHome } = props;
	const [status, setStatus] = useState('loading');
	const [error, setError] = useState(null);
	logger.debug('SignUpConfirmationController Props', props);

	useEffect(() => {
		// After retrieving the confirmation code from the user
		Auth.confirmSignUp(username, confirmationCode).then(data => {
			logger.info('Confirmed signup for '+username);
			logger.debug(data);
			setStatus('success');
			logEvent('sign_up', {method: "email"});
		}).catch(err => {
			logger.error('Could not confirm user');
			logger.error(err);
			setError(typeof err.message === "string" ? err.message : null);
			setStatus('error');
		});
	}, [username, confirmationCode]);

	switch (status) {
		case "success":
			logger.debug('Rendering success message. Platform:', environment.platform);
			if (environment.platform === "web")
				return (<SignUpConfirmationSuccessWeb/>);
			else
				return (<SignUpConfirmationSuccess/>);
		case "error":
			return (<SignUpConfirmationError error={error || "Unknown error"} onRetry={onGoHome}/>);
		default:
			return <SignUpConfirmationLoading/>;
	}
}