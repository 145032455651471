import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { FormattedMessage } from 'react-intl';


const useStyles = makeStyles(theme => ({
	container: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	header: {
		padding: theme.spacing(2),
	},
	heading: {
		marginBottom: theme.spacing(1),
	},
	bottomGrid: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		marginTop: theme.spacing(2),
	},
}));

interface Props {
	title: string,
	onChangeTitle(value: string): void,
	description: string,
	onChangeDescription(value: string): void,
	onFieldBlur(): void,
}

function GoalWizardTitle(props: Props) {
	const { title, onChangeTitle, description, onChangeDescription, onFieldBlur } = props;
	const classes = useStyles();

	return (
		<Container maxWidth="sm" className={classes.container}>
			<Box className={classes.header}>
				<Typography variant="h4" color="inherit" className={classes.heading}><FormattedMessage id="goalWizard.title.title" defaultMessage="Your goal"/></Typography>
			</Box>

			<Box className={classes.header}>
				<Typography variant="body1" color="inherit"><FormattedMessage id="goalWizard.title.description" defaultMessage="Give your goal a descriptive title."/></Typography>
				<TextField
						label={<FormattedMessage id="goalWizard.title.label" defaultMessage="Title"/>}
						margin="dense"
						fullWidth	
						value={title}
						onChange={(event) => onChangeTitle(event.target.value)}
						InputProps={{
							onBlur: onFieldBlur,
						}}
					/>
			</Box>
			<Box className={classes.header}>
			<Typography variant="body1" color="inherit"><FormattedMessage id="goalWizard.title.descriptionDescription" defaultMessage="Optionally, write down more details about your goal. This will help you get clarity about it."/></Typography>
				<TextField
						label={<FormattedMessage id="goalWizard.title.descriptionLabel" defaultMessage="Description (optional)"/>}
						multiline
						margin="dense"
						fullWidth	
						value={description}
						// helperText={<FormattedMessage id="goalWizard.title." defaultMessage="You can skip this if you feel you already have a great grasp of it or wrote it down elsewhere."/>}
						onChange={(event) => onChangeDescription(event.target.value)}
						InputProps={{
							onBlur: onFieldBlur,
						}}
					/>
				<Typography variant="body2" color="inherit" gutterBottom>
					<FormattedMessage id="goalWizard.title.skippingNote" defaultMessage="You can skip this if you feel you already have a great grasp of it or wrote it down elsewhere."/>
				</Typography>
			</Box>
			<Box className={classes.header}>
			</Box>
		</Container>
	);
}

export default GoalWizardTitle;
