import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import FlareIcon from '@material-ui/icons/Flare';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { FormattedMessage } from 'react-intl';


const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
			backgroundColor: theme.palette.common.white,
		},
	},
	container: {
		backgroundColor: "white",
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	paper: {
		// marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	confirmationText: {
		marginTop: theme.spacing(1),
	}
}));

interface Props {
	onSignIn(): void,
}

export default function SignUpAwaitingConfirmation(props: Props) {
	const { onSignIn } = props;
	const classes = useStyles();

	const handleSignIn = (e:any) => {
		e.preventDefault();
		onSignIn();
	}

	return (
		<Container component="main" maxWidth="xs" className={classes.container}>
		<CssBaseline />
		<div className={classes.paper}>
			<Avatar className={classes.avatar}>
				<FlareIcon />
			</Avatar>

			<Typography variant="h5"><FormattedMessage id="signup.awaitingConfirmation.title" defaultMessage="Confirm email address"/></Typography>
			<Typography variant="body1" paragraph className={classes.confirmationText}><FormattedMessage id="signup.awaitingConfirmation.description1" defaultMessage="To complete your signup, please click the link which we just sent to your email address. Check your spam folder if the email does not arrive shortly."/></Typography>
			<Typography variant="body1" paragraph className={classes.confirmationText}><FormattedMessage id="signup.awaitingConfirmation.description2" defaultMessage="Once you have verified your email address, you can <loginlink>log in</loginlink>." values={{loginlink: (msg: string) => <Link onClick={handleSignIn}>{msg}</Link>}}/></Typography> 
		</div>
		</Container>
	);
}
