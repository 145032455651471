import React, { useEffect, useState, useContext } from 'react';
// import logger from '../services/logger';
import AppV2 from '../AppV2';
import { User } from './AuthController';
import { addDays, isAfter } from 'date-fns';
import { useIntl } from 'react-intl';
import logger from '../services/logger';
import 'whatwg-fetch';
import * as Sentry from '@sentry/browser';
import RemoteConfigContext from './RemoteConfigContext';
import { Environment } from '..';

// const messages = defineMessages({
// 	year: {
// 		id: 'period.year',
// 		defaultMessage: 'Year',
// 	},
// });

interface Props {
	user: User,
	environment: Environment,
}

enum States {
	loading,
	active,
	expired,
	subscribe,
}

export enum SubscriptionCategory {
	free = "free",
	trial = "trial",
	paid = "paid",
}

export interface Subscription {
	type: SubscriptionType,
	category: SubscriptionCategory,
	expirationDate: Date | null,
	title: string,
	unlimited: boolean,
	doRefresh(): void,
	doOverride(sub: string): void,
}

export enum SubscriptionType {
	free = "free",
	standard = "standard",
	standardTrial = "standard-trial",
	innovator = "innovator",
	innovatorTrial = "innovator-trial",
}


const getSubscription = async (userId: string): Promise<[SubscriptionType | null, Date | null, boolean]> => {
	logger.debug("Fetching subscription status...");
	let type: SubscriptionType | null = null;
	let unlimited = false;
	let expirationDate = null;
	try {
		const response = await fetch("https://api.revenuecat.com/v1/subscribers/"+encodeURIComponent(userId), {
			headers: {
				'Content-Type': 'application/json',
				"Authorization": "Bearer iqzAvQLnmLWAmzBGdhEeehlRAZdDcnTT",
			}
		});
		logger.debug("Subscription response", response);
		if (!response.ok)
			throw new Error("Response was not ok. "+response.status+": "+response.statusText);
		const jsonResponse = await response.json();
		logger.debug("Subscription response JSON", jsonResponse);
		if (!jsonResponse.subscriber || !jsonResponse.subscriber.subscriptions || !jsonResponse.subscriber.entitlements) {
			throw new Error("Unexpected response");
		}

		const now = new Date();
		const subscriptionIds = Object.keys(jsonResponse.subscriber.subscriptions);
		const activeSubscriptionIds = subscriptionIds.filter(id => (new Date(jsonResponse.subscriber.subscriptions[id].expires_date) > now));
		activeSubscriptionIds.forEach(id => {
			switch (id) {
				case "pro_monthly":
					type = SubscriptionType.standard;
					expirationDate = new Date(jsonResponse.subscriber.subscriptions[id].expires_date);
					break;
				case "innovator_monthly":
					type = SubscriptionType.innovator;
					expirationDate = new Date(jsonResponse.subscriber.subscriptions[id].expires_date);
					break;
				default:
					logger.info("Unknown subscription id:", id);
			}
		});
		if (typeof jsonResponse.subscriber.entitlements.unlimited !== "undefined" && typeof jsonResponse.subscriber.entitlements.unlimited.expires_date !== "undefined") {
			const expiration = new Date(jsonResponse.subscriber.entitlements.unlimited.expires_date);
			if (isAfter(expiration, new Date()))
				unlimited = true;
		}
		
		// if (!type && userId === "6b148369-ae0c-4448-b525-c519342c964f") {
		// 	type = SubscriptionType.innovatorTrial;
		// 	expirationDate = new Date("2020-01-16");
		// }

	} catch (error) {
		logger.error("Failed to fetch subscription status", error);
		Sentry.captureException(error);
	}
	return [type, expirationDate, unlimited];
};

function getSubscriptionCategory(sub: SubscriptionType) {
	switch (sub) {
		case SubscriptionType.standard:
		case SubscriptionType.innovator:
			return SubscriptionCategory.paid;
		case SubscriptionType.standardTrial:
		case SubscriptionType.innovatorTrial:
			return SubscriptionCategory.trial;
		case SubscriptionType.free:
		default:
			return SubscriptionCategory.free;
	}
}

function getDefaultSubscription(subscriptionId: string | null, baseDate: Date): [SubscriptionType, Date | null] {
	const twoWeeksAfter = addDays(baseDate, 14);
	switch (subscriptionId) {
		case "standard-trial":
			return [SubscriptionType.standardTrial, twoWeeksAfter];
		case "innovator-trial":
			return [SubscriptionType.innovatorTrial, twoWeeksAfter];
		case "free":
		case null:
		case undefined:
		case "standard": // signup for standard without trial: Treat initially as free. Update screen will be triggered from different component. Once subscription is purchased, signup subscription doesn't matter anymore.
			return [SubscriptionType.free, null];
		default:
			logger.error("Unknown signupSubscriptionId:", subscriptionId);
			return [SubscriptionType.free, null];
	};
}

export default function SubscriptionController(props: Props) {
	const { user, environment } = props;

	const { formatMessage } = useIntl();

	// const [state, setState] = useState(States.active);
	const state = States.active;
	const userCreated: Date = user.created || new Date('2019-11-01'); // older user do not have a created date. Assume date where created date tracking was implemented.
	const [signupSubscription, signupExpirationDate] = getDefaultSubscription(user.signupSubscriptionId || null, userCreated);

	const [subscriptionType, setSubscriptionType] = useState<SubscriptionType>(signupSubscription);
	const [expirationDate, setExpirationDate] = useState(signupExpirationDate);
	const [unlimited, setUnlimited] = useState(false);
	const [refreshTrigger, setRefreshTrigger] = useState(0);
	const remoteConfig = useContext(RemoteConfigContext);

	// const subscriptionState = SubscriptionState.paid;
	const subscription: Subscription = {
		expirationDate: expirationDate,
		type: subscriptionType,
		category: getSubscriptionCategory(subscriptionType),
		title: formatMessage({id: "subscriptions."+subscriptionType+".title"}),
		unlimited: unlimited,
		doRefresh: () => {setRefreshTrigger(Date.now())},
		doOverride: (sub: string) => {
			const [s, d] = getDefaultSubscription(sub, new Date());
			setSubscriptionType(s);
			setExpirationDate(d);
		},
	}

	useEffect(() => {
		if (subscriptionType === SubscriptionType.standardTrial && remoteConfig.getInnovators().indexOf(user.idHashed) !== -1) {
			setSubscriptionType(SubscriptionType.innovatorTrial);
		}
	}, [remoteConfig, subscriptionType, user.idHashed]);

	useEffect(() => {
		getSubscription(user.id).then(([t, d, u]) => {
			if (t)
				setSubscriptionType(t);
			setUnlimited(u || subscriptionType === SubscriptionType.innovatorTrial || subscriptionType === SubscriptionType.standardTrial); // Unlimited either because stored in remote subscription or because subscription type is one of the trial subsciptions
			if (d) {
				setExpirationDate(d);
			}
		});
	}, [user.id, refreshTrigger, subscriptionType]);

	logger.debug('Subscription', subscription);

	switch (state) {
		default:
			return <AppV2 user={user} subscription={subscription} environment={environment}/>
	}
}
