import React, { useState, useEffect } from 'react';
import { Objective } from '../services/objective-helpers';
import { List } from '../services/list-helpers';
import { Plan, PlanType, getPlanObjectives, getPlanEstimate, calculateCompletionStreaks, findCurrentStreak, PlanUnsaved, getPlanByTypePeriod, getBeginning, getEnd, getReflectionRate, calculateReflectionStreaks } from '../services/plan-helpers';
import { Recurrer, getVirtualObjectives } from '../services/recurring-helpers';
import ReflectionWizard, { ReflectionWizardStep } from './ReflectionWizard';
import { getPlanPath } from './PlanViewController';
import logger from '../services/logger';
import { getPlanEstimateCompletionRates, planningRateDenominators } from './InsightsController';
import NotFoundView from './NotFoundView';
import { setScreenName, logEvent } from '../services/analytics';
import FailedObjectiveDialogController from './FailedObjectiveDialogController';

interface ReflectionWizardControllerProps {
	lists: Array<List>,
	plans: Array<Plan>,
	objectives: Array<Objective>,
	allRecurrers: Array<Recurrer>,
	createVirtualPlan(type: PlanType, period: string): Promise<Plan>,
	onSavePlan(plan: Plan | PlanUnsaved): void,
	type: PlanType,
	period: string,
	onSaveObjective(objective: Objective): void,
	onCheckObjective(objective: Objective): void,
	onUncheckObjective(objective: Objective): void,
	onCreateObjective(goal: List | null, plan: Plan | PlanUnsaved | null, title: string, estimate: number | null, secondary: boolean): Promise<Objective>,
	history: any,
	onGetPlanOrCreateVirtualPlan(type: PlanType, period: string): Promise<Plan>,
}

function ReflectionWizardController(props: ReflectionWizardControllerProps) {
	const {
		lists,
		plans,
		objectives,
		allRecurrers,
		createVirtualPlan,
		type,
		period,
		onSaveObjective,
		onCheckObjective,
		onUncheckObjective,
		onCreateObjective,
		onSavePlan,
		onGetPlanOrCreateVirtualPlan,
		history
	} = props;
	let [step, setStep] = useState(ReflectionWizardStep.objectives);
	const [failedObjective, setFailedObjective] = useState<null|Objective>(null);

	setScreenName('ReflectionWizard');
	useEffect(() => {
		logEvent('reflection_start');
	}, []);
	useEffect(() => {
		if (step === ReflectionWizardStep.reflectionInsights) {
			logEvent('reflection_complete');
		}
	}, [step]);

	const plan = getPlanByTypePeriod(type, period, plans);

	useEffect(() => {
		if (!plan) {
			try {
				logger.debug("Creating virtual plan...");
				createVirtualPlan(type, period);
			} catch (e) {
				logger.error("Could not load plan.", e);
			}
		}
	}, [plan, type, period, createVirtualPlan]);


	if (!plan) {
		return <NotFoundView/>;
	}

	const planStart = getBeginning(plan);
	const planEnd = getEnd(plan);
	const virtualObjectives = getVirtualObjectives(allRecurrers, objectives, plans, lists, planStart, planEnd, plan.type);
	const joinedObjectives = objectives.concat(virtualObjectives);
	logger.debug('Created virtual objectives', plan, virtualObjectives);


	const objectivesChecked = !("id" in plan)
		|| !getPlanObjectives(plan, joinedObjectives).find((o: Objective) => o.success === null || (o.success === false && o.failedReason === null))
	;
	const ratingsCompleted = plan.periodSatisfactory !== null && plan.planSatisfactory !== null;
	
	const onClose = () => {
		history.push(getPlanPath(plan));
	};

	const completionStreaks = calculateCompletionStreaks(plan.type, plans, joinedObjectives);
	logger.debug('Completion streaks', completionStreaks);

	const planningStreaks = calculateReflectionStreaks(plan.type, plans, joinedObjectives);
	logger.debug('Planning streaks', planningStreaks);
	const longestPlanningStreak = planningStreaks[0];
	const currentPlanningStreak = findCurrentStreak(planningStreaks, planStart, true);
	const longestPlanningStreakLength = longestPlanningStreak ? longestPlanningStreak.length : 0;
	const currentPlanningStreakLength = currentPlanningStreak ? currentPlanningStreak.length : 0;

	const reflectionRateDenominator = planningRateDenominators[plan.type];
	const reflectionRateNumerator = getReflectionRate(plan.type, reflectionRateDenominator, planStart, plans);

	const planEstimateCompletionRates = getPlanEstimateCompletionRates(plans.filter(p => plan && p.type === plan.type), joinedObjectives);
	const currentEstimate = ("id" in plan) ? getPlanEstimate(plan, joinedObjectives) : 0;
	// const currentPlanEstimateCompletionRate = getPlanEstimateCompletionRate(plan, joinedObjectives);

	const handleFailObjective = (objective: Objective) => {
		setFailedObjective(objective);
	};

	return (
		<React.Fragment>
			{failedObjective !== null &&
				<FailedObjectiveDialogController
				objective={failedObjective}
				allObjectives={objectives}
				allLists={lists}
				allPlans={plans}
				doSaveObjective={onSaveObjective}
				doCreateObjective={onCreateObjective}
				doClose={() => setFailedObjective(null)}
				doGetPlanOrCreateVirtualPlan={onGetPlanOrCreateVirtualPlan}
				/>
			}
		
			<ReflectionWizard
				step={step}
				plan={plan}
				lists={lists}
				objectives={joinedObjectives}
				onSetStep={setStep}
				objectivesChecked={objectivesChecked}
				ratingsCompleted={ratingsCompleted}
				onCheckObjective={onCheckObjective}
				onUncheckObjective={onUncheckObjective}
				onFailObjective={handleFailObjective}
				onSavePlan={onSavePlan}
				onClose={onClose}
				longestPlanningStreakLength={longestPlanningStreakLength}
				currentPlanningStreakLength={currentPlanningStreakLength}
				planEstimateCompletionRates={planEstimateCompletionRates}
				reflectionRateNumerator={reflectionRateNumerator}
				reflectionRateDenominator={reflectionRateDenominator}
				currentEstimate={currentEstimate}
			/>
		</React.Fragment>
	);
}

export default ReflectionWizardController;
