import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';


const useStyles = makeStyles(theme => ({
	container: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	header: {
		padding: theme.spacing(2),
	},
	heading: {
		marginBottom: theme.spacing(1),
	},
}));

interface Props {
	motivationWhy: string,
	onChangeMotivationWhy(value: string): void,
	onFieldBlur(): void,
}

function GoalWizardMotivation(props: Props) {
	const { motivationWhy, onChangeMotivationWhy, onFieldBlur } = props;
	const classes = useStyles();

	return (
		<Container maxWidth="sm" className={classes.container}>
			<Box className={classes.header}>
				<Typography variant="h4" color="inherit" className={classes.heading}><FormattedMessage id="goalWizard.motivation.title" defaultMessage="Motivation"/></Typography>
			</Box>

			<Box className={classes.header}>
				<Typography variant="body1" color="inherit"><FormattedMessage id="goalWizard.motivation.description" defaultMessage="Describe why you chose to pursue this goal."/></Typography>
				<TextField
						label={<FormattedMessage id="goalWizard.motivation.label" defaultMessage="Your motivations"/>}
						margin="dense"
						multiline
						fullWidth
						value={motivationWhy}
						onChange={(event) => onChangeMotivationWhy(event.target.value)}
						InputProps={{
							onBlur: onFieldBlur,
						}}
					/>
			</Box>
			<Box className={classes.header}>
				<Typography variant="body2" color="inherit" component="div">
					<FormattedHTMLMessage id="goalWizard.motivation.explanation" defaultMessage="
					Writing this down will help you better understand your motivation and keep you going. Try to go as deep as possible. Here are a few queries that will help you think:
					<ul>
						<li>Why do you want to achieve this goal?</li>
						<li>How will your life look like if you are successful?</li>
						<li>What will happen if you do not pursue it?</li>
					</ul>"/>
				</Typography>
				<Typography variant="body2" color="inherit" component="div">
					<FormattedHTMLMessage id="goalWizard.motivation.skippingNote" defaultMessage="<strong>Note:</strong> You can skip this part as well if you feel that you already have a good understanding of your motivations. You can also come back later at any time to complete this part."/>
				</Typography>
			</Box>

		</Container>
	);
}

export default GoalWizardMotivation;
