import React from 'react';
import RemoteConfigContext, { RemoteConfig } from './RemoteConfigContext';

const RemoteConfigConsumerHOC = (WrappedComponent: any) => {
	class HOC extends React.Component {
		render() {
			return (
				<RemoteConfigContext.Consumer>
					{(remoteConfig:RemoteConfig) => <WrappedComponent {...this.props } remoteConfig={remoteConfig}/>}
				</RemoteConfigContext.Consumer>
			);
		}
	}
	
	return HOC;
};

export default RemoteConfigConsumerHOC;
