import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import DoneIcon from '@material-ui/icons/Done';
import OnboardingGoal from './OnboardingGoal';
import OnboardingGoalImage from './OnboardingGoalImage';
import OnboardingStart from './OnboardingStart';
import OnboardingObjectiveYear from './OnboardingObjectiveYear';
import OnboardingObjectiveMonth from './OnboardingObjectiveMonth';
import OnboardingObjectiveWeek from './OnboardingObjectiveWeek';
import OnboardingPrinciple from './OnboardingPrinciple';
import OnboardingNotifications from './OnboardingNotifications';
import OnboardingFocus from './OnboardingFocus';
import OnboardingInsightsPreview from './OnboardingInsightsPreview';

const useStyles = makeStyles(theme => ({
	title: {
		flexGrow: 1,
	},
	body: {
		paddingBottom: theme.spacing(16),
	},
	footer: {
		padding: theme.spacing(2),
		position: "fixed",
		bottom: 0,
		width: "100%",
		boxSizing: "border-box",
	},
}));

export enum OnboardingStep { 
	interview = "interview",
	survey = "survey",
	start = "start",
	principle = "principle",
	goal = "goal",
	goalImage = "goalImage",
	focus = "focus",
	objectiveYear = "objectiveYear",
	objectiveMonth = "objectiveMonth",
	objectiveWeek = "objectiveWeek",
	notifications = "notifications",
	insightsPreview = "insightsPreview",
}

export const stepSequence = [
	// OnboardingStep.start,
	OnboardingStep.principle,
	OnboardingStep.goal,
	OnboardingStep.notifications,
	OnboardingStep.insightsPreview,
];
// const stepSequence = [OnboardingStep.start, OnboardingStep.principle, OnboardingStep.goal, OnboardingStep.objectiveYear, OnboardingStep.objectiveMonth, OnboardingStep.objectiveWeek, OnboardingStep.notifications];

interface Props {
	goalTitle: string,
	onChangeGoalTitle(value: string): void,
	image: string,
	onNextImage(): void,
	onPreviousImage(): void,
	step: OnboardingStep,
	onSetStep(step: OnboardingStep): void,
	objectiveYearTitle: string,
	onChangeObjectiveYearTitle(value: string): void,
	objectiveMonthTitle: string,
	onChangeObjectiveMonthTitle(value: string): void,
	objectiveWeekTitle: string,
	onChangeObjectiveWeekTitle(value: string): void,
	onFinish(): void,
	completedSteps: OnboardingStep[],
	doEnableNotifications(): void,
	doPostponeNotifications(): void,
	awaitingNotificationPermission: boolean,
}

function Onboarding(props: Props) {
	const {
		step, onSetStep, completedSteps,
		goalTitle, onChangeGoalTitle,
		objectiveYearTitle, onChangeObjectiveYearTitle,
		objectiveMonthTitle, onChangeObjectiveMonthTitle,
		objectiveWeekTitle, onChangeObjectiveWeekTitle,
		image, onNextImage, onPreviousImage,
		onFinish,
		doEnableNotifications,
		doPostponeNotifications,
		awaitingNotificationPermission,
		// onFieldBlur,
	} = props;
	const classes = useStyles();

	var stepIndex = stepSequence.indexOf(props.step);
	const handleNext = () => {
		onSetStep(stepSequence[stepIndex + 1]);
	}
	const handleBack = () => {
		onSetStep(stepSequence[stepIndex - 1]);
	}

	return (
		<React.Fragment>
			<Box className={classes.body}>
				{step === OnboardingStep.start && <OnboardingStart/>}
				{step === OnboardingStep.principle && <OnboardingPrinciple/>}
				{step === OnboardingStep.goal && <OnboardingGoal title={goalTitle} onChangeTitle={onChangeGoalTitle} image={image} onNext={onNextImage} onPrevious={onPreviousImage}/>}
				{step === OnboardingStep.goalImage && <OnboardingGoalImage image={image} onNext={onNextImage} onPrevious={onPreviousImage}/>}
				{step === OnboardingStep.focus && <OnboardingFocus/>}
				{step === OnboardingStep.objectiveYear && <OnboardingObjectiveYear value={objectiveYearTitle} onChange={onChangeObjectiveYearTitle}/>}
				{step === OnboardingStep.objectiveMonth && <OnboardingObjectiveMonth objectiveYearTitle={objectiveYearTitle} value={objectiveMonthTitle} onChange={onChangeObjectiveMonthTitle}/>}
				{step === OnboardingStep.objectiveWeek && <OnboardingObjectiveWeek value={objectiveWeekTitle} onChange={onChangeObjectiveWeekTitle} objectiveMonthTitle={objectiveMonthTitle}/>}
				{step === OnboardingStep.notifications && <OnboardingNotifications doEnableNotifications={doEnableNotifications} doPostponeNotifications={doPostponeNotifications} awaitingNotificationPermission={awaitingNotificationPermission}/>}
				{step === OnboardingStep.insightsPreview && <OnboardingInsightsPreview/>}
			</Box>
			<Paper className={classes.footer} elevation={4}>
				<MobileStepper
					variant="dots"
					steps={stepSequence.length}
					position="static"
					activeStep={stepIndex}
					nextButton={
						stepIndex === (stepSequence.length - 1) ?
						<Button onClick={onFinish} disabled={completedSteps.indexOf(step) === -1} variant="contained" color="secondary" style={{color: "white"}}><DoneIcon/>&nbsp;<FormattedMessage id="onboarding.done" defaultMessage="Done"/></Button>
						:
						<Button onClick={handleNext} disabled={completedSteps.indexOf(step) === -1} variant="contained" color="primary"><FormattedMessage id="onboarding.continue" defaultMessage="Continue"/><KeyboardArrowRight /></Button>
						// <Button size="small" onClick={handleNext} disabled={activeStep === 5}>
						// 	Next
						// 	{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
						// </Button>
					}
					backButton={
						<Button onClick={handleBack} variant="text" color="default" disabled={stepIndex === 0}><KeyboardArrowLeft /><FormattedMessage id="reflectionWizard.back" defaultMessage="Back"/></Button>
					}
				/>
			</Paper>
		</React.Fragment>
	);
}

export default Onboarding;