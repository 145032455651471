import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
// import moodImage from '../assets/misc-images/david-gavi-Ijx8OxvKrgM-unsplash.signup-teaser.jpg';
import moodImage from '../assets/reflection-images/20160704-2004--DSC_2771.landscape.jpg';


const useStyles = makeStyles(theme => ({
	headerBackground: {
		color: theme.palette.common.white,
		backgroundImage: `linear-gradient(135deg, rgba(0, 0, 0, 0.5) 30%, rgba(0, 0, 0, 0.0) 60%),url(${moodImage})`,
		// backgroundImage: `linear-gradient(135deg, rgba(255, 255, 255, 1), rgba(0, 0, 0, 0.0)),url(${moodImage})`,
		// backgroundImage: `url(${moodImage})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		marginBottom: theme.spacing(1),
	},
	header: {
		padding: theme.spacing(2),
	},
	heading: {
		marginBottom: theme.spacing(1),
	},
	description: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(-1),
	},
	signup: {
		margin: theme.spacing(3, 0, 1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	signIn: {
		position: "absolute",
		bottom: 0,
	},
}));

interface Props {
}

function OnboardingStart(props: Props) {
	// const { onSignIn, onSignUp } = props;
	const classes = useStyles();

	return (
		<React.Fragment>
			<Box className={classes.headerBackground}>
				<Container maxWidth="xs">
					<Box className={classes.header}>
						<Typography variant="h4" color="inherit" className={classes.heading}><FormattedHTMLMessage id="onboarding.start.title" defaultMessage="Your journey<br/>begins now"/></Typography>
						<Typography variant="body1" color="inherit"><FormattedMessage id="onboarding.start.subtitle" defaultMessage="First steps"/></Typography>
					</Box>
				</Container>
			</Box>

			<Container maxWidth="xs">
				<Box className={classes.description}>
					<Typography variant="body1" color="inherit"><FormattedMessage id="onboarding.start.description" defaultMessage="Thank you for signing up for Focality! We will quickly guide you through the first steps so that your feet will hit the ground running. You will:"/></Typography>
					<ul>
						<li><FormattedMessage id="onboarding.start.bullet1" defaultMessage="Define your first goal."/></li>
						<li><FormattedMessage id="onboarding.start.bullet2" defaultMessage="Set your first objectives."/></li>
						<li><FormattedMessage id="onboarding.start.bullet3" defaultMessage="Get an impression of what's to come."/></li>
					</ul>
				</Box>
			</Container>
		</React.Fragment>
	);
}

export default OnboardingStart;
