import { Plan, PlanType, PlanStub, TTPlanType, isBefore, getBeginning, getEnd, getPeriodsByTime, getOrCreatePlan } from '../services/plan-helpers';
import { Objective, ObjectiveState } from '../services/objective-helpers';
import uuidv4 from 'uuid';
import isEqual from 'lodash.isequal';
import t from 'tcomb-validation';
import { StringOrNull } from './validation-types';
import { getISODay, getDate, eachDayOfInterval, getMonth, getDay } from 'date-fns';
import { findListById, List } from './list-helpers';
// import logger from '../services/logger';

export enum PeriodType {
	days = "days",
	weeks =  "weeks",
	months = "months",
	years = "years",
}

export enum MonthDayType {
	day = "day",
	nthWeekDay = "nthWeekday",
	lastWeekDay = "lastWeekday",
}

export enum WeekDay {
	monday = "monday",
	tuesday = "tuesday",
	wednesday = "wednesday",
	thursday = "thursday",
	friday = "friday",
	saturday = "saturday",
	sunday = "sunday",
}

export const allWeekDays = [WeekDay.monday, WeekDay.tuesday, WeekDay.wednesday, WeekDay.thursday, WeekDay.friday, WeekDay.saturday, WeekDay.sunday];

export type Recurring = {
	start: Date,
	end: Date | null,
	periodType: PeriodType,
	periodFrequency: number,
	monthdayType: MonthDayType | null,
	weekDays: WeekDay[],
}

export type Recurrer = {
	id: string,
	title: string,
	estimate: number | null,
	secondary: boolean,
	version: number,
	listId: string | null,
	planType: PlanType,
	recurring: Recurring,
}


const TTRecurring = t.struct({
	start: t.Date,
	end: t.maybe(t.Date),
	periodType: t.enums.of('days weeks months years'),
	periodFrequency: t.Number,
	monthdayType: t.maybe(t.enums.of('day nthWeekday lastWeekday')),
	weekDays: t.list(t.enums.of("monday tuesday wednesday thursday friday saturday sunday")),
});

const TTRecurrer = t.struct({
	id: t.String,
	title: t.String,
	estimate: t.maybe(t.Number),
	secondary: t.Boolean,
	version: t.Number,
	listId: StringOrNull,
	planType: TTPlanType,
	recurring: TTRecurring,
});


export function recurringIsEqual(a: Recurring, b: Recurring) {
	return isEqual(a, b);
}

export function getVirtualObjectives(recurringObjectives: Recurrer[], objectives: Objective[], plans: Plan[], allLists: List[], from: Date, to: Date, type: PlanType | null) {
	/*
	for each period type:
	* get all periods and for each:
	** get all recurringObjectives which match the period and for each:
	*** check if there is already a real objective for it. If not, add a virtual one
	*/

	const virtualObjectives: Objective[] = [];

	const activeRecurrers = recurringObjectives.filter(r => {
		if (!r.listId)
			return true;
		const list = findListById(r.listId, allLists);
		if (list && list.archived)
			return false;
		else
			return true;
	});

	const types = type ? [type] : [PlanType.day, PlanType.week, PlanType.month, PlanType.year];

	types.forEach(type => {
		const periods = getPeriodsByTime(type, from, to);
		periods.forEach(period => {
			const planStub: PlanStub = {
				type: type,
				period: period
			}
			const recurrers = activeRecurrers.filter(o => appliesToPlan(o, planStub));
			recurrers.forEach(r => {
				const plan = getOrCreatePlan(planStub.type, planStub.period, plans);
				if (!instanceExists(r, plan, objectives)) {
					// if (instance_should_exist()) {
						virtualObjectives.push(createVirtualObjective(r, plan));
					// }
				}
			});
		});
	});
	return virtualObjectives;
}

export function getVirtualObjectivesByPlan(plan: Plan | PlanStub, recurringObjectives: Recurrer[], objectives: Objective[], plans: Plan[], allLists: List[]) {
	const virtualObjectives: Objective[] = [];

	const activeRecurrers = recurringObjectives.filter(r => {
		if (!r.listId)
			return true;
		const list = findListById(r.listId, allLists);
		if (list && list.archived)
			return false;
		else
			return true;
	});

	const planStub: PlanStub = {
		type: plan.type,
		period: plan.period,
	}
	const recurrers = activeRecurrers.filter(o => appliesToPlan(o, planStub));
	recurrers.forEach(r => {
		const plan = getOrCreatePlan(planStub.type, planStub.period, plans);
		if (!instanceExists(r, plan, objectives)) {
			// if (instance_should_exist()) {
				virtualObjectives.push(createVirtualObjective(r, plan));
			// }
		}
	});
	return virtualObjectives;
}

/**
 * Does the recurring objective apply to the given period? Should it have an instance?
 * @param recurringObjective 
 * @param period 
 */
export function appliesToPlan(recurringObjective: Recurrer, plan: Plan | PlanStub) {
	const recurring = recurringObjective.recurring;
	const start = recurringObjective.recurring.start;
	const end = recurringObjective.recurring.end;
	if (plan.type !== recurringObjective.planType) {
		return false;
	}
	if (isBefore(plan, start)) {
		return false;
	}
	if (end) {
		const startPlan = getBeginning(plan);
		if (startPlan > end)
			return false;
	}

	if (recurringObjective.planType === PlanType.day && recurring.periodType === PeriodType.days && recurring.weekDays.length !== 7) {
		// if (recurring.periodFrequency !== 1)
		// 	throw new Error("Not implemented");
		const weekday = getISODay(getBeginning(plan));
		const weekdayIdxs = recurring.weekDays.map(i => (allWeekDays.indexOf(i) + 1)); // offset by 1 to get ISO day
		return weekdayIdxs.indexOf(weekday) !== -1;
	}
	if (recurring.periodType === PeriodType.years || recurring.periodType === PeriodType.weeks || recurring.periodType === PeriodType.months) {
		if (recurring.periodType === PeriodType.months && recurring.monthdayType !== MonthDayType.day) {
			throw new Error("Monthly recurrance with MonthDayType other than day are not implemented");
		}	
		const periods = recurring.periodFrequency === 1 ? [plan.period] : getPeriodsByTime(plan.type, recurring.start, getEnd(plan));
		const eligiblePeriods = periods.filter(period => {
			const planStub: PlanStub = {
				period: period,
				type: plan.type,
			};
			const planStart = getBeginning(planStub);
			const planEnd = getEnd(planStub);
			const targetMonth = getMonth(start);
			const targetDay = getDate(start);
			const targetWeekday = getDay(start);
			const days = eachDayOfInterval({start: planStart, end: planEnd});
			const match = days.filter(d => {
				switch (recurring.periodType) {
					case PeriodType.years:
						return targetMonth === getMonth(d) && targetDay === getDate(d);
					case PeriodType.weeks:
						// console.log("week day compare", getDay(start), getDay(d));
						return targetWeekday === getDay(d);
					case PeriodType.months:
						return targetDay === getDate(d)
					default:
						return false;
				}
			});
			// console.log("days and matches", days, match);
			return match.length > 0;
		});
		if (eligiblePeriods.length === 0)
			return false;
		if (eligiblePeriods.length === 1)
			return true; // First period alwas applies. Also, if frequency === 1 this also always applies.
		return ((eligiblePeriods.length-1) % recurring.periodFrequency) === 0;
	}
	if (recurring.periodFrequency !== 1) {
		const periods = getPeriodsByTime(plan.type, recurring.start, getEnd(plan));
		// console.log(periods);
		if (periods.length === 1)
			return true; // first period always applies
		return ((periods.length-1) % recurring.periodFrequency) === 0;
	}
	return true;
}

export function instanceExists(r: Recurrer, plan: Plan, objectives: Objective[]) {
	return typeof objectives.find(o => o.planId === plan.id && o.recurrerId === r.id) !== "undefined";
}

export function createVirtualObjective(r: Recurrer, plan: Plan): Objective {
	const newId = uuidv4();
	return {
		id: newId,
		title: r.title,
		estimate: r.estimate,
		secondary: r.secondary,
		success: null,
		failedReason: undefined,
		version: 1,
		listId: r.listId,
		planId: plan.id,
		recurrerId: r.id,
		state: ObjectiveState.virtual,
		// @ts-ignore
		// _plan: plan,
		// @ts-ignore
		// _period: plan.period,
	}
}

// export function recurrerCreateFromObjective(objective: Objective, planType: PlanType, recurring: Recurring) {
// 	const recurrer: Recurrer = {
// 		id: uuidv4(),
// 		title: objective.title,
// 		estimate: objective.estimate,
// 		secondary: objective.secondary,
// 		version: 0,
// 		listId: objective.listId,
// 		planType: planType,
// 		recurring: Object.assign({}, recurring)
// 	}
// 	return recurrer;
// }

export function findRecurrerById(id: string, allRecurrers: Recurrer[]) {
	return allRecurrers.find(r => r.id === id);
}

export function updateRecurring(recurrer: Recurrer, recurring: Recurring): Recurrer {
	return Object.assign({}, recurrer, {
		recurring: recurring
	});
}

export function recurrerIsValid(recurrer: Recurrer) {
	const r = t.validate(recurrer, TTRecurrer, {strict: true});
	if (!r.isValid()) {
		return false;
	} else {
		return true;
	}
}

export function recurrerValidationErrors(objective: Recurrer) {
	return t.validate(objective, TTRecurrer, {strict: true}).errors;
}
