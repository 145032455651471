/** List plans including objectives
 * Generated version misses items from getObjectives connection.
 */
export const listPlans = `query ListPlans(
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        period
        complete
        periodSatisfactory
        planSatisfactory
        goodThings
        badThings
        insights
        getObjectives {
          items {
            id
          }
          nextToken
        }
        version
      }
      nextToken
    }
  }
  `;

  export const listLists = `query ListLists(
    $filter: ModelListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        due
        archived
        motivationWhy
        motivationFail
        image
        getObjectives {
          items {
            id
          }
          nextToken
        }
        version
      }
      nextToken
    }
  }
  `;


  export const listObjectives = `query ListObjectives(
    $filter: ModelObjectiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listObjectives(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        list {
          id
          title
          description
          due
          motivationWhy
          motivationFail
          image
          version
        }
        plan {
          id
          type
          period
          periodSatisfactory
          planSatisfactory
          goodThings
          badThings
          insights
          version
        }
        recurrer {
          id
        }
        title
        estimate
        secondary
        success
        failedReason
        version
      }
      nextToken
    }
  }
  `; 

  export const listRecurrers = `query ListRecurrers(
    $filter: ModelRecurrerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecurrers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        estimate
        secondary
        planType
        list {
          id
        }
        start
        end
        periodType
        periodFrequency
        monthdayType
        weekdays
        version
        owner
      }
      nextToken
    }
  }
  `;

export const listEverything_WIP = `query listEverything(
  $limit: Int
  $nextToken: String
) {
  listLists(limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      description
      due
      motivationWhy
      motivationFail
      image
      version
    }
    nextToken
  }
  listObjectives(limit: $limit, nextToken: $nextToken) {
    items {
      id
      list {
        id
        title
        description
        due
        motivationWhy
        motivationFail
        image
        version
      }
      plan {
        id
        type
        period
        periodSatisfactory
        planSatisfactory
        goodThings
        badThings
        insights
        version
      }
      title
      estimate
      secondary
      success
      failedReason
      version
    }
    nextToken
  }
  listPlans(limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      period
      periodSatisfactory
      planSatisfactory
      goodThings
      badThings
      insights
      getObjectives {
        items {
          id
        }
        nextToken
      }
      version
    }
    nextToken
  }
}
`;
