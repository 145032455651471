// @ts-check

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import logger from '../services/logger';
import InsightsView from './InsightsView';
import { getPlanEstimate, planSuccessful, PlanType, calculateReflectionStreaks, findCurrentStreak, getBeginning, calculatePlanningStreaks, getPlanningRate, getRatingsData, getReflectionRate } from '../services/plan-helpers';
import { Recurrer, getVirtualObjectives } from '../services/recurring-helpers';
import { Objective, getFailedReasonsCounted } from '../services/objective-helpers';
import { Plan } from '../services/plan-helpers';
import { List } from '../services/list-helpers';
import { setScreenName } from '../services/analytics';
// import type { PlanType } from '../services/plan-helpers';

export type EstimateCompletionRate = {estimate: number, completionRate: number};
export type EstimateCompletionRates = Array<EstimateCompletionRate>;
// type InsightsPlanType = "all" | "day" | "week" | "month" | "year";
// export enum InsightsPlanType {
// 	all = "all",
// 	day = "day",
// 	week = "week",
// 	month = "month",
// 	year = "year",
// }
export type InsightsPlanType = PlanType | "all";

export const planningRateDenominators = {
	[PlanType.day]: 7,
	[PlanType.week]: 4,
	[PlanType.month]: 3,
	[PlanType.year]: 3,
}

export function getPlanEstimateCompletionRates(plans: Array<Plan>, objectives: Array<Objective>): Array<EstimateCompletionRate> {
	interface PlanGroup {
		[key: string]: Array<Plan>
	}
	const estimates: PlanGroup = plans.reduce((acc: PlanGroup, plan) => {
		const estimate = getPlanEstimate(plan, objectives);
		const key = estimate.toString();
		if (typeof acc[key] === "undefined") {
			acc[key] = [];
		}
		acc[key].push(plan);
		return acc;
	}, {});
	const result = Object.keys(estimates).map(key => {
		const estimate = parseFloat(key);
		const plans = estimates[key];
		return {
			estimate: estimate,
			completionRate: plans.reduce((acc, p) => planSuccessful(p, objectives) ? acc+1 : acc, 0.0) / plans.length,
		}
	});
	return result;
}

export function getObjectiveEstimateCompletionRates(objectives: Array<Objective>): Array<EstimateCompletionRate> {
	interface ObjectiveGroup {
		[key: string]: Array<Objective>
	}
	const estimates: ObjectiveGroup = objectives.reduce((acc: ObjectiveGroup, o) => {
		const key = o.estimate ? o.estimate.toString() : "0";
		if (typeof acc[key] === "undefined") {
			acc[key] = [];
		}
		acc[key].push(o);
		return acc;
	}, {});
	return Object.keys(estimates).map(key => {
		const estimate = parseFloat(key);
		const objectives = estimates[key];
		return {
			estimate: estimate,
			completionRate: objectives.reduce((acc, o) => o.success === true ? acc+1 : acc, 0.0) / objectives.length,
		}
	});
}

type InsightsControllerProps = {
	goals: Array<List>,
	plans: Array<Plan>,
	objectives: Array<Objective>,
	recurrers: Array<Recurrer>,
}

function InsightsController(props: InsightsControllerProps) {

	const {goals, plans, objectives, recurrers} = props;
	// const [type, setType] = useState<InsightsPlanType>("all");
	const [type, setType] = useState<InsightsPlanType>(PlanType.day);

	// Find earliest date of existing plans (required to calculate recurring objectives)
	const firstDate = plans.reduce((first, plan) => {
		const beginning = getBeginning(plan);
		if (!first || beginning < first)
			return beginning;
		else
			return first;
	}, new Date());

	const virtualObjectives = getVirtualObjectives(recurrers, objectives, plans, goals, firstDate, new Date(), type === "all" ? null : type);
	const joinedObjectives = objectives.concat(virtualObjectives);

	const filteredPlans = plans.filter(p => type === "all" || p.type === type);
	const filteredObjectives = joinedObjectives.filter(o => {
		if (type === "all")
			return true;
		if (filteredPlans.find(p => p.id === o.planId))
			return true;
		else
			return false;
	});

	const allReflectionInsights = filteredPlans.filter(p => p.insights).map(p => p.insights || "");
	const allGoodThings = filteredPlans.filter(p => p.goodThings).map(p => p.goodThings || "");
	const allBadThings = filteredPlans.filter(p => p.badThings).map(p => p.badThings || "");
	const failedReasons = getFailedReasonsCounted(filteredObjectives);

	const goalStats = goals.map(list => {
		const goalObjectives = filteredObjectives.filter(o => o.listId === list.id);
		const estimateSum = goalObjectives.reduce((accumulator, currentValue) => accumulator + (currentValue.estimate || 0), 0);
		const objectivesWithStatus = goalObjectives.filter(o => o.success === true || o.success === false);
		const completionRate = (goalObjectives.reduce((accumulator, objective) => accumulator + (objective.success?1:0), 0) / objectivesWithStatus.length) * 100;
		const r = {
			id: list.id,
			title: list.title,
			objectives: goalObjectives,
			estimateSum: estimateSum,
			objectivesWithStatus: objectivesWithStatus,
			completionRate: completionRate,
		};
		return r;
	});
	// logger.debug('goalStats', goalStats, JSON.stringify(goalStats));

	// const streaks = calculateCompletionStreaks(type === "all" ? PlanType.day : PlanType[type as keyof typeof PlanType], filteredPlans, filteredObjectives);
	// logger.debug('Streaks', streaks);
	// const currentPeriod = dateToPeriod(new Date(), PlanType.day);
	// const longestStreak: Streak | undefined = streaks[0];
	// const currentStreak = streaks.find(s => s.plans.find(p => p.period === currentPeriod));

	const now = new Date();

	const cycleStreaks = calculateReflectionStreaks(type === "all" ? PlanType.day : PlanType[type as keyof typeof PlanType], plans, objectives);
	// logger.debug('Reflection streaks', cycleStreaks);
	const longestCycleStreak = cycleStreaks[0];
	const currentCycleStreak = findCurrentStreak(cycleStreaks, now, true);
	// logger.debug('findCurrentStreak', currentCycleStreak);
	const longestCycleStreakLength = longestCycleStreak ? longestCycleStreak.length : 0;
	const currentCycleStreakLength = currentCycleStreak ? currentCycleStreak.length : 0;
	
	const planningStreaks = calculatePlanningStreaks(type === "all" ? PlanType.day : PlanType[type as keyof typeof PlanType], plans, objectives);
	const planningStreakMaxLength = planningStreaks[0] ? planningStreaks[0].length : 0;
	const planningStreakCurrent = findCurrentStreak(planningStreaks, now, false);
	const planningStreakCurrentLength = planningStreakCurrent ? planningStreakCurrent.length : 0;

	logger.debug("Planning streaks", planningStreaks, planningStreakCurrent);

	const typeWithoutAll = type === "all" ? PlanType.day : PlanType[type as keyof typeof PlanType];

	const planningRateDenominator = planningRateDenominators[typeWithoutAll];
	const planningRateNumerator = getPlanningRate(typeWithoutAll, planningRateDenominator, now, plans);
	const reflectionRateNumerator = getReflectionRate(typeWithoutAll, planningRateDenominator, now, plans);

	const planEstimateCompletionRates = getPlanEstimateCompletionRates(filteredPlans, filteredObjectives);
	// logger.debug('estimateCompletionRates', planEstimateCompletionRates);
	const objectiveEstimateCompletionRates = getObjectiveEstimateCompletionRates(filteredObjectives);
	// logger.debug('objectiveEstimateCompletionRates', objectiveEstimateCompletionRates);

	if (type === "all")
		throw new Error("Insights type 'all' not implemented");
	const ratingsData = getRatingsData(plans, type);
	logger.debug("ratingsData", ratingsData);	

	setScreenName('InsightsView');

	return(
		<InsightsView
			onSetType={type => setType(type)}
			type={type}
			goalStats={goalStats}
			allReflectionInsights={allReflectionInsights}
			allGoodThings={allGoodThings}
			allBadThings={allBadThings}
			failedReasons={failedReasons}
			cycleStreakMax={longestCycleStreakLength}
			cycleStreakCurrent={currentCycleStreakLength}
			planningStreakCurrent={planningStreakCurrentLength}
			planningStreakMax={planningStreakMaxLength}
			planningRateDenominator={planningRateDenominator}
			planningRateNumerator={planningRateNumerator}
			reflectionRateNumerator={reflectionRateNumerator}
			planEstimateCompletionRates={planEstimateCompletionRates}
			objectiveEstimateCompletionRates={objectiveEstimateCompletionRates}
			ratingsData={ratingsData}
		/>
	);	

}

InsightsController.propTypes = {
	goals: PropTypes.array.isRequired,
	plans: PropTypes.array.isRequired,
	objectives: PropTypes.array.isRequired,
};

export default InsightsController;