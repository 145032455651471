import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import moodImage from '../assets/reflection-images/20160704-2004--DSC_2771.landscape.jpg';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import goalImages from '../assets/goal-images/goalImages';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBeforeOutlined';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
	headerBackground: {
		color: theme.palette.common.white,
		backgroundImage: `linear-gradient(135deg, rgba(0, 0, 0, 0.5) 30%, rgba(0, 0, 0, 0.0) 60%),url(${moodImage})`,
		// backgroundImage: `linear-gradient(135deg, rgba(255, 255, 255, 1), rgba(0, 0, 0, 0.0)),url(${moodImage})`,
		// backgroundImage: `url(${moodImage})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	description: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(-1),
	},
	container: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	header: {
		padding: theme.spacing(2),
	},
	heading: {
		marginBottom: theme.spacing(1),
	},
	bottomGrid: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		marginTop: theme.spacing(2),
	},

	cardMediaControls: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		height: "100%",
	},
	buttonIcon: {
		backgroundColor: "rgba(256,256,256,0.5)",
		borderRadius: "5px",
	},
	cardMedia: {
		height: 140,
	},

}));

interface Props {
	title: string,
	onChangeTitle(value: string): void,
	image: string,
	onNext(): void,
	onPrevious(): void,
}

function OboardingGoal(props: Props) {
	const { title, onChangeTitle, image, onNext, onPrevious } = props;
	const classes = useStyles();

	return (
		<React.Fragment>
			<Box className={classes.headerBackground}>
				<Container maxWidth="xs">
					<Box className={classes.header}>
						<Typography variant="h4" color="inherit" className={classes.heading}><FormattedHTMLMessage id="onboarding.goal.title" defaultMessage="Your first goal"/></Typography>
						{/* <Typography variant="body1" color="inherit"><FormattedMessage id="onboarding.start.subtitle" defaultMessage="First steps"/></Typography> */}
					</Box>
				</Container>
			</Box>


			<Container maxWidth="xs">
				<Box className={classes.description}>
					<Typography variant="body1" color="inherit">
						<FormattedMessage id="onboarding.goal.description" defaultMessage="What is the most important goal that you are currently working to achieve?"/>
					</Typography>
				</Box>
				<Box className={classes.header}>
					<TextField
							label={<FormattedMessage id="goalWizard.title.label" defaultMessage="Title"/>}
							margin="dense"
							fullWidth	
							value={title}
							autoFocus
							onChange={(event) => onChangeTitle(event.target.value)}
							helperText={<FormattedMessage id="onboarding.goal.titlehelper" defaultMessage="e.g. start my own business; run a half marathon; start a rock band"/>}
						/>
				</Box>



				<Box className={classes.description}>
					<Typography variant="body1" color="inherit">
						<FormattedMessage id="onboarding.goal.imagedescription" defaultMessage="Choose an inspiring image for your goal:"/>
					</Typography>
				</Box>

				<Card>
					<CardMedia
						className={classes.cardMedia}
						image={goalImages[image]}
						title=""
						>
						{/* <div className={classes.cardMediaControls}>
							<IconButton onClick={imagePrev}><NavigateBeforeIcon/></IconButton>
							<IconButton onClick={imageNext}><NavigateNextIcon/></IconButton>
						</div> */}
						<Grid container justify="space-between" alignItems="center" className={classes.cardMediaControls}>
							<Grid item><IconButton onClick={onNext}><NavigateBeforeIcon className={classes.buttonIcon} fontSize="large"/></IconButton></Grid>
							<Grid item><IconButton onClick={onPrevious}><NavigateNextIcon className={classes.buttonIcon} fontSize="large"/></IconButton></Grid>
						</Grid>
					</CardMedia>
				</Card>

				<Box className={classes.header}>
					<Typography variant="body2" color="inherit" gutterBottom>
						<FormattedMessage id="onboarding.goal.smallprint" defaultMessage="You can add more goals later. Let's focus on the most important one for now to get you set up."/>
					</Typography>
				</Box>

				{/* <Box className={classes.header}>
					<Typography variant="body2" color="inherit" gutterBottom>
						<FormattedMessage id="goalWizard.image.description" defaultMessage="Use the left/right arrows to switch through the available images."/>
					</Typography>
				</Box> */}

			</Container>
		</React.Fragment>
	);
}

export default OboardingGoal;
