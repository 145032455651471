import { Plugins } from '@capacitor/core';
import logger from './logger';
// import * as firebase from "firebase/app";
// import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
// import "firebase/analytics";
// import '@capacitor-community/firebase-analytics';
// const { FirebaseAnalytics } = Plugins;

// let platform: "ios" | "android" | "electron" | "web" | null = null;
declare var gtag: any;

declare var heap: {
	load(projectId: string, parameters: any): void,
	track(eventId: string, properties: any): void,
	identify(id: string): void,
	resetIdentity(): void,
};

async function getPlatform() {
	const deviceInfo = await Plugins.Device.getInfo();
	return deviceInfo.platform;
}

export async function setUserId(idHashed: string) {
	const platform = await getPlatform();
	logger.debug("Analytics: Setting user id (idHashed, platform)", idHashed, platform);
	// if (platform === "android") {
	// 	FirebaseAnalytics.setUserId({
	// 		userId:idHashed
	// 	});
	// } else
	if (typeof gtag !== "undefined") {
		gtag('config', 'G-5450PECLL4', {'user_id': idHashed});
	}

	if (typeof heap !== "undefined") {
		heap.identify(idHashed);
	}
}

var currentScreen: string | null = null;

export async function setScreenName(screenName: string, screenClass?: string) {
	if (screenName === currentScreen)
		return;
	const platform = await getPlatform();
	logger.debug('Analytics: Setting streen name (screenName, screenClass, platform)', screenName, screenClass, platform);
	// if (["ios", "android"].indexOf(platform) !== -1) {
	// 	Plugins.CapacitorFirebaseAnalytics.setScreenName({ screenName: screenName, screenClassOverride: screenClass});
	// 	FirebaseAnalytics.setScreenName({
	// 		screenName: screenName,
	// 		nameOverride: screenClass || "default",
	// 	  });
	// }
	logEvent('screen_view', {
		firebase_screen: screenName, 
		firebase_screen_class: screenClass || "default",
	});
	if (typeof heap !== "undefined") {
		heap.track('screen_view', {screenName: screenName});
	}
	currentScreen = screenName;
}

type EventParameters = {
	[index: string]: string | number,
}
export async function logEvent(event: string, parameters?: EventParameters) {
	const platform = await getPlatform();
	logger.debug('Analytics: Logging event (event, parameters, platform)', event, parameters, platform);
	// if (platform === "android") {
	// 	FirebaseAnalytics.logEvent({
	// 		name: event,
	// 		params: parameters || {},
	// 	});
	// } else
	if (typeof gtag !== "undefined") {
		gtag("event", event, parameters || {});
	} else {
		logger.debug("Analytics: No event logging option for analytics/firebase available.");
	}
	if (typeof heap !== "undefined" && event !== "screen_view") {
		heap.track(event, parameters);
	}
}

export function signOut() {
	if (typeof heap !== "undefined") {
		heap.resetIdentity();
	}
}

// function gtag(...args:any[]){
// 	//@ts-ignore
// 	window.dataLayer.push(arguments);
// }

// function init_web() {
// 	//@ts-ignore
// 	window.dataLayer = window.dataLayer || [];
// 	gtag('js', new Date());
// 	gtag('config', 'G-CF1S16GLNC', {
// 		'send_page_view': false,
// 	});
// }

function init_ga4() {
	gtag('js', new Date());
	gtag('config', process.env.REACT_APP_GA_MEASUREMENT_ID);	
}

getPlatform().then(p => {
	// if (p !== "android") {
	// 	var firebaseConfig = {
	// 		apiKey: "AIzaSyBwjBocKDhngMEgm2E5UTw_rpecaNWjASk",
	// 		authDomain: "focality-19fbd.firebaseapp.com",
	// 		databaseURL: "https://focality-19fbd.firebaseio.com",
	// 		projectId: "focality-19fbd",
	// 		storageBucket: "focality-19fbd.appspot.com",
	// 		messagingSenderId: "24021391572",
	// 		appId: "1:24021391572:web:c7933ab59bd6df6b820341",
	// 		measurementId: "G-5450PECLL4"
	// 	  };
	// 	FirebaseAnalytics.initializeFirebase(firebaseConfig);
	// }
	// if (p !== "android")
		init_ga4();
	if (p !== "ios") {
		// Initialize Heap
		try {
			// @ts-ignore
			window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=t.forceSSL||"https:"===document.location.protocol,a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src=(r?"https:":"http:")+"//cdn.heapanalytics.com/js/heap-"+e+".js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(a,n);for(var o=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],c=0;c<p.length;c++)heap[p[c]]=o(p[c])}; // eslint-disable-line
			if (typeof heap !== "undefined" && typeof process.env.REACT_APP_HEAP_ID !== "undefined") {
				const heapId = process.env.REACT_APP_HEAP_ID;
				const heapOptions = {
					disableTextCapture: true,
					forceSSL: true,
				}
				logger.debug('Initializing heap', heapId, JSON.stringify(heapOptions));
				heap.load(heapId, heapOptions);
			} else {
				logger.error('Could not load heap: Heap object or settings missing', heap, process.env.REACT_APP_HEAP_ID);
			}
		} catch (e) {
			logger.error('Error while initializing heap: ', e);
		}
	}
});

