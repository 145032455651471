import React, { useContext, useState } from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import { makeStyles } from '@material-ui/core/styles';


import Checkbox from '@material-ui/core/Checkbox';

// import teal from '@material-ui/core/colors/teal';
import green from '@material-ui/core/colors/green';
import { Objective } from '../services/objective-helpers';
import SettingsContext from './SettingsContext';
import { useLongPress } from 'use-long-press';
import logger from '../services/logger';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	successIconChecked: {
		color: green[700] + ' !important',
	},
	failIconChecked: {
		color: theme.palette.secondary.main + ' !important',
	},
}));

export default function ObjectiveCheckbox(props: {
	objective: Objective,
	doFailObjective(objective: Objective): void,
	doUncheckObjective(objective: Objective): Promise<Objective>,
	doCheckObjective(objective: Objective): Promise<Objective>,
	disabled?: boolean,
}) {
	const classes = useStyles();
	const [saving, setSaving] = useState(false);
	const settings = useContext(SettingsContext);

	let checkboxClass = null;
	if (settings.experimentA) {
		if (props.objective.success === true)
			checkboxClass = classes.successIconChecked;
		else if (props.objective.success === false)
			checkboxClass = classes.failIconChecked;
	}

	const handleSingleCheckboxClick = () => {
		logger.debug('ObjectiveCheckbox: Executing single click', props.objective.success);
		setSaving(true);
		let p: Promise<Objective> | null = null;
		if (props.objective.success === null)
			p = props.doCheckObjective(props.objective);
		else
			p = props.doUncheckObjective(props.objective);
		p.then(o => setSaving(false));
	}

	const bind = useLongPress(() => {
		logger.debug('ObjectiveCheckbox: Long pressed. Failing objective.');
		props.doFailObjective(props.objective);
	}, {threshold: 500});

	if (saving)
		return <CircularProgress size={24} style={{margin:9}}/>

	return <Checkbox 
		onClick={handleSingleCheckboxClick}
		{...bind}
		checked={props.objective.success !== null}
		color="primary"
		icon={<RadioButtonUncheckedOutlinedIcon/>}
		checkedIcon={props.objective.success === true ? <CheckCircleOutlineIcon/> : <RemoveCircleOutlineIcon/>}
		classes={checkboxClass ? {checked: checkboxClass} : undefined}
		disabled={props.disabled || saving}
		data-onboarding-markers="ObjectiveCheckbox"
		data-analytics-id="ObjectiveCheckbox"
	/>
}
