import Auth, { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';

import React, { useState, useEffect, useContext, useRef } from 'react';
import logger from '../services/logger';
import LoadingViewController from './LoadingViewController';
import SignInController from './SignInController';
import SignUpController from './SignUpController';
import SubscriptionController from './SubscriptionController';
import SignUpTeaserController from './SignUpTeaserController';
import { parseISO, formatISO } from 'date-fns';
import { setUserId, setScreenName, logEvent } from '../services/analytics';
import { Environment } from '../index';
import ErrorView from './ErrorView';
import { getUrlParameter } from '../services/getUrlParameter';
import { useLocation } from "react-router-dom";
import SettingsContext from './SettingsContext';
import appSyncClient from '../AppSyncClient';
import gql from 'graphql-tag';
import { getUser } from '../graphql/queries';
import { createUser, updateUser } from '../graphql/mutations';
import * as Sentry from '@sentry/browser';
import { useIntl } from 'react-intl';
import { Plugins } from '@capacitor/core';
// import { useSnackbar } from 'notistack';
import sha256 from 'crypto-js/sha256';
import Purchases from 'cordova-plugin-purchases/www/plugin';

enum AuthStates {
	loading,
	authenticated,
	teaser,
	signIn,
	signUp,
}

export interface User {
	id: string,
	idHashed: string, // SHA-256 hash of id
	username: string,
	email: string,
	created: Date | null,
	signupSubscriptionId: string | undefined,
}

interface BackendUser {
	id: string,
	username: string,
	email: string,
	marketingOptin?: boolean,
}

export interface SignUpOptins {
	optinTOSPP: boolean,
	optinMarketing: boolean,
}

export interface TeaserValidationErrors {
	optinTOSPP?: string,
}

/**
 * Generates a SHA-256 hash
 * @param message 
 */
// async function generateHash(message: string) {
// 	const msgUint8 = new TextEncoder().encode(message);                           // encode as (utf-8) Uint8Array
// 	const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);           // hash the message
// 	const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
// 	const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
// 	// const base64 = btoa(
// 	// 	new Uint8Array(hashBuffer)
// 	// 	  .reduce((data, byte) => data + String.fromCharCode(byte), '')
// 	//   );
// 	return hashHex;	
// }

async function loadUser(cognitoUser: any): Promise<User> {
	logger.debug('Loading user', cognitoUser);
	logger.debug('User attributes', JSON.stringify(cognitoUser.attributes));
	if (!cognitoUser || !cognitoUser.attributes)
		throw new Error("Not a valid user");
	if (!cognitoUser.attributes.sub)
		throw new Error("Missing user id in cognito user");
	if (!cognitoUser.attributes.email)
		throw new Error("Missing email in cognito user");
	let created = null;
	let signupSubscriptionId = undefined;
	if (cognitoUser.attributes["custom:focalityCustomData"]) {
		try {
			const meta = JSON.parse(cognitoUser.attributes["custom:focalityCustomData"]);
			created = meta.created ? parseISO(meta.created) : null;
			signupSubscriptionId = meta.subscription;
		} catch (e) {
			logger.error('Could not parse user meta data', e);
		}
	}
	const user = {
		id: cognitoUser.attributes.sub,
		idHashed: sha256(cognitoUser.attributes.sub).toString(),
		username: cognitoUser.username,
		email: cognitoUser.attributes.email,
		created: created,
		signupSubscriptionId: signupSubscriptionId,
	}
	return user;
}

async function backendUserSync(user: User, marketingOptIn: boolean | null) {
	logger.debug("Syncing backend user");
	let backendUser = await backendUserLoad(user);
	if (!backendUser) {
		logger.debug("No backend user");
		backendUser = await backendUserCreate(user, marketingOptIn);
	}
	return backendUser;
}

async function backendUserLoad(user: User) {
	logger.debug("Loading backend user");
	const response = await appSyncClient.query<{getUser: BackendUser}>({
		query: gql(getUser),
		fetchPolicy: "network-only",
		variables: {
			id: user.id,
		},
	});
	logger.debug("Backend user response: ", response);
	if (!response.data.getUser) {
		logger.debug("No backend user");
		return null;
	} else {
		return response.data.getUser;
	}
}

async function backendUserCreate(user: User, marketingOptIn: boolean | null) {
	logger.info("Creating backend user");
	const input = {
		id: user.id,
		username: user.username,
		email: user.email,
		marketingOptIn: marketingOptIn === true ? true : (marketingOptIn === false ? false : undefined),
		cognitoCreated: user.created ? formatISO(user.created) : undefined,
	};
	await appSyncClient.mutate({
		mutation: gql(createUser),
		variables: {
			input: input
		}
	});
	const backendUser = await backendUserLoad(user);
	if (!backendUser)
		throw new Error("Coud not load newly created backend user.");
	else
		return backendUser;
}

async function backendUserUpdate(user: User, backendUser: any, language: string) {
	const deviceInfo = await Plugins.Device.getInfo(); // cannot use global platform prop, might be uninitialized due to race condition. Todo: Clean solution.
	const input = {
		id: user.id,
		lastSeen: formatISO(new Date()),
		language: language,
		android: deviceInfo.platform === "android" ? true : undefined,
		ios: deviceInfo.platform === "ios" ? true : undefined,
		web: deviceInfo.platform === "web" ? true : undefined,
		expectedVersion: backendUser.version,
	}
	logger.debug("Mutating backend user. Input:", input);
	appSyncClient.mutate({
		mutation: gql(updateUser),
		variables: {
			input: input
		}
	}).catch(err => {
		logger.error(err);
		Sentry.captureException(err);
	});
}

let userSynchInProgress = false;

interface Props {
	environment: Environment,
}

function AuthController(props: Props) {
	const { environment } = props;

	const [state, setState] = useState(AuthStates.loading);
	const [loadingWhat, setLoadingWhat] = useState("checking authentification");
	const [user, setUser] = useState<User|null>(null);
	const [optins, setOptins] = useState<SignUpOptins>({optinTOSPP: false, optinMarketing: false});
	// const [userEmail, setUserEmail] = useState<string|null>(null);

	let location = useLocation();
	const settings = useContext(SettingsContext);

	const latestOptin = settings.getLatestSignUpOptIn();
	const latestMarketingOptIn = latestOptin ? latestOptin.optins.optinMarketing : null;
	const latestMarketingOptInRef = useRef(latestMarketingOptIn);
	useEffect(() => {
		latestMarketingOptInRef.current = latestMarketingOptIn;
	}, [latestMarketingOptIn]);

	const { locale } = useIntl();

	const loadingTimer = useRef(0);

	// Todo: Check if it really needs to be a ref.
	// const { enqueueSnackbar } = useSnackbar();
	// const enqueueSnackbarRef = useRef(enqueueSnackbar);
	// useEffect(() => {
	// 	enqueueSnackbarRef.current = enqueueSnackbar;
	// }, [enqueueSnackbar])


	// Ugly hack: Amplify does not automatically detect the redirect from social logins when run inside an app container
	// => Manually trigger auth response if app is called with social login parameters.
	useEffect(() => {
		const path = location.pathname;
		const query = location.search;
		if (path === "/" && query.includes('?code=') && query.includes('&state=')) {
			logger.info("Handling third party auth response...");
			// Plugins.Toast.show({text: "Handling third party auth response..."+path+"@ @"+query, duration: "long"});
			(Auth as any)._handleAuthResponse(path+query);
		// } else {
		// 	Plugins.Toast.show({text: "Location changed: "+path+"@ @"+query, duration: "long"});
		}
	}, [location]);

	
	// Check current login state and listen to state changes
	useEffect(() => {
		logger.debug("Checking login status.");

		const onLogIn = async (cognitoUser: any) => {
			logger.debug("onLogIn()");

			try {
				if(localStorage) {
					localStorage.setItem('focality.lastSignIn', (new Date()).toISOString());
				}
			} catch (e) {
				logger.debug('Error while setting login date:', e);
			}
			// setUserEmail(cognitoUser.attributes.email);
			const user = await loadUser(cognitoUser);
			logger.debug("User loaded:", user, JSON.stringify(user));
			setUser(user);
			setState(AuthStates.authenticated);
			if (!userSynchInProgress) {
				// onLogIn() gets triggered twice during social logins. Once initially by useEffect and
				// once by the signIn event from amplify hub. The second sync would fail because expected_version
				// would not match. This workaround stops the second sync. Assumption: The second sync is not
				// needed anyway.
				// Todo: Cleaner solution.
				try {
					userSynchInProgress = true;
					const backendUser = await backendUserSync(user, latestMarketingOptInRef.current);
					backendUserUpdate(user, backendUser, locale);
					userSynchInProgress = false;
				} catch (e) {
					userSynchInProgress = false;
					logger.error("Error updating backend user", e);
					Sentry.captureException(e);
				}
			} else {
				logger.debug("User synch already in progress. Skipping.");
			}

			if (environment.platform === "android" || environment.platform === "ios") {
				logger.debug("Identifying Purchases user");
				Purchases.identify(
					user.id,
					info => {
						logger.debug("Purchases.identify result: ", info);
					},
					error => {
						logger.error("Error identifying Purchases user", error.message);
					}
				);
			}
		}

		// Auth.currentSession().then(info => console.log('Session', info));
		const checkLoginStatus = async () => {
			logger.debug("checkLoginStatus()");
			try {
				Auth.currentAuthenticatedUser()
				.then(cognitoUser => {
					logger.info('User is authenticated');
					// setUser(cognitoUser);
					// setState(AuthStates.authenticated);
					onLogIn(cognitoUser);
				})
				.catch(err => {
					logger.info('User is probably not authenticated:', err);
					const view = getUrlParameter("view");
					if (view === "signup") {
						setState(AuthStates.signUp);
					} else {
						setState(AuthStates.teaser);
					}
				});
			} catch (err) {
				// Try/catch only added to track down mysterious "Not implemented" error. Todo: Remove it if it turns out to be unnecessary
				logger.error('Caught try/catch-error while checking login status:', err);
				logger.info('Assuming user is not authenticated');
				const view = getUrlParameter("view");
				if (view === "signup") {
					setState(AuthStates.signUp);
				} else {
					setState(AuthStates.teaser);
				}
			}
			logger.debug("checkLoginStatus() done");
		}

		logger.debug("checkLoginStatus initial");
		checkLoginStatus();
		
		Hub.listen('auth', (data) => {
			logger.debug('A new auth event has happened: ', data.payload.data.username + ' has ' + data.payload.event, data);
			logger.debug('Data: ', JSON.stringify(data));
			
			if (data.payload.event === "signOut") {
				// setUserEmail(null);
				setUser(null);
				setState(AuthStates.signIn);
				if (environment.platform === "android" || environment.platform === "ios") {
					logger.info("Resetting Purchases user.");
					Purchases.reset(info => {
						logger.debug("Purchases.reset() result: ", info);
						},
						error => {
							logger.error("Error resetting Purchases user", error.message);
					});
				}
			} else if (data.payload.event === "signIn") {
				logger.debug("checkLoginStatus signIn");
				checkLoginStatus();
			// } else if (data.payload.event === "signIn_failure"
			// 	&& !(data.payload.data.name === "SecurityError" && typeof data.payload.data.stack === "string" && data.payload.data.stack.includes("Failed to execute 'replaceState' on 'History'"))
			// 	) {
			// 	logger.error("signIn_failure", data.payload.message, data.payload.data);
			// 	const errorMessage = "signIn_failure: "+data.payload.data.name+' '+data.payload.message;
			// 	Sentry.captureException(new Error(errorMessage));
			// 	enqueueSnackbarRef.current("An error occurred. :( "+errorMessage, {variant: "error"});
			} else if (data.payload.event === "customState_failure") {
				logger.debug("checkLoginStatus customState");
				// Ugly hack: customState_failure usually means that a social login happened within the app container (see _handleAuthResponse above)
				// In that case amplify tries to rewrite history with an external host (run.focalityapp.com) which consequently fails.
				// In that special case the error can be ignored. The login should be successful.
				checkLoginStatus();
			}

			loadingTimer.current = 0; // reset timer in loading view
			Plugins.Browser.close();
		});
		logger.debug("Checking login status: Effect done (synchronous part)");
		
	}, [latestMarketingOptInRef, locale, loadingTimer]); // use ref for optin. Otherwise effect will trigger on settings change. Re-triggering will re-evaluate auth state and reset view to teaser instead of next step.

	useEffect(() => {
		if (user) {
			setUserId(user.idHashed);
		}
	}, [user]);

	const handleFacebook = (optins: SignUpOptins) => {
		logger.info("Initiating Facebook login...");
		setOptins(optins);
		settings.addSignUpOptIn(optins, new Date());
		// Save optin
		Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Facebook});
		setLoadingWhat("initiating Facebook login");
		setState(AuthStates.loading);
		logEvent("login", {method: "facebook"});
	}

	const handleApple = (optins: SignUpOptins) => {
		logger.info("Initiating Apple login...");
		setOptins(optins);
		settings.addSignUpOptIn(optins, new Date());
		// Save optin
		Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Apple});
		setLoadingWhat("initiating Apple login");
		setState(AuthStates.loading);
		logEvent("login", {method: "apple"});
	}

	const handleGoogle = (optins: SignUpOptins) => {
		logger.info("Initiating Google login...");
		setOptins(optins);
		settings.addSignUpOptIn(optins, new Date());
		// Save optin
		Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google});
		setLoadingWhat("initiating Google login");
		setState(AuthStates.loading);
		logEvent("login", {method: "google"});
	}

	const handleEmailSignUp = (optins: SignUpOptins) => {
		setOptins(optins);
		settings.addSignUpOptIn(optins, new Date());
		setState(AuthStates.signUp);
	}

	switch (state) {
		case AuthStates.loading:
			return <LoadingViewController what={loadingWhat} timeSpent={loadingTimer}/>;
		case AuthStates.signIn:
			return <SignInController onLogIn={() => {}} onForgotPassword={() => {}} onSignUp={() => {setState(AuthStates.teaser)}}/>
		case AuthStates.authenticated:
			if (!user) {
				setState(AuthStates.teaser);
			} else {
				return <SubscriptionController user={user} environment={environment}/>
			}
			break;
		case AuthStates.teaser:
			setScreenName('SignUpTeaser');
			return <SignUpTeaserController
				onFacebook={handleFacebook}
				onApple={handleApple}
				onGoogle={handleGoogle}
				onEmailSignUp={handleEmailSignUp}
				onEmailSignIn={() => {setState(AuthStates.signIn)}}
			/>
		case AuthStates.signUp:
			return <SignUpController
				onSignIn={() => {setState(AuthStates.signIn)}}
				environment={environment}
				onBack={() => setState(AuthStates.teaser)}
				optins={optins}
			/>
	}
	return <ErrorView eventId={null}/>; // unreachable
}

export default AuthController;
